import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit = 50, completeWords = true, ellipsis = '...') {
    if (completeWords && limit > value?.length) {
      limit = Math.max(value?.substr(0, limit)?.lastIndexOf(' '), 0);
    }
    return value.length > limit
      ? value.substr(0, limit) + ellipsis
      : value;
  }
}
