import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SubscriptionComponent} from '@components/subscription/subscription/subscription.component';
import {GearModule} from '@components/gear/gear.module';
import {SpotDayModule} from '@components/spot-day/spot-day.module';
import {RiderModule} from '@components/rider/rider.module';
import {PipesModule} from '@pipes/pipes.module';
import {ItemModule} from '@components/item/item.module';

@NgModule({
  declarations: [
    SubscriptionComponent
  ],
  imports: [
    CommonModule,
    GearModule,
    SpotDayModule,
    RiderModule,
    PipesModule,
    ItemModule
  ],
  exports: [
    SubscriptionComponent
  ]
})
export class SubscriptionModule {
}
