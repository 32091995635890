<ion-item *ngFor='let link of field.value; let i = index' lines='none' class="transparent">
  <ion-grid>
    <ion-row>
      <ion-col size='11'>
        <ion-card>
          <ion-card-content *ngIf='forms[i] as form' [formGroup]='form.group'>

            <ion-list>
              <ion-item class="transparent">
                <ion-label
                  class='input'>{{ form.fields.type | fieldLabel : FieldLabelType.TITLE }}</ion-label>

                <zef-enum-input
                  [(model)]='form.fields.type.value'
                  [enumType]='_linkType'
                  [enumName]='enumName'
                ></zef-enum-input>
              </ion-item>
              <zef-error-line [field]='form.fields.type'></zef-error-line>
              <ion-item class="transparent">
                <ion-label
                  class='input'>{{ form.fields.url | fieldLabel : FieldLabelType.TITLE }}</ion-label>
                <ion-input [formControlName]='form.fields.url.name'></ion-input>
              </ion-item>
              <zef-error-line [field]='form.fields.url'></zef-error-line>
              <ng-container *ngIf="_linkType === LinkType">
                <ion-item class="transparent">
                  <ion-label
                    class='input'>{{ form.fields.name | fieldLabel : FieldLabelType.TITLE }}</ion-label>
                  <ion-input [formControlName]='form.fields.name.name'></ion-input>
                </ion-item>
                <zef-error-line [field]='form.fields.name'></zef-error-line>
                <zef-multi-language-input
                  title='{{form.fields.description | fieldLabel : FieldLabelType.TITLE}}'
                  placeholder='{{form.fields.description | fieldLabel : FieldLabelType.PLACEHOLDER}}'
                  [(model)]='form.fields.description.value'
                ></zef-multi-language-input>
                <zef-error-line [field]='form.fields.description'></zef-error-line>
              </ng-container>
            </ion-list>

          </ion-card-content>
        </ion-card>
      </ion-col>
      <ion-col size='1' class='remove'>
        <ion-button (click)='removeElement(i)' fill='clear' color='dark'>
          <ion-icon name='trash' slot='icon-only'></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-item>
<ion-item class="transparent">
  <ion-button slot='end' color='light' (click)='addElement()' [disabled]='!canAdd'>
    <ion-icon name='add-circle-outline'></ion-icon>&nbsp;{{ "LIST.ADD" | translate: { entity: EntityType.links } }}
  </ion-button>
</ion-item>
<zef-error-line [field]='field'></zef-error-line>
