import {Entity, Id} from './entity';
import {TrickLevels} from './trick';
import {Language, MultiLanguageText} from './multi-language-text';
import {CreatedUser} from './schema-model';
import { NamedEntity, namedEntityFields } from './entity-with-description';
import {EntityType} from './entity-type';
import {EntityLink} from './entity-link';

export interface RiderGear {
  itemId: Id;
  acquiredAt?: Date;
  leftAt?: Date;
  notes?: MultiLanguageText;
  // TODO[model]: public review of the gear (rate + comment)
}

export type RiderQuiver = RiderGear[];

export enum EmailNotificationType {
  forecast = "forecast",
  others = "others"
}

export type TypedConfig = { lastRun: Date };

export interface Rider extends Entity, CreatedUser, NamedEntity {
  weightKg?: number;
  quiver?: RiderQuiver;
  tricks?: TrickLevels;
  language: Language;
  spokenLanguages: string[];
  emailNotifications?: { [type in EmailNotificationType]: TypedConfig };
}

export const getRiderEntity = (rider: Pick<Rider, '_id' | 'shortName'>): EntityLink => ({
  type: EntityType.riders,
  id: rider._id,
  shortName: rider.shortName
});

export const riderFields: (keyof Rider)[] = [
  ...namedEntityFields,
  'weightKg',
  'quiver',
  'tricks',
  'language',
  'spokenLanguages',
  'emailNotifications'
];
