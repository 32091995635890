import {DayMetric} from '../model/metrics';
import {NamedEntity} from '@model/entity-with-description';
import {ProductSubType, ProductType} from '@model/gear/product';
import {Brand} from '@model/gear/brand';
import {Variant} from '@model/gear/gear-item';
import {Id} from '@model/entity';
import {Photo} from '@model/photo';
import {Rider, RiderGear} from '@model/rider';
import {Period} from '@core/date-utils';
import {SpotDay} from '@model/spot-day';
import {Session} from '@model/session';
import {Spot} from '@model/spot';
import {SpotLaunch} from '@model/spot-launch';
import {WithLanguageText} from '@model/schema-model';
import {IdentifiedGearItem} from '../../../../src/app/components/gear/gear-item-item/gear-item-item.component';
import {nullOrUndefined} from '@core/utils';
import {FinFamily} from '@model/gear/variants';
import {Characteristic, GearModel} from '@model/gear/gear-model';

export type DayMetrics = DayMetric[];

export interface BrandList {
  list: NamedEntity[]; // TODO could add metrics such as how many models
}

export type GearModelListItem =
  NamedEntity
  & Pick<GearModel<unknown>, 'year' | 'type' | 'subType' | 'brandId'>
  & {
  brandName: string,
  score?: number
};

export interface DetailedGearItem extends Omit<NamedEntity, '_id'> {
  itemId: Id;
  type: ProductType;
  subType: ProductSubType,
  characteristic: Characteristic;
  compatibleFinFamilies?: FinFamily[];
  modelId: Id;
  variant: Variant;
  brandName: string;
  year: number;
  avatar?: string;
}

export interface GearModelAndBrand<T = unknown, M extends GearModel<T> = GearModel<T>> {
  model: M;
  brand: Brand;
  photos: Photo<unknown>[];
}

export interface RiderList {
  list: NamedEntity[];
}

export type RiderAndQuiverItem = DetailedGearItem & RiderGear;

export interface RiderAndQuiver extends Omit<Rider, 'quiver'> {
  quiver: RiderAndQuiverItem[];
}

export interface RiderDayMetricsInput {
  riderId: Id;
  period: Period;
}

export type SessionWithQuiver = Omit<Session, 'quiver'> & {
  quiver?: IdentifiedGearItem[];
}

export interface SpotDaySessions {
  spotDay: SpotDay;
  sessions: SessionWithQuiver[];
}

export type SpotListItem =
  NamedEntity
  & Pick<Spot, 'location' | 'abstract' | 'countryCode'>
  & { normalizedName: string }

export const compareSpotListItems = (spotA: SpotListItem, spotB: SpotListItem): number => {
  // Let's compare the names, but normalized to remove pronouns
  return spotA.normalizedName.localeCompare(spotB.normalizedName);
};


export interface SpotList {
  list: SpotListItem[];
}

export interface SpotAndLaunches extends Spot {
  photos: Photo<unknown>[];
  launches: SpotLaunch[];
  launchesPhotos: Photo<unknown>[];
}

export interface InputProperty<T> {
  value: T,
  details?: WithLanguageText[]
}

export interface SpotDayAndSpot {
  spot: Spot;
  spotDay?: SpotDay;
}

export interface SpotDayMetricsInput {
  spotId: Id;
  userTZ: string;
  timeZone: string;
  period: Period;
}

export interface RiderDaySessions {
  riderId: Id;
  day: string;
}

// Function to always generate new variable and avoid collisions
export const newEmptyProperty = <T>(defaultValue: T | null = null): { value: T | null, details: null } => ({
  value: defaultValue,
  details: null
});

export const getInputValue = <T>(value: InputProperty<T>): InputProperty<T> | undefined => nullOrUndefined(value?.value) ? undefined : value as InputProperty<T> | undefined;

