<ng-template #template>
  <ion-chip *ngIf='isIdentifiedItem; else unidentified' outline
            [routerLink]='path?.link' router-direction='forward'
            [disabled]='!clickable'
            inViewport [zefMarkAsRead]='entity'>
    <ion-avatar>
      <zef-entity-avatar [entity]='itemAsIdentifiedItem'></zef-entity-avatar>
    </ion-avatar>
    <ion-label><span>{{ itemAsIdentifiedItem | gearModelItemVariation }}</span><ng-container *ngIf="showDetails">&nbsp;{{itemAsIdentifiedItem.brandName}}
      {{itemAsIdentifiedItem.name}} {{itemAsIdentifiedItem.year}}</ng-container>
    </ion-label>
  </ion-chip>
  <ng-template #unidentified>
    <ion-chip disabled='{{true}}' outline>
      <ion-avatar>
        <zef-text-avatar text='x' color='white' textColor='white'></zef-text-avatar>
      </ion-avatar>
      <ion-label><span>{{ _gearItem | gearModelItemVariation }}</span>
      </ion-label>
    </ion-chip>
  </ng-template>
</ng-template>
