<ion-header>
  <ion-toolbar>
    <ion-title>
      {{ formField | fieldLabel : FieldLabelType.TITLE  }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list *ngIf='allRiders; else loadingList'>
    <ion-item *ngFor='let rider of allRiders' [class.unselected]='!rider.selected' (click)='toggle(rider)'>
      <ion-icon name='{{ rider.selected ? "checkbox-outline" : "square-outline"  }}'></ion-icon>
      <ion-avatar>
        <zef-entity-avatar [entity]='rider'></zef-entity-avatar>
      </ion-avatar>
      <ion-label>
        {{ rider.name }}
      </ion-label>
    </ion-item>
  </ion-list>
  <ng-template #loadingList>
    <zef-loading-list [count]='10'></zef-loading-list>
  </ng-template>
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-buttons slot='start' class="secondary">
      <ion-button (click)='close()'>
        {{ 'SHARED.CANCEL' | translate }}
      </ion-button>
    </ion-buttons>
    <ion-buttons slot='end'>
      <ion-button (click)='submit()' color='primary'>
        {{ 'SHARED.OK' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
