import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {PipesModule} from '@pipes/pipes.module';
import {ErrorLineComponent} from '@components/input/error-line/error-line.component';

@NgModule({
  declarations: [ErrorLineComponent],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule
  ],
  exports: [ErrorLineComponent]
})
export class ErrorLineModule {
}
