<form [formGroup]='form.group'>
    <ion-item>
        <ion-label
          class='input'>{{ score5Field | fieldLabel: FieldLabelType.TITLE }}</ion-label>
        <ng-template [ngIf]='score5Field.isValidNumber()'>
            <ion-text [color]="score5Field.isValidNumber() ? 'primary' : 'medium'">{{score5Field.value}} / 5</ion-text>
            <ion-icon name='close-circle-outline' (click)='score5Field.resetValue()'></ion-icon>
        </ng-template>
        <ionic-rating-component [rating]='score5Field.value'
                                halfStar='true'
                                formControlName='{{score5Field.name}}'
        ></ionic-rating-component>
    </ion-item>
    <zef-error-line [field]='score5Field'></zef-error-line>
</form>
