import Selector = Mongo.Selector;
import {Method} from '@api/method';
import {ListOfDenyIds, ListOfIds, ListOfShortNames} from '@api/queries';
import {first, map, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {Id} from '@model/entity';
import {notUndefined, unique} from '@core/utils';

export class MeteorCache<T extends { _id: Id, shortName: string }> {
  private cache: Mongo.Collection<T>;

  constructor(private queryEntities: Method<ListOfIds | ListOfShortNames | ListOfDenyIds, T[]>) {
    this.cache = new Mongo.Collection<T>(null);
  }

  private getEntities(input: ListOfIds | ListOfShortNames): Observable<Array<T>> {
    const entity_id: keyof T = '_id';
    const entity_shortName: keyof T = 'shortName';

    const isListOfIds = !!(input as ListOfIds).ids;

    const list = (isListOfIds ? (input as ListOfIds).ids : (input as ListOfShortNames).shortNames)
      .filter(notUndefined)
      .filter(unique)

    const selector = isListOfIds
      ? {[entity_id]: {$in: list}}
      : {[entity_shortName]: {$in: list}};

    const entitiesFromCache = this.cache.find(selector as Selector<T>).fetch();
    const keysFromCache: ListOfIds | ListOfShortNames = isListOfIds
      ? {ids: entitiesFromCache.map(entity => entity._id)}
      : {shortNames: entitiesFromCache.map(entity => entity.shortName)};

    const missingKeys: ListOfIds | ListOfShortNames = isListOfIds
      ? {ids: list.filter(key => !(keysFromCache as ListOfIds).ids.includes(key))}
      : {shortNames: list.filter(key => !(keysFromCache as ListOfShortNames).shortNames.includes(key))};

    return this.loadMissingEntities(missingKeys, entitiesFromCache).pipe(first()) /*
      .pipe(
      map(() => {
        const entities = this.cache.find(selector as Selector<T>).fetch();
        return entities;
      })
    );*/
  }

  private loadMissingEntities(missingOrDenyKeys: ListOfIds | ListOfShortNames | ListOfDenyIds, entitiesFromCache: Array<T>): Observable<Array<T>> {
    return (
      (missingOrDenyKeys &&
        (
          (missingOrDenyKeys as ListOfIds).ids?.length > 0
          || (missingOrDenyKeys as ListOfShortNames).shortNames?.length > 0
          || (missingOrDenyKeys as ListOfDenyIds).denyIds // Empty denyIds is also accepted
        )
      )
        ? this.queryEntities.call$(missingOrDenyKeys).pipe(
          first(),
          // Insert all loaded entities into cache
          tap(entities => {
            entities?.forEach(entity => {
              // @ts-ignore
              const selector: Selector<T> = {_id: entity._id};
              return this.cache.upsert(selector, {$set: entity});
            });
          }),
          // Now return the preexisting entities + the loaded ones
          map(loadedEntities => ([...entitiesFromCache, ...(loadedEntities ?? [])]))
        )
        : of(entitiesFromCache)
    ).pipe(
      map(entities => entities.sort((a, b) => a.shortName.localeCompare(b.shortName)))
    );
  }

  getEntitiesByIds$(ids: Id[]): Observable<T[]> {
    return this.getEntities({ids});
  }

  getEntityById$(_id: Id): Observable<T> {
    return this.getEntitiesByIds$([_id]).pipe(map(entities => entities[0]));
  }

  getEntitiesByShortnames$(shortNames: string[]): Observable<T[]> {
    return this.getEntities({shortNames});
  }

  getEntityByShortName$(shortName: string): Observable<T> {
    return this.getEntities({shortNames: [shortName]}).pipe(map(entities => entities[0]));
  }

  getAllEntities$(): Observable<T[]> {
    const entitiesFromCache = this.cache.find().fetch();
    const missingKeys: ListOfDenyIds = {denyIds: entitiesFromCache.map(entity => entity._id)};
    return this.loadMissingEntities(missingKeys, entitiesFromCache);
  }

  removeEntityById(_id: Id): void {
    const idKey: keyof T = '_id';
    // @ts-ignore
    const selector: Selector<T> = {[idKey]: _id};
    this.cache.remove(selector);
  }
}
