import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { EntityModule } from '@components/entity/entity.module';
import { SpotLaunchItemComponent } from '@components/spot-launch/spot-launch-item/spot-launch-item.component';
import { SpotLaunchMapComponent } from '@components/spot-launch/spot-launch-map/spot-launch-map.component';
import { PipesModule } from '@pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { FollowModule } from '@components/follow/follow.module';
import { NgIconsModule } from '@ng-icons/core';
import { MapModule } from '@components/map/map.module';
import { SpotLaunchComponent } from '@components/spot-launch/spot-launch/spot-launch.component';
import { PhotosModule } from '@components/photos/photos/photos.module';
import {
  SpotLaunchPropertyIconComponent
} from '@components/spot-launch/spot-launch-property-icon/spot-launch-property-icon.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LinksModule } from '@components/links/links.module';
import { RiderModule } from '@components/rider/rider.module';
import { EntityUpdatedModule } from '@components/entity-updated/entity-updated.module';

@NgModule({
  declarations: [SpotLaunchComponent, SpotLaunchItemComponent, SpotLaunchMapComponent, SpotLaunchPropertyIconComponent],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    EntityModule,
    PipesModule,
    TranslateModule,
    FollowModule,
    NgIconsModule,
    MapModule,
    PhotosModule,
    MatTooltipModule,
    LinksModule,
    RiderModule,
    EntityUpdatedModule
  ],
  exports: [SpotLaunchComponent, SpotLaunchItemComponent, SpotLaunchMapComponent, SpotLaunchPropertyIconComponent],
  entryComponents: []
})
export class SpotLaunchModule {
}
