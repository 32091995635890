import {QuiverItem} from './gear/quiver';
import {BoardType, ProductType, PropulsionType} from './gear/product';
import {keysToDict} from '../utils';
import {FinFamily} from './gear/variants';

export enum Activity {
  windsurf = 'windsurf', // Propulsion.sail FinFamily.fins
  windfoil = 'windfoil', // Propulsion.sail FinFamily.foil
  kitesurf = 'kitesurf', // Propulsion.kite FinFamily.fins || FinFamily.twintip
  kitefoil = 'kitefoil', // Propulsion.kite FinFamily.foil
  wingfoil = 'wingfoil', // Propulsion.wing FinFamily.foil
  surf = 'surf', // Propulsion.kite FinFamily.fins
  surffoil = 'surffoil', // Propulsion.kite FinFamily.foil
  sup = 'sup' // Propulsion.paddle FinFamily.fins
}

// TODO For the moment store it as side properties, but Activity might become an Entity on its own
export interface ActivitySpecs {
  propulsion: PropulsionType;
  fins: FinFamily[];
  boards: BoardType[];
}

export const ActivityConf: { [activity in Activity]?: ActivitySpecs } = {
  [Activity.windsurf]: {
    propulsion: PropulsionType.windsurfSail,
    fins: [FinFamily.fins],
    boards: [BoardType.windsurfBoard]
  },
  [Activity.kitesurf]: {
    propulsion: PropulsionType.kite,
    fins: [FinFamily.fins, FinFamily.twintip],
    boards: [BoardType.kiteBoard]
  },
  [Activity.windfoil]: {
    propulsion: PropulsionType.windsurfSail,
    fins: [FinFamily.foil],
    boards: [BoardType.windsurfBoard]
  },
  [Activity.kitefoil]: {
    propulsion: PropulsionType.kite,
    fins: [FinFamily.foil],
    boards: [BoardType.kiteBoard]
  },
  [Activity.surf]: {
    propulsion: PropulsionType.kite,
    fins: [FinFamily.fins],
    boards: [BoardType.surfBoard]
  },
  [Activity.surffoil]: {
    propulsion: PropulsionType.kite,
    fins: [FinFamily.foil],
    boards: [BoardType.surfBoard]
  },
  // FIXME support paddle
  /*
  [Activity.sup]: {
    propulsion: PropulsionType.,
    fins: [FinFamily.fins],
    boards: []
  },

   */
  [Activity.wingfoil]: {
    propulsion: PropulsionType.wing,
    fins: [FinFamily.foil],
    boards: []
  }
};

export interface ActivityProbability {
  activity: Activity;
  probability: number;
}

export const activities = [
  Activity.windsurf,
  Activity.windfoil,
  Activity.kitesurf,
  Activity.kitefoil,
  Activity.wingfoil,
  Activity.surf,
  Activity.surffoil,
  Activity.sup
];

type ActivitiesProbabilities = { [activity in Activity]?: number };

export const getQuiverActivityMatches = (quiver: QuiverItem[]): ActivitiesProbabilities => {
  // Only filter if there is at least one quiver item
  if (!quiver || quiver.length === 0) {
    return keysToDict(activities, () => 50) as ActivitiesProbabilities;
  }

  const getMatches = <T extends QuiverItem>(t: T, match: (x: ActivitySpecs | undefined, y: T) => boolean): ActivitiesProbabilities => {
    const boardActivities = Object.keys(ActivityConf).filter(activity => {
      // Appropriate board and if there are fins, appropriate fins
      const conf = ActivityConf[activity as Activity];
      return match(conf, t);
    });

    // Each matching activity is set a match percentage
    return keysToDict(boardActivities, () => 100 / boardActivities.length);
  };

  const boards: (QuiverItem & { subType: BoardType })[] = quiver.filter(q => q.type === ProductType.board).map(q => ({
    ...q,
    subType: q.subType as BoardType
  }));

  // Select the list of activities that match each board
  const boardsActivities = boards.map(board => getMatches(board, (conf: ActivitySpecs | undefined, board: QuiverItem & { subType: BoardType }) =>
    (conf?.boards?.includes(board.subType) && (!board.finFamily || conf?.fins?.includes(board.finFamily))) as boolean));

  const propulsions: (QuiverItem & { subType: PropulsionType })[] = quiver.filter(q => q.type === ProductType.propulsion).map(q => ({
    ...q,
    subType: q.subType as PropulsionType
  }));

  // Select the list of activities that match each propulsion
  const propulsionsActivities = propulsions.map(propulsion => getMatches(propulsion, (conf: ActivitySpecs | undefined, propulsion: QuiverItem & { subType: PropulsionType }) =>
    (conf?.propulsion === propulsion.subType)));

  // By default, when there is a quiver, all activities are not selected
  const result = keysToDict(activities, () => 0) as ActivitiesProbabilities;

  // Now, for all matched activities per board or propulsion, take the "max match"
  return [...boardsActivities, ...propulsionsActivities].reduce((accumulator: ActivitiesProbabilities, boardActivities: ActivitiesProbabilities) => {
    Object.keys(boardActivities).forEach((key) => {
      const activity = key as Activity;
      accumulator[activity] = Math.max(accumulator[activity] ?? 0, boardActivities[activity] ?? 0);
    });
    return accumulator;
  }, result);
};
