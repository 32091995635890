import {Component, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {AccessControl, TabPageInfo} from '@pages/page-info';
import {IAuthService} from '@services/i-auth.service';
import {UntilDestroy} from "@ngneat/until-destroy";
import {Queries} from "@api/queries";
import {combineLatest, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {MeteorNotificationService} from "@services/meteor/meteor-notification.service";
import {isReadBy} from "@model/events";

@UntilDestroy()
@Component({
  selector: 'zef-tab-badge',
  templateUrl: './tab-badge.component.html'
})
export class TabBadgeComponent implements OnInit {
  AccessControl = AccessControl;
  @Input() tabPageInfo: TabPageInfo;
  @Input() showBadge = false;
  @ViewChild('template', { static: true }) template;

  unreadNotificationCount$: Observable<number>;

  constructor(
    public auth: IAuthService,
    private viewContainerRef: ViewContainerRef,
    private queries: Queries,
    private notificationsService: MeteorNotificationService
    ) {
  }

  ngOnInit(): void {
    this.unreadNotificationCount$ = combineLatest([
      this.notificationsService.unfinishedSessions$.pipe(map(sessions => sessions?.length)),
      this.notificationsService.notifications$.pipe(
        map(notifications => notifications?.filter(event => !isReadBy(event, this.auth.userId)).length))
    ]).pipe(map(counts => counts.reduce((total: number, val: number) => (total += val)), 0));

    this.viewContainerRef.createEmbeddedView(this.template);
  }

  get isLocked(): boolean {
    return !this.auth.isLoggedIn && this.tabPageInfo?.accessControl === AccessControl.LOGGED_ONLY;
  }
}
