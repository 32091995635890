<ion-header>
    <ion-toolbar>
        <ion-title>
            {{ "TRACK_LOGS.ADD.TITLE" | translate }}
        </ion-title>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-card *ngIf='file; else dropzone'>
        <ion-card-header>
            <ion-card-title>{{ file.fileName}}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
            <ion-list>
                <ion-item>
                    <ion-label class='input'>{{ titles.from }}</ion-label>
                    <ion-text>
                        <ng-container
                                *ngIf="file.data.startDate; else noValue">{{file.data.startDate | formatTime : FormatType.DATE_AND_TIME }}</ng-container>
                    </ion-text>
                </ion-item>
                <ion-item>
                    <ion-label class='input'>{{ titles.to }}</ion-label>
                    <ion-text>
                        <ng-container
                                *ngIf="file.data.endDate; else noValue">{{file.data.endDate | formatTime : FormatType.DATE_AND_TIME }}</ng-container>
                    </ion-text>
                </ion-item>
                <ion-item>
                    <ion-label class='input'>{{ titles.distanceKm }}</ion-label>
                    <ion-text>{{file.data.distanceKm | number : '1.0-2' }}
                        {{ 'UNIT.KILOMETER' | translate : {count: 0} }}</ion-text>
                </ion-item>
                <ion-item>
                    <ion-label class='input'>{{ titles.avgSpeedKnot }}</ion-label>
                    <ion-text>
                        <ng-container
                                *ngIf="file.data.avgSpeedKmPerH; else noValue">{{file.data.avgSpeedKmPerH | kmPerHToKnots | number : '1.0-2' }}
                            {{ 'UNIT.KNOT' | translate : {count: 0} }}</ng-container>
                    </ion-text>
                </ion-item>
                <ion-item>
                    <ion-label class='input'>{{ titles.maxSpeedKnot }}</ion-label>
                    <ion-text>
                        <ng-container
                                *ngIf="file.data.maxSpeedKmPerH; else noValue">{{file.data.maxSpeedKmPerH | kmPerHToKnots | number : '1.0-2' }}
                            {{ 'UNIT.KNOT' | translate : {count: 0} }}</ng-container>
                    </ion-text>
                </ion-item>
                <ng-template #noValue>
                    <div class="unknown">{{ 'SHARED.UNKNOWN' | translate }}</div>
                </ng-template>
            </ion-list>
            <zef-track-log-map
                    [gpxData]='file.content'
                    [options]='{ heightPx: 300 }'
            >
            </zef-track-log-map>
        </ion-card-content>
    </ion-card>
    <ng-template #dropzone>
        <ngx-dropzone accept='{{accept}}' (change)='onFileDropped($event)' [multiple]='false'>
            <ngx-dropzone-label>
                <ion-icon name='navigate-circle-outline'></ion-icon>
                {{ (isMobile ? 'TRACK_LOGS.ADD.ADD' : 'TRACK_LOGS.ADD.ADD_OR_DROP') | translate }}
            </ngx-dropzone-label>
        </ngx-dropzone>
        <ion-item>
            <ion-input type="text" [(ngModel)]="url" [placeholder]="'TRACK_LOGS.ADD.URL' | translate"></ion-input>
            <ion-button (click)="downloadFromUrl()" [disabled]="(url?.length ?? 0) === 0" item-right
                        [title]="'TRACK_LOGS.ADD.DOWNLOAD' | translate">
                <ng-icon name="ion-cloud-download-outline"></ng-icon>
            </ion-button>
        </ion-item>
    </ng-template>
</ion-content>
<ion-footer>
    <ion-toolbar>
        <ion-buttons slot='start' class="secondary">
            <ion-button (click)='close()'>
                {{ 'SHARED.CANCEL' | translate }}
            </ion-button>
        </ion-buttons>
        <ion-buttons slot='end'>
            <ion-button (click)='submit()' [disabled]='!file' color='primary'>
                {{ 'TRACK_LOGS.ADD.OK' | translate }}
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
