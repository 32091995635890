import {EntityWithDescription} from './entity-with-description';
import {Point, Polygon} from 'geojson';
import {fromInput, MultiLanguageText, toInput} from './multi-language-text';
import {InputProperty} from '@api/queries/queries.model';

// GeoJSON format: [longitude, latitude]
export type Location = Point | Polygon;

export interface PlaceProperty<T> {
  value: T;
  details?: MultiLanguageText;
}

export interface Properties {
  [type: string]: PlaceProperty<unknown> | undefined;
}

export interface Place extends EntityWithDescription {
  location: Location;
  countryCode: string;
  timezone: string;
  properties: Properties;
}

export type BooleanProperty = PlaceProperty<boolean>;

export interface RawLatLng {
  lat: number;
  lng: number;
}

export const fromInputProperty = <T>(input: InputProperty<T> | undefined): PlaceProperty<T> | undefined => input ? ({
  value: input.value,
  details: fromInput(input.details)
}) : undefined;

export const toInputProperty = <T>(property: PlaceProperty<T> | undefined): InputProperty<T> | undefined => property ? ({
  value: property.value,
  details: toInput(property.details)
}) : undefined;
