import {Observable} from 'rxjs';
import {MeteorObservable} from 'meteor-rxjs';
import SimpleSchema from "simpl-schema";
import {Publication} from './publication';
import {AllowedTo, forbiddenToAnonymous} from './access-rules';

export class Method<IN, OUT> {
  public name?: string;
  public type?: string;

  // TODO see how to make it callable?
  call$(args: IN): Observable<OUT> {
    return MeteorObservable.call(this.name!, args);
  }
}

export class LocalMethod<IN, OUT> {
  constructor(protected schema: SimpleSchema | null, protected allowedTo: AllowedTo, private run: (args: IN) => OUT) {
  }

  call(args: IN): OUT {
    // Check rights
    if (this.allowedTo !== AllowedTo.ANONYMOUS) {
      forbiddenToAnonymous();
    }

    // Validate data
    this.schema?.validate(args);

    return this.run(args);
  }

}

export interface ObjectWithMethods {
  [methodName: string]: Method<any, any>;
}

export const affectNamesAutomatically = (object: ObjectWithMethods) => {
  // Automatically affect names to methods
  for (const methodKey of Object.keys(object)) {
    if (object[methodKey] instanceof Method || object[methodKey] instanceof Publication) {
      object[methodKey].name = methodKey;
    }
  }
};
