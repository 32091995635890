import {
  gearBasePageInfo,
  helpBasePageInfo,
  newsBasePageInfo,
  notificationsBasePageInfo,
  registerBasePageInfo,
  ridersBasePageInfo,
  sessionsBasePageInfo,
  settingsBasePageInfo,
  spotsBasePageInfo, tabsBasePageInfo,
  wallBasePageInfo
} from "@core/routing/pages-list";
import {ZefRoute} from "@core/routing/zef-route";

export const tabsRoutes: ZefRoute[] = [
  {
    path: '',
    key: tabsBasePageInfo.key
  },
  {
    path: wallBasePageInfo.path,
    key: wallBasePageInfo.key
  },
  /*
    {
      path: forecastBasePageInfo.path,
      key:forecastBasePageInfo.key
    },

   */
  {
    path: notificationsBasePageInfo.path,
    key: notificationsBasePageInfo.key
  },
  {
    path: settingsBasePageInfo.path,
    key: settingsBasePageInfo.key
  },
  {
    path: spotsBasePageInfo.path,
    key: spotsBasePageInfo.key
  },
  {
    path: ridersBasePageInfo.path,
    key: ridersBasePageInfo.key
  },
  {
    path: gearBasePageInfo.path,
    key: gearBasePageInfo.key
  },
  {
    path: helpBasePageInfo.path,
    key: helpBasePageInfo.key
  },
  {
    path: newsBasePageInfo.path,
    key: newsBasePageInfo.key
  },
  {
    path: registerBasePageInfo.path,
    key: registerBasePageInfo.key
  },
  {
    path: sessionsBasePageInfo.path,
    key: sessionsBasePageInfo.key
  },
]

