<zef-property [field]='field' [form]='form'>
  <ion-select
    placeholder='{{ capitalize("ENUM.UNDEFINED" | translate) }}'
    [(ngModel)]='field.value.value'
    [ngModelOptions]='{standalone: true}'
  >
    <ion-select-option [value]='null' class='unknown'>
      {{ capitalize("ENUM.UNDEFINED" | translate) }}
    </ion-select-option>
    <ion-select-option *ngFor='let key of [true, false]'
                       [value]='key'>
      {{ capitalize("ENUM.BOOLEAN.VALUES." + key | translate : { name: field.name }) }}
    </ion-select-option>
  </ion-select>
</zef-property>
