import {Id, ToPersist} from '@model/entity';
import {SpotDay} from '@model/spot-day';
import {SpotDays} from '../collections/spot-day.collection';
import {Booking, EventType, getBookingEntity} from '../../core/model/model';
import {Bookings} from '../collections/booking.collection';
import {ZefCollection} from '../collections/zef-collection';
import {EventsQueries} from './event.queries';

export class BookingsQueries {
  static persist = (bookingToPersist: ToPersist<Booking>, spotDay: SpotDay | null = null): string => {
    if (spotDay === null) {
      spotDay = SpotDays.findOne(bookingToPersist.spotDayId);
    }

    const booking = ZefCollection.setCreatedDate(bookingToPersist);
    const _id = Bookings.insertSync(booking);

    EventsQueries.persistSpotDayEvent(
      getBookingEntity({ ...booking, _id }),
      EventType.created,
      booking.createdAt,
      spotDay,
      booking.riderId
    );

    return _id;
  };

  static selectBySpotId(spotDayId: Id): { [p: string]: string } {
    const local_spotDayId: keyof Booking = 'spotDayId';
    return { [local_spotDayId]: spotDayId };
  }

  static getBooking(_id: Id): Booking {
    return Bookings.findOne({ _id });
  }
}
