import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { EntityType } from '@model/entity-type';
import { ErrorTypes } from '@model/schemas';
import { FieldLabelPipe } from '@pipes/fields/field-label.pipe';
import { ErrorPipe } from '@pipes/fields/error.pipe';
import { NewLinesBrPipe } from '@pipes/new-lines-br.pipe';

type ErrorDetail = { name: string, type: string, message: string };

type TextErrorParams = { subTitle: string; errorMsg: string };

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  constructor(
    public alertController: AlertController,
    private toastController: ToastController,
    private translate: TranslateService,
    private fieldLabelPipe: FieldLabelPipe,
    private errorPipe: ErrorPipe,
    public newLinesBr: NewLinesBrPipe
  ) {
  }

  async textError(content: TextErrorParams) {
    const alert = await this.alertController.create({
      header: this.translate.instant('SHARED.ERROR'),
      subHeader: content.subTitle,
      message: content.errorMsg,
      buttons: [this.translate.instant('SHARED.OK')]
    });

    await alert.present();
  }

  async meteorError(error: Meteor.Error) {
    console.error(error);

    let content: TextErrorParams;

    switch (error.message) {
      case 'Incorrect password [403]':
        content = {
          subTitle: this.translate.instant('FORMS.ERRORS.INVALID_PASSWORD'),
          errorMsg: ''
        };
        break;
      case 'validation-error':
      case '[validation-error]':
        // @ts-ignore
        content = this.handleValidationError(error.details as ErrorDetail[]);
        break;
      default:
        if (error.details === undefined) {
          content = {
            subTitle: 'Meteor error',
            errorMsg: JSON.stringify(error)
          };
        } else {
          content = {
            subTitle: '',
            errorMsg: error.message
          };
        }
        break;
    }
    await this.textError(content);
  }

  private handleValidationError(details: ErrorDetail[]): TextErrorParams {
    const subTitle = this.translate.instant('FORMS.ERRORS.validation-error');

    const messages = [];

    // field => index => type => list of subFields
    const groupedDetails: {
      [field: string]: { [index: number]: { [type: string]: string[] } }
    } = details.reduce((accumulator, detail) => {
        // We expect a list of errors on sub-objects like variants or links
        // ex: variants.0.volumeL
        const [field, index, subField] = detail.name.split('.');
        accumulator[field] ??= {};
        accumulator[field][index] ??= {};
        accumulator[field][index][detail.type] ??= [];
        accumulator[field][index][detail.type].push(subField);
        return accumulator;
      },
      {});
    const separator = '<br/> - ';

    for (const fieldName in groupedDetails) {

      let entityType: EntityType;
      switch (fieldName) {
        case 'variants':
          entityType = EntityType.gearVariants;
          break;
        case 'links':
          entityType = EntityType.links;
          break;
        default:
      }

      for (const index in groupedDetails[fieldName]) {
        for (const type in groupedDetails[fieldName][index]) {
          const subFields = groupedDetails[fieldName][index][type];
          const displayIndex = (Number(index)).valueOf() + 1;
          if (type === ErrorTypes.atLeastOne) {

            const type = 'atLeastOne';

            const fields = subFields.map(subField => this.fieldLabelPipe.getFieldTitle(subField, entityType));
            // "At least one of the fields is mandatory"

            const subMessage = this.translate.instant(`FORMS.ERRORS.${type}`);
            // "At least one of the fields is mandatory for variant #3"
            const fullMessage = this.translate.instant(`FORMS.ERRORS.forWhat`, {
              subMessage,
              field: fieldName,
              index: displayIndex
            });

            // "At least one of the fields is mandatory for variant #3 - volumeL - weightKg - priceEur"
            messages.push([fullMessage, ...fields].join(separator));
          } else {
            messages.push(...subFields.map(subField => {
              const subMessage = this.errorPipe.transform({
                code: type,
                fieldName: subField,
                schemaDefinition: null // FIXME
              }, entityType, this.fieldLabelPipe.getFieldTitle(subField, entityType));
              return this.translate.instant(`FORMS.ERRORS.forWhat`, {
                subMessage,
                field: fieldName,
                index: displayIndex
              });
            }));
          }
        }
      }
    }

    const errorMsg = messages.join('<br/>');
    return { subTitle, errorMsg };
  }

  async toast(message: string, duration: number = 2000) {
    const toast = await this.toastController.create({
      message: message.replace(/\n/g, '<br/>'),
      duration,
      position: 'top'
    });
    await toast.present();
  }

  async alert(header: string, message: string, okBtn = this.translate.instant('SHARED.OK')) {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      cssClass: 'alert',
      header,
      message: this.newLinesBr.transform(message),
      buttons: [
        {
          text: okBtn,
          role: 'ok',
          id: 'confirm-button',
          handler: () => {
          }
        }
      ]
    });

    await alert.present();
  }

  async confirmDelete(entityType: EntityType) {
    const roles = {
      OK: 'ok',
      CANCEL: 'cancel'
    };

    const alert = await this.alertController.create({
      header: this.translate.instant('LIST.REMOVE.HEADER', { entity: entityType }),
      message: this.translate.instant('LIST.REMOVE.BODY', { entity: entityType }),
      buttons: [
        {
          text: this.translate.instant('SHARED.CANCEL'),
          role: roles.CANCEL,
          id: 'cancel-button',
          cssClass: 'secondary'
        }, {
          text: this.translate.instant('LIST.REMOVE.OK', { entity: entityType }),
          role: roles.OK,
          id: 'confirm-button'
        }
      ]
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    return role === roles.OK;
  }
}
