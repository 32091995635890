import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ColorGenerator} from './color-generator';
import {TextAvatarComponent} from '@components/text-avatar/text-avatar.component';

@NgModule({
  imports: [CommonModule],
  declarations: [TextAvatarComponent],
  exports: [TextAvatarComponent],
  providers: [ColorGenerator]
})
export class TextAvatarModule {}
