import {Queries, RiderItem} from '@api/queries';
import {Id} from '@model/entity';
import {Observable} from 'rxjs';
import {ICachedRidersService} from '@services/i-cached-riders.service';
import {Injectable} from '@angular/core';
import {MeteorCache} from '@services/meteor/meteor.cache';
import {first} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MeteorCachedRidersService extends ICachedRidersService {
  private meteorCache: MeteorCache<RiderItem>;

  constructor(private queries: Queries) {
    super();
    this.meteorCache = new MeteorCache<RiderItem>(this.queries.getRiderItems);
  }

  getEntitiesByIds$(ids: Id[]): Observable<RiderItem[]> {
    return this.meteorCache.getEntitiesByIds$(ids);
  }

  getEntityById$(_id: Id): Observable<RiderItem> {
    return this.meteorCache.getEntityById$(_id).pipe(first());
  }

  getEntityByShortName$(shortName: string): Observable<RiderItem> {
    return this.meteorCache.getEntityByShortName$(shortName);
  }

  getAllEntities$(): Observable<RiderItem[]> {
    return this.meteorCache.getAllEntities$();
  }

  removeEntityById(_id: Id): void {
    return this.meteorCache.removeEntityById(_id);
  }
}

