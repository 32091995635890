<ng-template #template>
  <ion-avatar *ngIf='isMePage() && auth.loggedRider$ | async as rider ; else standard'>
    <zef-entity-avatar [entity]='rider'></zef-entity-avatar>
  </ion-avatar>
  <ng-template #standard>
    <ng-icon slot='{{slot}}' [name]='tabPageInfo.icon' size='24px'>
    </ng-icon>
  </ng-template>
</ng-template>

