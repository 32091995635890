import {schemas} from '@model/schemas';
import {NewPassword} from '@model/schema-model';
import {Id} from '@model/entity';
import {Event} from '@model/events';
import {Events} from '@api/collections/event.collection';
import {DistantCommands} from '@api/commands';
import {LocalMethod} from '@api/method';
import {AllowedTo} from '@api/access-rules';

export class Commands extends DistantCommands {

  updatePassword = new LocalMethod(schemas.NewPassword, AllowedTo.AUTHENTICATED,
    ({ oldPassword, password }: NewPassword) => {
      Accounts.changePassword(oldPassword, password);
    });

  markEventAs = new LocalMethod(
    null,
    AllowedTo.AUTHENTICATED,
    ({ eventId, read }: { eventId: Id, read: boolean }): number => {
      const userId = Meteor.userId() as Id;
      const id: keyof Event = '_id';
      const readBy: keyof Event = 'readBy';
      // TODO would be safer to user $addToSet, but doesn't seem to work on Mini Mongo
      const action = read ? '$push' : '$pull';
      return Events.updateSync({ [id]: eventId }, { [action]: { [readBy]: userId } });
    }
  );
}
