import {aboutBasePageInfo, tabsBasePageInfo} from "@core/routing/pages-list";
import {ZefRoute} from "@core/routing/zef-route";

export const appRoutes: ZefRoute[] = [
  {
    path: aboutBasePageInfo.path,
    key: aboutBasePageInfo.key,
  },
  {
    path: tabsBasePageInfo.path,
    key: tabsBasePageInfo.key
  }
];
