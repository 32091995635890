<ng-container *ngIf='leafletOptions'>
    <div
            (tap)="tap()"
            class='leaflet'
            leaflet
            [leafletOptions]='leafletOptions'
            (leafletMapReady)='initMap($event)'
            [leafletMarkerCluster]='cluster'
            style='{{options.heightPx ? "height: " + options.heightPx + "px" : ""}}'>
        <div *ngIf='edit'
             leafletDraw
             [leafletDrawOptions]='leafletDrawOptions'
             (leafletDrawCreated)='onDrawCreated($event)'
             (leafletDrawDeleted)='onDrawDeleted($event)'
             (leafletDrawReady)='onDrawReady($event)'
             (leafletDrawEdited)='onDrawEdited($event)'
        >
        </div>
    </div>
</ng-container>
