import {Component, Input, OnInit} from '@angular/core';
import {FormField, IForm} from '@utils/form';
import {InputProperty, newEmptyProperty} from '@api/queries/queries.model';
import {FieldLabelType} from '@pipes/fields/field-label.pipe';
import {nullOrUndefined} from '@core/utils';

@Component({
  selector: 'zef-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss']
})
export class PropertyComponent<T> implements OnInit {
  @Input() field: FormField<InputProperty<T>>;
  @Input() form: IForm;

  FieldLabelType = FieldLabelType;
  emptyValue: { value: T | null; details: null };

  get hasValue(): boolean {
    return !nullOrUndefined(this.field.value?.value);
  }

  constructor() {
  }

  ngOnInit() {
    this.emptyValue = newEmptyProperty();
  }

}
