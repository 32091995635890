import {DateStringFormField, Form, FormField} from '@utils/form';
import {FormGroup} from '@angular/forms';
import {Id} from '@model/entity';
import {Activity} from '@model/activity';
import {BookingToInsert} from '@model/schema-model';
import {schemas} from '@model/schemas';
import {untilDestroyed} from '@ngneat/until-destroy';
import {EntityType} from '@model/entity-type';

type BookingToInsertDefaults = Partial<BookingToInsert>;

export class BookingForm extends Form {
  constructor(formGroup: FormGroup, private defaults: BookingToInsertDefaults) {
    super(formGroup, schemas.BookingToInsert, EntityType.bookings);
  }

  fields = {
    activities: new FormField<Activity[]>(this.schema, this.vf, this.group, this.defaults.activities),
    spotId: new FormField<Id>(this.schema, this.vf, this.group, this.defaults.spotId),
    spotLaunchId: new FormField<Id>(this.schema, this.vf, this.group, this.defaults.spotLaunchId),
    fromInUserTZ: new DateStringFormField(
      this.schema,
      this.vf,
      this.group,
      this.defaults.fromInUserTZ
    ),
    toInUserTZ: new DateStringFormField(
      this.schema,
      this.vf,
      this.group,
      this.defaults.toInUserTZ
    ),
    abstract: new FormField<string>(this.schema, this.vf, this.group, this.defaults.abstract)
  };

  init(parent: any): this {
    super.init(parent);

    // Whenever "fromInUserTZ" date changes, "toInUserTZ" date is updated (but time remains unchanged)
    this.fields.fromInUserTZ.valueChanges$
      .pipe(untilDestroyed(parent))
      .subscribe(from => this.fields.toInUserTZ.setDateDay(new Date(from)));

    // Init "to"
    this.fields.toInUserTZ.setDateDay(new Date(this.fields.fromInUserTZ.dateValue));

    return this;
  }

  get minTo(): Date {
    // "to" must be at least after "from"
    return this.fields.fromInUserTZ.dateValue;
  }
}
