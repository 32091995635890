import {GearModel} from "@model/gear/gear-model";

export enum KiteType {
  tube = "tube",
  foil = "foil"
}

export interface KiteModel<VariantType> extends GearModel<VariantType> {
  kiteType: KiteType;
}
