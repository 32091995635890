import { Id, ToPersist } from '@model/entity';
import { SpotDay } from '@model/spot-day';
import { EventType, getEventFields, isReadBy } from '@model/events';
import { Spots } from '../collections/spot.collection';
import {
  EmailNotificationType,
  EntityLink,
  EntityLinkType,
  EntityLinkWithOwner,
  Event,
  Rider,
  ShortNames,
  Spot,
  Subscription
} from '@model/model';
import { Events } from '../collections/event.collection';
import { EntityType } from '@model/entity-type';
import { getCenterPoint } from '@model/place.utils';
import { DateUtils } from '@core/date-utils';
import moment from 'moment-timezone';
import { Subscriptions } from '@api/collections/subscription.collection';
import { onlyUniqueById } from '@core/array.utils';
import { getProjection, getSelectorOnEntities } from '@api/queries/utils.queries';
import Cursor = Mongo.Cursor;
import Selector = Mongo.Selector;

export class EventsQueries {
  private static createdField: keyof Event = 'createdAt';
  static sortOption = {sort: {[EventsQueries.createdField]: -1}};

  // Number of "latest events"
  private static readonly latestEventCount = 50;

  static insertEvent(event: Omit<ToPersist<Event>, 'readBy'>) {
    return Events.insertSync({
      ...event,
      readBy: []
    });
  }

  /**
   * Mark all events related to the entity, as read
   * @param userId
   * @param eventLocator
   */
  static markEventsAsRead(userId: Id, eventLocator: EventLocator) {
    const id: keyof Event = '_id';
    const readBy: keyof Event = 'readBy';
    const entities: keyof Event = 'entities';
    const actorId: keyof Event = 'actorId';
    const entity_type: keyof EntityLinkType = 'type';

    const orSelectors: Selector<Event>[] = [];

    if (eventLocator.id) {
      // A specific event identified by its id
      orSelectors.push({[id]: eventLocator.id});
    } else if (eventLocator.ids) {
      // A list of event ids
      orSelectors.push({[id]: {$in: eventLocator.ids}});
    } else if (eventLocator.entityLink) {
      // A specific entity at a specific level
      // "entities.0"

      // Copy type and id OR shortName
      const eventEntityLink = eventLocator.entityLink;

      const selectEntityAtLevel = (level: number) => getSelectorOnEntities(eventEntityLink, level);
      const selectTypeAtLevel = (level: number, type: EntityType) => ({[`${entities}.${level}.${entity_type}`]: type});

      orSelectors.push(
        // Direct match of this entity at level 0
        selectEntityAtLevel(0),
        // Reaction on this entity
        {
          ...selectTypeAtLevel(0, EntityType.emoji),
          ...selectEntityAtLevel(1)
        }
      );

      // Special cases
      switch (eventEntityLink.type) {
        case EntityType.riders:
          orSelectors.push();
          orSelectors.push(
            // Also mark events related to avatar
            {
              // Notice indices 1 & 2, index 0 is about a photo
              ...selectTypeAtLevel(1, EntityType.avatar),
              ...selectEntityAtLevel(2)
            },
            // Also mark events of this rider following something or someone
            {
              ...selectTypeAtLevel(0, EntityType.subscriptions),
              [actorId]: eventEntityLink.id
            }
          );
        // Note: meant to omit break
        // eslint-disable-next-line no-fallthrough
        case EntityType.brands:
        case EntityType.spots:
        case EntityType.spotLaunches:
          orSelectors.push(
            // Also mark events of other riders following this rider, spot or spot launch
            {
              ...selectTypeAtLevel(0, EntityType.subscriptions),
              ...selectEntityAtLevel(1)
            }
          );
          break;
        // Note: meant to omit break
        case EntityType.spotDays:
          orSelectors.push(
            // Also mark events of other riders following this rider, spot or spot launch
            {
              ...selectTypeAtLevel(0, EntityType.potentials),
              ...selectEntityAtLevel(1)
            }
          );
          break;
      }
    } else {
      throw `EventSelector must not be empty`
    }

    return Events.updateSync(
      {
        $or: [...orSelectors]
      },
      {$addToSet: {[readBy]: userId}},
      {multi: true}
    );
  }

  static persistSpotDayEvent = (
    entityLink: EntityLinkType | EntityLinkWithOwner,
    eventType: EventType,
    eventDate: Date,
    spotDay: SpotDay,
    actorId: Id | null,
    spot?: Spot
  ): string => {

    return EventsQueries.saveSpotDayEvent(eventType, eventDate, entityLink, spotDay, actorId, spot);
  };

  static persistSpotDayEventExcludeSpot = (
    entityLink: EntityLinkType | EntityLinkWithOwner,
    eventType: EventType,
    eventDate: Date,
    spotDay: SpotDay,
    actorId: Id | null,
    spot?: Spot
  ): string => {
    return EventsQueries.saveSpotDayEvent(eventType, eventDate, entityLink, spotDay, actorId, spot, false);
  };

  private static saveSpotDayEvent(
    eventType: EventType,
    eventDate: Date,
    entityLink: EntityLinkType | EntityLinkWithOwner,
    spotDay: SpotDay,
    actorId: string | null,
    spot?: Spot,
    includeSpot = true) {
    spot = spot || Spots.findOne(spotDay.spotId);

    const entities = [
      entityLink,
      {
        type: EntityType.spotDays,
        id: spotDay._id,
        shortName: spotDay.shortName
      }
    ];

    if (includeSpot) {
      entities.push(
        {
          type: EntityType.spots,
          id: spotDay.spotId,
          shortName: spot.shortName
        })
    }

    return EventsQueries.insertEvent({
      eventType,
      createdAt: eventDate,
      entities,
      // Keep track of the center position of the event
      location: getCenterPoint(spot.location),
      actorId: actorId || undefined
    });
  }

  static getEventsForEntity = (entityLink: EntityLinkType | EntityLink): Cursor<Event> =>
    Events.nativeFind(
      EventsQueries.getOrSelectorsFromEntityLinks([entityLink]),
      EventsQueries.sortOption
    );

  static getEventsFromActor = (actorId: Id): Cursor<Event> =>
    Events.nativeFind(
      EventsQueries.getOrSelectorsFromActors([actorId]),
      EventsQueries.sortOption
    );

  /*
  static getGroupedEventsForEntity = (entityLink: EntityLinkType | EntityLink) => {
    const mongoAggregateSync = Events.getw();

    const id: keyof Entity = '_id';

    // Event
    const eventType: keyof Event = 'eventType';
    const createdAt: keyof Event = 'createdAt';
    const entities: keyof Event = 'entities';
    const actorId: keyof Event = 'actorId';

    // Linked entity
    const entityLink_type: keyof EntityLinkType = 'type';
    const entityLink_id: keyof EntityLink = 'id';
    const entityOwnerId: keyof EntityLinkWithOwner = 'entityOwnerId';

    // Entity with name and avatar
    const namedEntity_name: keyof NamedEntity = 'name';
    const namedEntity_avatar: keyof NamedEntity = 'avatar';
    const namedEntity_shortName: keyof NamedEntity = 'shortName';

    // SpotSituation
    const startDate: keyof SpotSituation = 'startDate';

    // Return value
    const out_actor: keyof GroupedEvent = 'actor';
    const out_eventType: keyof GroupedEvent = 'eventType';
    const out_created: keyof GroupedEvent = 'createdAt';
    const out_owner: keyof GroupedEvent = 'owner';
    const out_events: keyof GroupedEvent = 'events';
    const out_entities: keyof GroupedEvent = 'entities';

    const projectAsEntityWithNameAndAvatar = {
      [id]: 1,
      [namedEntity_name]: 1,
      [namedEntity_avatar]: 1,
      [namedEntity_shortName]: 1
    };

    const projectEntityWithNameAndAvatarAndDate = {
      ...projectAsEntityWithNameAndAvatar,
      [startDate]: 1
    };

    const projectEntity = (field: string, out_field: string) => {
      const defaultProject = {
        _id: 1,
        [out_entity]: 1,
        [out_parentEntities]: 1,
        [out_grandParentEntities]: 1,
        [out_events]: 1
      };

      const localEntityLookup = (entityType: EntityType) => {
        return [
          { $match: { [`${out_events}.${field}.${entityLink_type}`]: entityType } },
          {
            $lookup: {
              from: entityType,
              localField: `${out_events}.${field}.${entityLink_id}`,
              foreignField: id,
              as: out_field
            }
          },
          {
            $project: {
              ...defaultProject,
              [out_field]: {
                ...projectEntityWithNameAndAvatarAndDate,
                type: entityType
              }
            }
          }
        ];
      };
      const matchings: EntityType[] = [
        EntityType.sessions,
        EntityType.observations,
        EntityType.bookings,
        EntityType.spots,
        EntityType.spotDays
      ];

      const facets = matchings.reduce((accumulator: { [name: string]: unknown }, current: EntityType) => {
        accumulator[current] = localEntityLookup(current);
        return accumulator;
      }, {});

      const unions = matchings.map(name => `$${name}`);

      return [
        {
          $facet: {
            typeOnly: [
              { $match: { [`${out_events}.${field}.${entityLink_id}`]: { $exists: false } } },
              {
                $project: {
                  ...defaultProject,
                  [out_field]: `$${out_events}.${field}`
                }
              }
            ],
            ...facets
          }
        },
        {
          $project: {
            all: {
              $setUnion: ['$typeOnly', ...unions]
            }
          }
        },
        // FIXME for some reason the same entity is duplicated when there are multiple parent entities or grand parent entities
        { $unwind: '$all' },
        { $replaceRoot: { newRoot: '$all' } }
      ];
    };

    const aggregation = [
      {
        // TODO make this a parameter and make this method more generic, used for all types of queries
        $match: { $or: EventsQueries.getOrSelectorsFromEntityLinks([entityLink]) }
      },
      {
        // Make sure to group events if they are about the same entity, done by the same user at the same time
        $group: {
          _id: {
            [eventType]: '$eventType',
            [createdAt]: '$updated',
            [entities]: '$entities',
            [actorId]: '$actorId'
          },
          [out_events]: {
            $push: '$$ROOT'
          }
        }
      },
      { $limit: 100 }, // TODO implement more elaborate pagination/scrolling of events
      ...projectEntity(entities, out_entities),
      {
        // Find minimum info about actor
        $lookup: {
          from: EntityType.riders,
          localField: `_id.${actorId}`,
          foreignField: id,
          as: out_actor
        }
      },
      {
        // Find minimum info about owner
        $lookup: {
          from: EntityType.riders,
          localField: `_id.${entity}.${entityOwnerId}`,
          foreignField: id,
          as: out_owner
        }
      },
      {
        // Array of actors => one entry per actor (but should be only one!)
        $unwind: {
          path: `$${out_actor}`,
          preserveNullAndEmptyArrays: true
        }
      },
      {
        // Array of owners => one entry per owner (but should be only one!)
        $unwind: {
          path: `$${out_owner}`,
          preserveNullAndEmptyArrays: true
        }
      },
      {
        // Array of entities => one entry per entity (but should be only one!)
        $unwind: `$${out_entity}`
      },
      {
        $project: {
          _id: 0,
          [out_eventType]: `$_id.${eventType}`,
          [out_created]: `$_id.${createdAt}`,
          [out_entity]: 1,
          [out_parentEntities]: 1,
          [out_grandParentEntities]: 1,
          [out_actor]: projectAsEntityWithNameAndAvatar,
          [out_owner]: projectAsEntityWithNameAndAvatar,
          [out_events]: 1
        }
      },
      {
        $sort: { [`_id.${out_created}`]: -1 }
      }
    ];

    return mongoAggregateSync(aggregation);

  };
   */

  /**
   * Get the minimum date for this subscriber's notifications
   * @param subscriber
   */
  static getMinimumNotificationDate(subscriber: Rider): Date {
    // For the moment, only ignore events that occurred before the rider's subscription date
    // In the future we might ignore events older than xxx months
    return subscriber.createdAt
  }

  /**
   * Build the Mongo selector associated to the list of subscriptions
   */
  static getSelectorFromSubscriptionsAndSubscriber = (subscriptions: Subscription[], subscriber: Rider, type?: EmailNotificationType): Mongo.Selector<Event> => {
    const entities: keyof Event = 'entities';
    const actorId: keyof Event = 'actorId';
    const createdAt: keyof Event = 'createdAt';
    const entityOwnerId: keyof EntityLinkWithOwner = 'entityOwnerId';
    const entityType: keyof EntityLink = 'type';
    const entityId: keyof EntityLink = 'id';

    // Subscriptions that target entities
    const hasIdOrShortName = (s: Subscription) => s.entities?.[0]?.id !== undefined || s.entities?.[0]?.shortName !== undefined;
    const entityLinks = subscriptions.filter(hasIdOrShortName).map(s => s.entities?.[0] as EntityLink);
    const entityLinkSelectors = EventsQueries.getOrSelectorsFromEntityLinks(entityLinks);

    // Subscriptions that target types
    const hasOnlyType = (s: Subscription) => s.entities?.[0]?.type !== undefined && (s.entities?.[0]?.id === undefined && s.entities?.[0]?.shortName === undefined);
    const entityTypes = subscriptions.filter(hasOnlyType).map(s => s.entities?.[0] as EntityLinkType);
    const entityTypeSelectors = EventsQueries.getOrSelectorsFromEntityTypes(entityTypes);

    // Subscriptions that target owners
    const entityOwnerAndSubscriberIds = subscriptions
      .filter(s => s.entityOwnerId !== undefined)
      .map(s => s.entityOwnerId as Id);
    const entityOwnerSelectors = {
      [entities]:
        {
          $elemMatch: {
            [entityOwnerId]: {$in: [...entityOwnerAndSubscriberIds]}
          }
        }
    };

    // And also events ON the rider themselves
    const entityOwnerSelectorsAsEntities = {
      [entities]: {
        $elemMatch: {
          [entityType]: EntityType.riders,
          [entityId]: {$in: [...entityOwnerAndSubscriberIds]}
        }
      }
    };

    // Filter depending on the notifications type that is targeted
    let onlyRelevantEntities;
    const inForecastTypes = {$in: [EntityType.potentials, EntityType.forecasts]};
    const link_type: keyof EntityLinkType = 'type';
    const mainEntityLinkKey = `${entities}.0.${link_type}`;

    switch (type) {
      case EmailNotificationType.forecast:
        onlyRelevantEntities = {
          [mainEntityLinkKey]: inForecastTypes,
        };
        break;
      case EmailNotificationType.others:
        onlyRelevantEntities = {
          [mainEntityLinkKey]: {$not: inForecastTypes},
        };
        break;
      default:
        onlyRelevantEntities = {}
        break;
    }

    // Subscriptions that target actions made by actors
    const actorIds = subscriptions
      .filter(s => s.actorId !== undefined)
      .map(s => s.actorId as Id);
    const actorSelectors = EventsQueries.getOrSelectorsFromActors(actorIds);

    // Note: in fact, should only show events that occurred after the subscription date
    // But this is quite complicated as an event can match multiple subscriptions so we should take
    // the minimum date of all subscriptions that match an event...

    return {
      $and: [
        // Only interested in events triggered by another user
        {
          [actorId]: {
            $not: {
              $eq: subscriber._id
            }
          },
          [createdAt]: {
            $gte: EventsQueries.getMinimumNotificationDate(subscriber)
          }
        },
        {
          $or: [
            entityTypeSelectors,
            entityLinkSelectors,
            entityOwnerSelectors,
            entityOwnerSelectorsAsEntities,
            actorSelectors
          ]
        },
        onlyRelevantEntities
      ]
    };
  };

  /**
   * Filter some events after they have been queried from Mongo
   * TODO this should be done on the Mongo query, with a proper aggregation. Not done yet because of complexity
   * @param events
   * @param riderId
   */
  static postProcessFilterLatestEvents(events: Event[], riderId: Id): Event[] {

    // Special case: ignore events about other riders that subscribed to "all entities" of a type.
    // Example: rider X subscribed to all news (headlines), all spots, etc
    // This happens either by default for all users, or for special "admin" users so we don't want to expose it
    events = events.filter(n => {
      const mainType = n.entities[0].type;
      if (mainType === EntityType.subscriptions) {
        const targetEntity = n.entities[1];
        if ((targetEntity as EntityLink)?.id === undefined && (targetEntity as EntityLinkWithOwner)?.shortName === undefined) {
          return false;
        }
      }
      return true;
    })

    // Add missing data
    events = events.map(n => {
      const mainType = n.entities[0].type;
      if (mainType === EntityType.potentials) {

        // Events with just potential updates do not include spot.
        // Let's add it
        if (n.entities.length === 2) {
          if ((n.entities[1] as EntityLink)?.shortName !== undefined)
            n.entities.push({
              type: EntityType.spots,
              shortName: ShortNames.getShortNameAndDay((n.entities[1] as EntityLink).shortName!).shortName
            })
        }
      }

      return n;
    })


    // Now filter events about forecast to remove "noise"
    // General principles:
    // - I want to see all events I have already read
    // - If I read the status of a spot day, I'm only interested in the latest status, if it is ≠ from the last status I read
    // - If I never read the status of a spot day, I'm only interested in the latest status, if it is ON


    // If is read => ok
    // else
    //  if spotday in the past => ignore
    //  else
    //    if there is a read event for this spot day
    //      if status is ≠ => OK
    //      else => exit
    //    else
    //      if is ON => OK
    //      else => exit


    // Sort by date DESCENDING (younger to older)
    events = events
      .sort((eA, eB) => eB.createdAt.getTime() - eA.createdAt.getTime())

    // Now build a list of last read event per spot day
    const perSpotDayEvents: {
      [spotDayId: string]: {
        latestRead?: Event,
        latestUnRead?: Event
      }
    } = {}

    events
      .forEach(n => {
        const mainType = n.entities[0].type

        if (mainType === EntityType.potentials) {
          const isRead = isReadBy(n, riderId)
          const targetEntity = n.entities[1];
          const spotDayId = (targetEntity as EntityLink).id as Id;

          if (!perSpotDayEvents[spotDayId]?.latestRead && isRead) {
            perSpotDayEvents[spotDayId] ??= {}
            perSpotDayEvents[spotDayId].latestRead = n
          }
        }
      })

    const keepEvent = (n: Event) => {
      const mainType = n.entities[0].type;
      const isRead = isReadBy(n, riderId)

      if (mainType !== EntityType.potentials || isRead) {
        return true;
      }

      // Unread potential event
      const targetEntity = n.entities[1];
      const spotDayId = (targetEntity as EntityLink).id as Id;

      if (perSpotDayEvents[spotDayId]?.latestUnRead) {
        // This is not the latest unread event (it already exists) => not interesting
        return false;
      }

      perSpotDayEvents[spotDayId] ??= {}
      perSpotDayEvents[spotDayId].latestUnRead = n

      let spotDayDate = new Date(ShortNames.getShortNameAndDay((targetEntity as EntityLink).shortName!).day);
      const now = DateUtils.toMidnightDate(moment())
      if (spotDayDate.getTime() < now.getTime()) {
        // This is an unread update about a spot day in the past => ignore
        return false
      }

      const lastUnreadEvent = perSpotDayEvents[spotDayId]?.latestRead
      if (lastUnreadEvent) {

        // If the current event is before the last read event, then ignore it
        if (lastUnreadEvent.createdAt.getTime() > n.createdAt.getTime()) {
          return false
        }

        // This event has a different status than the latest read one => is relevant info. Otherwise, ignore
        return n.eventType !== lastUnreadEvent.eventType
      }

      // Last case, only interested if this is a "ON" wind alert
      return n.eventType === EventType.created
    }

    events = events
      .filter(n => {
        const keep = keepEvent(n);

        // @ts-ignore // To be used for debugging
        // n.FILTERED = !keep; return true;

        return keep;
      })
      .filter(onlyUniqueById)
      // Keep only the latest
      .slice(0, this.latestEventCount)

    return events
  }

  static getLatestEventsForSubscriber(subscriber: Rider, type?: EmailNotificationType): Mongo.Cursor<Event> {
    const subscriptions = Subscriptions.nativeFind({subscriberId: subscriber._id}).fetch();
    const readBy: keyof Event = 'readBy';

    // FIXME here, readBy should be filtered to only contain the subscriberId (should not return the list of all users who read the event)
    // See https://docs.mongodb.com/manual/tutorial/project-fields-from-query-results/#project-specific-array-elements-in-the-returned-array
    return Events.nativeFind(
      EventsQueries.getSelectorFromSubscriptionsAndSubscriber(subscriptions, subscriber, type),
      {
        ...EventsQueries.sortOption,
        // Take more than the limit, because some will be filtered out on post-processing
        limit: this.latestEventCount * 3,
        fields: {
          ...getProjection(getEventFields()),
          [readBy]: ({$elemMatch: {$eq: subscriber._id}} as unknown as number) // Note: Mongo / Meteor is stupid, thinks it only supports number projections
        }
      }
    );
  }

  /**
   * Get the list of selectors for a list of entity links:
   * Group them in a $or to get all the related events
   * @param entityLinks
   */
  private static getOrSelectorsFromEntityLinks = (entityLinks: EntityLink[]) => {
    const entities: keyof Event = 'entities';
    return {[entities]: {$elemMatch: {$in: [...entityLinks]}}};
  };

  /**
   * Get the list of selectors for a list of entity types:
   * Group them in a $or to get all the related events
   * @param entityTypes
   */
  private static getOrSelectorsFromEntityTypes = (entityTypes: EntityLinkType[]) => {
    const entities: keyof Event = 'entities';
    const link_type: keyof EntityLinkType = 'type';
    return {[`${entities}.0.${link_type}`]: {$in: [...entityTypes.map(link => link.type)]}};
  };

  /**
   * Get the list of selectors for a list of actors:
   * Group them in a $or to get all the related events
   * @param actorIds
   */
  private static getOrSelectorsFromActors = (actorIds: Id[]) => {
    const actorId: keyof Event = 'actorId';
    const entities: keyof Event = 'entities';
    const entities_type: keyof EntityLink = 'type';

    // Do not follow every reaction that a user creates!
    return {
      [actorId]: {$in: [...actorIds]},
      [`${entities}.${entities_type}`]: {$ne: EntityType.emoji}
    };
  };
}

export interface EventLocator {
  id?: Id;
  ids?: Id[];
  entityLink?: EntityLink | EntityLinkWithOwner;
}
