<ng-container *ngFor='let alias of field.value; let i = index'>
  <ng-container *ngIf='forms[i] as form' [formGroup]='form.group'>
    <ion-item lines='none' class="transparent">
      <ion-grid>
        <ion-row>
          <ion-col size='11'>
            <div>
              <ion-label
                class='input'>{{ form.fields.alias | fieldLabel : FieldLabelType.TITLE }}</ion-label>
              <ion-input [formControlName]='form.fields.alias.name'></ion-input>
            </div>
          </ion-col>
          <ion-col size='1' class='remove'>
            <ion-button (click)='removeElement(i)' fill='clear' color='dark'>
              <ion-icon name='trash' slot='icon-only'></ion-icon>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>
    <zef-error-line [field]='form.fields.alias'></zef-error-line>
  </ng-container>
</ng-container>
<ion-item class="transparent">
  <ion-button slot='end' color='light' (click)='addElement()' [disabled]='!canAdd'>
    <ion-icon name='add-circle-outline'></ion-icon>&nbsp;{{ "LIST.ADD" | translate: { entity: 'aliases' } }}
  </ion-button>
</ion-item>
<zef-error-line [field]='field'></zef-error-line>
