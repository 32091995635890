import {Pipe, PipeTransform} from '@angular/core';
import {TranslateConfigService} from '@services/translate-config.service';

@Pipe({
  name: 'countryName'
})
export class CountryNamePipe implements PipeTransform {
  constructor(private translateConfig: TranslateConfigService) {
  }

  transform(countryCode: string): string {
    return this.translateConfig.getCountryName(countryCode);
  }
}
