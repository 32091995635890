import {Form, FormField} from '@utils/form';
import {FormGroup} from '@angular/forms';
import {Link, SpotLaunchToInsert, SpotLaunchToUpdate, WithLanguageText} from '@model/schema-model';
import {schemas} from '@model/schemas';
import {EntityType} from '@model/entity-type';
import SimpleSchema from "simpl-schema";
import {InputProperty, newEmptyProperty} from '@api/queries/queries.model';
import {Id} from '@model/entity';
import {Activity} from '@model/activity';
import {Point} from 'geojson';
import {BottomTypeValue} from '@model/spot-launch';


abstract class SpotLaunchLaunchForm extends Form {
  constructor(formGroup: FormGroup, schemas: SimpleSchema, protected defaults: SpotLaunchToInsertDefaults | SpotLaunchToUpdateDefaults) {
    super(formGroup, schemas, EntityType.spotLaunches);
  }

  fields = {
    name: new FormField<string>(this.schema, this.vf, this.group, this.defaults.name),
    aliases: new FormField<{ alias: string }[]>(this.schema, this.vf, this.group, this.defaults.aliases?.map(alias => ({ alias }))),
    abstract: new FormField<WithLanguageText[]>(this.schema, this.vf, this.group, this.defaults.abstract),
    description: new FormField<WithLanguageText[]>(this.schema, this.vf, this.group, this.defaults.description),
    links: new FormField<Link[]>(this.schema, this.vf, this.group, this.defaults.links),
    location: new FormField<Point>(this.schema, this.vf, this.group, this.defaults.location),
    spotId: undefined, // Special: will be defined in child class
    gearForRent: new FormField<InputProperty<Activity[]>>(this.schema, this.vf, this.group, this.defaults.gearForRent ?? newEmptyProperty()),
    bottomType: new FormField<InputProperty<BottomTypeValue>>(this.schema, this.vf, this.group, this.defaults.bottomType ?? newEmptyProperty()),
    publicAccess: new FormField<InputProperty<boolean>>(this.schema, this.vf, this.group, this.defaults.publicAccess ?? newEmptyProperty()),
    freeParking: new FormField<InputProperty<boolean>>(this.schema, this.vf, this.group, this.defaults.freeParking ?? newEmptyProperty()),
    hasToilets: new FormField<InputProperty<boolean>>(this.schema, this.vf, this.group, this.defaults.hasToilets ?? newEmptyProperty()),
    hasShower: new FormField<InputProperty<boolean>>(this.schema, this.vf, this.group, this.defaults.hasShower ?? newEmptyProperty()),
    suitableForKite: new FormField<InputProperty<boolean>>(this.schema, this.vf, this.group, this.defaults.suitableForKite ?? newEmptyProperty()),
    howToGetThere: new FormField<WithLanguageText[]>(this.schema, this.vf, this.group, this.defaults.howToGetThere)
  };

}

export type SpotLaunchToInsertDefaults = Partial<SpotLaunchToInsert>;

export class CreateSpotLaunchForm extends SpotLaunchLaunchForm {
  constructor(formGroup: FormGroup, defaults: SpotLaunchToInsertDefaults) {
    super(formGroup, schemas.SpotLaunchToInsert, defaults);
    this.fields.spotId = new FormField<Id>(this.schema, this.vf, this.group, defaults.spotId);
  }
}

export type SpotLaunchToUpdateDefaults = Partial<SpotLaunchToUpdate>;

export class EditSpotLaunchForm extends SpotLaunchLaunchForm {
  constructor(formGroup: FormGroup, defaults: SpotLaunchToUpdateDefaults) {
    super(formGroup, schemas.SpotLaunchToUpdate, defaults);
  }
}
