import {Component, Input, OnChanges, SimpleChange} from '@angular/core';
import {ColorGenerator} from './color-generator';
import {EntityType} from "@model/entity-type";

@Component({
  selector: 'zef-text-avatar',
  templateUrl: 'text-avatar.component.html',
  styleUrls: ['./text-avatar.scss']
})
export class TextAvatarComponent implements OnChanges {
  @Input() text: string;
  @Input() color: string;
  @Input() textColor: string;
  @Input() entityType: EntityType;

  initials = '';
  styles = {
    'background-color': '#fff',
    color: '#000'
  };

  constructor(private colorGenerator: ColorGenerator) {
  }

  ngOnChanges(changes: {[key in keyof TextAvatarComponent]: SimpleChange}) {

    const text = changes.text ? changes.text.currentValue : null;
    const color = changes.color ? changes.color.currentValue : null;
    const textColor = changes.textColor ? changes.textColor.currentValue : this.styles.color;

    this.initials = (text ?? []).split(' ').map(s => s.charAt(0)).slice(0, 2).join('');
    this.styles = {
      ...this.styles,
      'background-color': this.backgroundColorHexString(color, text),
      color: textColor
    };
  }

  private backgroundColorHexString(color: string, text: string): string {
    return color || this.colorGenerator.getColor(text);
  }
}
