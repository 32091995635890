import {Id, ToPersist} from '@model/entity';
import {SpotDay} from '@model/spot-day';
import {SpotDays} from '../collections/spot-day.collection';
import {EventType, Observation} from '../../core/model/model';
import {EventsQueries} from './event.queries';
import {EntityType} from '@model/entity-type';
import {Observations} from '../collections/observation.collection';
import {ZefCollection} from '../collections/zef-collection';

export class ObservationsQueries {
  static persist = (observationToPersist: ToPersist<Observation>, spotDay: SpotDay | null = null): Id => {
    if (spotDay === null) {
      spotDay = SpotDays.findOne(observationToPersist.spotDayId);
    }
    const observation = ZefCollection.setCreatedDate(observationToPersist);
    const observationId = Observations.insertSync(observation);

    EventsQueries.persistSpotDayEvent(
      {
        type: EntityType.observations,
        id: observationId,
        entityOwnerId: observation.riderId
      },
      EventType.created,
      observation.createdAt,
      spotDay,
      observation.riderId
    );

    return observationId;
  };

  static selectBySpotId(spotDayId: Id): { [p: string]: Id } {
    const local_spotDayId: keyof Observation = 'spotDayId';
    return { [local_spotDayId]: spotDayId };
  }

  static getObservation(_id: Id): Observation {
    return Observations.findOne({ _id });
  }
}
