import {Component, Input, OnInit} from '@angular/core';
import {FormField, IForm} from '@utils/form';
import {UntilDestroy} from '@ngneat/until-destroy';
import {QuiverItem} from '@model/schema-model';
import {SelectGearItemsComponent} from '@components/gear/select-gear-items/select-gear-items.component';
import {ModalController} from '@ionic/angular';
import {RiderAndQuiverItem} from '@api/queries/queries.model';
import {toDict} from '@core/utils';

@UntilDestroy()
@Component({
  selector: 'zef-edit-situation-gear',
  templateUrl: './edit-situation-gear.component.html',
  styleUrls: ['./edit-situation-gear.component.scss']
})
export class EditSituationGearComponent implements OnInit {
  @Input() form: IForm;
  @Input() formField: FormField<QuiverItem[]>;

  @Input() set riderQuiver(value: RiderAndQuiverItem[] | undefined) {
    this._riderQuiver = value;
    if (value) {
      this.riderQuiverDict = toDict(value, (val: RiderAndQuiverItem) => val.itemId, val => val);
    }
  }

  private _riderQuiver: RiderAndQuiverItem[] | undefined;
  /**
   * Dictionary of item id => rider quiver item, used to display full info about quiver item
   */
  riderQuiverDict: { [id: string]: RiderAndQuiverItem } = {};

  constructor(
    private modalController: ModalController) {
  }

  ngOnInit() {
    // Make sure anytime a value is changed on the list of items, it remains sorted
    /*
    this.formField.valueChanges$.pipe(
      tap(newValue => this.formField.value = newValue.sort((qA, qB) => compareQuiverItems(qA, qB))),
      untilDestroyed(this)
    ).subscribe();


     */
  }

  async edit() {
    const modal = await this.modalController.create({
      component: SelectGearItemsComponent,
      componentProps: {
        form: this.form,
        formField: this.formField,
        riderQuiver: this._riderQuiver
      }
    });
    return await modal.present();
  }
}
