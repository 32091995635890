import {ElementRef, Injectable} from '@angular/core';

// Adapted from https://medium.com/@JordanBenge/..
//   ..ionic-4-hiding-showing-tabs-on-certain-pages-31cf2380a5db
// (with many improvements -DT)

@Injectable({
  providedIn: 'root'
})
export class TabBarService {
  get isDesktop(): boolean {
    return this._isDesktop;
  }

  set isDesktop(value: boolean) {
    if (value) {
      this.hideTabBar();
    } else {
      this.showTabBar();
    }

    this._isDesktop = value;
  }

  private tabBarRef: ElementRef;

  private _isDesktop = false;

  constructor() {
  }

  public init(tabBarRef: ElementRef): void {
    this.tabBarRef = tabBarRef;
  }

  public hideTabBar(): void {
    const display: string = this.tabBarRef?.nativeElement?.style?.display;
    if (display !== undefined && display !== 'none') {
      this.tabBarRef.nativeElement.style.display = 'none';
    }
  }

  public showTabBar(): void {
    const display: string = this.tabBarRef.nativeElement.style.display;
    if (display !== 'flex') {
      this.tabBarRef.nativeElement.style.display = 'flex';
    }
  }
}
