import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InViewportModule} from 'ng-in-viewport';
import 'intersection-observer';
import {MarkAsReadDirective} from './mark-as-read.directive';

@NgModule({
  declarations: [MarkAsReadDirective],
  imports: [
    CommonModule,
    InViewportModule
  ],
  exports: [MarkAsReadDirective]
})
export class MarkAsReadModule {
}
