import {Entity, Id} from './entity';
import {TrickLevels} from './trick';
import {SpotSituationInThePast} from './spot-situation';
import {Quiver} from './gear/quiver';
import {EntityType} from './entity-type';
import {EntityLinkWithOwner} from './entity-link';

export interface Session extends SpotSituationInThePast, Entity {
  withRiderIds?: Id[];
  tricks?: TrickLevels;
  quiver?: Quiver;

  // Can be automatically extracted from KML / GPX etc.
  distanceKm?: number;
  maxSpeedKmPerH?: number;
  avgSpeedKmPerH?: number; // average speed _when moving_
  trackLogId?: Id;
}

export const getSessionEntity = (session: Pick<Session, '_id' | 'riderId'>): EntityLinkWithOwner => ({
  type: EntityType.sessions,
  id: session._id,
  entityOwnerId: session.riderId
});

export const getSessionEntityWithOwner = (session: Pick<Session, '_id' | 'riderId'>): EntityLinkWithOwner => ({
  ...getSessionEntity(session),
  entityOwnerId: session.riderId
});

export const getDurationInMs = (session: Pick<Session, 'startDate' | 'endDate'>): number|undefined => session.endDate ? session.endDate.getTime() - session.startDate.getTime() : undefined
