import {Id} from '@model/entity';
import {Observable} from 'rxjs';
import {SpotDayItem} from '@api/queries';

export abstract class ICachedSpotDaysService {
  abstract getEntitiesByIds$(ids: Id[]): Observable<SpotDayItem[]>;

  abstract getEntityById$(_id: Id): Observable<SpotDayItem>;

  abstract getEntityByShortName$(shortName: string): Observable<SpotDayItem>;

  // TODO this should not be used anymore
  abstract getAllEntities$(): Observable<SpotDayItem[]>;
}
