import {Pipe, PipeTransform} from '@angular/core';
import {Activity} from '@model/activity';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'activities'
})
export class ActivitiesPipe implements PipeTransform {
  constructor(private translate: TranslateService) {
  }

  transform(activities: Activity[] | undefined) {
    return activities?.map((a) => this.translate.instant(`ENUM.Activity.VALUES.${a}`)).join(', ');
  }
}
