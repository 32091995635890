import { ZefRoute } from '@core/routing/zef-route';
import { LoadChildren, NavigationExtras, Router, Routes } from '@angular/router';
import { Injectable, Type } from '@angular/core';

export interface ComponentMap {
  [key: string]: Type<any>
}

export interface ModuleMap {
  [key: string]: {
    loadChildren: LoadChildren,
    canActivate?: any[];
  }
}

export const toAngularRoutes =
  (routes: ZefRoute[], componentMap: ComponentMap): Routes =>
    routes.map(({matcher, path, key}) => {
      const component = componentMap[key];
      return component ? {matcher, path, component} : undefined;
    }).filter(n => n !== undefined)

export const toAngularModules =
  (routes: ZefRoute[], moduleMap: ModuleMap): Routes =>
    routes.map(({matcher, path, key}) => {
      const module = moduleMap[key];
      return module ? {matcher, path, loadChildren: module.loadChildren, canActivate: module.canActivate} : undefined;
    }).filter(n => n !== undefined)


export abstract class IAdvancedRouter {
  constructor(protected router: Router) {
  }

  abstract navigateAndReload(commands: any[], extras?: NavigationExtras): Promise<boolean>;
}

@Injectable({
  providedIn: 'root'
})
export class AdvancedRouter extends IAdvancedRouter {
  constructor(router: Router) {
    super(router);
  }

  async navigateAndReload(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    /*
    const previous_onSameUrlNavigation = this.router.onSameUrlNavigation;
    const previous_shouldReuseRoute = this.router.routeReuseStrategy.shouldReuseRoute;

    // Force reload
    this.router.onSameUrlNavigation = 'reload';
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    const ret = await this.router.navigate(commands, extras);

    // Restore previous values of config
    this.router.onSameUrlNavigation = previous_onSameUrlNavigation;
    this.router.routeReuseStrategy.shouldReuseRoute = previous_shouldReuseRoute;

    return ret;

     */

    const ret = await this.router.navigate(commands, extras);
    window.location.reload();
    return ret;

  }
}