<ng-template #template>
  <ion-label *ngIf="showLabel" class='input'>
    {{"ENUM." + enumName + ".TITLE" | translate}}
  </ion-label>
  <ion-select #toggleSelect
              *ngIf='allowUnknown || allowNone'
              placeholder='{{ allowUnknown ? capitalize("ENUM.UNDEFINED" | translate : { enumName }) : ("ENUM." + enumName + ".TITLE" | translate) }}'
              [ngModel]='toggleValue'
              (ionChange)='changeToggle($event)'
  >
    <ion-select-option [value]='ToggleValue.UNKNOWN' *ngIf='allowUnknown' class='unknown'>
      {{ capitalize("ENUM.UNDEFINED" | translate : { enumName }) }}
    </ion-select-option>
    <ion-select-option [value]='ToggleValue.NONE' *ngIf='allowNone'>
      {{ capitalize("ENUM.NONE" | translate : { enumName }) }}
    </ion-select-option>
    <ion-select-option [value]='ToggleValue.SOME'>
      {{ model?.length > 0 ? getFormattedValue() : ("ENUM." + enumName + ".TITLE" | translate) + "..." }}
    </ion-select-option>
  </ion-select>

  <ion-select #valueSelect
              [hidden]='allowUnknown || allowNone'
              placeholder='{{ allowUnknown ? capitalize("ENUM.UNDEFINED" | translate : { enumName }) : ("ENUM." + enumName + ".TITLE" | translate) }}'
              [ngModel]='model' (ngModelChange)='changeValue($event)'
              [multiple]='true'
  >
    <ion-select-option *ngFor='let key of keys(enumDict)'
                       [value]='enumDict[key]'>
      {{ capitalize("ENUM." + enumName + ".VALUES." + key | translate : { count: 1 }) }}
    </ion-select-option>
  </ion-select>
</ng-template>
