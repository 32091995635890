import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage-angular';

export enum StorageKey {
  lang = 'lang'
}

/**
 * Init function
 * @param service
 */
export const initLocalStorageServiceFactory = (service: LocalStorageService) => async () => {
  await service.init();
};

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private _storage: Storage | null = null;

  constructor(private storage: Storage) {
  }

  async init() {
    this._storage = await this.storage.create();
  }

  public set<T>(key: StorageKey, value: T): Promise<void> {
    return this._storage?.set(key, value);
  }

  public get<T>(key: StorageKey): Promise<T | null> {
    return this._storage?.get(key) as Promise<T | null>;
  }
}
