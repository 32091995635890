import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'zef-loading-list',
  templateUrl: './loading-list.component.html',
  styleUrls: ['./loading-list.component.scss']
})
export class LoadingListComponent implements OnInit {
  @Input() count: number = 3;

  constructor() {
  }

  ngOnInit() {
  }

}
