import {Component, Input, OnInit} from '@angular/core';
import {SpotLaunch, SpotLaunchProperties} from '@model/spot-launch';
import {IconService} from '@services/icon.service';
import {nullOrUndefined} from '@core/utils';
import {ActivitiesPipe} from '@pipes/activities.pipe';
import {TranslateService} from '@ngx-translate/core';
import {notEmpty} from '@model/multi-language-text';
import {TranslateConfigService} from '@services/translate-config.service';

@Component({
  selector: 'zef-spot-launch-property-icon',
  templateUrl: './spot-launch-property-icon.component.html',
  styleUrls: ['./spot-launch-property-icon.component.scss']
})
export class SpotLaunchPropertyIconComponent implements OnInit {
  @Input() launch: SpotLaunch;
  @Input() propertyType: (keyof SpotLaunchProperties);
  @Input() showLabel = false;

  active: boolean;
  label: string;

  notEmpty = notEmpty;

  constructor(
    public icon: IconService,
    private activitiesPipe: ActivitiesPipe,
    private translate: TranslateService,
    public translateConfig: TranslateConfigService
  ) {
  }

  ngOnInit() {
    this.active = this.computeActive();
    if (nullOrUndefined(this.launch.properties[this.propertyType]?.value)) {
      this.label = this.translate.instant('SPOTS.PROPERTIES.SPOT_LAUNCH.noInfo', { enum: this.translate.instant('FORMS.FIELDS.' + this.propertyType + '.TITLE') });
    } else if (this.propertyType === 'gearForRent') {
      const activities = this.launch.properties.gearForRent?.value;
      this.label = this.translate.instant('SPOTS.PROPERTIES.SPOT_LAUNCH.' + this.propertyType, { value: activities?.length === 0 ? 'NONE' : this.activitiesPipe.transform(activities) });
    } else if (this.propertyType === 'bottomType') {
      this.label = this.translate.instant('ENUM.BottomTypeValue.TITLE', { value: this.translate.instant('ENUM.BottomTypeValue.VALUES.' + this.launch.properties[this.propertyType]?.value) });
    } else {
      this.label = this.translate.instant('SPOTS.PROPERTIES.SPOT_LAUNCH.' + this.propertyType, { value: this.launch.properties[this.propertyType]?.value });
    }
  }

  private computeActive(): boolean {
    const value = this.launch.properties[this.propertyType]?.value;
    if (nullOrUndefined(value)) {
      return undefined;
    }

    switch (this.propertyType) {
      case 'gearForRent':
        return (value as unknown as Array<never>).length > 0;

      default:
        return value as unknown as boolean;
    }

  }

}
