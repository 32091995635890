import {Queries, SpotDayItem} from '@api/queries';
import {Id} from '@model/entity';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {MeteorCache} from '@services/meteor/meteor.cache';
import {ICachedSpotDaysService} from '@services/i-cached-spot-days.service';
import {first} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MeteorCachedSpotDaysService extends ICachedSpotDaysService {
  private meteorCache: MeteorCache<SpotDayItem>;

  constructor(private queries: Queries) {
    super();
    this.meteorCache = new MeteorCache(this.queries.getSpotDayItems);
  }

  getEntitiesByIds$(ids: Id[]): Observable<SpotDayItem[]> {
    return this.meteorCache.getEntitiesByIds$(ids);
  }

  getEntityById$(_id: Id): Observable<SpotDayItem> {
    return this.meteorCache.getEntityById$(_id).pipe(first());
  }

  getEntityByShortName$(shortName: string): Observable<SpotDayItem> {
    return this.meteorCache.getEntityByShortName$(shortName);
  }

  getAllEntities$(): Observable<SpotDayItem[]> {
    return this.meteorCache.getAllEntities$();
  }
}

