import {MeteorObservable} from 'meteor-rxjs';
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {defineCustomElements} from '@ionic/pwa-elements/loader';

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.log(err));

Meteor.startup(() => {
  const subscription = MeteorObservable.autorun().subscribe(() => {
    if (Meteor.loggingIn()) {
      return;
    }

    setTimeout(() => subscription.unsubscribe());

    // platformBrowserDynamic().bootstrapModule(AppModule);
  });

  // Make sure a click on the verification email is handled properly
  Accounts.onEmailVerificationLink((token, done) => {
    Accounts.verifyEmail(token, done);
  });
});
