import { Entity, Id, ToPersist } from '@model/entity';
import { GearItemAndModel } from '@model/gear/gear-item-and-model';
import { GearItems } from '../collections/gear-item.collection';
import { GearModels } from '../collections/gear-model.collection';
import { QuiverItem } from '@model/gear/quiver';
import { NamedEntity, namedEntityFields } from '@model/entity-with-description';
import { GearItem, Variant } from '@model/gear/gear-item';
import { GearModel } from '@model/gear/gear-model';
import { EntityType } from '@model/entity-type';
import { Brand } from '@model/gear/brand';
import { PhotosQueries } from './photo.queries';
import { DetailedGearItem, GearModelAndBrand, GearModelListItem } from './queries.model';
import { Optional, ToImport } from '../../../server/import-export/model';
import { Photo } from '@model/photo';
import { getThumbnailUrl } from '@model/imagekit-utils';
import { EntitySelector, getProjection, getSearchAggregation, search, Searched } from './utils.queries';
import { ZefCollection } from '../collections/zef-collection';
import { ProductVariant, WindsurfBoard } from '@model/gear/variants';
import { BoardType, getClosestVariant, ProductSubType, PropulsionType, WithSize } from '@model/gear/product';
import { ErrorType, ZefError } from '../errors';
import {
  GearVariantKite,
  GearVariantKiteBoard,
  GearVariantPaddleBoard,
  GearVariantSurfBoard,
  GearVariantWindsurfBoard,
  GearVariantWindsurfSail,
  GearVariantWing,
  GearVariantWingBoard,
  KiteBoardToInsert,
  KiteBoardToUpdate,
  KiteToInsert,
  KiteToUpdate,
  PaddleBoardToInsert,
  PaddleBoardToUpdate,
  SurfBoardToInsert,
  SurfBoardToUpdate,
  WindsurfBoardToInsert,
  WindsurfBoardToUpdate,
  WindsurfSailToInsert,
  WindsurfSailToUpdate,
  WingBoardToInsert,
  WingBoardToUpdate,
  WingToInsert,
  WingToUpdate
} from '@model/schema-model';
import { EventsQueries } from '@api/queries/event.queries';
import { EventType } from '@model/events';
import { ShortNames } from '@model/short-names';
import { fromInput } from '@model/multi-language-text';
import { Brands } from '@api/collections/brand.collection';
import { KiteModel } from '@model/gear/kite-model';
import { KiteBoardModel } from '@model/gear/kite-board-model';
import { feetParse, getCharacteristic, getCompatibleFinFamilies } from '@model/gear/gear-utils';
import { nullOrUndefined } from '@core/utils';
import Selector = Mongo.Selector;

export type SimpleGearItem = Optional<Omit<GearItem, 'gearModelId' | 'type' | 'subType' | 'characteristic'>, 'createdAt'>;

export type SearchedGearModel = Searched<GearModelToSearch>

type GearModelToSearch = NamedEntity & Pick<GearModel<unknown>, 'brandName' | 'year' | 'version' | 'type' | 'subType'>;

/**
 * At least one of the properties should be set
 */
export interface GearModelSearchInput {
  search?: string;
  brandId?: Id;
  productSubType?: ProductSubType;
  year?: number;
}

export type GearModelToInsert =
  SurfBoardToInsert
  | WindsurfBoardToInsert
  | WingBoardToInsert
  | WindsurfSailToInsert
  | WingToInsert
  | KiteToInsert
  | KiteBoardToInsert
  | PaddleBoardToInsert

export type GearModelToUpdate =
  SurfBoardToUpdate
  | WindsurfBoardToUpdate
  | WingBoardToUpdate
  | WindsurfSailToUpdate
  | WingToUpdate
  | KiteToUpdate
  | KiteBoardToUpdate
  | PaddleBoardToUpdate

export type GearVariant =
  GearVariantSurfBoard
  | GearVariantWindsurfBoard
  | GearVariantWingBoard
  | GearVariantWindsurfSail
  | GearVariantWing
  | GearVariantKite
  | GearVariantKiteBoard
  | GearVariantPaddleBoard

export type ListOfOneTypeOfVariant = GearVariantSurfBoard[]
  | GearVariantWindsurfBoard[]
  | GearVariantWingBoard[]
  | GearVariantWindsurfSail[]
  | GearVariantWing[]
  | GearVariantKite[]
  | GearVariantKiteBoard[]
  | GearVariantPaddleBoard[]

export type AnyModel<T> = GearModel<T> | KiteBoardModel<T> | KiteModel<T>;

export class GearQueries {

  static createGearModel<T extends WithSize>(gearModelToInsert: GearModelToInsert, riderId: Id): Id {
    const { gearModel, brand } = this.getGearModelAndBrandObjects<T>(gearModelToInsert);

    const gearModelToPersist: ToPersist<GearModel<T>> = ZefCollection.setCreatedDate<GearModel<T>>({
      ...gearModel,
      creatorId: riderId
    });

    const id = (GearModels as ZefCollection<GearModel<T>>).insertSync(gearModelToPersist);

    EventsQueries.insertEvent({
      eventType: EventType.created,
      createdAt: gearModelToPersist.createdAt,
      entities: [
        {
          type: EntityType.gearModels,
          id: id,
          shortName: gearModelToPersist.shortName
        },
        {
          type: EntityType.brands,
          id: brand._id,
          shortName: brand.shortName
        }
      ],
      actorId: riderId
    });

    return id;
  }

  static updateGearModel<T extends WithSize>(gearModelToUpdate: GearModelToUpdate, riderId: Id): number {
    const { gearModel, brand } = this.getGearModelAndBrandObjects<T>(gearModelToUpdate);

    const gearModelToPersist: ToPersist<GearModel<T>> = {
      ...gearModel,
      updatedAt: new Date(),
      updaterId: riderId
    };

    const selector = { _id: gearModelToUpdate._id };

    const updated = (GearModels as ZefCollection<GearModel<T>>).updateSync(selector, { $set: gearModelToPersist });

    if (updated === 1) {

      EventsQueries.insertEvent({
        eventType: EventType.updated,
        createdAt: gearModelToPersist.updatedAt,
        entities: [
          {
            type: EntityType.gearModels,
            id: gearModelToUpdate._id,
            shortName: gearModelToPersist.shortName
          },
          {
            type: EntityType.brands,
            id: brand._id,
            shortName: brand.shortName
          }
        ],
        actorId: riderId
      });
    }

    return updated;
  }

  static getVariantCharacteristics(subType: ProductSubType, gearVariant: GearVariant): WithSize {
    // TODO should handle specific dimensions like "construction"

    switch (subType) {
      case BoardType.surfBoard:
      case BoardType.windsurfBoard:
      case BoardType.wingBoard:
      case BoardType.paddleBoard:
      case BoardType.kiteBoard:
        // eslint-disable-next-line no-case-declarations
        const kiteBoard = gearVariant as (GearVariantKiteBoard | GearVariantWingBoard | GearVariantPaddleBoard);

        // Order matters! If lengthFt is set, use it in priority
        if (kiteBoard.lengthFt) {
          return { size: kiteBoard.lengthFt };
        }
        // Otherwise, volume
        if (kiteBoard.volumeL) {
          return { size: kiteBoard.volumeL.toString() };
        }
        if (kiteBoard.lengthCm) {
          return { size: kiteBoard.lengthCm.toString() };
        }
        throw `${subType} variant doesn't have neither lengthFt or lengthCm or volumeL: ${JSON.stringify(gearVariant)}`;

      case PropulsionType.kite:
      case PropulsionType.windsurfSail:
      case PropulsionType.wing:
        return { size: (gearVariant as GearVariantWing).surfaceM2.toString() };
    }

    throw `${subType} variant: can't find size for: ${JSON.stringify(gearVariant)}`;
  }

  /*
  private static doAllVariantsHaveSameValueForDimension<T>(variants: ProductVariant<T>[], key: keyof T): boolean {
    const firstValue = variants[0].variant[key] // Note there is always at least one variant
    // There is not a single variant that has a different value => it means they all have the same value
    return variants.findIndex(v => v.variant[key] !== firstValue) === -1;
  }
   */

  private static getGearModelAndBrandObjects<T extends WithSize>(gearModel: GearModelToInsert | GearModelToUpdate): {
    gearModel: Omit<AnyModel<T>, '_id' | 'createdAt'>,
    brand: Brand
  } {
    const brand = Brands.findOne({ _id: gearModel.brandId });

    const shortName = ShortNames.gearModel({ brand, name: gearModel.name, year: gearModel.year });

    let {
      type,
      subType
    } = gearModel as (KiteBoardToInsert & KiteToInsert); // Little trick to make sure we take all properties, even the specific ones
    const {
      kiteBoardType,
      kiteType,
      name,
      version,
      infoUrl,
      year,
      brandId,
      programs,
      description
    } = gearModel as (KiteBoardToInsert & KiteToInsert); // Little trick to make sure we take all properties, even the specific ones

    // If updating, take the existing type and sub-type (cannot be changed by user)
    if ((gearModel as GearModelToUpdate)._id) {
      const existingModel = GearModels.findOne({ _id: (gearModel as GearModelToUpdate)._id });
      type = existingModel.type;
      subType = existingModel.subType;
    }

    const variants: ProductVariant<T>[] = gearModel.variants.map(v => {
      const characteristicVariant = this.getVariantCharacteristics(subType, v) as Partial<T>;
      const originalVariant = v.variant?.reduce(
        (accumulator: { [key: string]: unknown }, { key, value }: { key: string, value: unknown }) => {
          accumulator[key] = value;
          return accumulator;
        },
        {}
      );

      const lengthFtNumber = !nullOrUndefined((v as GearVariantWingBoard).lengthFt)
        ? feetParse((v as GearVariantWingBoard).lengthFt!)
        : undefined;

      return {
        ...v,
        lengthFt: lengthFtNumber,
        variant: {
          // Note: set original variant first, to make sure "size" is overridden by characteristicVariant if needed
          ...originalVariant,
          ...characteristicVariant
        }
      };
    });

    const dimensions: (keyof T)[] = variants.reduce((accumulator: (keyof T)[], variant: ProductVariant<WithSize>) => {
      accumulator.push(...(Object.keys(variant.variant) as (keyof T)[]));
      return [...new Set(accumulator)];
    }, []);

    /*

    // Now verify that each dimension is relevant, do the cleanup if needed
    for (let i = dimensions.length - 1; i >= 0; i--) {
      const keyToCheck = dimensions[i];
      if (this.doAllVariantsHaveSameValueForDimension(variants, keyToCheck)) {
        // Remove key from the variants
        variants = variants.map(variant => {
          const {size, ...rest} = variant.variant
          return {
            ...variant,
            variant: rest as Partial<T>
          }
        })

        // Remove it from the dimensions
        dimensions.pop()
      }
    }
     */

    const gearModelObject = {
      type,
      subType,
      kiteBoardType,
      kiteType,
      name,
      shortName,
      version,
      infoUrl,
      year,
      brandId,
      brandName: brand.name,
      programs: programs ?? [],
      abstract: {},
      description: fromInput(description) ?? {},
      variants,
      dimensions
    };

    // Don't pollute the object with undefined properties
    if (kiteType) {
      gearModelObject.kiteType = kiteType;
    }
    if (kiteBoardType) {
      gearModelObject.kiteBoardType = kiteBoardType;
    }

    return {
      gearModel: gearModelObject,
      brand
    };
  }

  static getGearItemModelBrand = (gearItemId: Id): GearItemAndModel | undefined => {
    const item = GearItems.findOne({ _id: gearItemId });
    if (!item) {
      return undefined;
    }
    const model = GearModels.findOne({ _id: item.gearModelId });
    if (!model) {
      return undefined;
    }

    return {
      item,
      model,
      brandName: model.brandName
    };
  };

  static getMapToGearModelAggregateSteps(quiver: string, quiver_itemId: string, out_quiver: string) {
    const entity_id: keyof Entity = '_id';
    const quiver_type: keyof QuiverItem = 'type';
    const quiver_subType: keyof QuiverItem = 'subType';
    const quiver_characteristic: keyof QuiverItem = 'characteristic';

    const detail_modelId: keyof DetailedGearItem = 'modelId';
    const detail_variant: keyof DetailedGearItem = 'variant';
    const detail_brandName: keyof DetailedGearItem = 'brandName';
    const detail_year: keyof DetailedGearItem = 'year';
    const detail_avatar: keyof DetailedGearItem = 'avatar';
    const detail_type: keyof DetailedGearItem = 'type';
    const detail_subType: keyof DetailedGearItem = 'subType';
    const detail_compatibleFinFamilies: keyof DetailedGearItem = 'compatibleFinFamilies';
    const detail_characteristic: keyof DetailedGearItem = 'characteristic';

    const gearItem_modelId: keyof GearItem = 'gearModelId';
    const gearItem_id: keyof GearItem = '_id';
    const gearItem_variant: keyof GearItem = 'variant';
    const gearItem_avatar: keyof GearItem = 'avatar';
    const gearItem_type: keyof GearItem = 'type';
    const gearItem_subType: keyof GearItem = 'subType';
    const gearItem_compatibleFinFamilies: keyof WindsurfBoard<unknown> = 'compatibleFinFamilies';
    const gearItem_characteristic: keyof GearItem = 'characteristic';

    const gearModel_id: keyof GearModel<unknown> = '_id';
    const gearModel_brandName: keyof GearModel<unknown> = 'brandName';
    const gearModel_year: keyof GearModel<unknown> = 'year';
    const gearModel_avatar: keyof GearModel<unknown> = 'avatar';

    const getGearProp = (itemName: string, outName: string, alternateProp: string | undefined = undefined) => ({
      $addFields: {
        [`${out_quiver}.${outName}`]: {
          $cond: [
            // If looked up element is not empty, then take the first one
            {
              $ne: ['$LOOKEDUP', []]
            },
            {
              $arrayElemAt: [`$LOOKEDUP.${itemName}`, 0]
            },
            // Otherwise, if an alternate property has been provided, use it. Last resort, is undefined
            alternateProp ? `$${quiver}.${alternateProp}` : undefined
          ]
        }
      }
    });

    const projectNamedEntity = namedEntityFields
      .filter(key => key !== gearModel_avatar) // We want to ignore gear model avatar in favor of gear item avatar
      .map((key) => getGearProp(key, key));

    return [
      // First stage: get gear item and some info (mainly variant)
      {
        $unwind: {
          path: `$${quiver}`,
          preserveNullAndEmptyArrays: true
        }
      },
      {
        $lookup: {
          from: EntityType.gearItems,
          localField: `${quiver}.${quiver_itemId}`,
          foreignField: gearItem_id,
          as: 'LOOKEDUP'
        }
      },
      // For these, try to lookup gear item but if not found, take "local" properties
      getGearProp(gearItem_type, detail_type, quiver_type),
      getGearProp(gearItem_subType, detail_subType, quiver_subType),
      getGearProp(gearItem_characteristic, detail_characteristic, quiver_characteristic),

      getGearProp(gearItem_modelId, detail_modelId),
      getGearProp(gearItem_compatibleFinFamilies, detail_compatibleFinFamilies),
      getGearProp(gearItem_variant, detail_variant),
      getGearProp(gearItem_avatar, detail_avatar),
      // Second stage: now get gear model info
      {
        $lookup: {
          from: EntityType.gearModels,
          localField: `${out_quiver}.${detail_modelId}`,
          foreignField: gearModel_id,
          as: 'LOOKEDUP'
        }
      },
      getGearProp(gearModel_brandName, detail_brandName),
      getGearProp(gearModel_year, detail_year),
      ...projectNamedEntity,
      {
        $project: {
          [`${out_quiver}.${gearModel_id}`]: 0,
          'LOOKEDUP': 0
        }
      },
      // Finally, group back by session
      // @see https://stackoverflow.com/questions/52233768/mongodb-aggregation-group-specify-a-default-operator
      // To re-group by id, push quiver, but preserve the rest
      { $addFields: { tempRoot: '$$ROOT' } },
      {
        $group: {
          _id: `$${entity_id}`,
          [out_quiver]: {
            $push:
              {
                // Only push non empty (nulls have been preserved so far so we want to remove completely null items)
                $cond: {
                  if: `$${out_quiver}.${quiver_type}`,
                  then: `$${out_quiver}`,
                  else: '$$REMOVE'
                }
              }
          },
          tempRoot: { $first: '$tempRoot' }
        }
      },
      {
        $replaceRoot: {
          newRoot: {
            $mergeObjects: ['$tempRoot', { [out_quiver]: `$${out_quiver}` }]
          }
        }
      }
    ];
  }

  static async tryToGetGearModelAndBrand(gearModelShortName: string): Promise<GearModelAndBrand> {
    const gearModel_shortName: keyof GearModel<unknown> = 'shortName';

    let foundDocuments = await this.getGearModelAndBrands({ [gearModel_shortName]: gearModelShortName });

    if (!foundDocuments[0]) {
      // No direct match
      // Now try a "like"
      const regexp = new RegExp(`${gearModelShortName}.*`);
      foundDocuments = await this.getGearModelAndBrands({ [gearModel_shortName]: regexp });
    }

    return foundDocuments[0];
  }

  /**
   * Get a list of models with their brand
   * @param selector
   */
  static async getGearModelAndBrands(selector: Selector<GearModel<unknown>>): Promise<GearModelAndBrand[]> {
    const aggregate = GearModels.getAggregateAsync<GearModelAndBrand>();

    const gearModel_brandId: keyof GearModel<unknown> = 'brandId';
    const gearModel_year: keyof GearModel<unknown> = 'year';
    const brand_id: keyof Brand = '_id';

    const out_brand: keyof GearModelAndBrand = 'brand';
    const out_model: keyof GearModelAndBrand = 'model';

    const aggregateQuery = [
      {
        $match: selector
      },
      {
        $project: {
          [out_model]: '$$ROOT'
        }
      },
      {
        $lookup: {
          from: EntityType.brands,
          localField: `${out_model}.${gearModel_brandId}`,
          foreignField: brand_id,
          as: out_brand
        }
      },
      {
        $unwind: `$${out_brand}` // Unwind but should be just one
      },
      {
        $sort: {
          [`${out_model}.${gearModel_year}`]: -1 // show the latest gear first
        }
      },
      PhotosQueries.aggregatePhotos(EntityType.gearModels)
    ];

    return await aggregate(aggregateQuery).toArray();
  }

  static async completeSimpleItem<T>(simpleGearItem: SimpleGearItem, selector: EntitySelector): Promise<{
    brand: Brand,
    item: ToImport<GearItem>,
    model: GearModel<T>
  }> {
    // TODO getGearModelAndBrands to return gearmodel and brand
    const gearModelAndBrand = await this.getGearModelAndBrands(selector);
    if (gearModelAndBrand[0] === undefined)
      throw `Could not find gear model for ${JSON.stringify(selector)}`

    const { model, brand, photos } = gearModelAndBrand[0];
    const { variants, type, subType, _id } = model;

    // In the model, extract information regarding the variant (if not found, throw)
    const foundVariant = getClosestVariant(simpleGearItem.variant, variants);

    if (!foundVariant) {
      throw new ZefError(ErrorType.VariantNotFound, `Variant nof found`, JSON.stringify(simpleGearItem.variant));
    }

    const compatibleFinFamilies = getCompatibleFinFamilies(subType, foundVariant);
    const characteristic = getCharacteristic(subType, foundVariant);

    // Find a picture that matches
    const listOfVariants: (Photo<unknown> & { variant: Partial<unknown> })[] = photos.map(p => ({
      ...p,
      variant: (p.entity.variant as Variant)
    }));
    const foundPicture = getClosestVariant(simpleGearItem.variant, listOfVariants);

    return {
      item: {
        avatar: foundPicture ? getThumbnailUrl(foundPicture.url) : undefined,
        ...simpleGearItem,
        createdAt: simpleGearItem.createdAt,
        type,
        subType,
        compatibleFinFamilies,
        gearModelId: _id,
        characteristic
      },
      brand,
      model
    };
  }

  static search(text: string): Promise<SearchedGearModel[]> {
    const otherFields: (keyof GearModelToSearch)[] = ['brandName', 'year', 'version', 'type', 'subType'];
    return search<GearModelToSearch>(GearModels as ZefCollection<Entity>, getProjection<GearModelToSearch>(otherFields))(text).toArray();
  }

  static async searchGearModels({
                                  search,
                                  brandId,
                                  productSubType,
                                  year
                                }: GearModelSearchInput): Promise<GearModelListItem[]> {
    if (!search && !brandId && !productSubType && !year) {
      throw `searchGearModels requires at least one search input property`;
    }

    const mongoAggregate = GearModels.getAggregateAsync<GearModelListItem>();
    const brand_id: keyof Brand = '_id';
    const gearModel_brandId: keyof GearModel<unknown> = 'brandId';
    const gearModel_subType: keyof GearModel<unknown> = 'subType';
    const gearModel_year: keyof GearModel<unknown> = 'year';
    const outputKeys: (keyof GearModelListItem)[] = [...namedEntityFields, 'year', 'type', 'subType', 'brandName', 'brandId'];
    const out_score: keyof GearModelListItem = 'score';
    const out_brandName: keyof GearModelListItem = 'brandName';
    const projection = getProjection<GearModelListItem>(outputKeys);

    const aggregation = [];

    // Mongo requires that search is the first aggregation last
    if (search) {
      aggregation.push(...getSearchAggregation(search, projection));
    }

    if (brandId) {
      aggregation.push({
        $match: { [gearModel_brandId]: brandId }
      });
    }

    if (productSubType) {
      aggregation.push({
        $match: { [gearModel_subType]: productSubType }
      });
    }

    if (year) {
      aggregation.push({
        $match: { [gearModel_year]: year }
      });
    }

    aggregation.push(
      {
        $lookup: {
          from: EntityType.brands,
          localField: gearModel_brandId,
          foreignField: brand_id,
          as: 'brand'
        }
      },
      {
        $unwind: `$brand` // Unwind but should be just one
      },
      {
        $project: {
          ...projection,
          [out_score]: 1,
          [out_brandName]: '$brand.name'
        }
      }
    );

    return (await mongoAggregate(aggregation).toArray());
  }

  static async getRandomPhotos(productSubType: ProductSubType, count: number): Promise<string[]> {
    const mongoAggregate = GearModels.getAggregateAsync<Pick<GearModel<unknown>, '_id' | 'avatar'>>();
    const model_subType: keyof GearModel<unknown> = 'subType';
    const model_avatar: keyof GearModel<unknown> = 'avatar';
    const aggregation = [
      {
        $match: {
          [model_subType]: productSubType,
          [model_avatar]: { $exists: true }
        }
      },
      { $sample: { size: count } },
      { $project: { [model_avatar]: 1 } }
    ];
    return (await mongoAggregate(aggregation).toArray()).map(b => b.avatar!);
  }

  static getGearModelEntities(ids: Id[]) {
    return GearModels.find({ _id: { $in: ids } }).fetch();
  }


  /**
   * Update gear items that don't have avatars, while their gear model has one
   */
  static async updateGearItemsAvatars() {
    const aggregate = GearItems.getAggregateAsync<unknown>();

    const item_modelId: keyof GearItem = 'gearModelId';
    const item_avatar: keyof GearItem = 'avatar';

    const out_model = 'model';

    const aggregateQuery = [
      // Items without avatars
      {
        $match: {[item_avatar]: {$exists: false}}
      },
      // Find corresponding model
      {
        $lookup: {
          from: EntityType.gearModels,
          localField: item_modelId,
          foreignField: '_id',
          as: out_model
        }
      },
      // Array of models => one entry per model (but should be only one!)
      {
        $unwind: {
          path: `$${out_model}`
        }
      },
      // Corresponding model has an avatar
      {
        $match: {[`${out_model}.avatar`]: {$exists: true}}
      },
      {
        $set: {
          // Simply use this avatar for the gear item
          [item_avatar]: `$${out_model}.avatar`,
          // Remove projected model
          [out_model]: undefined
        }
      },
      // Update collection
      {
        $out: EntityType.gearItems
      }
    ];

    return (await aggregate(aggregateQuery).toArray())[0];
  }
}

