import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {capitalize} from 'lodash';
import {mapDictionary, nullOrUndefined} from '@core/utils';
import {IonSelect} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

enum ToggleValue {
  UNKNOWN = 'UNKNOWN',
  NONE = 'NONE',
  SOME = 'SOME'
}

@Component({
  selector: 'zef-enum-multi-input',
  templateUrl: './enum-multi-input.component.html',
  styleUrls: ['./enum-multi-input.component.scss']
})
export class EnumMultiInputComponent<Enum extends Record<string, string>> implements OnInit {
  @Input() set enumType(type: Enum) {
    /**
     * 0: "OPEN_SEA"
     * 1: "LAKE"
     * 2: "RIVER"
     * 3: "SALTED_LAKE"
     *
     * ======= keep only this part
     * LAKE: 1
     * OPEN_SEA: 0
     * RIVER: 2
     * SALTED_LAKE: 3
     */
    this.enumDict = mapDictionary({ ...type }, (key, value) => ({ key, value: <Enum> <unknown> value }), (key) => isNaN(parseInt(key)));
    this.reverseEnumDict = mapDictionary(this.enumDict, (key, value) => ({ key: <string> <unknown> value, value: key }));
  }

  @Input() allowUnknown = false;
  @Input() showLabel = false
  @Input() allowNone = false;
  @Input() enumName: string;
  @Input() model: Enum[];
  @Output() modelChange = new EventEmitter<Enum[]>();

  @ViewChild('template', { static: true }) template;
  @ViewChild('toggleSelect', { static: false }) toggleSelectRef: IonSelect;
  @ViewChild('valueSelect', { static: false }) selectRef: IonSelect;

  capitalize = capitalize;
  ToggleValue = ToggleValue;
  keys = Object.keys;


  get toggleValue() {
    if (nullOrUndefined(this.model)) {
      return ToggleValue.UNKNOWN;
    }

    // this.model === [] won't work
    if (Array.isArray(this.model) && this.model.length === 0) {
      return ToggleValue.NONE;
    }

    return ToggleValue.SOME;
  }

  enumDict: { [key: string]: Enum };
  private reverseEnumDict: { [p: string]: string };

  constructor(
    private viewContainerRef: ViewContainerRef,
    private translate: TranslateService) {
  }

  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
  }

  changeToggle($event: any) {
    if (!this.toggleSelectRef) {
      return;
    }
    switch ($event.detail.value) {
      case ToggleValue.UNKNOWN:
        this.model = undefined;
        this.modelChange.emit(this.model);
        this.toggleSelectRef.selectedText = null;
        break;
      case ToggleValue.NONE:
        this.model = [];
        this.modelChange.emit(this.model);
        this.toggleSelectRef.selectedText = null;
        break;
      default:
        this.selectRef.open();
    }
  }

  getFormattedValue(value = this.model) {
    return value
      ?.map(k => capitalize(this.translate.instant('ENUM.' + this.enumName + '.VALUES.' + this.reverseEnumDict[<string> <unknown> k], { count: 1 })))
      .join(', ');
  }

  changeValue(value: Enum[]) {
    this.modelChange.emit(value);
    // Need to update the displayed value of the "main" ion-select
    if (this.toggleSelectRef) {
      this.toggleSelectRef.selectedText = this.getFormattedValue(value);
    }
  }
}
