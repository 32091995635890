import {IonicModule} from '@ionic/angular';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TabsPageRoutingModule} from '@pages/tabs/tabs-routing.module';
import {TabsPage} from '@pages/tabs/tabs.page';
import {TabBadgeComponent} from '@components/page-tabs/tab-badge.component';
import {TabIconComponent} from '@components/page-tabs/tab-icon.component';
import {EntityModule} from '@components/entity/entity.module';
import {NgIconsModule} from '@ng-icons/core';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';

@NgModule({
  imports: [IonicModule, CommonModule, FormsModule, TabsPageRoutingModule, EntityModule, NgIconsModule, TranslateModule, RouterModule],
  exports: [
    TabBadgeComponent, TabIconComponent
  ],
  declarations: [TabsPage, TabBadgeComponent, TabIconComponent]
})
export class TabsPageModule {
}
