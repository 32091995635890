import {Injectable} from "@angular/core";
import {Id} from "@model/entity";
import {StartSessionComponent} from "@components/session/edit-session/start-session/start-session.component";
import {EditSessionComponent} from "@components/session/edit-session/edit-session/edit-session.component";
import {EditObservationComponent} from "@components/observation/edit-observation/edit-observation.component";
import {EditBookingComponent} from "@components/booking/create-booking/edit-booking.component";
import {AddTrackLogComponent} from "@components/tracklog/add-tracklog/add-track-log.component";
import {ModalController} from "@ionic/angular";

export interface ActionContext {
  spotId?: Id;
  date?: Date;
}

@Injectable({
  providedIn: 'root'
})
export class ActionMenuService {
  context: ActionContext = {};

  constructor(private modalController: ModalController) {}

  async startSession() {
    const componentProps: Partial<StartSessionComponent> = {
      spotId: this.context.spotId,
    };
    const modal = await this.modalController.create({
      component: StartSessionComponent,
      componentProps
    });
    return await modal.present();
  }

  async recordOldSession() {
    const componentProps: Partial<EditSessionComponent> = {
      spotId: this.context.spotId,
      spotDayDate: this.context.date ? new Date(Math.min(new Date().getTime(), this.context.date.getTime())) : undefined // Old session cannot be in the future
    };
    const modal = await this.modalController.create({
      component: EditSessionComponent,
      componentProps
    });
    return await modal.present();
  }

  async addObservation() {
    const componentProps: Partial<EditObservationComponent> = {
      spotId: this.context.spotId
    };
    const modal = await this.modalController.create({
      component: EditObservationComponent,
      componentProps
    });
    return await modal.present();
  }

  async addBooking() {
    const componentProps: Partial<EditBookingComponent> = {
      spotId: this.context.spotId,
      spotDayDate: this.context.date ? new Date(Math.max(new Date().getTime(), this.context.date.getTime())) : undefined // Booking cannot be in the past
    };
    const modal = await this.modalController.create({
      component: EditBookingComponent,
      componentProps
    });
    return await modal.present();
  }

  async uploadGPX() {
    const modal = await this.modalController.create({
      component: AddTrackLogComponent,
      componentProps: {}
    });
    return await modal.present();
  }
}
