<ion-item *ngFor='let withLanguageText of model; let index = index' lines='inset' class="transparent">
  <ion-label
    position='floating'
    [ngClass]='{input: true, small}'
  >{{ capitalize("MULTI_LANGUAGE.INPUT.PLACEHOLDER" | translate : {
    lang: capitalize(this.langDict[withLanguageText.lang].name),
    field: title
  }) }}</ion-label>
  <ion-textarea
    [ngModel]='withLanguageText.text'
    (ngModelChange)='update(index, $event)'
    auto-grow="true"
    placeholder='{{ capitalize("MULTI_LANGUAGE.INPUT.PLACEHOLDER" | translate : {
    lang: capitalize(this.langDict[withLanguageText.lang].name),
    field: placeholder
  }) }}'></ion-textarea>
</ion-item>
<!--
<ion-item *ngIf='availableLangList.length > 0'>
  <ion-button color='light' (click)='chooseLang.open()' slot='end'>
    <ion-icon name='add-circle-outline'></ion-icon>
    {{ "MULTI_LANGUAGE.INPUT.ADD" | translate }}</ion-button>
  <ion-select #chooseLang id='chooseLang' interface='popover'
              placeholder='{{ "MULTI_LANGUAGE.INPUT.ADD" | translate }}'
              (ionChange)='add($event)'>
    <ion-select-option *ngFor='let lang of availableLangList' value='{{lang.code}}'>
      {{ lang.name }}
    </ion-select-option>
  </ion-select>
</ion-item>
-->
