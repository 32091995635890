import {EntityLink} from './entity-link';
import {Photo} from './photo';
import {EntityType} from './entity-type';

const imageKitRoot = 'https://ik.imagekit.io/zef/';

export const getTransformationUrl = (url: string, transformation: string) => url?.startsWith(imageKitRoot)
  ? `${(url.split(/[?#]/)[0])}?tr=${transformation}`
  : url;

export const getDefaultPhoto = (entityLink: EntityLink): Photo<unknown> | undefined => {
  const base = {
    _id: '0',
    entity: entityLink,
    createdAt: new Date()
  };
  switch (entityLink?.type) {
    case EntityType.spots:
      return {
        ...base,
        url: 'https://ik.imagekit.io/zef/stock/pexels-tamba-budiarsana-244466_1wioqEjoj.jpg',
        width: 2048,
        height: 1365
      };
    case EntityType.spotLaunches:
      return {
        ...base,
        url: 'https://ik.imagekit.io/zef/stock/pexels-gya-den-2303781_irrQXrpu2F.jpg',
        width: 3707,
        height: 3806
        /*url: 'https://ik.imagekit.io/zef/stock/pexels-daniel-wheeler-1179117_d7UfWxe7c.jpg',
        width: 5184,
        height: 3456
         */
      };
    default:
      return undefined;
  }
};

export const getThumbnailUrl = (url: string): string => getTransformationUrl(url, 'n-media_library_thumbnail');

export const getDefaultAvatar = (entityType: EntityType | undefined): string | undefined => {
  if (!entityType) {
    return undefined;
  }
  const defaultPhoto = getDefaultPhoto({ type: entityType });
  return defaultPhoto
    ? getThumbnailUrl(defaultPhoto.url)
    : undefined;
};
