import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TextAvatarModule } from '@components/text-avatar';
import { ImagekitioAngularModule } from 'imagekitio-angular';
import { NgIconsModule } from '@ng-icons/core';
import { PipesModule } from '@pipes/pipes.module';
import { EntityUpdatedComponent } from '@components/entity-updated/entity-updated.component';
import { RiderModule } from '@components/rider/rider.module';

@NgModule({
  declarations: [
    EntityUpdatedComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    TextAvatarModule,
    ImagekitioAngularModule,
    NgIconsModule,
    PipesModule,
    RiderModule
  ],
  exports: [
    EntityUpdatedComponent
  ]
})
export class EntityUpdatedModule {}
