import {Injectable} from '@angular/core';
import {EntityType, EntityTypeWithCollection} from '@model/entity-type';
import {ridersPageInfo} from '@pages/riders/riders.page.info';
import {spotsPageInfo} from '@pages/spots/spots.page.info';
import {Link, LinkType, SocialLinkType} from '@model/link';
import {SpotLaunchProperties} from '@model/spot-launch';
import {forecastPageInfo} from '@pages/forecast/forecast.page.info';
import {DivIcon, divIcon} from 'leaflet';
import {SpotProperties} from '@model/spot';
import {gearBrandsPageInfo} from "@pages/gear/gear-brands/gear-brands.page.info";
import {icons} from "../icons";
import { gearPageInfo } from '@pages/gear/gear.page.info';

@Injectable({
  providedIn: 'root'
})
export class IconService {
  get(entityType: EntityType
    | 'links' | 'edit' | 'share' | 'copy',
      subType?: EntityType): keyof typeof icons {
    switch (entityType) {
      case EntityType.spots:
        return spotsPageInfo.icon;
      case EntityType.spotDays:
        return 'ion-calendar-outline';
      case EntityType.riders:
        return ridersPageInfo.icon;
      case EntityType.gearModels:
        return gearPageInfo.icon;
      case EntityType.brands:
        return gearBrandsPageInfo.icon;
      case EntityType.comments:
        return 'ion-chatbox-ellipses';
      case EntityType.bookings:
        return 'mat-edit-calendar';
      case EntityType.observations:
        return 'ion-eye';
      case EntityType.sessions:
        return 'ion-speedometer';
      case EntityType.subscriptions:
        return this.getFollowing(subType, true);
      case EntityType.events:
        return 'ion-notifications-circle';
      case EntityType.potentials:
      case EntityType.forecasts:
        return forecastPageInfo.icon;
      case EntityType.stats:
        return 'ion-stats-chart';
      case EntityType.spotLaunches:
        return 'jam-arrow-square-down-f';
      case EntityType.photos:
        return 'ion-image';
      case EntityType.headlines:
        return 'bootstrap-megaphone-fill';
      case 'links':
        return 'jam-link';
      case 'edit':
        return 'ion-ellipsis-horizontal';
      case 'share':
        return 'ion-share-social-outline';
      case 'copy':
        return 'ion-copy-outline';
    }
  }

  getSvg(entityType: EntityType): string {
    return icons[this.get(entityType)];
  }

  getLeafletIcon(entityType: EntityType): DivIcon {
    switch (entityType) {
      case EntityType.spotLaunches:
        const launchIcon = this.getSvg(entityType);

        return divIcon({
          html: launchIcon,
          iconSize: [30, 30] // See map.component.scss as well: needed to center the icon correctly
        });
      default:
        throw `Unsupported icon type for leaflet: ${entityType}`;
    }
  }

  // FIXME move to ng-icon
  getExternal(urlType: SocialLinkType): string {
    switch (urlType) {
      case SocialLinkType.instagram:
        return 'logo-instagram';
      case SocialLinkType.youtube:
        return 'logo-youtube';
      case SocialLinkType.twitter:
        return 'logo-twitter';
      case SocialLinkType.vimeo:
        return 'logo-vimeo';
      case SocialLinkType.facebook:
        return 'logo-facebook';
    }
  }

  // FIXME move to ng-icon
  getLink(link: Link): string | undefined {
    switch (link.type) {
      case LinkType.information:
        return 'ion-information-circle-outline';
      case LinkType.live_report:
        return 'ion-rainy-outline';
      case LinkType.webcam:
        return 'ion-videocam-outline';
      case LinkType.club:
        return 'mat-groups';
      case LinkType.shop:
        return 'mat-storefront-outline';
    }
    return undefined;
  }

  getSpotLaunchProperty<T>(propertyType: (keyof SpotLaunchProperties), propertyValue: T): keyof typeof icons {
    let icon;
    switch (propertyType) {
      case 'publicAccess':
        return propertyValue ? 'mat-lock-open-outline' : 'mat-lock';
      case 'freeParking':
        return propertyValue ? 'tabler-receipt-off' : 'tabler-receipt2';
      case 'hasShower':
        icon = 'mat-shower';
        break;
      case 'hasToilets':
        icon = 'mat-wc';
        break;
      case 'suitableForKite':
        icon = 'mat-kitesurfing';
        break;
      case 'bottomType':
        // TODO symmetry via CSS: transform: scale(-1, 1);
        return 'tabler-stairs-down';
      case 'gearForRent':
        icon = 'mat-storefront';
        break;
    }

    if (icon) {
      return propertyValue ? icon : `${icon}-outline`;
    }
    return undefined;
  }

  getSpotProperty<T>(propertyType: (keyof SpotProperties), propertyValue: T): keyof typeof icons {
    switch (propertyType) {
      case 'depth':
        return 'iconoir-depth';
      case 'entryLevel':
        return 'ion-school';
      case 'dangers':
        return (propertyValue as unknown as Array<unknown>).length === 0 ? 'jam-triangle-danger' : 'jam-triangle-danger-f';
      case 'spotType':
        return 'mat-sailing';
    }
    return undefined;
  }

  getFollowing(entityType: EntityType, following: boolean): keyof typeof icons {
    if (following) {
      return entityType === EntityTypeWithCollection.riders ? 'bootstrap-person-check-fill' : 'mat-favorite';
    }
    return entityType === EntityTypeWithCollection.riders ? 'bootstrap-person-plus' : 'mat-favorite-border';
  }

  getWindAlert(on: boolean): keyof typeof icons {
    return on ? 'mat-notifications' : 'mat-notifications-off';
  }

  getForecastUpdate(): keyof typeof icons {
    return 'mat-autorenew'
  }
}
