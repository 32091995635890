import {Pipe, PipeTransform} from '@angular/core';
import {TranslateConfigService} from '@services/translate-config.service';
import {IdentifiedGearItem} from "@components/gear/gear-item-item/gear-item-item.component";
import {NamedEntity} from "@model/entity-with-description";
import {ShortNames} from "@model/short-names";
import {toDict} from "@core/utils";
import {Comment} from "@model/comment";
import {MultiLanguageText} from "@model/multi-language-text";
import {SurfBoard, WindsurfBoard, WindsurfSail} from "@model/gear/variants";
import {Wing} from "@model/gear/variants/wing";
import {WingBoard} from "@model/gear/variants/wing-board";
import {EmojiSearch} from "@ctrl/ngx-emoji-mart";
import {PerEmojiReactions, Reactions} from "@components/reaction/reaction.component";
import {Stats} from "@api/queries";
import {activities} from "@model/activity";
import {date, firstName, imageUrlFromPlaceIMG, lastName, name, number} from 'minifaker'
import {DateUtils} from "@core/date-utils";
import 'minifaker/locales/en' // the first locale import is set as default
import 'minifaker/locales/fr'

@Pipe({
  name: 'fake'
})
export class FakePipe implements PipeTransform {
  constructor(
    protected translateConfig: TranslateConfigService,
    private emojiSearch: EmojiSearch,
  ) {
  }

  transform(quiverItem: IdentifiedGearItem): IdentifiedGearItem {
    return {
      ...quiverItem,
      brandName: this.getBrandName(),
      name: this.getProductName(),
      year: this.getProductYear()
    };
  }

  getProductYear() {
    return date({
      from: new Date(new Date().getTime() - DateUtils.ONE_DAY_IN_MS * 365 * 10),
      to: new Date(new Date().getTime() + DateUtils.ONE_DAY_IN_MS * 365),
    }).getFullYear();
  }

  getProductName() {
    return firstName()
  }

  getProductPicture() {
    return imageUrlFromPlaceIMG(
      {
        width: 56,
        height: 56,
        category: 'tech'
      }
    );
  }

  static getRandomNumber(min: number, max: number): number {
    return number({min, max})
  }

  getFakeProductVariant<T = {}>(): SurfBoard<T> & WindsurfBoard<T> & WindsurfSail<T> & Wing<T> & WingBoard<T> {
    let fromM2 = FakePipe.getRandomNumber(3, 7);
    return {
      variant: {},
      lengthFt: FakePipe.getRandomNumber(3, 10),
      lengthCm: FakePipe.getRandomNumber(78, 455),
      compatibleFinFamilies: [],
      fins: [],
      sailRange: {fromM2, toM2: FakePipe.getRandomNumber(fromM2, 14)},
      strapInsertCount: FakePipe.getRandomNumber(0, 5),
      volumeL: FakePipe.getRandomNumber(10, 400),
      weightKg: FakePipe.getRandomNumber(1, 12),
      widthCm: FakePipe.getRandomNumber(10, 200),
      thicknessCm: FakePipe.getRandomNumber(5, 150),
      battenCount: FakePipe.getRandomNumber(1, 10),
      boomLengthsCm: [],
      camCount: 0,
      luffLengthCm: 0,
      masTypes: [],
      mastExtensionLengthsCm: [],
      mastIMCS: [],
      mastLengthsCm: [],
      surfaceM2: FakePipe.getRandomNumber(3.4, 10.14),
      topType: undefined
    }
  }

  getBrandName() {
    return firstName()
  }

  static getFakeRider(): NamedEntity {
    let fullName = name();
    return {
      name: fullName,
      _id: '',
      avatar: imageUrlFromPlaceIMG(
        {
          width: 56,
          height: 56,
          category: 'people'
        }
      ),
      createdAt: new Date(),
      shortName: ShortNames.rider({name: fullName})
    }
  }

  static getRandomSizeArray(min: number, max: number): number[] {
    const count = FakePipe.getRandomNumber(min, max);

    return Array.from(Array(count).keys());
  }

  static getFakeRiderList(min = 0, max = 5): NamedEntity[] {
    return this.getRandomSizeArray(min, max).map(i => this.getFakeRider());
  }

  getFakeCommentList(): Comment[] {
    return FakePipe.getRandomSizeArray(1, 3).map(() => ({
      createdAt: date({
        from: new Date(new Date().getTime() - DateUtils.ONE_DAY_IN_MS * 8),
        to: new Date(),
      }),
      commenterId: '',
      entities: [],
      content: {
        [this.translateConfig.currentLanguage]: FakePipe.getRandomText()
      },
      _id: ''
    }))
  }

  static getRandomText(min: number = 14, max: number = 24) {
    return FakePipe.getRandomSizeArray(min, max).map(() => lastName()).join(' ');
  }

  getFakeEmojiReactions(): Reactions {
    const availableEmojis = Object.keys(this.emojiSearch.emojisList);
    const countEmojis = availableEmojis.length;

    return toDict(
      FakePipe.getRandomSizeArray(0, 8),
      // Key == random emoji
      () => availableEmojis[FakePipe.getRandomNumber(0, countEmojis - 1)],
      // Value == random list of riders & random date
      (): PerEmojiReactions => {
        const riders = FakePipe.getFakeRiderList(1, 4)
        return toDict(
          riders,
          (rider) => rider._id,
          (rider) => ({
            date: date({
              from: new Date(new Date().getTime() - DateUtils.ONE_DAY_IN_MS * 10),
              to: new Date(),
            })
          })
        )
      }
    )
  }

  getFakeAbstract(): MultiLanguageText {
    return {
      [this.translateConfig.currentLanguage]: FakePipe.getRandomText()
    }
  }

  getFakeYearlyStats(): Stats {
    const currentYear = new Date().getFullYear();
    const years = [...Array(2).keys()].map(i => (currentYear - i).toString());

    return {
      flat: years.map(year => ({
        count: FakePipe.getRandomNumber(12, 344),
        maxSpeedKmPerH: FakePipe.getRandomNumber(34, 120),
        distanceKm: FakePipe.getRandomNumber(238, 1023),
        durationSec: FakePipe.getRandomNumber(12, 24 * 45) * 3600,
        year,
        dimensions: null,
      })),
      perActivity: activities.map(activity => years.map(year => ({
        count: FakePipe.getRandomNumber(12, 344),
        maxSpeedKmPerH: FakePipe.getRandomNumber(34, 120),
        distanceKm: FakePipe.getRandomNumber(238, 1023),
        durationSec: FakePipe.getRandomNumber(12, 24 * 45) * 3600,
        year,
        dimensions: {
          activity
        },
      }))).flat()
    }
  }

  static getFakeDate(): Date {
    return date({
      from: new Date(new Date().getTime() - DateUtils.ONE_DAY_IN_MS),
      to: new Date(new Date().getTime() + DateUtils.ONE_DAY_IN_MS),
    })
  }
}
