import {Pipe, PipeTransform} from '@angular/core';
import {EntityType} from '@model/entity-type';
import {TranslateService} from '@ngx-translate/core';
import { appName } from '@model/const';

export enum EntityTypePrefix {
  A,
  PLURAL,
  NEUTRAL
}

@Pipe({
  name: 'entityType'
})
export class EntityTypePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(entityType: EntityType, prefix: EntityTypePrefix = EntityTypePrefix.A): string {
    let count: number;
    switch (prefix) {
      case EntityTypePrefix.A:
        count = 1;
        break;
      case EntityTypePrefix.PLURAL:
        count = 10;
        break;
      case EntityTypePrefix.NEUTRAL:
        count = 0;
        break;
    }

    return this.translate.instant(`ENTITY.${entityType}.type`, { count });
  }

  placeholders = {
    aSession: this.transform(EntityType.sessions),
    session: this.transform(EntityType.sessions, EntityTypePrefix.NEUTRAL),
    aUser: this.transform(EntityType.riders),
    users: this.transform(EntityType.riders, EntityTypePrefix.PLURAL),
    aBrand: this.transform(EntityType.brands),
    APP_NAME: appName,
  };
}
