export enum EntityTypeWithCollection {
  bookings = 'bookings',
  brands = 'brands',
  headlines = 'headlines',
  comments = 'comments',
  events = 'events',
  gearItems = 'gearItems',
  gearModels = 'gearModels',
  observations = 'observations',
  photos = 'photos',
  sessions = 'sessions',
  spotDayEvents = 'spotDayEvents',
  spotDays = 'spotDays',
  spotLaunches = 'spotLaunches',
  spots = 'spots',
  subscriptions = 'subscriptions',
  tricks = 'tricks',
  weatherData = 'weatherData',
  trackLogs = 'trackLogs',
  riders = 'users', // /!\ Special case
  reactions = 'reactions'
}

export enum EntityTypeWithoutCollection {
  forecasts = 'forecasts',
  potentials = 'potentials',
  links = 'links',
  gearVariants = 'gearVariants',
  properties = 'properties',
  stats = 'stats',
  avatar = 'avatar',
  emoji = 'emoji',
  finBoxes = 'finBoxes'
}

export const EntityType = { ...EntityTypeWithCollection, ...EntityTypeWithoutCollection };
export type EntityType = EntityTypeWithCollection | EntityTypeWithoutCollection;
