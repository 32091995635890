import {Entity, Id, Revision, ToPersist} from '@model/entity';
import {Observable} from 'rxjs';
import {MongoObservable} from 'meteor-rxjs';
import {toDict} from '@core/utils';
import Cursor = Mongo.Cursor;
import OptionalId = Mongo.OptionalId;
import Selector = Mongo.Selector;
import ObjectID = Mongo.ObjectID;
import Modifier = Mongo.Modifier;
import Options = Mongo.Options;
import { EntityTypeWithCollection } from '@model/entity-type';

export interface UpsertResult {
  numberAffected?: number;
  insertedId?: string;
}

export const bound = Meteor.bindEnvironment((callback: () => unknown) => {
  callback();
});

export type AsyncAggregateCursor<A> = Cursor<A> & { toArray: () => Promise<A[]> };

export class ZefCollection<T extends Entity> extends MongoObservable.Collection<T> {
  name: EntityTypeWithCollection;

  constructor(nameOrExisting: EntityTypeWithCollection | Mongo.Collection<T>) {
    super(nameOrExisting);
    this.name = nameOrExisting.toString() as EntityTypeWithCollection;
  }

  setUniqueIndexes(value: (keyof T)[][]): this {
    this.uniqueIndexes = value;
    return this;
  }

  setTextIndex(value: (keyof T)[]): this {
    this.textIndex = value;
    return this;
  }

  protected textIndex?: (keyof T)[];
  protected uniqueIndexes?: (keyof T)[][];

  static setCreatedDate<T extends Entity>(doc: ToPersist<T>): ToPersist<T> & { createdAt: Date } {
    return {
      ...doc,
      createdAt: doc.createdAt ?? new Date()
    };
  }

  static getUpdatedDate<T extends { updatedAt?: Date }>(doc: T): Date {
    return doc.updatedAt ?? new Date();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  nativeFind<U extends Partial<T> = T, O extends Options<T> = any>(selector?: Mongo.Selector<T> | Mongo.ObjectID | string, options?: O): Cursor<U> {
    return this.collection.find(selector, options) as unknown as Cursor<U>;
  }

  insert$(doc: ToPersist<T>): Observable<Id> {
    return super.insert(ZefCollection.setCreatedDate(doc) as T);
  }

  insertSync(doc: ToPersist<T>): Id {
    return this.collection.insert(ZefCollection.setCreatedDate(doc) as T as OptionalId<T>);
  }

  removeSync(selector: Selector<T> | Mongo.ObjectID | string): number {
    return this.collection.remove(selector);
  }

  updateSync(selector: Selector<T> | ObjectID | string,
             modifier: Modifier<T>,
             options?: {
               /** True to modify all matching documents; false to only modify one of the matching documents (the default). */
               multi?: boolean | undefined;
               /** True to insert a document if no matching documents are found. */
               upsert?: boolean | undefined;
               /**
                * Used in combination with MongoDB [filtered positional operator](https://docs.mongodb.com/manual/reference/operator/update/positional-filtered/) to specify which elements to
                * modify in an array field.
                */
               arrayFilters?: { [identifier: string]: unknown }[] | undefined;
             },
             callback?: (any: unknown) => unknown): number {
    return this.collection.update(selector, modifier, options, callback);
  }

  upsert(
    selector: Mongo.Selector<T> | Mongo.ObjectID | string,
    modifier: Mongo.Modifier<T>,
    options?: { multi?: boolean }
  ): Observable<number> {
    ZefCollection.sanitizeModifier(modifier);
    return super.upsert(selector, modifier, options);
  }

  upsertSync(
    selector: Mongo.Selector<T> | Mongo.ObjectID | string,
    modifier: Mongo.Modifier<T>,
    options?: { multi?: boolean }
  ): UpsertResult {
    ZefCollection.sanitizeModifier(modifier || {});
    return this.collection.upsert(selector, modifier, options);
  }

  update(
    selector: Mongo.Selector<T> | Mongo.ObjectID | string,
    modifier: Mongo.Modifier<T>,
    options?: { multi?: boolean; upsert?: boolean }
  ): Observable<number> {
    ZefCollection.sanitizeModifier(modifier);
    return super.update(selector, modifier, options);
  }

  getAggregateAsync<A>(): (params: unknown) => AsyncAggregateCursor<A> {
    const rawCollection = this.collection.rawCollection();
    return rawCollection.aggregate.bind(rawCollection);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static sanitizeModifier(modifier: Mongo.Modifier<any>): void {
    // Set "updatedAt" date in case of an update
    const updatedAt: keyof Entity = 'updatedAt';
    if (modifier.$set && !modifier.$set[updatedAt]) {
      modifier.$set[updatedAt] = new Date();
    }

    // Set "createdAt" date in case of an insert
    const createdAt: keyof Entity = 'createdAt';
    if (!modifier.$setOnInsert) {
      modifier.$setOnInsert = {};
    }
    if (!modifier.$setOnInsert[createdAt]) {
      modifier.$setOnInsert[createdAt] = new Date();
    }
  }

  async addTextIndex(keys: (keyof T)[]): Promise<this> {
    await this.rawCollection().createIndex(toDict(keys, key => key as string, () => 'text'));
    return this;
  }

  async addUniqueIndex(keys: (keyof T)[]): Promise<this> {
    await this.rawCollection().createIndex(toDict(keys, key => key as string, () => 1),
      {
        unique: true,
        /* FIXME: re-activate when Meteor Cloud is compatible
        collation: {
          locale: "simple",
          strength: 1 // Case-insensitive
        }
         */
      }
    );
    return this;
  }

  async init(): Promise<this> {
    this.ensureExists();
    if (this.uniqueIndexes) {
      await Promise.all(this.uniqueIndexes.map(keys => {
        this.addUniqueIndex(keys);
      }));
    }
    if (this.textIndex) {
      // brandName_text_name_text
      const indexName = this.textIndex
        .reduce((accumulator: string[], current: keyof T) => {
          accumulator.push(current as string, 'text');
          return accumulator;
        }, [])
        .join('_');

      const indexes = this.getIndexes();

      if (!indexes.map(i => i.name).includes(indexName)) {
        // First remove any existing text index, there can only be one text index
        const existingTextIndex = indexes.find(i => i.key._fts === 'text'); // empirical way to identify text index

        if (existingTextIndex) {
          console.debug(`${this.name}: Removing existing text index ${existingTextIndex.name}`);
          this.rawCollection().dropIndex(existingTextIndex.name);
        }

        console.debug(`${this.name}: Adding index ${indexName}`);
        await this.addTextIndex(this.textIndex);
      }
    }

    return this;
  }

  removeIndex(name: string): unknown {
    return this.rawCollection().removeIndex(name);
  }

  getIndexes(): { key: { _fts?: string }, name: string }[] {
    const Future = Npm.require('fibers/future');

    const raw = this.rawCollection();
    const future = new Future();

    raw.indexes(function(err: Error, indexes: unknown[]) {
      if (err) {
        future.throw(err);
      }

      future.return(indexes);
    });

    return future.wait();
  }

   ensureExists() {
     const existingOne = this.findOne({});
     if (existingOne === undefined) {
       console.log(`Creating collection ${this.name}`)
       // this.rawDatabase().createCollection(this.name); // Fails if already exists
       // Since createCollection is creating issues, let's simply insert an item (this will create the collection)
       // and then immediately remove it
       const newId = this.insertSync({} as T)
       this.removeSync(newId);
     }
  }
}

export class ZefRevisionCollection<T extends Entity> extends ZefCollection<T & Revision> {
  constructor(nameOrExisting: EntityTypeWithCollection | Mongo.Collection<T & Revision>) {
    if (!(nameOrExisting instanceof Mongo.Collection)) {
      nameOrExisting = (nameOrExisting + '_revisions') as EntityTypeWithCollection;
    }
    // Note: no unique indexes for revisions
    super(nameOrExisting);
  }

  insertRevision(doc: T, revisionDate?: Date): void {
    const { _id, ...rest } = doc;

    this.insertSync({
      ...rest as T,
      updatedAt: revisionDate || new Date(),
      originalId: _id
    } as ToPersist<T & Revision>);
  }

  insert$(): Observable<Id> {
    throw 'insert$ not surpported for revisions';
  }

  insertSync(doc: ToPersist<T & Revision>): Id {
    return super.insertSync(doc);
  }
}
