import {Component, Input} from '@angular/core';
import {MapComponent} from '@components/map/map.component';
import {gpxToGeoJson} from '@model/place.utils';

@Component({
  selector: 'zef-track-log-map',
  templateUrl: '../../map/map.component.html', // /!\ Use map template and CSS
  styleUrls: ['../../map/map.component.scss']
})
export class TrackLogMapComponent extends MapComponent {
  @Input() gpxData: string;

  protected onMapReady() {
    //const location = this.addPolygon(this.spot.location);

    const geoJson = gpxToGeoJson(this.gpxData);
    const location = this.addPolygon(geoJson);
    location.setStyle({ color: 'red' });

    // TODO would be good to avoid this hack
    window.setTimeout(() => {
      // FIXME this will break if user navigates out before 1 second
      this.map.invalidateSize(true);
      this.map.fitBounds(location.getBounds(), { padding: [0, 0] });
    }, 1000);
  }

}
