<ng-template #template>
  <ion-chip outline
            [routerLink]='clickable ? routing.pathToRider(rider)?.link : undefined'
            [disabled]='!clickable'
            routerDirection='forward'>
    <ion-avatar *ngIf='showAvatar'>
      <zef-entity-avatar [entity]='rider'></zef-entity-avatar>
    </ion-avatar>
    <ion-label>{{rider.name}}</ion-label>
  </ion-chip>
</ng-template>
