import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormField} from '@utils/form';
import {FieldLabelType} from '@pipes/fields/field-label.pipe';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'zef-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss']
})
export class CustomSelectComponent implements OnInit {
  @Input() formField: FormField<unknown[]>;
  @Output() edit = new EventEmitter();

  get isEmpty() {
    return (this.formField?.value || []).length === 0;
  }

  FieldLabelType = FieldLabelType;

  constructor() {
  }

  ngOnInit() {
  }

  async click() {
    this.edit.emit();
  }
}
