import {Pipe, PipeTransform} from '@angular/core';
import {TranslateConfigService} from '@services/translate-config.service';

@Pipe({
  name: 'separator'
})
export class TextSeparatorPipe implements PipeTransform {
  constructor(protected translateConfig: TranslateConfigService) {
  }

  transform(text: string): string {
    // In French, put a space before ':'
    return this.translateConfig.currentLanguage === 'fr' ? `${text} : ` : `${text}: `;
  }
}
