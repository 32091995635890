import {Component} from '@angular/core';
import {SingleAliasForm} from '@components/input/edit-aliases/edit-aliases.form';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ListInputComponent} from '@components/edit/list-input-component.directive';
import {EntityType} from '@model/entity-type';

@UntilDestroy()
@Component({
  selector: 'zef-edit-aliases',
  templateUrl: './edit-aliases.component.html',
  styleUrls: ['./edit-aliases.component.scss']
})
export class EditAliasesComponent extends ListInputComponent<{ alias: string }, SingleAliasForm> {
  EntityType = EntityType;

  protected getNewForm(element: { alias: string }): SingleAliasForm {
    return new SingleAliasForm(this.fb.group({}), element ?? { alias: '' });
  }
}
