import {Component, Input, OnInit} from '@angular/core';
import {FormField, IForm} from '@utils/form';
import {FieldLabelType} from '@pipes/fields/field-label.pipe';
import {Queries} from '@api/queries';
import {Activity, getQuiverActivityMatches} from '@model/activity';
import {filterNullish} from '@utils/rxjs.utils';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {tap} from 'rxjs/operators';
import {QuiverItem} from '@model/schema-model';
import {FeedbackService} from '@services/feedback.service';

@UntilDestroy()
@Component({
  selector: 'zef-activity-select',
  templateUrl: './activity-select.component.html',
  styleUrls: ['./activity-select.component.scss']
})
export class ActivitySelectComponent implements OnInit {
  @Input() form: IForm;
  @Input() activitiesField: FormField<Activity[]>;

  private quiver: QuiverItem[];
  @Input() quiverField: FormField<QuiverItem[]>;

  private filterActivities() {
    // only filter if the user hasn't set a value already
    if ((!this.activitiesField.value || this.activitiesField.value?.length === 0) && this.activities) {
      const activityMatches = getQuiverActivityMatches(this.quiver);

      // Select only activities that match 100%
      this.activitiesField.value = (Object.keys(activityMatches) as Activity[]).filter(activity => activityMatches[activity] === 100);
    }
  }

  FieldLabelType = FieldLabelType;

  activities: Activity[];

  constructor(private queries: Queries, private feedback: FeedbackService) {
  }

  ngOnInit() {
    this.quiver = this.quiverField?.value;

    this.queries.getActivities.call$().pipe(
      filterNullish(),
      tap(activities => {
        this.activities = activities;
        this.filterActivities();
      }),
      untilDestroyed(this)
    ).subscribe();

    this.quiverField?.valueChanges$.pipe(
      untilDestroyed(this),
      tap(quiver => {
        this.quiver = quiver;
        this.filterActivities();
      })
    ).subscribe();
  }
}
