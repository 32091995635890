import {ForbiddenFromClientError, UnloggedError} from './errors';

export interface MethodInvocation {
  connection: unknown | null;
}

export enum AllowedTo {
  ANONYMOUS = 'to anonymous',
  AUTHENTICATED = 'to authenticated users only',
  SERVER_ONLY = 'from server only'
}

export const forbiddenToAnonymous = (): void => {
  if (!Meteor.userId()) {
    throw new UnloggedError();
  }
};
const isFromClient = (context: MethodInvocation) => context.connection !== null;

export const forbiddenFromClient = (context: MethodInvocation) => {
  if (isFromClient(context)) {
    throw new ForbiddenFromClientError();
  }
};
