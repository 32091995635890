import {MeteorObservable, ObservableCursor} from 'meteor-rxjs';
import {Observable} from 'rxjs';
import {tap} from "rxjs/operators";

export class Publication<IN, OUT> {
  public name?: string;
  public type = 'Publication';

  constructor(public clientRun?: (args: IN) => ObservableCursor<OUT>) {
  }

  subscribe$(args: IN): Observable<void> {
    return MeteorObservable.subscribe(this.name!, args);
  }

  // TODO see how to make it callable?
  call$(args: IN): Observable<OUT[]> {
    console.log(`Publication ${this.name} - START`);
    // TODO for the moment the main subscription is tight to this call. Might want to implement a more elaborate cache
    const observableCursor = this.clientRun!(args).pipe(
      tap(entities => console.debug(`Publication ${this.name} - ${entities.length} entities`))
    );
    console.log(`Publication ${this.name} - END`);
    return observableCursor;
  }
}
