import { Component, Input, OnInit } from '@angular/core';
import { EditComponent } from '@components/edit/edit-component.directive';
import { Queries } from '@api/queries';
import { FeedbackService } from '@services/feedback.service';
import { ModalController, Platform } from '@ionic/angular';
import { ErrorPipe } from '@pipes/fields/error.pipe';
import { TranslateService } from '@ngx-translate/core';
import { IAuthService } from '@services/i-auth.service';
import { FormBuilder } from '@angular/forms';
import { catchError, take, tap } from 'rxjs/operators';
import { filterNullish } from '@utils/rxjs.utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Commands } from '@services/meteor/commands';
import { toInput } from '@model/multi-language-text';
import { nullOrUndefined } from '@core/utils';
import { toInputLink } from '@model/link';
import { toInputProperty } from '@model/place';
import { Observable, of } from 'rxjs';
import { Id } from '@model/entity';
import { Point } from 'geojson';
import { Layer, Marker } from 'leaflet';
import { BottomTypeValue, SpotLaunch } from '@model/spot-launch';
import { CreateSpotLaunchForm, EditSpotLaunchForm } from '@components/spot-launch/edit-spot-launch/spot-launch.form';
import { Activity } from '@model/activity';
import { SpotLaunchToInsert, SpotLaunchToUpdate } from '@model/schema-model';
import { getInputValue, SpotAndLaunches } from '@api/queries/queries.model';
import { DismissRole } from '@utils/dismissRole';
import { EntityTypePipe } from '@pipes/entity-type.pipe';
import { EntityType } from '@model/entity-type';

@UntilDestroy()
@Component({
  selector: 'zef-edit-spot-launch',
  templateUrl: './edit-spot-launch.component.html',
  styleUrls: [
    '../../edit/edit.component.scss', // /!\ notice this is a shared scss
    'edit-spot-launch.component.scss'
  ]
})
export class EditSpotLaunchComponent extends EditComponent<CreateSpotLaunchForm | EditSpotLaunchForm> implements OnInit {
  @Input() spot: SpotAndLaunches;
  @Input() launch: SpotLaunch;
  @Input() defaultLocation: Point | undefined;

  isUpdate: boolean;
  activities: Activity[];
  Activity = Activity;
  BottomTypeValue = BottomTypeValue;

  ngOnInit() {
    this.isUpdate = !nullOrUndefined(this.launch);

    this.queries.getActivities.call$().pipe(
      filterNullish(),
      tap(activities => {
        this.activities = activities;
      }),
      untilDestroyed(this)
    ).subscribe();

    if (this.isUpdate) {
      const { properties, abstract, description, howToGetThere, links, _id, ...rest } = this.launch;
      const { publicAccess, freeParking, hasToilets, hasShower, suitableForKite, gearForRent } = properties;
      this.form = new EditSpotLaunchForm(this.formGroup, {
        abstract: toInput(abstract),
        description: toInput(description),
        howToGetThere: toInput(howToGetThere),
        links: links?.map(toInputLink),
        // Properties
        publicAccess: toInputProperty(publicAccess),
        freeParking: toInputProperty(freeParking),
        hasToilets: toInputProperty(hasToilets),
        hasShower: toInputProperty(hasShower),
        suitableForKite: toInputProperty(suitableForKite),
        gearForRent: toInputProperty(gearForRent),
        ...rest
      }).init(this);
    } else {
      this.form = new CreateSpotLaunchForm(this.formGroup, {
        spotId: this.spot._id,
        location: this.defaultLocation
      }).init(this);
    }
  }

  protected doSubmit() {
    const spotLaunch = this.getDataToSubmit();

    const command$: Observable<number | string> = this.isUpdate
      ? this.commands.updateSpotLaunch
        .call$({
          ...spotLaunch,
          _id: this.launch._id
        })
        .pipe(
          take(1),
          tap(async (updated: number) => {
            if (updated === 1) {
              await this.feedback.toast(this.translateService.instant('SPOT_LAUNCH.UPDATE.UPDATED'));
              await this.modalController.dismiss(undefined, DismissRole.UPDATED);
              this.broadcastService.sendRefresh({ type: EntityType.spotLaunches, id: this.launch._id });
            }
            // FIXME handle error case
          }),
          untilDestroyed(this)
        )
      : this.commands.createSpotLaunch
        .call$({
          ...spotLaunch,
          spotId: this.spot._id
        })
        .pipe(
          take(1),
          filterNullish(),
          tap(async (_id: Id) => {
            await this.feedback.toast(this.translateService.instant('SPOT_LAUNCH.CREATE.CREATED'));
            await this.modalController.dismiss(undefined, DismissRole.CREATED);
            this.broadcastService.sendRefresh({ type: EntityType.spotLaunches, id: _id });
            // ⚠️ Notice we also trigger a refresh on the parent spot
            this.broadcastService.sendRefresh({ type: EntityType.spots, id: this.spot._id });
          }),
          untilDestroyed(this)
        );

    return command$.toPromise();
  }

  protected getDataToSubmit(): Omit<SpotLaunchToInsert, 'spotId'> | Omit<SpotLaunchToUpdate, '_id'> {
    return {
      name: this.form.fields.name.value,
      aliases: this.form.fields.aliases.value?.map(({ alias }) => alias),
      abstract: this.form.fields.abstract.value,
      description: this.form.fields.description.value,
      howToGetThere: this.form.fields.howToGetThere.value,
      links: this.form.fields.links.value,
      location: this.form.fields.location.value,
      // Properties
      publicAccess: getInputValue(this.form.fields.publicAccess.value),
      freeParking: getInputValue(this.form.fields.freeParking.value),
      hasToilets: getInputValue(this.form.fields.hasToilets.value),
      hasShower: getInputValue(this.form.fields.hasShower.value),
      suitableForKite: getInputValue(this.form.fields.suitableForKite.value),
      gearForRent: getInputValue(this.form.fields.gearForRent.value),
      bottomType: getInputValue(this.form.fields.bottomType.value)
    };
  }

  locationEdited(layers: Layer[]) {
    this.form.fields.location.value = (layers[0] as Marker).toGeoJSON().geometry as Point;
  }
}
