<ng-template #template>
        <ion-img *ngIf='avatarUrl ; else fallBack' src='{{avatarUrl}}'
                 [class]='entityType'></ion-img>
        <ng-template #fallBack>
            <ng-icon *ngIf='iconName; else textAvatar' name='{{ iconName }}' size='100%'></ng-icon>
            <ng-template #textAvatar>
                <zef-text-avatar text='{{ name }}' [entityType]='entityType'></zef-text-avatar>
            </ng-template>
        </ng-template>
</ng-template>
