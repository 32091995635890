import { MultiLanguageText, toInput } from './multi-language-text';
import { Link as InputLink, SocialLink, SocialLink as SocialInputLink } from './schema-model';
import { fromInput } from './model';

export enum LinkType {
  webcam = 'webcam',
  live_report = 'live_report',
  information = 'information',
  club = 'club',
  shop = 'shop'
}

export interface Link<T = LinkType> {
  type: T;
  url: string;
  name: string;
  description?: MultiLanguageText;
}

export const fromInputLink = <I extends InputLink | undefined>(input: I): I extends undefined ? undefined : Link =>
  input ? {
    type: input.type,
    url: input.url,
    name: input.name,
    description: fromInput(input.description)
  } : undefined as any;

export const toInputLink = (link: Link): InputLink => {
  const { description, ...rest } = link;
  return {
    description: toInput(description),
    ...rest
  };
};

export const fromSocialInputLink = <I extends SocialInputLink | undefined>(input: I): I extends undefined ? undefined : SocialLink =>
  input ? {
    type: input.type,
    url: input.url,
  } : undefined as any;

export const toSocialInputLink = (link: SocialLink): SocialInputLink => {
  return link;
};

export enum SocialLinkType {
  instagram = 'instagram',
  facebook = 'facebook',
  youtube = 'youtube',
  twitter = 'twitter',
  vimeo = 'vimeo'
}

export const guessLinkType = (link: string): SocialLinkType | undefined => {
  // "https://www.youtube.com/channel/UCKF5AYr4WtwStFN1PoqZfEg"
  if (link.match('youtube.com')) {
    return SocialLinkType.youtube;
  }
  // "https://www.instagram.com/patrikwindsurf/"
  if (link.match('instagram.com')) {
    return SocialLinkType.instagram;
  }
  // "https://www.facebook.com/patrikwindsurf/", not "http://strikable.com/facebook-page-plugin-likebox-for-wordpress/"
  if (link.match('facebook.com')) {
    return SocialLinkType.facebook;
  }
  // https://twitter.com/fanaticcom
  if (link.match('twitter.com')) {
    return SocialLinkType.twitter;
  }
  // http://vimeo.com/channels/fanaticinternational
  if (link.match('vimeo.com')) {
    return SocialLinkType.vimeo;
  }
};
