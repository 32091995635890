import {Form, FormField} from '@utils/form';
import {FormGroup} from '@angular/forms';
import {entityName} from '@model/schemas';
import SimpleSchema from "simpl-schema";

export class SingleAliasForm extends Form {
  constructor(formGroup: FormGroup, private defaults: { alias: string }) {
    super(formGroup, new SimpleSchema({ alias: entityName }), undefined);
  }

  fields = {
    alias: new FormField<string>(this.schema, this.vf, this.group, this.defaults.alias)
  };
}
