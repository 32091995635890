import {Component, Input, OnInit} from '@angular/core';
import {EditComponent} from '@components/edit/edit-component.directive';
import {Id} from '@model/entity';
import {Queries} from '@api/queries';
import {FeedbackService} from '@services/feedback.service';
import {ModalController, Platform} from '@ionic/angular';
import {ErrorPipe} from '@pipes/fields/error.pipe';
import {TranslateService} from '@ngx-translate/core';
import {IAuthService} from '@services/i-auth.service';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {FrontendRoutingService} from '@services/frontend-routing.service';
import {BookingToInsert} from '@model/schema-model';
import {DateUtils} from '@core/date-utils';
import {catchError, switchMap, take, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {filterNullish} from '@utils/rxjs.utils';
import {NamedEntity} from '@model/entity-with-description';
import {EntityType} from '@model/entity-type';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {BookingForm} from '@components/booking/create-booking/booking.form';
import {ICachedSpotsService} from '@services/i-cached-spots.service';
import moment from 'moment-timezone';
import {Commands} from '@services/meteor/commands';
import {DismissRole} from '@utils/dismissRole';
import {toFullPath} from "@core/routing/route-serializing.service";
import { EntityTypePipe } from '@pipes/entity-type.pipe';
import { BroadcastService } from '@services/broadcast.service';
import { boolean } from 'minifaker';

@UntilDestroy()
@Component({
  selector: 'zef-edit-booking',
  templateUrl: './edit-booking.component.html',
  styleUrls: ['../../edit/edit.component.scss'] // /!\ notice this is a shared scss
})
export class EditBookingComponent extends EditComponent<BookingForm> implements OnInit {
  @Input() spotId: Id;

  @Input() spotDayDate: Date;

  constructor(
    queries: Queries,
    commands: Commands,
    feedback: FeedbackService,
    modalController: ModalController,
    platform: Platform,
    errorPipe: ErrorPipe,
    translateService: TranslateService,
    authService: IAuthService,
    entityTypePipe: EntityTypePipe,
    fb: FormBuilder,
    private router: Router,
    private routing: FrontendRoutingService,
    private spots: ICachedSpotsService,
    broadcastService: BroadcastService,
  ) {
    super(queries, commands, feedback, modalController, errorPipe, translateService, authService, platform, entityTypePipe, fb, broadcastService);
  }

  ngOnInit() {

    // If no spotday, consider it is tomorrow
    const date = this.spotDayDate ?? moment().add(1, 'days').toDate();

    // If a spot day is already selected, use this date and set it to the default time of noon
    // The spot day date is in the spot's timezone, but we _consider it_ to be user's timezone
    const defaultFrom = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    defaultFrom.setHours(12, 0, 0, 0);

    const defaultTo = new Date();
    defaultTo.setHours(defaultFrom.getHours() + 2, 0, 0, 0);

    this.form = new BookingForm(this.formGroup, {
      spotId: this.spotId,
      fromInUserTZ: defaultFrom,
      toInUserTZ: defaultTo
    }).init(this);

  }

  protected doSubmit() {
    const booking = this.getDataToSubmit();
    return this.commands.createBooking
      .call$(booking)
      .pipe(
        take(1),
        filterNullish(),
        switchMap(() => this.spots.getEntityById$(booking.spotId)),
        tap(async ({shortName}: NamedEntity) => {
          await this.feedback.toast(this.translateService.instant('BOOKING.CREATE.CREATED'));
          // Now redirect to the spot day
          const path = this.routing.pathToDatedEntity(
            {type: EntityType.spots, shortName},
            booking.fromInUserTZ
          );
          await this.router.navigate(path.link, {fragment: path.fragment});
          await this.modalController.dismiss(undefined, DismissRole.CREATED);
        }),
        untilDestroyed(this)
      ).toPromise();
  }

  protected getDataToSubmit(): BookingToInsert {
    return {
      activities: this.form.fields.activities.value,
      spotId: this.form.fields.spotId.value,
      spotLaunchId: this.form.fields.spotLaunchId.value,
      fromInUserTZ: this.form.fields.fromInUserTZ.dateValue,
      toInUserTZ: this.form.fields.toInUserTZ.dateValue,
      abstract: this.form.fields.abstract.value,
      userTZ: DateUtils.getLocaleTz()
    };
  }
}
