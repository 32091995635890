import { ZefCollection, ZefRevisionCollection } from './zef-collection';
import { Spot } from '@model/spot';
import { EntityType } from '@model/entity-type';

export const Spots = new ZefCollection<Spot>(EntityType.spots)
  .setUniqueIndexes([
    ['shortName'],
    ['countryCode', 'name']
  ]).setTextIndex([
    'name',
    'aliases',
    'abstract',
    'description'
  ]);

export const SpotRevisions = new ZefRevisionCollection<Spot>(EntityType.spots);
