<ion-header>
  <ion-toolbar>
    <ion-title>
      {{ 'OBSERVATION.CREATE.TITLE' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]='formGroup' (keydown.meta.Enter)='submit()'>
    <ion-list>

      <zef-spot-select [form]='form' [spotField]='form.fields.spotId'
                       [spotLaunchField]='form.fields.spotLaunchId'></zef-spot-select>

      <zef-from-select [form]='form' [fromField]='form.fields.fromInUserTZ'></zef-from-select>

      <zef-edit-situation-gear [form]='form'
                               [formField]='form.fields.quiver'></zef-edit-situation-gear>

      <zef-activity-select [form]='form' [activitiesField]='form.fields.activities'
                           [quiverField]='form.fields.quiver'></zef-activity-select>

      <zef-rate [form]='form' [score5Field]='form.fields.score5'></zef-rate>

      <zef-rate [form]='form' [score5Field]='form.fields.windStability5'></zef-rate>

      <zef-number-input
        [form]='form'
        [field]='form.fields.measuredWindKt'
        [placeholder]='"UNIT.KNOT" | translate : {count: 2} '
      ></zef-number-input>

      <zef-abstract [form]='form' [field]='form.fields.abstract'></zef-abstract>

    </ion-list>
  </form>
</ion-content>

<ion-footer>
  <ion-toolbar [popper]='popperAllErrors'
               [popperTrigger]='popperTrigger'
               [popperDisabled]='formGroup.valid'
               (popperOnShown)='onPopperShown()'>
    <ion-buttons slot='start' class="secondary">
      <ion-button (click)='cancel()'>
        {{ 'SHARED.CANCEL' | translate }}
      </ion-button>
    </ion-buttons>
    <ion-buttons slot='end'>
      <ion-button (click)='submit()' [disabled]='disabledButton || !formGroup.valid' color='primary'>
        {{ 'OBSERVATION.CREATE.OK' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>

<popper-content #popperAllErrors>
  <p *ngFor='let error of allErrors'>{{error}}</p>
</popper-content>

