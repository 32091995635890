import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EnumInputComponent} from '@components/input/enum-input/enum-input.component';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EnumMultiInputComponent} from '@components/input/enum-input/enum-multi-input/enum-multi-input.component';

@NgModule({
  declarations: [
    EnumInputComponent,
    EnumMultiInputComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    EnumInputComponent,
    EnumMultiInputComponent
  ]
})
export class EnumInputModule {
}
