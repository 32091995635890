import {Pipe, PipeTransform} from '@angular/core';
import {getText, MultiLanguageText} from '@model/multi-language-text';
import {TranslateConfigService} from '@services/translate-config.service';

@Pipe({
  name: 'multiLanguage'
})
export class MultiLanguageTextPipe implements PipeTransform {
  constructor(private translateConfig: TranslateConfigService) {}

  transform(multiLanguageText: MultiLanguageText, lang): string {
    // TODO might want to warn the user if it's in a different language than expected
    return getText(multiLanguageText, lang) ?? '';
  }
}
