import {NgModule} from '@angular/core';
import {CommonModule, DecimalPipe} from '@angular/common';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {
  CreateUnidentifiedGearComponent
} from '@components/gear/create-unidentified-gear/create-unidentified-gear.component';
import {TranslateModule} from '@ngx-translate/core';
import {ReactiveFormsModule} from '@angular/forms';
import {NumberInputModule} from '@components/input/number-input/number-input.module';
import {PipesModule} from '@pipes/pipes.module';
import {NgxPopperModule} from 'ngx-popper';
import {ErrorLineModule} from '@components/input/error-line/error-line.module';
import {EnumInputModule} from '@components/input/enum-input/enum-input.module';
import {GearItemItemComponent} from '@components/gear/gear-item-item/gear-item-item.component';
import {EntityModule} from '@components/entity/entity.module';
import {TextAvatarModule} from '@components/text-avatar';
import {BrandItemComponent} from '@components/gear/brand-item/brand-item.component';
import {SelectGearItemsComponent} from '@components/gear/select-gear-items/select-gear-items.component';
import {AddToQuiverComponent} from '@components/gear/add-to-quiver/add-to-quiver.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {TypeSubTypeComponent} from '@components/gear/type-sub-type/type-sub-type.component';
import {NgIconsModule} from '@ng-icons/core';
import { RemoveFromQuiverComponent } from '@components/gear/remove-from-quiver/remove-from-quiver.component';
import { MarkAsReadModule } from '../../directives/mark-as-read.module';
import { InViewportModule } from 'ng-in-viewport';

@NgModule({
  declarations: [
    CreateUnidentifiedGearComponent,
    GearItemItemComponent,
    BrandItemComponent,
    SelectGearItemsComponent,
    AddToQuiverComponent,
    TypeSubTypeComponent,
    RemoveFromQuiverComponent,
  ],
  providers: [DecimalPipe],
  imports: [
    CommonModule,
    RouterModule,
    IonicModule,
    TranslateModule,
    ReactiveFormsModule,
    NumberInputModule,
    PipesModule,
    NgxPopperModule,
    ErrorLineModule,
    EnumInputModule,
    EntityModule,
    TextAvatarModule,
    MatTooltipModule,
    NgIconsModule,
    MarkAsReadModule,
    InViewportModule
  ],
  exports: [
    CreateUnidentifiedGearComponent,
    GearItemItemComponent,
    BrandItemComponent,
    SelectGearItemsComponent,
    AddToQuiverComponent,
    RemoveFromQuiverComponent,
    TypeSubTypeComponent,
  ]
})
export class GearModule {
}
