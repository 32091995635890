import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule} from '@angular/router';
import {aboutBasePageInfo, tabsBasePageInfo} from "@core/routing/pages-list";
import {ModuleMap, toAngularModules} from "./routing-utils";
import {appRoutes} from "@core/routing/app-routing";

const moduleMap: ModuleMap = {
  [aboutBasePageInfo.key]: {
    loadChildren: () => import('@pages/about/about.module').then(m => m.AboutPageModule)
  },
  [tabsBasePageInfo.key]: {
    loadChildren: () => import('@pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
}

@NgModule({
  imports: [RouterModule.forRoot(
    [
      ...toAngularModules(appRoutes, moduleMap)
    ],
    {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
