<ion-header>
  <ion-toolbar>
    <ion-title>
      <ion-icon [name]="(authService.isLoggedIn ? 'log-out' : 'log-in')"></ion-icon>
      {{ (authService.isLoggedIn ? 'LOGIN.OUT.TITLE' : 'LOGIN.IN.TITLE') | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col size='12' class='ion-align-self-center'>
        <div>
          <form (keydown.meta.enter)='login()' [formGroup]='formGroup'>
            <ion-list *ngIf='!authService.isLoggedIn'>
              <ion-item>
                <ion-input formControlName='email' type='email' placeholder='your@email.com' required></ion-input>
              </ion-item>
              <ion-item>
                <ion-input formControlName='password' type='password' placeholder='Password' required></ion-input>
              </ion-item>
            </ion-list>

            <div *ngIf='authService.isLoggedIn'>
              👋
              <br />
              {{ 'LOGIN.OUT.BODY' | translate }}
            </div>

            <!--
            <div>
              <a [routerLink]="'/' + registerPageInfo.path">register</a>
            </div>
            -->

          </form>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>


<ion-footer>
  <ion-toolbar>
    <ion-buttons slot='start' class="secondary">
      <ion-button (click)='cancel()'>
        {{ 'SHARED.CANCEL' | translate }}
      </ion-button>
    </ion-buttons>
    <ion-buttons slot='end'>
      <ion-button color='primary' *ngIf='!authService.isLoggedIn' type='submit' [disabled]='!formGroup.valid || inProgress'
                  (click)='login()'
                  expand='block'>
        {{ 'LOGIN.IN.BUTTON' | translate }}
      </ion-button>
      <ion-button color='danger' *ngIf='authService.isLoggedIn' expand='block' (click)='logout()' [disabled]="inProgress">
        {{ 'LOGIN.OUT.BUTTON' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
