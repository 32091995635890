<form [formGroup]='gearItemTypeformGroup'>
  <ion-item lines='none'>
    <zef-enum-input
      [(model)]='gearItemTypeForm.fields.type.value'
      enumName='ProductType' [enumType]='ProductType'></zef-enum-input>
  </ion-item>
  <ion-item lines='none' *ngIf='currentType !== undefined'>
    <zef-enum-input
      [(model)]='gearItemTypeForm.fields.subType.value'
      enumName='{{subTypeEnum.name}}' [enumType]='subTypeEnum.enum'></zef-enum-input>
  </ion-item>
</form>

<form [formGroup]='characteristicFormGroup' *ngIf='currentSubType' (keydown.meta.Enter)='submit()'>
  <ion-item>
    <ion-label [innerHTML]='characteristicPlaceholder'></ion-label>
    <ion-input *ngIf='characteristicField?.name === "volumeL"' type='number' formControlName='volumeL'></ion-input>
    <ion-input *ngIf='characteristicField?.name === "lengthFt"' type='number' formControlName='lengthFt'></ion-input>
    <ion-input *ngIf='characteristicField?.name === "surfaceM2"' type='number' formControlName='surfaceM2'></ion-input>
  </ion-item>
  <zef-error-line [field]='characteristicField'
                  fieldName='{{getCharacteristicName(characteristicField?.name)}}'></zef-error-line>

  <ng-container *ngIf='characteristicForm.fields.finFamily'>
    <ion-item>
      <zef-enum-input
        [(model)]='characteristicForm.fields.finFamily.value'
        enumName='FinFamily' [enumType]='FinFamily'></zef-enum-input>
    </ion-item>
  </ng-container>
</form>

<ion-item lines='none' *ngIf='currentSubType'>
  <ion-button (click)='submit()' [disabled]='!isValid' color='primary'>
    {{ 'FORMS.FIELDS.quiver.OK' | translate }}
  </ion-button>
</ion-item>
