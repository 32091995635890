import { Entity, Id } from './entity';
import { Location } from './place';
import { EntityTypeWithCollection } from './entity-type';
import { NamedEntity } from './entity-with-description';
import { Subscription } from './subscription';
import { WithEntities } from '@model/with-entities';

export enum EventType {
  created = 'created',
  started = 'started',
  updated = 'updated',
  removed = 'removed',
}

export interface Event extends Entity, WithEntities {
  eventType: EventType;
  createdAt: Date; // this is mandatory here
  actorId?: Id;
  location?: Location;
  readBy: Id[];
}

export const getEventFields = (): (keyof Event)[] => {
  return ['_id', 'createdAt', 'updatedAt', 'eventType', 'actorId', 'location', 'entities', 'readBy'];
};

export interface AggregatedEntity extends NamedEntity {
  type: EntityTypeWithCollection;
}

export interface AggregatedEntityWithDate extends AggregatedEntity {
  startDate: Date;
  timezone: string;
}

export interface GroupedEvent {
  eventType: EventType;
  createdAt: Date;
  entities?: AggregatedEntity | AggregatedEntityWithDate[];
  actor?: NamedEntity;
  owner?: NamedEntity;
  events: Event[];
}

export const subscriptionEquals = (subA: Subscription, subB: Subscription) => {
  const entityA = subA.entities?.[0];
  const entityB = subB.entities?.[0];

  return (entityA !== undefined &&
      (entityA.type === entityB?.type && (entityA.id === entityB?.id || entityA.shortName === entityB?.shortName))
    )
    || (subA.actorId !== undefined && subA.actorId === subB.actorId)
    || (subA.entityOwnerId !== undefined && subA.entityOwnerId === subB.entityOwnerId);
};

export const isReadBy = (event: Event, riderId: Id) => event.readBy?.includes(riderId)
