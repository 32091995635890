import {Id} from '@model/entity';
import {NamedEntity} from '@model/entity-with-description';
import {Observable} from 'rxjs';

export abstract class ICachedSpotLaunchesService {
  abstract getEntitiesByIds$(ids: Id[]): Observable<NamedEntity[]>;

  abstract getEntityById$(_id: Id): Observable<NamedEntity>;

  abstract getEntityByShortName$(shortName: string): Observable<NamedEntity>;

  // TODO this should not be used anymore
  abstract getAllEntities$(): Observable<NamedEntity[]>;
}
