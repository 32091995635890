import {Component, Input, OnInit} from '@angular/core';
import {DateStringFormField, IForm} from '@utils/form';
import {FieldLabelType} from '@pipes/fields/field-label.pipe';
import {UntilDestroy} from '@ngneat/until-destroy';
import {DateUtils} from '@core/date-utils';
import moment from 'moment-timezone';
import {FormatType} from '@pipes/format-time.pipe';
import {TranslateConfigService} from '@services/translate-config.service';

@UntilDestroy()
@Component({
  selector: 'zef-from-select',
  templateUrl: './from-select.component.html',
  styleUrls: ['./from-select.component.scss']
})
export class FromSelectComponent implements OnInit {
  @Input() form: IForm;
  @Input() fromField: DateStringFormField;

  FormatType = FormatType;

  min: string;
  max: string;

  FieldLabelType = FieldLabelType;

  constructor(public translateConfig: TranslateConfigService) {
  }

  ngOnInit() {
    const min = this.fromField.getSchemaProperty('min') ?? moment().subtract(20, 'years').toDate();
    this.min = DateUtils.toIonicDateString(min);

    const max = this.fromField.getSchemaProperty('max') ?? moment().add(10, 'years').toDate();
    this.max = DateUtils.toIonicDateString(max);
  }
}
