import {ZefCollection} from './zef-collection';
import {Event} from '@model/events';
import {EntityType} from '@model/entity-type';
import {Id} from '@model/entity';

export const Events = new ZefCollection<Event>(EntityType.events);

Events.allow({
  // Only allow users to mark events as read
  update: (userId: string, _: Event, __: string[], modifier: { $push: { readBy?: Id }, $pull: { readBy?: Id } }) => {
    return modifier['$push']?.readBy === userId || modifier['$pull']?.readBy === userId;
  }
});
