import {EntityType} from '@model/entity-type';
import {DateUtils} from '@core/date-utils';
import {Id} from '@model/entity';
import {EntityLink} from '@model/entity-link';
import {
  gearBasePageInfo,
  newsBasePageInfo,
  ridersBasePageInfo,
  sessionsBasePageInfo,
  spotsBasePageInfo,
  statsPath,
  tabsBasePageInfo
} from "@core/routing/pages-list";
import {serverName} from "@model/const";

export interface Path {
  link: unknown[];
  fragment?: string;
}

export class RouteSerializingService {
  private splitPath(path: string | undefined): string[] {
    return path?.split('/') || [];
  }

  pathToEntity(entityLink: EntityLink): Path | undefined {
    switch (entityLink?.type) {
      case EntityType.spots:
        return {
          link: [tabsBasePageInfo.path, spotsBasePageInfo.path, ...this.splitPath(entityLink.shortName)]
        };
      case EntityType.riders:
        return {
          link: [tabsBasePageInfo.path, ridersBasePageInfo.path, ...this.splitPath(entityLink.shortName)]
        };
      case EntityType.brands:
        return {
          link: [tabsBasePageInfo.path, gearBasePageInfo.path, ...this.splitPath(entityLink.shortName)]
        };
      case EntityType.spotDays:
        // Special case: the entityLink can contain an ID that we use as anchor
        return {
          link: [tabsBasePageInfo.path, spotsBasePageInfo.path, ...this.splitPath(entityLink.shortName)],
          fragment: entityLink.id,
        };
      case EntityType.headlines:
        return {
          // For the moment we don't target a specific headline FIXME simply use fragments
          link: [tabsBasePageInfo.path, newsBasePageInfo.path]
        };
      case EntityType.gearModels:
        return this.pathToGearModel({shortName: entityLink.shortName!});
      case EntityType.sessions:
        return {
          link: [tabsBasePageInfo.path, sessionsBasePageInfo.path, entityLink.id]
        };
    }
    return undefined;
  }

  private addToPath(path: Path | undefined, extra: unknown[]): Path | undefined {
    if (!path) {
      return undefined;
    }
    const newPath = {
      ...path,
    };
    newPath?.link.push(...extra);
    return newPath;
  }

  pathToDatedEntity(entityLink: EntityLink, date: Date): Path | undefined {
    return this.addToPath(this.pathToEntity(entityLink), this.splitPath(DateUtils.getDay(date)));
  }

  pathToEntityStats(entityLink: EntityLink): Path | undefined {
    return this.addToPath(this.pathToEntity(entityLink), [statsPath]);
  }

  pathToGearItem({shortName, _id}: { shortName: string, _id: Id }) {
    return this.addToPath(this.pathToGearModel({shortName}), ['item', _id]);
  }

  pathToRider({shortName}: { shortName: string }) {
    return this.pathToEntity({type: EntityType.riders, shortName: shortName});
  }

  pathToSpot({shortName}: { shortName?: string, _id?: Id }) {
    // FIXME if no shortName is provided? => look into riders service?
    return this.pathToEntity({type: EntityType.spots, shortName: shortName});
  }

  pathToBrand({shortName}: { shortName: string }) {
    return this.pathToEntity({type: EntityType.brands, shortName: shortName});
  }

  pathToGearModel({shortName}: { shortName: string }): Path {
    return {link: [tabsBasePageInfo.path, gearBasePageInfo.path, ...this.splitPath(shortName)]};
  }

  pathToGear: Path = {
    link: [tabsBasePageInfo.path, gearBasePageInfo.path]
  }
}

export function toFullPath(path: Path | undefined): string {
  const anchor = path?.fragment?.length ?? 0 > 0
    ? `#${path!.fragment}`
    : '';

  return path
    ? `${path.link.join('/')}${anchor}`
    : "";
}

export function toFullUrlToPage(path: string) {
  return `https://${serverName}${path}`
}
