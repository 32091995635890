<form [formGroup]='form.group'>
  <ion-item [lines]='hasValue ? "none" : "inset"' [matTooltip]='field | fieldLabel : FieldLabelType.PLACEHOLDER' class="transparent">
    <ion-label class='input'>{{ field | fieldLabel : FieldLabelType.TITLE }}</ion-label>
    <ng-content></ng-content>
  </ion-item>
  <ng-container *ngIf='hasValue'>
    <zef-multi-language-input
      title='{{"FORMS.FIELDS.propertyDetails.TITLE" | translate : {field: field | fieldLabel : FieldLabelType.TITLE} }}'
      placeholder='{{"FORMS.FIELDS.propertyDetails.PLACEHOLDER" | translate : {field: field | fieldLabel : FieldLabelType.TITLE} }}'
      [(model)]='field.value.details'
      [small]='true'
    ></zef-multi-language-input>
    <zef-error-line [field]='field'></zef-error-line>
  </ng-container>
</form>
