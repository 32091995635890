<form [formGroup]='form.group'>
  <ion-item id='open-to'>
    <ion-label class='input'>{{ toField | fieldLabel : FieldLabelType.TITLE }}</ion-label>
    <ion-text>
      {{ toField.dateValue | formatTime : FormatType.TIME_ONLY }}
    </ion-text>
    <ion-popover trigger='open-to' alignment='end'>
      <ng-template>
        <ion-content>
          <ion-datetime presentation='time' formControlName='{{toField.name}}'
                        [locale]='translateConfig.currentLanguage'
                        [required]='toField.isRequired'
                        [min]='minString'>
          </ion-datetime>
        </ion-content>
      </ng-template>
    </ion-popover>
  </ion-item>
  <zef-error-line [field]='toField'></zef-error-line>
</form>
