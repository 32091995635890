import {Component, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {NamedEntity} from '@model/entity-with-description';
import {getDefaultAvatar} from '@model/imagekit-utils';
import {EntityType} from '@model/entity-type';
import {IconService} from '@services/icon.service';

@Component({
  selector: 'zef-entity-avatar',
  templateUrl: './entity-avatar.component.html',
  styleUrls: ['./entity-avatar.component.scss']
})
export class EntityAvatarComponent implements OnInit {
  @Input() entity: Pick<NamedEntity, 'name' | 'avatar'>;
  @Input() entityType: EntityType; // Optional
  @Input() iconName: string;

  @ViewChild('template', { static: true }) template;

  name: string;
  avatarUrl: string;

  EntityType = EntityType;

  constructor(private viewContainerRef: ViewContainerRef, public icons: IconService) {
  }

  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.template);
    this.name = this.entity?.name ?? ' ';
    this.avatarUrl = this.entity?.avatar ?? getDefaultAvatar(this.entityType);
  }
}
