import {Id} from '@model/entity';
import {Observable} from 'rxjs';
import {SpotItem} from '@api/queries';

export abstract class ICachedSpotsService {
  abstract getEntitiesByIds$(ids: Id[]): Observable<SpotItem[]>;

  abstract getEntityById$(_id: Id): Observable<SpotItem>;

  abstract getEntitiesByShortnames$(ids: Id[]): Observable<SpotItem[]>;

  abstract getEntityByShortName$(shortName: string): Observable<SpotItem>;

  // TODO this should not be used anymore
  abstract getAllEntities$(): Observable<SpotItem[]>;
}
