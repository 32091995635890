<form [formGroup]='form.group'>
  <ion-item>
    <ion-label class='input'>{{ spotField | fieldLabel : FieldLabelType.TITLE }}</ion-label>
    <ion-select *ngIf='spots$ | async as spots'
                formControlName='{{spotField.name}}'
                [required]='spotField.isRequired'
                [placeholder]='spotField | fieldLabel : FieldLabelType.REQUIRED'>
      <ion-select-option *ngFor='let spot of spots'
                         value='{{spot._id}}'>{{spot.name}}</ion-select-option>
    </ion-select>
  </ion-item>
  <zef-error-line [field]='spotField'></zef-error-line>

  <ion-item>
    <ion-label
      class='input'>{{ spotLaunchField | fieldLabel : FieldLabelType.TITLE }}</ion-label>
    <ion-select *ngIf='spotLaunches$ | async as spotsLaunches'
                formControlName='{{spotLaunchField.name}}'
                [required]='spotLaunchField.isRequired'
                [placeholder]='spotLaunchField | fieldLabel : FieldLabelType.REQUIRED'>
      <ion-select-option *ngFor='let spotLaunch of spotsLaunches'
                         value='{{spotLaunch._id}}'>{{spotLaunch.name}}</ion-select-option>
    </ion-select>
  </ion-item>
  <zef-error-line [field]='spotLaunchField'></zef-error-line>
</form>
