<form [formGroup]='form.group'>
  <ion-item>
    <ion-label
      class='input'>{{ activitiesField | fieldLabel : FieldLabelType.TITLE }}</ion-label>
    <ion-select *ngIf='activities'
                multiple='true'
                interface='alert' formControlName='{{activitiesField.name}}'
                [required]='activitiesField.isRequired'
                [placeholder]='activitiesField | fieldLabel : FieldLabelType.REQUIRED'>
      <ion-select-option *ngFor='let activity of activities'
                         value='{{activity}}'>{{ [activity] | activities }}</ion-select-option>
    </ion-select>
  </ion-item>

  <zef-error-line [field]='activitiesField'></zef-error-line>
</form>
