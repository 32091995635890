import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Characteristic, ProductSubTypeDef} from '@model/gear/gear-model';
import {FormBuilder, FormGroup} from '@angular/forms';
import {tap} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {capitalize} from 'lodash';
import {GearModelItemVariationPipe} from '@pipes/gear-model-item-variation.pipe';
import {TranslateService} from '@ngx-translate/core';
import {GearItemTypeForm} from '@components/gear/create-unidentified-gear/gear-item-type.form';
import {CharacteristicForm} from '@components/gear/create-unidentified-gear/characteristic.form';
import {IForm, NumberFormField} from '@utils/form';
import {QuiverItem} from '@model/gear/quiver';
import {schemas} from '@model/schemas';
import {Platform} from '@ionic/angular';
import {ErrorPipe} from '@pipes/fields/error.pipe';
import {BoardType, ProductSubType, ProductType, PropulsionType} from '@model/gear/product';
import {FinFamily} from '@model/gear/variants';
import SimpleSchema from "simpl-schema";
import {getCharacteristicKeys, getProductSubType} from "@model/gear/gear-utils";

@UntilDestroy()
@Component({
  selector: 'zef-create-unidentified-gear',
  templateUrl: './create-unidentified-gear.component.html',
  styleUrls: ['./create-unidentified-gear.component.scss']
})
export class CreateUnidentifiedGearComponent implements OnInit {
  ProductType = ProductType;
  FinFamily = FinFamily;
  ProductSubType = ProductSubType;
  capitalize = capitalize;

  allErrors: string[];

  gearItemTypeformGroup: FormGroup;
  gearItemTypeForm: GearItemTypeForm;
  characteristicFormGroup: FormGroup;
  characteristicForm: CharacteristicForm;
  subTypeEnum: ProductSubTypeDef;

  @Input() form: IForm;

  get isValid() {
    return this.gearItemTypeformGroup.valid && this.characteristicFormGroup.valid;
  }

  @Output() quiverItem = new EventEmitter<QuiverItem>();

  constructor(
    private translate: TranslateService,
    fb: FormBuilder,
    protected platform: Platform,
    protected errorPipe: ErrorPipe
  ) {
    this.gearItemTypeformGroup = fb.group({});
    this.characteristicFormGroup = fb.group({});
  }


  ngOnInit() {
    this.gearItemTypeForm = new GearItemTypeForm(this.gearItemTypeformGroup, this.form.parentEntityType).init(this);
    this.gearItemTypeForm.fields.type.valueChanges$.pipe(tap((type: ProductType) => {
        this.subTypeEnum = getProductSubType(type);
        this.gearItemTypeForm.fields.subType.resetValue();
      }),
      untilDestroyed(this)
    ).subscribe();

    this.gearItemTypeForm.fields.subType.valueChanges$.pipe(
      tap((subType: ProductSubType) => {
        const schema = this.getCharacteristicSchema(subType);
        this.characteristicForm = new CharacteristicForm(this.characteristicFormGroup, schema, this.form.parentEntityType).init(this);
        this.characteristicFormGroup.reset();
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  get currentType(): ProductType {
    return this.gearItemTypeForm.fields.type.value;
  };

  get currentSubType(): ProductSubType {
    return this.gearItemTypeForm.fields.subType.value;
  };

  get subTypePlaceholder(): string {
    if (!this.currentType) {
      return '';
    }

    return this.translate.instant(`GEAR.${this.currentType}.TITLE`);
  }

  get characteristicPlaceholder(): string {
    if (!this.currentSubType) {
      return '';
    }
    const { characteristicType, unit } = this.characteristicTypeAndUnit;
    const typeStr = this.getCharacteristicName(characteristicType);
    const unitStr = this.translate.instant(`UNIT.${unit}`, { count: 10 });
    return `${typeStr} (${unitStr})`;
  }

  /**
   * @deprecated
   * @param subType
   */
  getTypeAndUnit(subType: ProductSubType): { characteristicType: 'volumeL' | 'lengthFt' | 'surfaceM2', unit: string } {
    switch (subType) {

      // Boards
      case BoardType.windsurfBoard:
      case BoardType.wingBoard:
      case BoardType.paddleBoard:
      case BoardType.kiteBoard: // FIXME might not be the right characteristic for kite board
        return { characteristicType: 'volumeL', unit: 'LITER' };
      case BoardType.surfBoard:
        return { characteristicType: 'lengthFt', unit: 'FOOT' };

      // Propulsions
      case PropulsionType.windsurfSail:
      case PropulsionType.wing:
      case PropulsionType.kite:
        return { characteristicType: 'surfaceM2', unit: 'SQUARE_METER' };
    }
  }

  get characteristicTypeAndUnit() {
    // FIXME should propose the different alternatives to user (ex: for kite lengthFt or lengthCm)
    return this.currentSubType ? this.getTypeAndUnit(this.currentSubType) : undefined;
  }

  get characteristicField(): NumberFormField | undefined {
    return this.characteristicForm.fields[this.characteristicTypeAndUnit?.characteristicType];
  }

  getCharacteristicSchema(subType: ProductSubType): SimpleSchema {
    switch (subType) {

      // Boards
      case BoardType.paddleBoard:
      case BoardType.windsurfBoard:
        return schemas.BoardWithFinNoTwintipCharacteristic;

      case BoardType.kiteBoard:
        return schemas.BoardWithFinCharacteristic;

      case BoardType.wingBoard:
        // No choice, only foil
        return schemas.BoardNoFinCharacteristic;

      case BoardType.surfBoard:
        return schemas.SurfBoardCharacteristic;

      // Propulsions
      case PropulsionType.windsurfSail:
      case PropulsionType.wing:
      case PropulsionType.kite:
        return schemas.SailCharacteristic;
    }
  }

  get popperTrigger() {
    return this.platform.is('desktop') ? 'hover' : 'click';
  }

  getCharacteristicName(fieldName: string): string {
    return capitalize(this.translate.instant(`GEAR.CHARACTERISTIC.${fieldName}`));
  }

  onPopperShown() {
    this.allErrors = [
      ...this.gearItemTypeForm.allErrors.map(error => this.errorPipe.transform(error, this.gearItemTypeForm.parentEntityType)),
      ...(this.characteristicForm?.allErrors ?? []).map(error => {
        const fieldName = this.getCharacteristicName(error.fieldName);
        return this.errorPipe.transform(error, this.gearItemTypeForm.parentEntityType, fieldName);
      })
    ];
  }

  /**
   * @deprecated
   * @param subType
   * @param value
   */
  deserialize(subType: ProductSubType, value: number): Characteristic {
    const characteristicKeys = getCharacteristicKeys(subType);
    switch (characteristicKeys[0]) {
      case 'volumeL':
        return { volumeL: value };
      case 'lengthFt':
        return { lengthFt: value };
      case 'lengthCm':
        return { lengthCm: value };
      case 'surfaceM2':
        return { surfaceM2: value };
    }
  }

  submit() {
    if (!this.isValid) {
      this.onPopperShown();
      return;
    }
    const characteristicValue = this.characteristicField.value;

    // FIXME should allow different characteristics
    const characteristic = this.deserialize(this.currentSubType, characteristicValue);
    const finFamily = this.characteristicForm.fields.finFamily?.value; // could be undefined

    const quiverItem: QuiverItem = {
      type: this.currentType,
      subType: this.currentSubType,
      characteristic,
      finFamily
    };

    this.quiverItem.emit(quiverItem);

    this.gearItemTypeformGroup.reset();
    this.characteristicFormGroup.reset();
  }
}
