import {Component, Input, OnInit} from '@angular/core';
import {FormField, IForm} from '@utils/form';
import {FieldLabelType} from '@pipes/fields/field-label.pipe';
import {UntilDestroy} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'zef-abstract',
  templateUrl: './abstract.component.html',
  styleUrls: ['./abstract.component.scss']
})
export class AbstractComponent implements OnInit {
  @Input() form: IForm;
  @Input() field: FormField<string>;

  FieldLabelType = FieldLabelType;

  constructor() {
  }

  ngOnInit() {}
}
