import {Form, FormField} from '@utils/form';
import {FormGroup} from '@angular/forms';
import {Link, SpotToInsert, SpotToUpdate, WithLanguageText} from '@model/schema-model';
import {schemas} from '@model/schemas';
import {EntityType} from '@model/entity-type';
import SimpleSchema from "simpl-schema";
import {Danger, DepthValue, EntryLevelValue, SpotTypeValue} from '@model/spot';
import {InputProperty, newEmptyProperty} from '@api/queries/queries.model';
import {Polygon} from 'geojson';

abstract class SpotForm extends Form {
  constructor(formGroup: FormGroup, schemas: SimpleSchema, protected defaults: SpotToInsertDefaults | SpotToUpdateDefaults) {
    super(formGroup, schemas, EntityType.spots);
  }

  fields = {
    name: new FormField<string>(this.schema, this.vf, this.group, this.defaults.name),
    aliases: new FormField<{ alias: string }[]>(this.schema, this.vf, this.group, this.defaults.aliases?.map(alias => ({ alias }))),
    abstract: new FormField<WithLanguageText[]>(this.schema, this.vf, this.group, this.defaults.abstract),
    description: new FormField<WithLanguageText[]>(this.schema, this.vf, this.group, this.defaults.description),
    location: new FormField<Polygon>(this.schema, this.vf, this.group, this.defaults.location),
    links: new FormField<Link[]>(this.schema, this.vf, this.group, this.defaults.links),
    spotType: new FormField<InputProperty<SpotTypeValue>>(this.schema, this.vf, this.group, this.defaults.spotType ?? newEmptyProperty()),
    depth: new FormField<InputProperty<DepthValue>>(this.schema, this.vf, this.group, this.defaults.depth ?? newEmptyProperty()),
    entryLevel: new FormField<InputProperty<EntryLevelValue>>(this.schema, this.vf, this.group, this.defaults.entryLevel ?? newEmptyProperty()),
    dangers: new FormField<InputProperty<Danger[]>>(this.schema, this.vf, this.group, this.defaults.dangers ?? newEmptyProperty())
  };

}

export type SpotToInsertDefaults = Partial<SpotToInsert>;

export class CreateSpotForm extends SpotForm {
  constructor(formGroup: FormGroup, defaults: SpotToInsertDefaults) {
    super(formGroup, schemas.SpotToInsert, defaults);
  }
}

export type SpotToUpdateDefaults = Partial<SpotToUpdate>;

export class EditSpotForm extends SpotForm {
  constructor(formGroup: FormGroup, defaults: SpotToUpdateDefaults) {
    super(formGroup, schemas.SpotToUpdate, defaults);
  }
}
