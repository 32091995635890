import { Activity } from './activity';
import { SchemaMap } from 'schema-to-types';
import SimpleSchema, { SchemaDefinition, SimpleSchemaDefinition } from 'simpl-schema';
import { Id } from './entity';
import { EntityType } from './entity-type';
import { ProductSubType, ProductType, Program } from './gear/product';
import { FinBoxType, FinFamily, MastType, WindsurfSailTopType } from './gear/variants';
import * as moment from 'moment-timezone'; // /!\ don't use "import moment from", will make "schema-to-types" fail
import { DateUtils } from '../date-utils';
import { Point, Polygon } from 'geojson';
import { LinkType, SocialLinkType } from './link';
import { Danger, DepthValue, EntryLevelValue, SpotTypeValue } from './spot';
import { BottomTypeValue } from './spot-launch';
import { Languages } from './multi-language-text';
import { KiteType } from './gear/kite-model';
import { KiteBoardType } from './gear/kite-board-model';
import { Characteristic } from '@model/gear/gear-model';
import { nullOrUndefined } from '../utils';

SimpleSchema.extendOptions(['typeName']);

interface TypedSimpleSchema extends SimpleSchema {
  addDocValidator: (validator: (obj: any) => {name: string, type: string}[]) => void;
}

const getSimpleSchema = (schema: SimpleSchemaDefinition) => new SimpleSchema(schema) as TypedSimpleSchema;

export type TypedSchemaDefinition = Omit<SchemaDefinition, 'type'> & {
  type: any,
  typeName?: string
};

// Location == 2 numbers
const location: TypedSchemaDefinition = {
  type: Array,
  minCount: 2,
  maxCount: 2
};

const id: TypedSchemaDefinition = {
  type: String as (value?: unknown) => Id,
  typeName: 'Id'
};

const score5: TypedSchemaDefinition = {
  type: Number,
  min: 0,
  max: 5
};

const activity: TypedSchemaDefinition = {
  type: Activity,
  typeName: 'Activity'
};

const boardVolumeL: TypedSchemaDefinition = {
  type: SimpleSchema.Integer, // No decimals for board volume in liters
  min: 10,
  max: 500
};
const existingBoardLengthFt: TypedSchemaDefinition = {
  type: Number,
};
const inputBoardLengthFt: TypedSchemaDefinition = {
  type: String,
  // FIXME define min and max based on boardLengthCm
  // FIXME validate format
  /*
  min: 1,
  max: 20
   */
};
const boardLengthCm: TypedSchemaDefinition = {
  type: Number,
  min: 30,
  max: 600
};
const boardWidthCm: TypedSchemaDefinition = {
  type: Number
  // TODO set min and max
};
const boardStrapInsertCount: TypedSchemaDefinition = {
  type: Number,
  min: 0 // Because some boards are simply strapless
  // TODO set min and max
};
const boardThicknessCm: TypedSchemaDefinition = {
  type: Number
  // TODO set min and max
};
const sailSurfaceM2: TypedSchemaDefinition = {
  type: Number,
  min: 1,
  max: 30
};

const { twintip, ...finFamilyWithoutTwintip } = FinFamily;

const finFamilyNoTwintip: TypedSchemaDefinition = {
  type: { ...finFamilyWithoutTwintip },
  typeName: 'FinFamily'
};

const finFamily: TypedSchemaDefinition = {
  type: FinFamily,
  typeName: 'FinFamily'
};

const quiverCharacteristic: TypedSchemaDefinition = {
  // FIXME not working due to a bug that should be fixed in https://github.com/longshotlabs/simpl-schema/pull/435
  //type: SimpleSchema.oneOf(surfBoardCharacteristics, windsurfBoardCharacteristics, windsurfSailCharacteristics)
  type: new SimpleSchema({
    volumeL: {
      ...boardVolumeL,
      optional: true
    },
    lengthFt: {
      ...existingBoardLengthFt,
      optional: true
    },
    surfaceM2: {
      ...sailSurfaceM2,
      optional: true
    }
  }),
  typeName: 'Characteristic'
};

export const entityName: TypedSchemaDefinition = {
  type: String,
  min: 2
};

export const url: TypedSchemaDefinition = {
  type: String,
  // FIXME better validation, in particular filter spam / scam / porn
  regEx: SimpleSchema.RegEx.Url
};

/*
const polygon: TypedSchemaDefinition = {
  type: new SimpleSchema({
    coordinates: Array,
    'coordinates.$': { type: Array },
    'coordinates.$.$': Number
  })
};

 */

export enum ErrorTypes {
  toBeforeFrom = 'toBeforeFrom',
  NO_FUTURE = 'NO_FUTURE'
}

// FIXME as long as OneOf() doesn't work, this is needed to have an import of Characteristic here
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const tempShouldBeRemoved: Characteristic = { volumeL: 0 };

/*
const windsurfBoardCharacteristics = new SimpleSchema({
  volumeL: Number
});
const windsurfSailCharacteristics = new SimpleSchema({
  surfaceM2: Number
});
const surfBoardCharacteristics = new SimpleSchema({
  lengthFt: Number
});
*/

/*
color: {
        type: String,
        optional: true
      } // See https://www.w3schools.com/colors/colors_names.asp
 */

export enum ErrorTypes {
  atLeastOne = 'atLeastOne'
}

const validateAtLeastOneMandatory = <T>(atLeastOneOf: (keyof T)[]) => (obj: T): {name: string, type: string}[] => {
  return atLeastOneOf.every(key => nullOrUndefined(obj[key])) // All are missing
    ? atLeastOneOf.map((key: keyof T) => ({
      name: key as string,
      type: ErrorTypes.atLeastOne,
    })) : [];
};

// For some reason we have to explicitly check for variants on the full gear model
const docVariantValidator = <T>(atLeastOneOf: (keyof T)[]) => <U extends {variants: T[]}>(obj: U) => {
  return (obj.variants ?? [])
    .map((variant: T, index: number) =>
      validateAtLeastOneMandatory(atLeastOneOf)(variant) // Get all errors for each variant
        .map(({ name, type }) => ({ name: `variants.${index}.${name}`, type })) // Map the name of the field to the indexed variant
    ).flat();
};

export class Schema {
  WithLanguageText = new SimpleSchema({
    lang: String,
    text: String
  });
  QuiverItemType: TypedSimpleSchema;
  QuiverItem: TypedSimpleSchema;
  SailCharacteristic: TypedSimpleSchema;
  BoardNoFinCharacteristic: TypedSimpleSchema;
  BoardWithFinCharacteristic: TypedSimpleSchema;
  BoardWithFinNoTwintipCharacteristic: TypedSimpleSchema;
  SurfBoardCharacteristic: TypedSimpleSchema;
  SessionToStart: TypedSimpleSchema;
  SessionToEnd: TypedSimpleSchema;
  SessionToInsert: TypedSimpleSchema;
  SessionToUpdate: TypedSimpleSchema;
  // A full user to create, including password
  UserToCreate: TypedSimpleSchema;
  // A user that was just created (with password) and that now needs to be "registered" with username and language
  UserToRegister: TypedSimpleSchema;
  CreatedUser: TypedSimpleSchema;
  LoginUser: TypedSimpleSchema;
  EntityLink: TypedSimpleSchema;
  PhotoEntityLink: TypedSimpleSchema;
  CommentToInsert: TypedSimpleSchema;
  FinConfig: TypedSimpleSchema;
  RiderWeightRange: TypedSimpleSchema;
  WindRange: TypedSimpleSchema;
  HandleConfig: TypedSimpleSchema;
  GearVariantSurfBoard: TypedSimpleSchema;
  GearVariantWindsurfBoard: TypedSimpleSchema;
  GearVariantWindsurfSail: TypedSimpleSchema;
  GearVariantWing: TypedSimpleSchema;
  GearVariantKite: TypedSimpleSchema;
  GearVariantWingBoard: TypedSimpleSchema;
  GearVariantKiteBoard: TypedSimpleSchema;
  GearVariantPaddleBoard: TypedSimpleSchema;

  SurfBoardToInsert: TypedSimpleSchema;
  SurfBoardToUpdate: TypedSimpleSchema;
  WindsurfBoardToInsert: TypedSimpleSchema;
  WindsurfBoardToUpdate: TypedSimpleSchema;
  WingBoardToInsert: TypedSimpleSchema;
  WingBoardToUpdate: TypedSimpleSchema;
  KiteBoardToInsert: TypedSimpleSchema;
  KiteBoardToUpdate: TypedSimpleSchema;
  PaddleBoardToInsert: TypedSimpleSchema;
  PaddleBoardToUpdate: TypedSimpleSchema;

  WindsurfSailToInsert: TypedSimpleSchema;
  WindsurfSailToUpdate: TypedSimpleSchema;
  WingToInsert: TypedSimpleSchema;
  WingToUpdate: TypedSimpleSchema;
  KiteToInsert: TypedSimpleSchema;
  KiteToUpdate: TypedSimpleSchema;

  SpotSituation: TypedSimpleSchema;
  SpotSituationWithFeedback: TypedSimpleSchema;
  BookingToInsert: TypedSimpleSchema;
  ObservationToInsert: TypedSimpleSchema;
  PhotoToInsert: TypedSimpleSchema;
  SpotToInsert: TypedSimpleSchema;
  SpotToUpdate: TypedSimpleSchema;
  SpotLaunchToInsert: TypedSimpleSchema;
  SpotLaunchToUpdate: TypedSimpleSchema;
  LocationPoint: TypedSimpleSchema;
  LocationPolygon: TypedSimpleSchema;
  PasswordAndConfirm: TypedSimpleSchema;
  NewPassword: TypedSimpleSchema;
  NamedEntity: TypedSimpleSchema;
  EntityWithDescription: TypedSimpleSchema;
  Link: TypedSimpleSchema;
  SocialLink: TypedSimpleSchema;
  Property: TypedSimpleSchema;
  BrandToInsert: TypedSimpleSchema;
  BrandToUpdate: TypedSimpleSchema;

  constructor() {
    this.QuiverItemType = getSimpleSchema({
      type: {
        type: ProductType,
        typeName: 'ProductType'
      } as TypedSchemaDefinition,
      subType: {
        type: ProductSubType,
        typeName: 'ProductSubType'
      } as TypedSchemaDefinition
    });

    this.SailCharacteristic = getSimpleSchema({
      surfaceM2: sailSurfaceM2
    });

    this.BoardNoFinCharacteristic = getSimpleSchema({
      volumeL: boardVolumeL
    });

    this.BoardWithFinCharacteristic = getSimpleSchema({
      volumeL: boardVolumeL,
      finFamily: finFamily
    });

    this.BoardWithFinNoTwintipCharacteristic = getSimpleSchema({
      volumeL: boardVolumeL,
      finFamily: finFamilyNoTwintip
    });

    this.SurfBoardCharacteristic = getSimpleSchema({
      lengthFt: inputBoardLengthFt,
      finFamily: finFamilyNoTwintip
    });

    this.QuiverItem = getSimpleSchema({
      itemId: {
        ...id,
        optional: true
      },
      characteristic: quiverCharacteristic,
      finFamily: {
        ...finFamily,
        optional: true
      }
    });
    this.QuiverItem.extend(this.QuiverItemType);

    this.SpotSituation = getSimpleSchema({
      spotId: id,
      spotLaunchId: {
        ...id,
        optional: true
      },
      fromInUserTZ: {
        type: Date
      },
      userTZ: String,
      abstract: {
        type: String,
        optional: true
      },
      quiver: {
        type: Array,
        optional: true
      },
      'quiver.$': this.QuiverItem
      // Note: for activities, it depends, some have it optional, others mandatory.
      // Will be defined by inheritances
    });

    this.SpotSituationWithFeedback = getSimpleSchema({
      score5: {
        ...score5,
        optional: true
      },
      windStability5: {
        ...score5,
        optional: true
      },
      activities: {
        type: Array // No minimum: can be empty
      },
      'activities.$': activity
    });
    this.SpotSituationWithFeedback.extend(this.SpotSituation);

    this.SessionToStart = getSimpleSchema({
      // There must be at least one activity
      activities: {
        type: Array,
        optional: false,
        minCount: 1
      },
      'activities.$': activity,
      withRiders: {
        type: Array,
        optional: true
      },
      'withRiders.$': id,
      fromInUserTZ: {
        type: Date,
        // Can start now + 15 minutes maximum
        max: () => moment.utc().add(15, 'minutes').toDate()
      },
      trackLogId: {
        ...id,
        optional: true
      }
    });
    this.SessionToStart.extend(this.SpotSituation);

    this.SessionToEnd = getSimpleSchema({
      toInUserTZ: {
        type: Date,
        // Can't end in the future
        max: () => new Date(),
        custom() {
          if (this.value < this.field('fromInUserTZ').value) {
            return ErrorTypes.toBeforeFrom;
          }
          return undefined;
        }
      },
      distanceKm: {
        type: Number,
        optional: true,
        min: 0.1,
        max: 1000
      },
      maxSpeedKnot: {
        type: Number,
        optional: true,
        min: 0.1,
        max: 100
      },
      // average speed _when moving_
      avgSpeedKnot: {
        type: Number,
        optional: true,
        min: 0.1,
        max: 100
      }
    });
    this.SessionToEnd.extend(this.SpotSituationWithFeedback);


    this.SessionToInsert = getSimpleSchema({});
    // /!\ warning: must be extended in this order to take constraints on "activities" from SessionToStart
    this.SessionToInsert.extend(this.SessionToEnd);
    this.SessionToInsert.extend(this.SessionToStart);

    this.SessionToUpdate = getSimpleSchema({
      _id: id
    });
    this.SessionToUpdate.extend(this.SessionToStart);
    this.SessionToUpdate.extend(this.SessionToEnd);

    // Booking can only be "in the future".
    // In practice, it means has to start today at the earliest, and has to end in the future
    this.BookingToInsert = getSimpleSchema({
      fromInUserTZ: {
        type: Date,
        // Cannot start before the beginning of today
        min: () => DateUtils.toMidnightDate(moment.utc())
      },
      toInUserTZ: {
        type: Date,
        optional: true,
        // Must end in the future
        min: new Date(),
        custom() {
          if (this.value < this.field('fromInUserTZ').value) {
            return ErrorTypes.toBeforeFrom;
          }
          return undefined;
        }
      },
      activities: {
        type: Array // No minimum: can be empty
      },
      'activities.$': activity
    });
    this.BookingToInsert.extend(this.SpotSituation);

    // Observation doesn't have an end date: "start date" is the observation date
    // It can only be for the current day or in the near future (to have some flexibility)
    this.ObservationToInsert = getSimpleSchema({
      measuredWindKt: {
        type: Number,
        optional: true,
        min: 0
      },
      activities: {
        type: Array, // No minimum: can be empty
        optional: true
      },
      'activities.$': activity,
      fromInUserTZ: {
        type: Date,
        min: DateUtils.getStartOfDayForTimezone(new Date()),
        // Can start now + 15 minutes maximum
        max: () => moment.utc().add(15, 'minutes').toDate()
      }
    });
    this.ObservationToInsert.extend(this.SpotSituationWithFeedback);
    this.ObservationToInsert.extend(this.SpotSituation);

    this.PasswordAndConfirm = getSimpleSchema({
      password: {
        type: String,
        regEx: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/, // At least 1 digit, 1 lower-case and 1 upper-case
        min: 8
      },
      confirm: {
        type: String,
        custom() {
          if (this.value !== this.field('password').value) {
            return 'passwordMismatch';
          }
        }
      }
    });

    // Any alphabetical characters and space, plus "-", see https://stackoverflow.com/questions/20690499/concrete-javascript-regular-expression-for-accented-characters-diacritics
    const userFullName = {
      type: String,
      regEx: /^[a-zA-Z\u00C0-\u024F\u1E00-\u1EFF-]+( [a-zA-Z\u00C0-\u024F\u1E00-\u1EFF-]+)+$/
    };

    const email = SimpleSchema.RegEx.EmailWithTLD;

    // FIXME ensure unique name
    const newUser = getSimpleSchema({
      name: userFullName,
      email: email,
      language: {
        type: String,
        allowedValues: [...Languages]
      }
    });

    this.UserToRegister = getSimpleSchema({
      _id: id
    });
    this.UserToRegister.extend(newUser);

    this.UserToCreate = getSimpleSchema({});
    this.UserToCreate.extend(newUser);
    this.UserToCreate.extend(this.PasswordAndConfirm);

    this.NewPassword = getSimpleSchema({
      oldPassword: String
    });
    this.NewPassword.extend(this.PasswordAndConfirm);

    this.CreatedUser = getSimpleSchema({
      _id: id,
      username: String,
      emails: { type: Array },
      'emails.$': { type: Object },
      'emails.$.address': email,
      'emails.$.verified': { type: Boolean },
      createdAt: { type: Date },
      services: { type: Object },
      'services.password': { type: Object },
      'services.password.bcrypt': { type: String }
    });

    this.LoginUser = getSimpleSchema({
      name: { type: String, optional: true },
      email: { type: String, optional: true },
      id: { ...id, optional: true },
      password: String
    });

    this.EntityLink = getSimpleSchema({
      type: {
        type: EntityType,
        typeName: 'EntityType'
      } as TypedSchemaDefinition,
      id: { ...id, optional: true },
      entityOwnerId: { ...id, optional: true },
      shortName: {
        type: String,
        optional: true
      }
    });

    this.PhotoEntityLink = getSimpleSchema({
      variant: { type: Object, optional: true }
    });
    this.PhotoEntityLink.extend(this.EntityLink);

    this.CommentToInsert = getSimpleSchema({
      entities: Array,
      'entities.$': this.EntityLink,
      content: {
        type: String,
        min: 2
      }
    });

    this.FinConfig = getSimpleSchema({
      count: {
        // TODO set min and max
        type: Number,
        optional: true
      },
      type: {
        type: FinBoxType,
        typeName: 'FinBoxType'
      } as TypedSchemaDefinition
    });

    this.RiderWeightRange = getSimpleSchema({
      fromKg: {
        min: 0,
        type: Number,
        optional: true
      },
      toKg: {
        max: 200,
        // TODO should be more than fromKg
        type: Number,
        optional: true
      }
    });

    this.WindRange = getSimpleSchema({
      fromKt: {
        min: 0,
        type: Number,
        optional: true
      },
      toKt: {
        max: 100,
        // TODO should be more than fromKt
        type: Number,
        optional: true
      }
    });

    const minimumGearVariant = {
      weightKg: {
        // TODO set min and max
        type: Number,
        optional: true
      },
      // Variant will contain a list of {key, value}
      variant: {
        type: Array,
        optional: true
      },
      'variant.$': {
        type: getSimpleSchema({
          key: String,
          value: String // This could sometimes be a number in the end
        })
      }
    };



    const mainCharacteristicsBoard = {
      volumeL: {
        ...boardVolumeL,
        optional: true // See below atLeastOneOfBoard
      },
      lengthCm: {
        ...boardLengthCm,
        optional: true // See below atLeastOneOfBoard
      },
      lengthFt: {
        ...inputBoardLengthFt,
        optional: true // See below atLeastOneOfBoard
      }
    };

    const atLeastOneOfBoard: (keyof typeof mainCharacteristicsBoard)[] =
      ['volumeL', 'lengthCm', 'lengthFt'];

    this.GearVariantSurfBoard = getSimpleSchema({
      ...minimumGearVariant,
      lengthFt: inputBoardLengthFt,
      lengthCm: {
        ...boardLengthCm,
        optional: true
      },
      widthCm: {
        ...boardWidthCm,
        optional: true
      },
      thicknessCm: {
        ...boardThicknessCm,
        optional: true
      }
    });

    this.GearVariantWindsurfBoard = getSimpleSchema({
      ...minimumGearVariant,
      volumeL: boardVolumeL,
      lengthCm: {
        ...boardLengthCm,
        optional: true
      },
      widthCm: {
        ...boardWidthCm,
        optional: true
      },
      compatibleFinFamilies: {
        type: Array,
        optional: true
      },
      'compatibleFinFamilies.$': {
        type: FinFamily,
        typeName: 'FinFamily'
      } as TypedSchemaDefinition,
      strapInsertCount: {
        ...boardStrapInsertCount,
        optional: true
      },
      fins: {
        type: Array,
        optional: true
      },
      'fins.$': this.FinConfig,
      sailRange: {
        type: getSimpleSchema({
          fromM2: {
            ...sailSurfaceM2,
            optional: true
          },
          toM2: {
            ...sailSurfaceM2,
            optional: true
          }
        }),
        optional: true
      }
    });

    this.GearVariantWindsurfSail = getSimpleSchema({
      ...minimumGearVariant,
      surfaceM2: sailSurfaceM2,
      topType: {
        type: WindsurfSailTopType,
        typeName: 'WindsurfSailTopType',
        optional: true
      } as TypedSchemaDefinition,
      luffLengthCm: {
        // TODO set min and max
        type: Number,
        optional: true
      },
      boomLengthsCm: {
        type: Array,
        optional: true
      },
      'boomLengthsCm.$': {
        // TODO set min and max
        type: Number
      },
      mastLengthsCm: {
        type: Array,
        optional: true
      },
      'mastLengthsCm.$': {
        // TODO set min and max
        type: Number
      },
      mastExtensionLengthsCm: {
        type: Array,
        optional: true
      },
      'mastExtensionLengthsCm.$': {
        // TODO set min and max
        type: Number
      },
      mastIMCS: {
        type: Array,
        optional: true
      },
      'mastIMCS.$': {
        // TODO set min and max
        type: Number
      },
      mastTypes: {
        type: Array,
        optional: true
      },
      'mastTypes.$': {
        type: MastType,
        typeName: 'MastType'
      } as TypedSchemaDefinition,
      battenCount: {
        // TODO set min and max
        type: Number,
        optional: true
      },
      camCount: {
        // TODO set min and max
        type: Number,
        optional: true
      }
    });

    this.HandleConfig = getSimpleSchema({
      boomCount: {
        // TODO set min and max
        type: Number
      },
      edgeCount: {
        // TODO set min and max
        type: Number
      }
    });

    this.GearVariantWing = getSimpleSchema({
      ...minimumGearVariant,
      surfaceM2: sailSurfaceM2,
      wingSpanM: {
        // TODO set min and max
        type: Number,
        optional: true
      },
      chordM: {
        // TODO set min and max
        type: Number,
        optional: true
      },
      handleConfig: {
        type: this.HandleConfig,
        optional: true
      }
    });

    this.GearVariantKite = getSimpleSchema({
      ...minimumGearVariant,
      surfaceM2: sailSurfaceM2,
      battenCount: {
        // TODO set min and max
        type: Number,
        optional: true
      },
      lineCount: {
        // TODO set min and max
        type: Number,
        optional: true
      },
      lineLengthsM: {
        type: Array,
        optional: true
      },
      'lineLengthsM.$': {
        // TODO set min and max
        type: Number
      },
      recommendedWeightRange: {
        type: this.RiderWeightRange,
        optional: true
      },
      windRange: {
        type: this.WindRange,
        optional: true
      }
    });

    const gearVariantWingBoardDefinition = {
      ...minimumGearVariant,
      ...mainCharacteristicsBoard,
      widthCm: {
        ...boardWidthCm,
        optional: true
      },
      thicknessCm: {
        ...boardThicknessCm,
        optional: true
      },
      strapInsertCount: {
        ...boardStrapInsertCount,
        optional: true
      },
      fins: {
        type: Array,
        optional: true
      },
      'fins.$': this.FinConfig,
      recommendedWeightRange: {
        type: this.RiderWeightRange,
        optional: true
      }
    };
    this.GearVariantWingBoard = getSimpleSchema(gearVariantWingBoardDefinition);
    this.GearVariantWingBoard.addDocValidator(validateAtLeastOneMandatory(atLeastOneOfBoard));

    this.GearVariantKiteBoard = getSimpleSchema({
      ...minimumGearVariant,
      ...mainCharacteristicsBoard,
      widthCm: {
        ...boardWidthCm,
        optional: true
      },
      compatibleFinFamilies: {
        type: Array,
        optional: true
      },
      'compatibleFinFamilies.$': {
        type: FinFamily,
        typeName: 'FinFamily'
      } as TypedSchemaDefinition,
      strapInsertCount: {
        ...boardStrapInsertCount,
        optional: true
      },
      fins: {
        type: Array,
        optional: true
      },
      'fins.$': this.FinConfig,
      recommendedWeightRange: {
        type: this.RiderWeightRange,
        optional: true
      }
    });
    this.GearVariantKiteBoard.addDocValidator(validateAtLeastOneMandatory(atLeastOneOfBoard));

    this.GearVariantPaddleBoard = getSimpleSchema({
      ...minimumGearVariant,
      ...mainCharacteristicsBoard,
      widthCm: {
        ...boardWidthCm,
        optional: true
      },
      compatibleFinFamilies: {
        type: Array,
        optional: true
      },
      'compatibleFinFamilies.$': {
        type: FinFamily,
        typeName: 'FinFamily'
      } as TypedSchemaDefinition,
      fins: {
        type: Array,
        optional: true
      },
      'fins.$': this.FinConfig
    });
    this.GearVariantPaddleBoard.addDocValidator(validateAtLeastOneMandatory(atLeastOneOfBoard));

    const rawGearModel = {
      brandId: id,
      name: String,
      year: {
        type: Number,
        min: 1970,
        max: new Date().getFullYear() + 1
      },
      version: {
        type: String,
        optional: true
      },
      infoUrl: {
        ...url,
        optional: true
      },
      programs: {
        type: Array,
        optional: true
      },
      'programs.$': {
        type: Program,
        typeName: 'Program'
      } as TypedSchemaDefinition,
      description: {
        type: Array,
        optional: true
      },
      'description.$': this.WithLanguageText
    };

    const gearModelToInsert = getSimpleSchema({
      type: {
        type: ProductType,
        typeName: 'ProductType'
      } as TypedSchemaDefinition,
      subType: {
        type: ProductSubType,
        typeName: 'ProductSubType'
      } as TypedSchemaDefinition,
      ...rawGearModel
    });

    // Not possible to change type and sub type of a gear
    const gearModelToUpdate = getSimpleSchema({
      ...rawGearModel,
      _id: id
    });

    const surfBoardSpecs = {
      variants: {
        type: Array,
        minCount: 1
      },
      'variants.$': this.GearVariantSurfBoard
    };
    this.SurfBoardToInsert = getSimpleSchema(surfBoardSpecs);
    this.SurfBoardToInsert.extend(gearModelToInsert);
    // For some reason it seems necessary to re-define a variant validator for the whole document
    this.SurfBoardToInsert.addDocValidator(docVariantValidator(atLeastOneOfBoard));

    this.SurfBoardToUpdate = getSimpleSchema(surfBoardSpecs);
    this.SurfBoardToUpdate.extend(gearModelToUpdate);
    // For some reason it seems necessary to re-define a variant validator for the whole document
    this.SurfBoardToUpdate.addDocValidator(docVariantValidator(atLeastOneOfBoard));

    const windsurfBoardSpecs = {
      variants: {
        type: Array,
        minCount: 1
      },
      'variants.$': this.GearVariantWindsurfBoard
    };
    this.WindsurfBoardToInsert = getSimpleSchema(windsurfBoardSpecs);
    this.WindsurfBoardToInsert.extend(gearModelToInsert);
    this.WindsurfBoardToUpdate = getSimpleSchema(windsurfBoardSpecs);
    this.WindsurfBoardToUpdate.extend(gearModelToUpdate);

    const wingBoardSpecs = {
      variants: {
        type: Array,
        minCount: 1
      },
      'variants.$': this.GearVariantWingBoard
    };
    this.WingBoardToInsert = getSimpleSchema(wingBoardSpecs);
    this.WingBoardToInsert.extend(gearModelToInsert);
    // For some reason it seems necessary to re-define a variant validator for the whole document
    this.WingBoardToInsert.addDocValidator(docVariantValidator(atLeastOneOfBoard));

    this.WingBoardToUpdate = getSimpleSchema(wingBoardSpecs);
    this.WingBoardToUpdate.extend(gearModelToUpdate);
    // For some reason it seems necessary to re-define a variant validator for the whole document
    this.WingBoardToUpdate.addDocValidator(docVariantValidator(atLeastOneOfBoard));

    const kiteBoardSpecs = {
      kiteBoardType: {
        type: KiteBoardType,
        typeName: 'KiteBoardType'
      } as TypedSchemaDefinition,
      variants: {
        type: Array,
        minCount: 1
      },
      'variants.$': this.GearVariantKiteBoard
    };
    this.KiteBoardToInsert = getSimpleSchema(kiteBoardSpecs);
    this.KiteBoardToInsert.extend(gearModelToInsert);
    // For some reason it seems necessary to re-define a variant validator for the whole document
    this.KiteBoardToInsert.addDocValidator(docVariantValidator(atLeastOneOfBoard));

    this.KiteBoardToUpdate = getSimpleSchema(kiteBoardSpecs);
    this.KiteBoardToUpdate.extend(gearModelToUpdate);
    // For some reason it seems necessary to re-define a variant validator for the whole document
    this.KiteBoardToUpdate.addDocValidator(docVariantValidator(atLeastOneOfBoard));

    const paddleBoardSpecs = {
      variants: {
        type: Array,
        minCount: 1
      },
      'variants.$': this.GearVariantPaddleBoard
    };
    this.PaddleBoardToInsert = getSimpleSchema(paddleBoardSpecs);
    this.PaddleBoardToInsert.extend(gearModelToInsert);
    // For some reason it seems necessary to re-define a variant validator for the whole document
    this.PaddleBoardToInsert.addDocValidator(docVariantValidator(atLeastOneOfBoard));

    this.PaddleBoardToUpdate = getSimpleSchema(paddleBoardSpecs);
    this.PaddleBoardToUpdate.extend(gearModelToUpdate);
    // For some reason it seems necessary to re-define a variant validator for the whole document
    this.PaddleBoardToUpdate.addDocValidator(docVariantValidator(atLeastOneOfBoard));

    const wingSpecs = {
      variants: {
        type: Array,
        minCount: 1
      },
      'variants.$': this.GearVariantWing
    };
    this.WingToInsert = getSimpleSchema(wingSpecs);
    this.WingToInsert.extend(gearModelToInsert);
    this.WingToUpdate = getSimpleSchema(wingSpecs);
    this.WingToUpdate.extend(gearModelToUpdate);

    const kiteSpecs = {
      kiteType: {
        type: KiteType,
        typeName: 'KiteType'
      } as TypedSchemaDefinition,
      variants: {
        type: Array,
        minCount: 1
      },
      'variants.$': this.GearVariantKite
    };
    this.KiteToInsert = getSimpleSchema(kiteSpecs);
    this.KiteToInsert.extend(gearModelToInsert);
    this.KiteToUpdate = getSimpleSchema(kiteSpecs);
    this.KiteToUpdate.extend(gearModelToUpdate);

    const windsurfSailSpecs = {
      variants: {
        type: Array,
        minCount: 1
      },
      'variants.$': this.GearVariantWindsurfSail
    };
    this.WindsurfSailToInsert = getSimpleSchema(windsurfSailSpecs);
    this.WindsurfSailToInsert.extend(gearModelToInsert);
    this.WindsurfSailToUpdate = getSimpleSchema(windsurfSailSpecs);
    this.WindsurfSailToUpdate.extend(gearModelToUpdate);

    this.LocationPoint = getSimpleSchema({
      type: {
        type: String,
        allowedValues: ['Point']
      },
      coordinates: location,
      'coordinates.$': { type: Number }
    });

    this.LocationPolygon = getSimpleSchema({
      type: {
        type: String,
        allowedValues: ['Polygon']
      },
      coordinates: {
        type: Array,
        // Don't allow multi-polygons
        minCount: 1,
        maxCount: 1
      },
      'coordinates.$': {
        type: Array,
        // Let's say the bare minimum is a triangle!
        minCount: 3
      },
      'coordinates.$.$': location,
      'coordinates.$.$.$': { type: Number }
    });

    this.PhotoToInsert = getSimpleSchema({
      entities: Array,
      'entities.$': this.PhotoEntityLink,
      url,
      thumbnailUrl: url,
      width: SimpleSchema.Integer,
      height: SimpleSchema.Integer,
      location: {
        type: this.LocationPoint,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO typings should allow it
        typeName: 'Point' as Point as unknown as string, // Hack to import type
        optional: true
      },
      description: {
        type: Array,
        optional: true
      },
      'description.$': this.WithLanguageText
    });

    this.NamedEntity = getSimpleSchema({
      name: entityName,
      aliases: {
        type: Array,
        optional: true
      },
      'aliases.$': entityName
    });

    const rawLink = {
      url,
      name: String,
      description: {
        type: Array,
        optional: true
      },
      'description.$': this.WithLanguageText
    };

    this.Link = getSimpleSchema({
      ...rawLink,
      type: {
        type: LinkType,
        typeName: 'LinkType'
      } as TypedSchemaDefinition
    });

    // Note: no name or description for social links
    this.SocialLink = getSimpleSchema({
      url,
      type: {
        type: SocialLinkType,
        typeName: 'SocialLinkType'
      } as TypedSchemaDefinition
    });

    this.EntityWithDescription = getSimpleSchema({
      abstract: {
        type: Array,
        optional: true
      },
      'abstract.$': this.WithLanguageText,
      description: {
        type: Array,
        optional: true
      },
      'description.$': this.WithLanguageText,
      links: {
        type: Array,
        optional: true
      },
      'links.$': this.Link
    });

    this.Property = getSimpleSchema({
      details: {
        type: Array,
        optional: true
      },
      'details.$': this.WithLanguageText
    });

    const SpotType = getSimpleSchema({
      value: {
        type: SpotTypeValue,
        typeName: 'SpotTypeValue'
      } as TypedSchemaDefinition
    });
    SpotType.extend(this.Property);

    const EntryLevel = getSimpleSchema({
      value: {
        type: EntryLevelValue,
        typeName: 'EntryLevelValue'
      } as TypedSchemaDefinition
    });
    EntryLevel.extend(this.Property);

    const Depth = getSimpleSchema({
      value: {
        type: DepthValue,
        typeName: 'DepthValue'
      } as TypedSchemaDefinition
    });
    Depth.extend(this.Property);

    const BooleanProperty = getSimpleSchema({
      value: {
        type: Boolean
      }
    });
    BooleanProperty.extend(this.Property);

    const Dangers = getSimpleSchema({
      value: {
        type: Array
        // No minimum: there can simply be "no danger"
      },
      'value.$': {
        type: Danger,
        typeName: 'Danger'
      } as TypedSchemaDefinition
    });
    Dangers.extend(this.Property);

    const GearForRent = getSimpleSchema({
      value: {
        type: Array
        // Note: no minimum as an empty array will mean: _I know_ that there is no gear for rent
      },
      'value.$': {
        type: Activity,
        typeName: 'Activity'
      } as TypedSchemaDefinition
    });
    GearForRent.extend(this.Property);

    const BottomType = getSimpleSchema({
      value: {
        type: BottomTypeValue,
        typeName: 'BottomTypeValue'
      } as TypedSchemaDefinition
    });
    BottomType.extend(this.Property);

    // FIXME ensure unique name
    this.SpotToInsert = getSimpleSchema({
      location: {
        type: this.LocationPolygon,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO typings should allow it
        typeName: 'Polygon' as Polygon as unknown as string // Hack to import type
      },
      spotType: {
        type: SpotType,
        optional: true
      },
      entryLevel: {
        type: EntryLevel,
        optional: true
      },
      depth: {
        type: Depth,
        optional: true
      },
      dangers: {
        type: Dangers,
        optional: true
      }
    });
    this.SpotToInsert.extend(this.NamedEntity);
    this.SpotToInsert.extend(this.EntityWithDescription);

    // Note that shortName, timezone and countryCode are not modified by user. They are calculated
    this.SpotToUpdate = getSimpleSchema({
      _id: id
    });
    this.SpotToUpdate.extend(this.SpotToInsert);

    // FIXME ensure unique name
    const spotLaunch = getSimpleSchema({
      location: {
        type: this.LocationPoint,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TODO typings should allow it
        typeName: 'Point' as Point as unknown as string // Hack to import type
      },
      howToGetThere: {
        type: Array,
        optional: true
      },
      'howToGetThere.$': this.WithLanguageText,
      freeParking: { type: BooleanProperty, optional: true },
      publicAccess: { type: BooleanProperty, optional: true },
      hasToilets: { type: BooleanProperty, optional: true },
      hasShower: { type: BooleanProperty, optional: true },
      suitableForKite: { type: BooleanProperty, optional: true },
      gearForRent: { type: GearForRent, optional: true },
      bottomType: { type: BottomType, optional: true }
    });
    spotLaunch.extend(this.NamedEntity);
    spotLaunch.extend(this.EntityWithDescription);

    this.SpotLaunchToInsert = getSimpleSchema({
      spotId: id
    });
    this.SpotLaunchToInsert.extend(spotLaunch);

    this.SpotLaunchToUpdate = getSimpleSchema({
      _id: id
    });
    this.SpotLaunchToUpdate.extend(spotLaunch);

    // FIXME ensure unique name
    this.BrandToInsert = getSimpleSchema({
      infoUrl: {
        ...url,
        optional: true
      },
      homePageUrl: {
        ...url,
        optional: true
      },
      socialLinks: {
        type: Array,
        optional: true
      },
      'socialLinks.$': this.SocialLink
      /**
       *   logoUrl?: string; FIXME add a menu to add photos to a brand, use it as a logo
       */
    });
    this.BrandToInsert.extend(this.NamedEntity);
    this.BrandToInsert.extend(this.EntityWithDescription);

    this.BrandToUpdate = getSimpleSchema({
      _id: id
    });
    this.BrandToUpdate.extend(this.BrandToInsert);

  }
}

export const schemas = new Schema();
export const schemaMap: SchemaMap = (schemas as unknown) as SchemaMap;
