import {Pipe, PipeTransform} from '@angular/core';
import {TranslateConfigService} from '@services/translate-config.service';
import humanizeDuration from "humanize-duration";

@Pipe({
  name: 'formatDuration'
})
export class FormatDurationPipe implements PipeTransform {
  constructor(protected translateConfig: TranslateConfigService) {
  }

  transform(durationInMsec: number): string {
    return humanizeDuration(durationInMsec, {
      language: this.translateConfig.currentLanguage,
      units: ['h'],
      round: true
    })
  }
}
