import {Component, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {AccessControl, TabPageInfo} from '@pages/page-info';
import {IAuthService} from '@services/i-auth.service';
import {mePageInfo} from '@pages/riders/me/me.page.info';

@Component({
  selector: 'zef-tab-icon',
  styleUrls: ['./tab-icon.component.scss'],
  templateUrl: './tab-icon.component.html'
})
export class TabIconComponent implements OnInit {
  AccessControl = AccessControl;
  @Input() tabPageInfo: TabPageInfo;
  @Input() slot: string;
  @ViewChild('template', { static: true }) template;

  constructor(
    private viewContainerRef: ViewContainerRef,
    public auth: IAuthService) {
  }

  isMePage(): boolean {
    return this.tabPageInfo.label === mePageInfo.label;
  }

  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.template);
  }
}
