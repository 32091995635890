<span
  [ngClass]='{active: active === true, inactive: active === false, unknown: active === undefined}'>
  <ng-icon [name]='icon.getSpotLaunchProperty(propertyType, launch.properties[propertyType]?.value)'
           [matTooltip]='showLabel ? undefined : label'
  ></ng-icon>
  <ng-container *ngIf='showLabel'>
    <ion-label>{{ label }}</ion-label>
    <div *ngIf='notEmpty(launch.properties[propertyType]?.details)'
         class='propNote quote'><ng-icon name='tabler-note'></ng-icon><ion-label>{{ launch.properties[propertyType].details | multiLanguage : translateConfig.currentLanguage }}</ion-label></div>
  </ng-container>
</span>
