import { Component, Input, OnInit } from '@angular/core';
import { EditComponent } from '@components/edit/edit-component.directive';
import { Queries } from '@api/queries';
import { FeedbackService } from '@services/feedback.service';
import { ModalController, Platform } from '@ionic/angular';
import { ErrorPipe } from '@pipes/fields/error.pipe';
import { TranslateService } from '@ngx-translate/core';
import { IAuthService } from '@services/i-auth.service';
import { FormBuilder } from '@angular/forms';
import { take, tap } from 'rxjs/operators';
import { filterNullish } from '@utils/rxjs.utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Commands } from '@services/meteor/commands';
import { CreateSpotForm, EditSpotForm } from '@components/spot/edit-spot/spot.form';
import { toInput } from '@model/multi-language-text';
import { nullOrUndefined } from '@core/utils';
import { toInputLink } from '@model/link';
import { toInputProperty } from '@model/place';
import { Danger, DepthValue, EntryLevelValue, SpotTypeValue } from '@model/spot';
import { Observable } from 'rxjs';
import { Id } from '@model/entity';
import { Polygon, Position } from 'geojson';
import { Layer, Polygon as LeafletPolygon } from 'leaflet';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { defaultCenter, rawLatLngToPosition } from '@model/place.utils';
import { CenterAndZoom } from '@components/map/map.component';
import { getInputValue } from '@api/queries/queries.model';
import { DismissRole } from '@utils/dismissRole';
import { SpotToInsert } from '@model/schema-model';
import { EntityTypePipe } from '@pipes/entity-type.pipe';
import { BroadcastService } from '@services/broadcast.service';
import { EntityType } from '@model/entity-type';

@UntilDestroy()
@Component({
  selector: 'zef-edit-spot',
  templateUrl: './edit-spot.component.html',
  styleUrls: [
    '../../edit/edit.component.scss', // /!\ notice this is a shared scss
    'edit-spot.component.scss'
  ]
})
export class EditSpotComponent extends EditComponent<CreateSpotForm | EditSpotForm> implements OnInit {
  @Input() private shortName: string;
  @Input() defaultCenterAndZoom: CenterAndZoom;

  private spotId: Id | undefined;

  isUpdate: boolean;

  SpotTypeValue = SpotTypeValue;
  DepthValue = DepthValue;
  Danger = Danger;
  EntryLevelValue = EntryLevelValue;

  constructor(
    queries: Queries,
    commands: Commands,
    feedback: FeedbackService,
    modalController: ModalController,
    platform: Platform,
    errorPipe: ErrorPipe,
    translateService: TranslateService,
    authService: IAuthService,
    entityTypePipe: EntityTypePipe,
    fb: FormBuilder,
    private geolocation: Geolocation,
    broadcastService: BroadcastService,
  ) {
    super(queries, commands, feedback, modalController, errorPipe, translateService, authService, platform, entityTypePipe, fb, broadcastService);
  }

  async ngOnInit() {
    this.isUpdate = !nullOrUndefined(this.shortName);

    if (this.isUpdate) {
      // TODO should only query spot info, not launches and photos
      this.queries.getSpotAndLaunches.call$(this.shortName)
        .pipe(
          filterNullish(),
          tap(spot => {
            const {properties, abstract, description, links, _id, ...rest} = spot;
            const {spotType, depth, dangers, entryLevel} = properties;
            this.form = new EditSpotForm(this.formGroup, {
              abstract: toInput(abstract),
              description: toInput(description),
              links: links?.map(toInputLink),
              // Properties
              spotType: toInputProperty(spotType),
              depth: toInputProperty(depth),
              dangers: toInputProperty(dangers), // FIXME add dangers input
              entryLevel: toInputProperty(entryLevel),
              ...rest
            }).init(this);
            this.spotId = _id;
          }),
          untilDestroyed(this)
        ).subscribe();
    } else {
      if (this.defaultCenterAndZoom === undefined) {
        let center: Position;
        try {
          const currentPos = (await this.geolocation.getCurrentPosition({timeout: 5_000})).coords;
          center = rawLatLngToPosition({lat: currentPos.latitude, lng: currentPos.longitude});
        } catch (e) {
          center = rawLatLngToPosition(defaultCenter);
        }
        this.defaultCenterAndZoom = {center, zoom: 14};
      }

      this.form = new CreateSpotForm(this.formGroup, {}).init(this);
    }
  }

  protected doSubmit() {
    const spot = this.getDataToSubmit();

    const command$: Observable<number | string> = this.isUpdate
      ? this.commands.updateSpot
        .call$({
          ...spot,
          _id: this.spotId
        })
        .pipe(
          take(1),
          tap(async (updated: number) => {
            if (updated === 1) {
              await this.feedback.toast(this.translateService.instant('SPOT.UPDATE.UPDATED'));
              await this.modalController.dismiss(undefined, DismissRole.UPDATED);
            }
            // FIXME handle error case
            this.broadcastService.sendRefresh({ type: EntityType.spots, id: this.spotId});
          }),
          untilDestroyed(this)
        )
      : this.commands.createSpot
        .call$(spot)
        .pipe(
          take(1),
          filterNullish(),
          tap(async (_id: Id) => {
            await this.feedback.toast(this.translateService.instant('SPOT.CREATE.CREATED'));
            await this.modalController.dismiss({_id}, DismissRole.CREATED);
            this.broadcastService.sendRefresh({ type: EntityType.spots, id: _id});
          }),
          untilDestroyed(this)
        )

    return command$.toPromise()
  }

  protected getDataToSubmit(): SpotToInsert {
    return {
      name: this.form.fields.name.value,
      abstract: this.form.fields.abstract.value,
      description: this.form.fields.description.value,
      links: this.form.fields.links.value,
      location: this.form.fields.location.value,
      aliases: this.form.fields.aliases.value?.map(({alias}) => alias),
      // Properties
      spotType: getInputValue(this.form.fields.spotType.value),
      depth: getInputValue(this.form.fields.depth.value),
      entryLevel: getInputValue(this.form.fields.entryLevel.value),
      dangers: getInputValue(this.form.fields.dangers.value)
    };
  }

  locationEdited(layers: Layer[]) {
    this.form.fields.location.value = (layers[0] as LeafletPolygon).toGeoJSON().geometry as Polygon;
  }
}
