<ng-template #template>
  <ion-badge *ngIf='isLocked' color='danger'>
    <ion-icon name='lock-closed'></ion-icon>
  </ion-badge>
  <ion-badge
    *ngIf='showBadge && auth.isLoggedIn && this.unreadNotificationCount$ | async as notificationsCount'
    color='danger'>
    {{ notificationsCount }}
  </ion-badge>
</ng-template>

