import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DEFAULT_LOGGED_PAGE, TabsPage} from '@pages/tabs/tabs.page';
import {LoggedInGuard} from '../../logged-in-gard';
import {mePageInfo} from '@pages/riders/me/me.page.info';
import {LoggedOutGuard} from '../../logged-out-gard';
import {ModuleMap, toAngularModules} from "../../routing-utils";
import {
  forecastBasePageInfo,
  gearBasePageInfo,
  helpBasePageInfo,
  newsBasePageInfo,
  notificationsBasePageInfo,
  registerBasePageInfo,
  ridersBasePageInfo,
  sessionsBasePageInfo,
  settingsBasePageInfo,
  spotsBasePageInfo,
  wallBasePageInfo
} from "@core/routing/pages-list";
import {tabsRoutes} from "@core/routing/tabs-routing";

const moduleMap: ModuleMap = {
  [wallBasePageInfo.key]: {
    loadChildren: () => import('../wall/wall.module').then(m => m.WallPageModule)
  },
  [forecastBasePageInfo.key]: {
    loadChildren: () => import('../forecast/forecast.module').then(m => m.ForecastPageModule)
  },
  [notificationsBasePageInfo.key]: {
    loadChildren: () => import('../notifications/notifications.module').then(m => m.NotificationsPageModule)
  },
  [settingsBasePageInfo.key]: {
    loadChildren: () => import('../settings/settings.module').then(m => m.SettingsPageModule)
  },
  [spotsBasePageInfo.key]: {
    loadChildren: () => import('../spots/spots.module').then(m => m.SpotsPageModule)
  },
  [ridersBasePageInfo.key]: {
    loadChildren: () => import('../riders/riders.module').then(m => m.RidersPageModule)
  },
  [gearBasePageInfo.key]: {
    loadChildren: () => import('../gear/gear.module').then(m => m.GearPageModule)
  },
  [helpBasePageInfo.key]: {
    loadChildren: () => import('../help/help.module').then(m => m.HelpPageModule)
  },
  [newsBasePageInfo.key]: {
    loadChildren: () => import('../news/news.module').then(m => m.NewsPageModule)
  },
  [registerBasePageInfo.key]: {
    loadChildren: () => import('../auth/register/register.module').then(m => m.RegisterPageModule),
    canActivate: [LoggedOutGuard]
  },
  [sessionsBasePageInfo.key]: {
    loadChildren: () => import('../sessions/sessions.module').then(m => m.SessionsModule),
  },
}


const routes: Routes = [
  {
    path: '',
    component: TabsPage,
    children: [
      {
        path: mePageInfo.path,
        loadChildren: () => import('../riders/me/me.module').then(m => m.MePageModule)
      },
      ...toAngularModules(tabsRoutes, moduleMap),
      // Default for logged-in users. Otherwise, will redirect to "about"
      {
        path: '',
        redirectTo: DEFAULT_LOGGED_PAGE.path,
        pathMatch: 'full'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  providers: [LoggedInGuard, LoggedOutGuard]
})
export class TabsPageRoutingModule {
}
