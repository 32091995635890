import {Component} from '@angular/core';
import {PropertyComponent} from '@components/input/property/property/property.component';
import {capitalize} from 'lodash';

@Component({
  selector: 'zef-bool-property',
  templateUrl: './boolean-property.component.html',
  styleUrls: ['./boolean-property.component.scss']
})
export class BooleanPropertyComponent extends PropertyComponent<boolean> {
  capitalize = capitalize;
}
