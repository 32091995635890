import { FormBuilder, FormGroup } from '@angular/forms';
import { Directive, HostListener } from '@angular/core';
import { Queries } from '@api/queries';
import { FeedbackService } from '@services/feedback.service';
import { ModalController, Platform } from '@ionic/angular';
import { ErrorPipe } from '@pipes/fields/error.pipe';
import { TranslateService } from '@ngx-translate/core';
import { IAuthService } from '@services/i-auth.service';
import { IForm } from '@utils/form';
import { FieldLabelType } from '@pipes/fields/field-label.pipe';
import { nullOrUndefined } from '@core/utils';
import { Commands } from '@services/meteor/commands';
import { DismissRole } from '@utils/dismissRole';
import { onlyUnique } from '@core/array.utils';
import { ErrorType } from '@api/errors';
import { EntityTypePipe, EntityTypePrefix } from '@pipes/entity-type.pipe';
import { Entity } from '@model/entity';
import { NamedEntity } from '@model/entity-with-description';
import { BroadcastService } from '@services/broadcast.service';

export interface DuplicateErrorReason<T extends Entity> {
  code: ErrorType,
  keyPattern: Record<keyof T, number>,
  keyValue: Partial<T>
}

@Directive()
export abstract class EditComponent<TFORM extends IForm> {
  form: TFORM;
  formGroup: FormGroup;
  allErrors: string[];

  FieldLabelType = FieldLabelType;
  nullOrUndefined = nullOrUndefined;
  disabledButton = false;

  constructor(
    protected queries: Queries,
    protected commands: Commands,
    protected feedback: FeedbackService,
    protected modalController: ModalController,
    protected errorPipe: ErrorPipe,
    protected translateService: TranslateService,
    protected authService: IAuthService,
    protected platform: Platform,
    protected entityTypePipe: EntityTypePipe,
    fb: FormBuilder,
    protected broadcastService: BroadcastService
  ) {
    this.formGroup = fb.group({});
  }

  async cancel() {
    await this.modalController.dismiss(undefined, DismissRole.CANCELLED);
  }

  onPopperShown() {
    this.allErrors = this.form.allErrors
      .map(error => this.errorPipe.transform(error, this.form.parentEntityType))
      .filter(onlyUnique);
  }

  @HostListener('window:beforeunload', ['$event'])
  confirmLeavingPage($event: any) {
    // Cancel the event as stated by the standard.
    $event.preventDefault();
    // the message is overridden by all modern browsers, but this is required for chrome to show the popup
    $event.returnValue = 'Your data will be lost!';
  }

  get popperTrigger() {
    return this.platform.is('desktop') ? 'hover' : 'click';
  }

  async submit() {
    this.disabledButton = true;

    if (!this.formGroup.valid) {
      this.onPopperShown();
    } else {
      try {
        await this.doSubmit();
      } catch (error) {
        await this.handleError(error);
      }
    }

    // In either case, let's re-enable the submit button
    this.disabledButton = false;
  }

  protected async handleError(error: Meteor.Error) {

    const reason = error.reason as unknown as DuplicateErrorReason<NamedEntity>;

    if (reason?.code === ErrorType.DuplicateCode) {
      if (await this.handleDuplicateError(reason)) {
        return;
      }
    }

    await this.feedback.meteorError(error);
  }

  protected async handleDuplicateError(reason: DuplicateErrorReason<NamedEntity>) {
    if ((reason as DuplicateErrorReason<NamedEntity>).keyPattern?.shortName === 1) {
      const type = this.form.parentEntityType;
      const content = {
        subTitle: this.translateService.instant('FORMS.ERRORS.duplicate.TITLE'),
        errorMsg: this.translateService.instant('FORMS.ERRORS.duplicate.DESCRIPTION', {
          entity: this.entityTypePipe.transform(type, EntityTypePrefix.A)
        })
      };
      await this.feedback.textError(content);
      return true;
    }
    return false;
  }

  protected abstract doSubmit(): Promise<unknown>;

  protected abstract getDataToSubmit();
}
