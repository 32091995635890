import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from '@angular/common';

export enum FormatType {
  AUTO,
  DATE_AND_TIME,
  TIME_ONLY,
  DATE_ONLY
}

@Pipe({
  name: 'percentage'
})
export class PercentagePipe implements PipeTransform {
  constructor(private decimal: DecimalPipe) {
  }

  transform(value: number, padding = false): string {
    const formatted = this.decimal.transform(value, '2.0-0');
    return padding ? formatted.replace(/^0/g, '&nbsp;&nbsp;') : formatted;
  }
}
