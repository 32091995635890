import {Component} from '@angular/core';
import {AccessControl} from '@pages/page-info';
import {TabBarService} from '@services/tab-bar.service';
import {ModalController, NavController, Platform} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {TranslateConfigService} from '@services/translate-config.service';
import {IAuthService} from '@services/i-auth.service';
import {Router} from '@angular/router';
import {DEFAULT_ANONYMOUS_PAGE, DEFAULT_LOGGED_PAGE, getFullPath} from '@pages/tabs/tabs.page';

@Component({
  selector: 'zef-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  AccessControl = AccessControl;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translateConfig: TranslateConfigService,
    private tabBarService: TabBarService,
    public auth: IAuthService,
    public modalController: ModalController,
    public router: Router,
    public navController: NavController
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    await this.translateConfig.setLoggedOutLanguage();
    await this.platform.ready();
    this.statusBar.styleDefault();
    this.splashScreen.hide();

    this.platform.backButton.subscribeWithPriority(999, async () => {
      if (await this.modalController.getTop()) {
        const modal = await this.modalController.getTop();
        console.log(modal);
        if (modal) {
          await this.modalController.dismiss();
          return;
        } else {
          if (this.isAtRootPage()) {
            navigator['app'].exitApp();
          } else {
            await this.navController.back();
          }
        }
      } else {
        if (this.isAtRootPage()) {
          navigator['app'].exitApp();
        } else {
          await this.navController.back();
        }
      }
    });
  }

  private isAtRootPage() {
    return this.router.url === getFullPath(DEFAULT_ANONYMOUS_PAGE) || this.router.url === getFullPath(DEFAULT_LOGGED_PAGE);
  }
}
