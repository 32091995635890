import {Pipe, PipeTransform} from '@angular/core';
import {kmPerHToKnots} from '@core/unit-utils';

@Pipe({
  name: 'kmPerHToKnots'
})
export class KmPerHToKnotsPipe implements PipeTransform {
  constructor() {
  }

  transform(value: number): number {
    return kmPerHToKnots(value);
  }
}
