import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NamedEntity } from '@model/entity-with-description';
import { FrontendRoutingService } from '@services/frontend-routing.service';
import { UnIdentifiedGearItem } from '@model/gear/gear-item';
import { Id } from '@model/entity';
import { nullOrUndefined } from '@core/utils';
import { SurfBoard, WindsurfBoard, WindsurfSail } from '@model/gear/variants';
import { WingBoard } from '@model/gear/variants/wing-board';
import { Wing } from '@model/gear/variants/wing';
import { Path } from '@core/routing/route-serializing.service';
import { ModelVariant } from '@model/gear/gear-utils';
import { EntityLink } from '@model/entity-link';
import { EntityType } from '@model/entity-type';

export type IdentifiedGearItem = ModelVariant & UnIdentifiedGearItem & NamedEntity & {
  brandName: string,
  year: number
};

export const toGearItem = (variant: ModelVariant): IdentifiedGearItem => {
  return {
    ...variant,
    itemId: variant.itemId!,
    characteristic: { ...variant.variant } as (WindsurfBoard<unknown> | WingBoard<unknown> | SurfBoard<unknown> | WindsurfSail<unknown> | Wing<unknown>)
  };
};

@Component({
  selector: 'zef-gear-item-item',
  templateUrl: './gear-item-item.component.html',
  styleUrls: ['./gear-item-item.component.scss']
})
export class GearItemItemComponent implements OnInit {
  _gearItem: IdentifiedGearItem | UnIdentifiedGearItem;
  itemAsIdentifiedItem: IdentifiedGearItem;
  isIdentifiedItem: boolean;
  entity: EntityLink;

  @Input() set gearItem(value: IdentifiedGearItem | UnIdentifiedGearItem) {
    this._gearItem = value;
    this.itemAsIdentifiedItem = this._gearItem as IdentifiedGearItem;
    this.isIdentifiedItem = !nullOrUndefined(this.itemAsIdentifiedItem.shortName);
    this.entity = this.isIdentifiedItem
      ? { id: this.itemAsIdentifiedItem.itemId, type: EntityType.gearItems }
      : null;
  }

  @Input() clickable = true;
  @Input() modelShortName: string | undefined;
  @Input() showDetails = true;

  @ViewChild('template', { static: true }) template;
  path: Path;

  constructor(public routing: FrontendRoutingService,
              private viewContainerRef: ViewContainerRef) {
  }

  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
    if (this.isIdentifiedItem && this.clickable) {
      this.path = this.routing.pathToGearItem({
        shortName: this.itemAsIdentifiedItem.shortName,
        _id: this.itemAsIdentifiedItem.itemId
      });
    }
  }

}
