import {WithLanguageText} from './schema-model';
import {toDict} from '../utils';

export const Languages = ['en', 'fr'] as const;
export type Language = typeof Languages[number];

export type MultiLanguageText = { [language in Language]?: string };

export const getLocale = (language: Language) => {
  switch (language) {
    case "fr":
      return 'fr_FR'
    default:
    case "en":
      return 'en_US'
  }
}

export const getBestLanguage = (text: MultiLanguageText | undefined, expectedLanguage: Language): Language | undefined => {
  if (!text) {
    return undefined;
  }

  const languages = Object.keys(text) as Language[];

  // Best case: includes this language
  if (languages.includes(expectedLanguage) && (text[expectedLanguage]?.length ?? 0 > 0)) {
    return expectedLanguage;
  }

  // Otherwise, try "default" language == English
  if (languages.includes('en')) {
    return 'en';
  }

  // Last chance, return first language
  return languages[0];
};

export const getText = (text: MultiLanguageText | undefined, expectedLanguage: Language) => {
  const language = getBestLanguage(text, expectedLanguage);
  if (!text || !language) {
    return undefined;
  }
  return text[language];
};

/**
 * Does this multi-language text contain at least one language with non-empty text?
 * @param text
 */
export const notEmpty = (text: MultiLanguageText): boolean => {
  return Object.keys(text ?? []).findIndex(language => text[<Language>language]!.length > 0) !== -1;
};

// Convert a list of (lang + text) into a dictionary of {lang => text}, filtering out empty texts
export const fromInput = <I extends WithLanguageText[] | undefined>(input: I): I extends undefined ? undefined : MultiLanguageText =>
  input
    ? toDict((input as WithLanguageText[]).filter(t => t?.text?.length > 0),
      t => t.lang,
      t => t.text
    )
    : undefined as any;

export const toInput = <I extends MultiLanguageText | undefined>(multiLanguageText: I): I extends undefined ? undefined : WithLanguageText[] =>
  multiLanguageText
    ? Object.keys(multiLanguageText).map((lang: string) => ({
      lang,
      text: multiLanguageText[lang as Language] as string
    }))
    : undefined as any;
