import {Pipe, PipeTransform} from '@angular/core';
import {UnIdentifiedGearItem} from '@model/gear/gear-item';
import {TranslateService} from '@ngx-translate/core';
import {getCharacteristicLabel} from "@model/gear/gear-utils";

@Pipe({
  name: 'gearModelItemVariation'
})
export class GearModelItemVariationPipe implements PipeTransform {
  constructor(private translate: TranslateService) {
  }

  transform(info: UnIdentifiedGearItem): string {
    return getCharacteristicLabel(info, (key: string | Array<string>, interpolateParams?: Object) => this.translate.instant(key, interpolateParams));
  }
}
