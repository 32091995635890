import {Id} from '@model/entity';
import {Observable} from 'rxjs';
import {NamedEntity} from '@model/entity-with-description';

export abstract class ICachedBrandsService {
  abstract getEntitiesByIds$(ids: Id[]): Observable<NamedEntity[]>;

  abstract getEntityById$(_id: Id): Observable<NamedEntity>;

  abstract getAllEntities$(): Observable<NamedEntity[]>;
}
