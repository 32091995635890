import {
  EntityLink,
  EntityLinkWithOwner,
  EventType,
  getThumbnailUrl,
  Id,
  NamedEntity,
  Photo,
  PhotoEntityLink,
  ToPersist
} from '../../core/model/model';
import {Photos} from '../collections/photo.collection';
import {EntityType, EntityTypeWithCollection} from '@model/entity-type';
import {EventsQueries} from './event.queries';
import {ZefCollection} from '../collections/zef-collection';
import {mapDictionary} from '@core/utils';
import {Spots} from '../collections/spot.collection';
import {Riders} from '../collections/rider.collection';
import {SpotLaunchs} from '../collections/spot-launch.collection';
import Selector = Mongo.Selector;
import Modifier = Mongo.Modifier;
import {GearModels} from "@api/collections/gear-model.collection";
import { getSelectorOnEntity } from '@api/queries/utils.queries';

export class PhotosQueries {
  static getPhotos<T>(entityLink: PhotoEntityLink<T>): Mongo.Cursor<Photo<unknown>> {
    return Photos.nativeFind(getSelectorOnEntity<Photo<T>>(entityLink));
  }

  static getFirstPhoto(entityLinks: PhotoEntityLink<unknown>[]): Photo<unknown> | undefined {
    return Photos.findOne({
      $or: entityLinks.map(entityLink => this.getSelector(entityLink))
    });
  }

  static async getRandomSessionPhoto(riderId: Id): Promise<Photo<unknown>[]> {
    const photo_photographerId: keyof Photo<unknown> = 'photographerId';
    const photo_entity: keyof Photo<unknown> = 'entity';
    const entity_type: keyof EntityLink = 'type';

    const aggregate = await Photos.getAggregateAsync<Photo<unknown>>();

    const aggregateQuery = [
      {
        // Look for photos from this rider, for a session
        $match: {
          [photo_photographerId]: riderId,
          [`${photo_entity}.${entity_type}`]: EntityTypeWithCollection.sessions
        }
      },
      // Take a random result
      {$sample: {size: 1}}
    ];

    return aggregate(aggregateQuery).toArray();
  }

  private static getSelector(entityLink: PhotoEntityLink<unknown>) {
    const photo_entity: keyof Photo<unknown> = 'entity';

    return mapDictionary(entityLink as unknown as { [key: string]: unknown }, (key, value) => ({
        key: `${photo_entity}.${key}`,
        value
      }),
      // Make sure that entityOwnerId is not part of the search!
      key => (key as keyof EntityLinkWithOwner) != 'entityOwnerId');
  }

  static aggregatePhotos(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore FIXME add entityType filtering, not just based on id
    entityType: EntityTypeWithCollection,
    localField = '_id',
    projectAs = 'photos') {

    const photos_entity: keyof Photo<unknown> = 'entity';
    const photos_entityId: keyof PhotoEntityLink<unknown> = 'id';

    // FIXME also add entityType filtering
    return {
      $lookup: {
        from: EntityType.photos,
        localField: localField,
        foreignField: `${photos_entity}.${photos_entityId}`,
        as: projectAs
      }
    };
  }

  static insertPhoto(photoToInsert: ToPersist<Photo<unknown>>, entities: EntityLink[]): Id {
    const photo = ZefCollection.setCreatedDate(photoToInsert);
    const insertedId = Photos.insertSync(photo);

    const createdAt = photo.createdAt;

    EventsQueries.insertEvent({
      eventType: EventType.created,
      createdAt,
      entities: [
        {
          type: EntityType.photos,
          id: insertedId,
          entityOwnerId: photo.photographerId
        },
        ...entities
      ],
      actorId: photo.photographerId
    });

    this.updateAvatar(photo.entity);

    return insertedId;
  }

  /**
   * Sets the first photo of an entity as avatar, if needed
   * @param entityLink
   */
  static updateAvatar(entityLink: PhotoEntityLink<unknown>): number {
    switch (entityLink.type) {
      case EntityType.riders:
        return this.setDefaultAvatar(Riders, entityLink);
      case EntityType.spots:
        return this.setDefaultAvatar(Spots, entityLink);
      case EntityType.spotLaunches:
        return this.setDefaultAvatar(SpotLaunchs, entityLink);
      case EntityType.gearModels:
        return this.setDefaultAvatar(GearModels, entityLink);

      // Other types of entities don't have avatar, or use a specific avatar (ex: Brands)
      default:
        return 0;
    }
  }

  protected static setDefaultAvatar<T extends NamedEntity>(collection: ZefCollection<T>, entityLink: PhotoEntityLink<unknown>): number {
    const photo = PhotosQueries.getFirstPhoto([entityLink]);
    if (photo && entityLink.id) {
      const entity_avatar: keyof T = 'avatar';
      const entity_id: keyof T = '_id';
      return collection.updateSync(
        <Selector<T>>{
          [entity_id]: entityLink.id,
          [entity_avatar]: {
            $exists: false
          }
        },
        <Modifier<T>>{
          $set: {[entity_avatar]: getThumbnailUrl(photo.url)}
        });
    }
    return 0;
  }
}
