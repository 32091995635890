import { Component, Input } from '@angular/core';
import { Link, SocialLink } from '@model/schema-model';
import { SingleLinkForm, SingleSocialLinkForm } from '@components/input/edit-links/edit-links.form';
import { LinkType, SocialLinkType } from '@model/link';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ListInputComponent } from '@components/edit/list-input-component.directive';
import { EntityType } from '@model/entity-type';

@UntilDestroy()
@Component({
  selector: 'zef-edit-links',
  templateUrl: './edit-links.component.html',
  styleUrls: ['./edit-links.component.scss']
})
export class EditLinksComponent<T extends Link|SocialLink> extends ListInputComponent<T, (T extends Link ? SingleLinkForm : SingleSocialLinkForm)> {
  enumName: string = 'LinkType';
  _linkType: typeof LinkType| typeof SocialLinkType = LinkType;
  @Input() set linkType(value: typeof LinkType | typeof SocialLinkType) {
    this._linkType = value;
    this.enumName = value === LinkType ? 'LinkType' : 'SocialLinkType';
  }

  EntityType = EntityType;

  protected getNewForm(element: T): (T extends Link ? SingleLinkForm : SingleSocialLinkForm) {
    let form = this._linkType == LinkType
      ? new SingleLinkForm(this.fb.group({}), element as Link ?? {})
      : new SingleSocialLinkForm(this.fb.group({}), element as SocialLink ?? {});
    return form as (T extends Link ? SingleLinkForm : SingleSocialLinkForm);
  }

  readonly LinkType = LinkType;
}
