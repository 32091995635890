import {Component, Input, OnInit} from '@angular/core';
import {DateStringFormField, IForm} from '@utils/form';
import {FieldLabelType} from '@pipes/fields/field-label.pipe';
import {UntilDestroy} from '@ngneat/until-destroy';
import {DateUtils} from '@core/date-utils';
import {FormatType} from '@pipes/format-time.pipe';
import {TranslateConfigService} from '@services/translate-config.service';

@UntilDestroy()
@Component({
  selector: 'zef-to-select',
  templateUrl: './to-select.component.html',
  styleUrls: ['./to-select.component.scss']
})
export class ToSelectComponent implements OnInit {
  @Input() form: IForm;
  @Input() toField: DateStringFormField;
  @Input() min: Date;

  FormatType = FormatType;

  FieldLabelType = FieldLabelType;

  constructor(public translateConfig: TranslateConfigService) {
  }

  get minString() {
    return DateUtils.toIonicDateString(this.min);
  }

  ngOnInit() {
  }
}
