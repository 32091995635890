export enum ErrorType {
  ShouldBeLogged = "ShouldBeLogged",
  NotEntityOwner = "NotEntityOwner",
  ForbiddenFromClient = "ForbiddenFromClient",
  EmailNotVerified = "EmailNotVerified",
  VariantNotFound = "VariantNotFound",
  UnsupportedGearModelType = "UnsupportedGearModelType",
  CannotRemoveGearItem = "CannotRemoveGearItem",
  DuplicateCode = 11000
}

export class ZefError extends Meteor.Error {
  constructor(type: ErrorType, reason?: string, details?: string) {
    super(type, reason, details);
    console.error(type, reason ?? '', details ?? '');
  }
}

export abstract class UnAuthorizedError extends ZefError {
  constructor(type: ErrorType, reason?: string, details?: string) {
    super(type, `[Unauthorized] ${reason}`, details);
  }
}

export class UnloggedError extends UnAuthorizedError {
  constructor(details?: string) {
    super(ErrorType.ShouldBeLogged, 'User must be logged in', details);
  }
}

export class EntityOwnerError extends UnAuthorizedError {
  constructor(details?: string) {
    super(ErrorType.NotEntityOwner, 'Entity owned by another user', details);
  }
}

export class ForbiddenFromClientError extends UnAuthorizedError {
  constructor(details?: string) {
    super(ErrorType.ForbiddenFromClient, 'Method not allowed from client', details);
  }
}

export class EmailNotVerifiedError extends UnAuthorizedError {
  constructor(details?: string) {
    super(ErrorType.EmailNotVerified, 'Verify your email address first', details);
  }
}
