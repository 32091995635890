/* eslint-disable @typescript-eslint/ban-ts-comment */
// Create globals so leaflet can load

let Leaflet: any;

if (Meteor.isServer) {

  // @ts-ignore
  global.window = {
    screen: {
      // @ts-ignore
      devicePixelRatio: 1
    }
  };

  // @ts-ignore
  global.document = {
    documentElement: {
      // @ts-ignore
      style: {}
    },
    // @ts-ignore
    getElementsByTagName: () => [],
    // @ts-ignore
    createElement: () => ({})
  };

  // @ts-ignore
  global.navigator = {
    userAgent: 'nodejs',
    platform: 'nodejs'
  };

  // @ts-ignore
  global.L = require('leaflet');

  // @ts-ignore
  global.DOMParser = require('xmldom').DOMParser;

  // @ts-ignore
  Leaflet = global.L;
} else {
  Leaflet = require('leaflet');
}

export const L = Leaflet;

// @ts-ignore
export const DOMParser = global.DOMParser;
