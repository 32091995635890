import {GearModel} from "@model/gear/gear-model";

export enum KiteBoardType {
  directional = "directional",
  bidirectional = "bidirectional"
}

export interface KiteBoardModel<VariantType> extends GearModel<VariantType> {
  kiteBoardType: KiteBoardType;
}
