import {Component, Input, OnInit} from '@angular/core';
import {FieldLabelType} from '@pipes/fields/field-label.pipe';
import {UntilDestroy} from '@ngneat/until-destroy';
import {IForm, NumberFormField} from '@utils/form';

@UntilDestroy()
@Component({
  selector: 'zef-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss']
})
export class RateComponent implements OnInit {
  @Input() form: IForm;
  @Input() score5Field: NumberFormField;

  FieldLabelType = FieldLabelType;

  constructor() {
  }

  ngOnInit() {
  }
}
