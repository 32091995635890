import {Component, Input, OnInit} from '@angular/core';
import {tap} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ICachedRidersService} from '@services/i-cached-riders.service';
import {IAuthService} from '@services/i-auth.service';
import {RiderItem} from '@api/queries';
import {FormField} from '@utils/form';
import {Id} from '@model/entity';
import {ModalController} from '@ionic/angular';
import {FieldLabelType} from '@pipes/fields/field-label.pipe';

@UntilDestroy()
@Component({
  selector: 'zef-select-riders',
  templateUrl: './select-riders.component.html',
  styleUrls: ['./select-riders.component.scss']
})
export class SelectRidersComponent implements OnInit {
  private _formField: FormField<Id[]>;
  get formField(): FormField<Id[]> {
    return this._formField;
  }

  @Input() set formField(formField: FormField<Id[]>) {
    this._formField = formField;
    this.setSelectedRiders(formField.value);
  }

  private setSelectedRiders(value: Id[]) {
    this.allRiders
      ?.forEach(rider => rider.selected = value?.includes(rider._id));
  }

  allRiders: (RiderItem & { selected?: boolean })[];

  FieldLabelType = FieldLabelType;

  constructor(
    private riders: ICachedRidersService,
    private authService: IAuthService,
    private modalController: ModalController
  ) {
  }

  ngOnInit() {
    this.riders.getAllEntities$()
      .pipe(
        tap(allRiders => {
          // Ignore myself
          this.allRiders = allRiders.filter(r => r._id !== this.authService.userId);
          this.setSelectedRiders(this.formField.value);
        }),
        untilDestroyed(this)
      ).subscribe();
  }

  toggle(rider: (RiderItem & { selected?: boolean })): void {
    rider.selected = !(rider.selected ?? false);
  }

  async close() {
    await this.modalController.dismiss();
  }

  async submit() {
    if (this.allRiders) {
      this.formField.value = this.allRiders
        .filter(rider => rider.selected)
        .map(rider => rider._id);
    }
    await this.close();
  }
}
