import {
  BookingToInsert, BrandToInsert, BrandToUpdate,
  CommentToInsert,
  KiteBoardToInsert, KiteBoardToUpdate,
  KiteToInsert, KiteToUpdate,
  ObservationToInsert,
  PaddleBoardToInsert, PaddleBoardToUpdate,
  PhotoToInsert,
  SessionToInsert,
  SessionToStart,
  SessionToUpdate,
  SpotLaunchToInsert,
  SpotLaunchToUpdate,
  SpotToInsert,
  SpotToUpdate,
  SurfBoardToInsert, SurfBoardToUpdate,
  UserToRegister,
  WindsurfBoardToInsert, WindsurfBoardToUpdate,
  WindsurfSailToInsert, WindsurfSailToUpdate,
  WingBoardToInsert, WingBoardToUpdate,
  WingToInsert, WingToUpdate
} from '@model/schema-model';
import {Id} from '@model/entity';
import {UpsertResult} from './collections/zef-collection';
import {affectNamesAutomatically, Method, ObjectWithMethods} from './method';
import {EntityLink, EntityLinkWithOwner} from '@model/entity-link';
import {Language} from '@model/multi-language-text';
import {Variant} from '@model/gear/gear-item';
import {EventLocator} from './queries/event.queries';

export interface SetReactionInput {
  entityLink: EntityLink | EntityLinkWithOwner,
  parentEntities?: (EntityLink | EntityLinkWithOwner)[];
  emoji: string,
  set: boolean
}

export class DistantCommands {
  constructor() {
    affectNamesAutomatically((this as any) as ObjectWithMethods);
  }

  createFullSession = new Method<SessionToInsert, Id>();
  startSession = new Method<SessionToStart, Id>();
  updateSession = new Method<SessionToUpdate, Id>();
  createObservation = new Method<ObservationToInsert, { _id: Id, spotDayShortName: string }>();
  createBooking = new Method<BookingToInsert, { _id: Id, spotDayShortName: string }>();

  createWindsurfBoard = new Method<WindsurfBoardToInsert, Id>();
  updateWindsurfBoard = new Method<WindsurfBoardToUpdate, number>();
  createWindsurfSail = new Method<WindsurfSailToInsert, Id>();
  updateWindsurfSail = new Method<WindsurfSailToUpdate, number>();
  createWingBoard = new Method<WingBoardToInsert, Id>();
  updateWingBoard = new Method<WingBoardToUpdate, number>();
  createWing = new Method<WingToInsert, Id>();
  updateWing = new Method<WingToUpdate, number>();
  createSurfBoard = new Method<SurfBoardToInsert, Id>();
  updateSurfBoard = new Method<SurfBoardToUpdate, number>();
  createKiteBoard = new Method<KiteBoardToInsert, Id>();
  updateKiteBoard = new Method<KiteBoardToUpdate, number>();
  createPaddleBoard = new Method<PaddleBoardToInsert, Id>();
  updatePaddleBoard = new Method<PaddleBoardToUpdate, number>();
  createKite = new Method<KiteToInsert, Id>();
  updateKite = new Method<KiteToUpdate, number>();

  insertComment = new Method<CommentToInsert, Id>();
  addPhotoToEntity = new Method<PhotoToInsert, Id>();

  // FIXME move to Commands (do on the client)
  subscribeToEntity = new Method<{ entity: EntityLink | EntityLinkWithOwner, parentEntities: (EntityLink | EntityLinkWithOwner)[] }, UpsertResult>();
  unsubscribeFromSubscription = new Method<Id, number>();

  registerUser = new Method<UserToRegister, number>();

  createSpot = new Method<SpotToInsert, Id>();
  createSpotLaunch = new Method<SpotLaunchToInsert, Id>();
  updateSpot = new Method<SpotToUpdate, number>();
  updateSpotLaunch = new Method<SpotLaunchToUpdate, number>();
  markEventsAsRead = new Method<EventLocator, number>();
  insertTrackLog = new Method<{ fileName: string, data: string }, Id>();
  downloadTrackLog = new Method<{ url: string }, string>();
  setUserLang = new Method<Language, boolean>();
  setEmailNotifications = new Method<boolean, boolean>();
  markAllAs = new Method<{ eventIds: Id[], read: boolean }, number>();
  createBrand = new Method<BrandToInsert, Id>();
  updateBrand = new Method<BrandToUpdate, number>();

  addGearToQuiver = new Method<{ variant: Variant, gearId: Id, acquiredDate: Date }, number>();
  sellGearItem = new Method<{ gearItemId: Id, soldDate: Date }, number>();
  removeGearItemFromQuiver = new Method<Id, number>();
  setReaction = new Method<SetReactionInput, number>();

}

