import {Queries, SpotItem} from '@api/queries';
import {Id} from '@model/entity';
import {Observable} from 'rxjs';
import {NamedEntity} from '@model/entity-with-description';
import {Injectable} from '@angular/core';
import {MeteorCache} from '@services/meteor/meteor.cache';
import {ICachedBrandsService} from '@services/i-cached-brands.service';

@Injectable({
  providedIn: 'root'
})
export class MeteorCachedBrandsService extends ICachedBrandsService {
  private meteorCache: MeteorCache<NamedEntity>;

  constructor(private queries: Queries) {
    super();
    this.meteorCache = new MeteorCache<NamedEntity>(this.queries.getBrands);
  }

  getEntitiesByIds$(ids: Id[]): Observable<NamedEntity[]> {
    return this.meteorCache.getEntitiesByIds$(ids);
  }

  getEntityById$(_id: Id): Observable<NamedEntity> {
    return this.meteorCache.getEntityById$(_id);
  }

  getAllEntities$(): Observable<NamedEntity[]> {
    return this.meteorCache.getAllEntities$();
  }
}

