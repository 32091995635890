import { ZefCollection } from './zef-collection';
import { EntityType } from '@model/entity-type';
import { Brand } from '@model/gear/brand';

export const Brands = new ZefCollection<Brand>(EntityType.brands)
  .setUniqueIndexes([
    ['shortName'],
    ['name']
  ]).setTextIndex([
    'name',
    'aliases',
    'abstract',
    'description'
  ]);
