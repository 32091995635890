import { Entity, Id } from './entity';
import { MultiLanguageText } from './multi-language-text';
import { EntityType } from './entity-type';
import { EntityLinkWithOwner } from './entity-link';
import { WithEntities } from '@model/with-entities';

export interface Comment extends Entity, WithEntities {
  commenterId: Id;
  content: MultiLanguageText;
}

export const getCommentEntity = (comment: Pick<Comment, '_id' | 'commenterId'>): EntityLinkWithOwner => ({
  type: EntityType.comments,
  id: comment._id,
  entityOwnerId: comment.commenterId
});
