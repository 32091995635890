import {DateStringFormField, Form, FormField, NumberFormField} from '@utils/form';
import {FormGroup} from '@angular/forms';
import {Id} from '@model/entity';
import {Activity} from '@model/activity';
import {ObservationToInsert, QuiverItem} from '@model/schema-model';
import {schemas} from '@model/schemas';
import {EntityType} from '@model/entity-type';

type ObservationToInsertDefaults = Partial<ObservationToInsert>;

export class ObservationForm extends Form {
  constructor(formGroup: FormGroup, private defaults: ObservationToInsertDefaults) {
    super(formGroup, schemas.ObservationToInsert, EntityType.observations);
  }

  fields = {
    activities: new FormField<Activity[]>(this.schema, this.vf, this.group, this.defaults.activities),
    score5: new NumberFormField(this.schema, this.vf, this.group, this.defaults.score5),
    windStability5: new NumberFormField(this.schema, this.vf, this.group, this.defaults.windStability5),
    spotId: new FormField<Id>(this.schema, this.vf, this.group, this.defaults.spotId),
    spotLaunchId: new FormField<Id>(this.schema, this.vf, this.group, this.defaults.spotLaunchId),
    fromInUserTZ: new DateStringFormField(
      this.schema,
      this.vf,
      this.group,
      this.defaults.fromInUserTZ
    ),
    abstract: new FormField<string>(this.schema, this.vf, this.group, this.defaults.abstract),
    quiver: new FormField<QuiverItem[]>(this.schema, this.vf, this.group, this.defaults.quiver),
    measuredWindKt: new NumberFormField(this.schema, this.vf, this.group, this.defaults.measuredWindKt)
  };
}
