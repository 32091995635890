import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PhotosComponent} from '@components/photos/photos/photos.component';
import {IonicModule} from '@ionic/angular';
import {PipesModule} from '@pipes/pipes.module';
import {LightgalleryModule} from 'lightgallery/angular/12';
import {MarkAsReadModule} from "../../../directives/mark-as-read.module";
import {InViewportModule} from "ng-in-viewport";

@NgModule({
  declarations: [PhotosComponent],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    LightgalleryModule,
    MarkAsReadModule, InViewportModule, // The two come together
  ],
  exports: [PhotosComponent]
})
export class PhotosModule {
}
