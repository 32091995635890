import {
  EntityLink,
  EntityLinkType,
  EntityLinkWithOwner,
  EventType,
  Id,
  Subscription,
  SubscriptionOrigin
} from '../../core/model/model';
import { Subscriptions } from '../collections/subscription.collection';
import { EntityType, EntityTypeWithCollection } from '@model/entity-type';
import { ObservableCursor } from 'meteor-rxjs';
import { UpsertResult } from '../collections/zef-collection';
import { EventsQueries } from './event.queries';
import { getSelectorOnEntities } from '@api/queries/utils.queries';
import Selector = Mongo.Selector;
import Cursor = Mongo.Cursor;

export class SubscriptionsQueries {
  static subscribeToEntity(riderId: Id, entity: EntityLink | EntityLinkWithOwner, parentEntities: (EntityLink | EntityLinkWithOwner)[], origin: SubscriptionOrigin): UpsertResult {
    const sub_subscriberId: keyof Subscription = 'subscriberId';
    const sub_entities: keyof Subscription = 'entities';
    const sub_origin: keyof Subscription = 'origin';

    const upsertResult = Subscriptions.upsertSync(
      {
        [sub_subscriberId]: riderId,
        // Subscribe to changes _on this entity_ at any level
        [`${sub_entities}.0`]: entity
      },
      {
        $set: {
          [sub_origin]: origin,
          [sub_entities]: [entity, ...parentEntities]
        }
      }
    );

    if (upsertResult.insertedId) {
      EventsQueries.insertEvent({
        actorId: riderId,
        createdAt: new Date(),
        eventType: EventType.created,
        entities: [
          {
            type: EntityType.subscriptions,
            id: upsertResult.insertedId,
            entityOwnerId: riderId
          },
          entity,
          ...parentEntities
        ]
      });
    }

    return upsertResult;
  }

  static subscribeToEntityOwner(riderId: Id, entityOwnerId: Id, origin: SubscriptionOrigin): UpsertResult {
    const sub_subscriberId: keyof Subscription = 'subscriberId';
    const sub_entityOwnerId: keyof Subscription = 'entityOwnerId';

    const upsertResult = Subscriptions.upsertSync(
      {
        [sub_subscriberId]: riderId,
        // Subscribe on changes _on any entity owned by this user_, at any level
        [sub_entityOwnerId]: entityOwnerId
      },
      {
        $set: { origin }
      }
    );

    if (upsertResult.insertedId && origin !== SubscriptionOrigin.automatic_rider_creation) {
      // Do not trigger an event if this is just the user following his own events
      EventsQueries.insertEvent({
        actorId: riderId,
        createdAt: new Date(),
        eventType: EventType.created,
        entities: [
          {
            type: EntityType.subscriptions,
            id: upsertResult.insertedId,
            entityOwnerId: riderId
          },
          {
            type: EntityType.riders,
            id: entityOwnerId
          }
        ]
      });
    }

    return upsertResult;
  }

  static subscribeToActor(riderId: Id, entityLink: EntityLink, origin: SubscriptionOrigin): UpsertResult {
    const sub_subscriberId: keyof Subscription = 'subscriberId';
    const sub_actorId: keyof Subscription = 'actorId';
    const actorId = entityLink.id;
    const upsertResult = Subscriptions.upsertSync(
      {
        [sub_subscriberId]: riderId,
        // Subscribe on changes _done_ by this user
        [sub_actorId]: actorId
      },
      {
        $set: { origin }
      }
    );

    if (upsertResult.insertedId) {
      EventsQueries.insertEvent({
        actorId: riderId,
        createdAt: new Date(),
        eventType: EventType.created,
        entities: [
          {
            type: EntityType.subscriptions,
            id: upsertResult.insertedId,
            entityOwnerId: riderId
          },
          {
            type: EntityType.riders,
            id: actorId
          }
        ]
      });
    }

    return upsertResult;
  }

  static subscribeToType(riderId: Id, type: EntityTypeWithCollection, origin: SubscriptionOrigin): UpsertResult {
    const entityLinkType: EntityLinkType = { type };
    // Subscribe on any creation, deletion or creation on this type
    const sub_subscriberId: keyof Subscription = 'subscriberId';
    const sub_entities: keyof Subscription = 'entities';
    const sub_origin: keyof Subscription = 'origin';

    const upsertResult = Subscriptions.upsertSync(
      {
        [sub_subscriberId]: riderId,
        [`${sub_entities}.0`]: entityLinkType
      },
      {
        $set: {
          [sub_origin]: origin,
          [sub_entities]: [entityLinkType]
        }
      }
    );

    if (upsertResult.insertedId) {
      EventsQueries.insertEvent({
        actorId: riderId,
        createdAt: new Date(),
        eventType: EventType.created,
        entities: [
          {
            type: EntityType.subscriptions,
            id: upsertResult.insertedId,
            entityOwnerId: riderId
          },
          entityLinkType
        ]
      });
    }

    return upsertResult;
  }

  static getSubscription(riderId: Id, entityLink: EntityLink | EntityLinkWithOwner): Subscription {
    const selector = this.getSelectorEntitySubscription(entityLink, riderId);

    return Subscriptions.findOne(selector);
  }

  static getSelectorEntitySubscription(entityLink: EntityLink | EntityLinkWithOwner, riderId: string): Selector<Subscription> {
    const s_subscriberId: keyof Subscription = 'subscriberId';

    return {
      ...this.getSelector(entityLink),
      [s_subscriberId]: riderId
    };
  }

  private static getSelector(entityLink: EntityLink | EntityLinkWithOwner) {
    const actorId: keyof Subscription = 'actorId';

    const selector: Mongo.Selector<Subscription> =
      (entityLink.type === EntityType.riders)
        ? { [actorId]: entityLink.id }
        : getSelectorOnEntities<Subscription>(entityLink, 0);

    return selector;
  }

  static getAllSubscriptions(riderId: Id): ObservableCursor<Subscription> {
    const subscriberId: keyof Subscription = 'subscriberId';
    return Subscriptions.find({ [subscriberId]: riderId });
  }

  static getFollowers(entity: EntityLink): Cursor<{ subscriberId: Id }> {
    const s_subscriberId: keyof Subscription = 'subscriberId';

    const selector = this.getSelector(entity);

    return Subscriptions
      .nativeFind(
        selector,
        {
          fields: { [s_subscriberId]: 1 }
        }
      );
  }

  static unsubscribe(subscription: Subscription): number {
    const entities: (EntityLinkType | EntityLink | EntityLinkWithOwner)[] = [
      {
        type: EntityType.subscriptions,
        id: subscription._id,
        entityOwnerId: subscription.entityOwnerId
      }
    ];

    const addRiderEntity = (riderId: string) => {
      entities.push(
        {
          type: EntityType.riders,
          id: riderId,
          entityOwnerId: riderId // Little trick so that the owner will be notified we unfollow them
        });
    };

    if (subscription.entities) {
      entities.push(...subscription.entities);
    } else if (subscription.entityOwnerId) {
      addRiderEntity(subscription.entityOwnerId);
    } else if (subscription.actorId) {
      addRiderEntity(subscription.actorId);
    }

    EventsQueries.insertEvent({
      actorId: subscription.subscriberId,
      createdAt: new Date(),
      eventType: EventType.removed,
      entities
    });
    return Subscriptions.removeSync({ _id: subscription._id });
  }
}
