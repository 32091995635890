import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from '@services/translate-config.service';

export enum FormatType {
  AUTO,
  DATE_AND_TIME,
  TIME_ONLY,
  DATE_ONLY
}

@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private translateConfig: TranslateConfigService) {
  }

  transform(date: Date | moment.Moment, formatType: FormatType = FormatType.AUTO): string {
    if (date === undefined) {
      return this.translate.instant("SHARED.UNKNOWN")
    }

    const rightDate = moment(date);
    rightDate.locale(this.translateConfig.currentLanguage);

    switch (formatType) {
      case FormatType.TIME_ONLY:
        return rightDate.format('LT');

      case FormatType.DATE_ONLY:
        // Split and replace any hour (00:00 or 12:00)
        let dateParts = rightDate.calendar().replace(/[\d]{2}:00/, '|').split(' '),
          index = dateParts.indexOf('|');

        // ['Yesterday', 'at', '00:00', 'PM'] -> ['Yesterday']
        dateParts.length = (index !== -1 ? (index - 1) : dateParts.length);
        return dateParts.join(" ").toLowerCase(); // TODO except for german

      case FormatType.DATE_AND_TIME:
        return rightDate.calendar(null, {
          sameElse: 'llll'
        });

      case FormatType.AUTO:
      default:
        return rightDate.calendar();

    }
  }
}
