// FIXME for some reason iconoir-depth is not in the list
import {IconName} from "@ng-icons/core/lib/icon-name";
import {BootstrapMegaphoneFill, BootstrapPersonCheckFill, BootstrapPersonPlus} from "@ng-icons/bootstrap-icons";
import {IconoirDepth} from "@ng-icons/iconoir";
import {
  IonAdd,
  IonBagCheck,
  IonBookmark,
  IonCalendarNumberOutline,
  IonCalendarOutline,
  IonCamera,
  IonChatboxEllipses,
  IonCloseOutline, IonCloudDownloadOutline,
  IonCloudyNight, IonCopyOutline,
  IonCreate,
  IonEllipsisHorizontal,
  IonEllipsisHorizontalOutline,
  IonEye,
  IonHelpCircle,
  IonHome,
  IonImage,
  IonInformationCircle,
  IonInformationCircleOutline,
  IonLanguageOutline,
  IonLogOut,
  IonMap,
  IonNavigateCircleOutline,
  IonNotificationsCircle,
  IonPeople,
  IonPersonCircle,
  IonPricetag,
  IonRainyOutline,
  IonSchool,
  IonSearchOutline,
  IonSettings,
  IonShareSocialOutline,
  IonSpeedometer,
  IonStar,
  IonStarOutline,
  IonStatsChart,
  IonVideocamOutline
} from "@ng-icons/ionicons";
import {
  JamAndroid,
  JamApple,
  JamArrowSquareDownF,
  JamLink,
  JamTriangleDanger,
  JamTriangleDangerF
} from "@ng-icons/jam-icons";
import {
  MatAddLocation,
  MatAutorenew,
  MatDirections,
  MatDoneAll,
  MatEditCalendar,
  MatFavorite,
  MatFavoriteBorder,
  MatGroups,
  MatKey,
  MatKitesurfing,
  MatLens,
  MatLocationOn,
  MatLock,
  MatNotifications,
  MatNotificationsOff,
  MatRadioButtonUnchecked,
  MatSailing,
  MatShower,
  MatSnowboarding,
  MatStorefront,
  MatTaskAlt,
  MatWarning,
  MatWc
} from "@ng-icons/material-icons/baseline";
import {
  MatKitesurfingOutline,
  MatLockOpenOutline,
  MatShowerOutline,
  MatStorefrontOutline,
  MatWcOutline
} from "@ng-icons/material-icons/outline";
import {TablerNote, TablerReceipt2, TablerReceiptOff, TablerStairsDown} from "@ng-icons/tabler-icons";

export const icons: { [key in IconName | 'iconoir-depth']?: string } = {
  'bootstrap-person-check-fill': BootstrapPersonCheckFill,
  'bootstrap-person-plus': BootstrapPersonPlus,
  'bootstrap-megaphone-fill': BootstrapMegaphoneFill,

  'iconoir-depth': IconoirDepth,

  'ion-add': IonAdd,
  'ion-ellipsis-horizontal': IonEllipsisHorizontal,
  'ion-ellipsis-horizontal-outline': IonEllipsisHorizontalOutline,
  'ion-log-out': IonLogOut,
  'ion-school': IonSchool,
  'ion-person-circle': IonPersonCircle,
  'ion-cloudy-night': IonCloudyNight,
  'ion-close-outline': IonCloseOutline,
  'ion-help-circle': IonHelpCircle,
  'ion-notifications-circle': IonNotificationsCircle,
  'ion-navigate-circle-outline': IonNavigateCircleOutline,
  'ion-people': IonPeople,
  'ion-settings': IonSettings,
  'ion-bag-check': IonBagCheck,
  'ion-search-circle-outline': IonSearchOutline,
  'ion-map': IonMap,
  'ion-camera': IonCamera,
  'ion-create': IonCreate,
  'ion-home': IonHome,
  'ion-calendar-outline': IonCalendarOutline,
  'ion-calendar-number-outline': IonCalendarNumberOutline,
  'ion-chatbox-ellipses': IonChatboxEllipses,
  'ion-bookmark': IonBookmark,
  'ion-eye': IonEye,
  'ion-language-outline': IonLanguageOutline,
  'ion-pricetag': IonPricetag,
  'ion-speedometer': IonSpeedometer,
  'ion-star': IonStar,
  'ion-star-outline': IonStarOutline,
  'ion-image': IonImage,
  'ion-rainy-outline': IonRainyOutline,
  'ion-stats-chart': IonStatsChart,
  'ion-information-circle': IonInformationCircle,
  'ion-information-circle-outline': IonInformationCircleOutline,
  'ion-videocam-outline': IonVideocamOutline,
  'ion-cloud-download-outline': IonCloudDownloadOutline,
  'ion-share-social-outline': IonShareSocialOutline,
  'ion-copy-outline': IonCopyOutline,

  'jam-arrow-square-down-f': JamArrowSquareDownF,
  'jam-link': JamLink,
  'jam-triangle-danger': JamTriangleDanger,
  'jam-triangle-danger-f': JamTriangleDangerF,
  'jam-apple': JamApple,
  'jam-android': JamAndroid,

  'mat-warning': MatWarning,
  'mat-lock-open-outline': MatLockOpenOutline,
  'mat-edit-calendar': MatEditCalendar,
  'mat-favorite': MatFavorite,
  'mat-favorite-border': MatFavoriteBorder,
  'mat-lock': MatLock,
  'mat-notifications': MatNotifications,
  'mat-notifications-off': MatNotificationsOff,
  'mat-autorenew': MatAutorenew,
  'mat-sailing': MatSailing,
  'mat-shower': MatShower,
  'mat-shower-outline': MatShowerOutline,
  'mat-wc': MatWc,
  'mat-wc-outline': MatWcOutline,
  'mat-kitesurfing': MatKitesurfing,
  'mat-kitesurfing-outline': MatKitesurfingOutline,
  'mat-directions': MatDirections,
  'mat-snowboarding': MatSnowboarding,
  'mat-location-on': MatLocationOn,
  'mat-key': MatKey,
  'mat-add-location': MatAddLocation,
  'mat-storefront': MatStorefront,
  'mat-storefront-outline': MatStorefrontOutline,
  'mat-groups': MatGroups,
  'mat-lens': MatLens,
  'mat-task-alt': MatTaskAlt,
  'mat-done-all': MatDoneAll,
  'mat-radio-button-unchecked': MatRadioButtonUnchecked,

  'tabler-receipt2': TablerReceipt2,
  'tabler-receipt-off': TablerReceiptOff,
  'tabler-note': TablerNote,
  'tabler-stairs-down': TablerStairsDown
};
