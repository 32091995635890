import {Component, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {FrontendRoutingService} from '@services/frontend-routing.service';
import {NamedEntity} from '@model/entity-with-description';

@Component({
  selector: 'zef-rider-item',
  templateUrl: './rider-item.component.html',
  styleUrls: ['./rider-item.component.scss']
})
export class RiderItemComponent implements OnInit {
  @Input() rider: NamedEntity;
  @Input() clickable = true;
  @Input() showAvatar = true;

  @ViewChild('template', { static: true }) template;

  constructor(
    private viewContainerRef: ViewContainerRef, public routing: FrontendRoutingService) {
  }

  ngOnInit(): void {
    this.viewContainerRef.createEmbeddedView(this.template);
  }
}
