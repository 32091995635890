<ng-template #template>
  <ion-label *ngIf="showLabel" class='input'>
    {{"ENUM." + enumName + ".TITLE" | translate}}{{(isRequired ? ' *' : '')}}
  </ion-label>
  <ion-select
    placeholder='{{ allowUnknown ? capitalize("ENUM.UNDEFINED" | translate : { enumName }) : ("ENUM." + enumName + ".TITLE" | translate) }}'
    [ngModel]='model' (ngModelChange)='modelChange.emit($event)'>
    <ion-select-option [value]='null' *ngIf='allowUnknown' class='unknown'>
      {{ capitalize("ENUM.UNDEFINED" | translate : { enumName }) }}
    </ion-select-option>
    <ion-select-option *ngFor='let key of keys(enumDict)'
                       [value]='enumDict[key]'>
      {{ "ENUM." + enumName + ".VALUES." + key | translate : { count: 1 } }}
    </ion-select-option>
  </ion-select>
</ng-template>
