import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SpotItemComponent} from '@components/item/spot-item/spot-item.component';
import {IonicModule} from '@ionic/angular';
import {EntityModule} from '@components/entity/entity.module';
import {RouterModule} from '@angular/router';
import {SpotDayItemComponent} from '@components/item/spot-day-item/spot-day-item.component';
import {PipesModule} from '@pipes/pipes.module';
import {GearModelItemComponent} from "@components/item/gear-model-item/gear-model-item.component";

@NgModule({
  declarations: [
    SpotItemComponent,
    SpotDayItemComponent,
    GearModelItemComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    EntityModule,
    RouterModule,
    PipesModule
  ],
  exports: [
    SpotItemComponent,
    SpotDayItemComponent,
    GearModelItemComponent
  ]
})
export class ItemModule {
}
