<form [formGroup]='form.group'>
    <ion-item id='open-from'>
        <ion-label class='input'>{{ fromField | fieldLabel : FieldLabelType.TITLE }}</ion-label>
        <ion-text>
            {{ fromField.dateValue | formatTime : FormatType.DATE_AND_TIME }}
        </ion-text>
        <ion-popover trigger='open-from' alignment='end'>
            <ng-template>
                <ion-content>
                    <ion-datetime formControlName='{{fromField.name}}'
                                  [locale]='translateConfig.currentLanguage'
                                  [required]='fromField.isRequired'
                                  min='{{min}}'
                                  max='{{max}}'>
                    </ion-datetime>
                </ion-content>
            </ng-template>
        </ion-popover>
    </ion-item>
    <zef-error-line [field]='fromField'></zef-error-line>
</form>
