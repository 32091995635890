import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoadingListComponent} from '@components/loading-list/loading-list.component';
import {IonicModule} from '@ionic/angular';
import {LoadingListItemModule} from '@components/loading-list-item/loading-list-item.module';


@NgModule({
  declarations: [LoadingListComponent],
  imports: [
    CommonModule,
    IonicModule,
    LoadingListItemModule
  ],
  exports: [LoadingListComponent]
})
export class LoadingListModule {
}
