import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {IAuthService} from '@services/i-auth.service';
import {Router} from '@angular/router';
import {FeedbackService} from '@services/feedback.service';
import {TranslateConfigService} from '@services/translate-config.service';
import {TranslateService} from '@ngx-translate/core';
import {catchError, finalize, switchMap, take, tap} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ModalController} from '@ionic/angular';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';

@UntilDestroy()
@Component({
  selector: 'zef-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  formGroup: FormGroup;
  inProgress = false;

  constructor(
    public authService: IAuthService,
    private router: Router,
    private feedback: FeedbackService,
    private changeDetector: ChangeDetectorRef,
    private translateConfig: TranslateConfigService,
    private translate: TranslateService,
    protected modalController: ModalController,
    fb: FormBuilder) {
    this.formGroup = fb.group({});
    this.formGroup.addControl('email', new FormControl());
    this.formGroup.addControl('password', new FormControl());
  }

  async cancel() {
    await this.modalController.dismiss();
  }

  login() {
    if (!this.formGroup.valid) {
      return;
    }

    this.inProgress = true;

    this.authService.login$(this.formGroup.value).pipe(
      catchError(async (error: Meteor.Error, caught) => {
        await this.feedback.meteorError(error);
        this.inProgress = false;
        throw error;
      }),
      switchMap(async () => {
        this.changeDetector.detectChanges();
        await this.feedback.toast(this.translate.instant('LOGIN.IN.CONFIRMATION'));
        await this.modalController.dismiss();
      }),
      take(1),
      finalize(() => this.inProgress = false),
      untilDestroyed(this),
    ).subscribe();
  }

  logout() {
    this.authService.logout$().pipe(
      catchError(async (error: Meteor.Error) => {
        await this.feedback.meteorError(error);
        this.inProgress = false;
        throw error;
      }),
      tap(async () => {
        this.changeDetector.detectChanges();
        await this.feedback.toast(this.translate.instant('LOGIN.OUT.CONFIRMATION'));
        await this.modalController.dismiss();
        await this.translateConfig.setLoggedOutLanguage();
      }),
      finalize(() => this.inProgress = false),
      untilDestroyed(this),
    ).subscribe();
  }

  ngOnInit(): void {
  }

}
