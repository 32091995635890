import grubbs from 'grubbs';
import {notUndefined} from './utils';

const weightedAverage = (data: (number | undefined)[], weights: number[] | undefined = undefined) => {
  let totalWeight = 0;
  const sum = data.reduce((accumulator: number, value: number | undefined, i: number) => {
    const weight = weights?.[i] ?? 1;
    totalWeight += weight;
    // Ignore undefined values from this average
    return accumulator + (value ?? 0) * weight;
  }, 0);
  return sum / totalWeight;
};

/**
 * Updates in place
 * @param values
 * @param getVal
 * @param setVal
 * @param weights
 */
export const smooth = <T>(values: (T | undefined)[], getVal: (t: T | undefined) => number | undefined, setVal: (t: T, val: number) => void, weights: { average: number, prev: number, curr: number, next: number }): void => {
  const globalAverage = weightedAverage(values.map(getVal).filter(notUndefined));
  values.forEach((iVal, i) => {
    if (values[i] !== undefined) {
      const curr = getVal(iVal);
      // Note that prev and next will be ignored if undefined
      const prev = getVal(values[i - 1]);
      const next = getVal(values[i + 1]);
      setVal(values[i]!, weightedAverage([globalAverage, prev, curr, next], [weights.average, weights.prev, weights.curr, weights.next]));
    }
  });
};

/**
 * Updates in place
 * @param data
 * @param getVal
 */
export const removeOutliers = <T>(data: (T | undefined)[], getVal: (t: T | undefined) => number | undefined): void => {
  // Grubbs test only takes max 100 figures.
  // We assume the distribution of speed is stable on the whole GPX file so looking at
  // slices of 100 items is acceptable
  const sliceSize = 100;

  for (let i = 0; i < data.length / sliceSize; i++) {
    const slice = data.slice(i * sliceSize, i * sliceSize + sliceSize - 1);

    // Remove outliers (grubbs needs more than 2 records)
    if (slice.length > 2) {

      const result = grubbs.test(slice.map(getVal), { useMedian: true });

      // Test result is made of multiple "rounds".
      result.forEach(result => result.outlierIndexes.forEach(j => data[i * sliceSize + j] = undefined));

      //filteredResults.forEach(n => console.log(n))
    }
  }
};
