import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {NgIconsModule} from '@ng-icons/core';
import {LinksComponent} from './links/links.component';

@NgModule({
  declarations: [
    LinksComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    NgIconsModule
  ],
  exports: [
    LinksComponent
  ]
})
export class LinksModule {
}
