import {Component, Input} from '@angular/core';
import {PropertyComponent} from '@components/input/property/property/property.component';

@Component({
  selector: 'zef-enum-property',
  templateUrl: './enum-property.component.html',
  styleUrls: ['./enum-property.component.scss']
})
export class EnumPropertyComponent<Enum extends Record<string, string>> extends PropertyComponent<Enum> {
  @Input() enumType: Enum;
  @Input() enumName: string;

}
