import {Component, Input, OnInit} from '@angular/core';
import {FormField} from '@utils/form';

@Component({
  selector: 'zef-error-line',
  templateUrl: './error-line.component.html',
  styleUrls: ['./error-line.component.scss']
})
export class ErrorLineComponent implements OnInit {
  @Input() field: FormField<unknown>;
  @Input() fieldName: string | undefined;

  constructor() {
  }

  ngOnInit() {
  }

}
