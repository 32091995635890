import { Entity, Id } from './entity';
import { WithEntities } from '@model/with-entities';

export enum SubscriptionOrigin {
  manual = 'manual',
  automatic_rider_creation = 'automatic_rider_creation'
}

export interface Subscription extends Entity, WithEntities {
  subscriberId: Id;
  origin: SubscriptionOrigin;

  // Any of these three possible interests
  // From WithEntities: interested in entities
  entityOwnerId?: Id; // Interested in any entity **owned by this user**
  actorId?: Id; // Interested in any entity updated by this user
}
