import {Injectable} from '@angular/core';
import {Subscription} from '@model/subscription';
import {Queries} from '@api/queries';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {EntityLink, Event, Id, Session} from '../../../../backend/imports/core/model/model';
import {tap} from "rxjs/operators";
import {Observable} from 'rxjs/internal/Observable';
import {Publication} from "@api/publication";
import {Subscription as RxjsSubscription} from "rxjs";
import {Subscriptions} from "@api/collections/subscription.collection";
import {SubscriptionsQueries} from "@api/queries/subscription.queries";
import {ObservableCursor} from "meteor-rxjs";

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class MeteorNotificationService {
  subscriptions$: Observable<Subscription[]>;
  unfinishedSessions$: Observable<Session[]>;
  notifications$: Observable<Event[]>;

  private pubSubs: {[name: string]: RxjsSubscription} = {};

  constructor(private queries: Queries) {
    this.subscribeToPublications();

    this.subscriptions$ = this.queries.getSubscriptions.call$().pipe(
      untilDestroyed(this),
    )

    this.unfinishedSessions$ = this.queries.getUnfinishedSessions.call$().pipe(
      untilDestroyed(this),
    )

    this.notifications$ = this.queries.getNotifications.call$().pipe(
      untilDestroyed(this),
    )
  }

  subscribeToPublications() {
    const subscribe = <T>(pub: Publication<void, T>) => {
      this.pubSubs[pub.name] = pub.subscribe$().pipe(
        tap(() => console.debug(`publication: ${pub.name}`)),
        untilDestroyed(this)
      ).subscribe();
    }

    this.unsubscribeFromPublications();

    // Subscribe to all 3 publications
    subscribe(this.queries.getSubscriptions)
    subscribe(this.queries.getUnfinishedSessions)
    subscribe(this.queries.getNotifications)
  }

  unsubscribeFromPublications() {
    Object.values(this.pubSubs).forEach(sub => sub.unsubscribe());
  }

  // Calling the local Mongo DB collection
  getEntitySubscriptionFromPub(entityLink: EntityLink): ObservableCursor<Subscription> {
    const subscriberId = Meteor.userId() as Id;
    return Subscriptions.find(SubscriptionsQueries.getSelectorEntitySubscription(entityLink, subscriberId));
  };
}
