import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FollowersComponent} from '@components/follow/followers/followers.component';
import {TranslateModule} from '@ngx-translate/core';
import {RiderModule} from '@components/rider/rider.module';
import {EntityModule} from '@components/entity/entity.module';
import {NgIconsModule} from '@ng-icons/core';
import {FollowersCountComponent} from '@components/follow/followers-count/followers-count.component';
import {FollowComponent} from '@components/follow/follow/follow.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FollowingComponent} from '@components/follow/following/following.component';
import {LoadingListModule} from '@components/loading-list/loading-list.module';
import {SubscriptionModule} from '@components/subscription/subscription.module';
import {InviteToLoginModule} from '@components/invite-to-login/invite-to-login.module';

@NgModule({
  declarations: [
    FollowersComponent,
    FollowersCountComponent,
    FollowComponent,
    FollowingComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    RiderModule,
    EntityModule,
    NgIconsModule,
    MatTooltipModule,
    LoadingListModule,
    SubscriptionModule,
    InviteToLoginModule
  ],
  exports: [
    FollowersComponent,
    FollowersCountComponent,
    FollowComponent,
    FollowingComponent
  ]
})
export class FollowModule {
}
