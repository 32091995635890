import {Component, Input, OnInit} from '@angular/core';
import {FormField, IForm} from '@utils/form';
import {FieldLabelType} from '@pipes/fields/field-label.pipe';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Id} from '@model/entity';
import {ICachedRidersService} from '@services/i-cached-riders.service';
import {ModalController} from '@ionic/angular';
import {SelectRidersComponent} from '@components/rider/select-rider/select-riders.component';

@UntilDestroy()
@Component({
  selector: 'zef-with-riders-select',
  templateUrl: './with-riders-select.component.html',
  styleUrls: ['./with-riders-select.component.scss']
})
export class WithRidersSelectComponent implements OnInit {
  @Input() form: IForm;
  @Input() formField: FormField<Id[]>;
  FieldLabelType = FieldLabelType;

  constructor(
    public riders: ICachedRidersService,
    private modalController: ModalController
  ) {
  }

  ngOnInit() {
  }

  async edit() {
    const modal = await this.modalController.create({
      component: SelectRidersComponent,
      componentProps: {
        formField: this.formField
      }
    });
    return await modal.present();
  }
}
