import {Directive, HostListener, Input} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {EntityLink, EntityLinkWithOwner} from '@model/entity-link';
import {Commands} from '@services/meteor/commands';
import {IAuthService} from '@services/i-auth.service';
import Timer = NodeJS.Timer;

@UntilDestroy()
@Directive({
  selector: '[zefMarkAsRead]'
})
export class MarkAsReadDirective {
  @Input() zefMarkAsRead: EntityLink | EntityLinkWithOwner;

  @Input() set bounceTimeS(value: number) {
    this.bounceTimeMS = value * 1000;
  }

  private marked = false;
  private bounceTimeMS: number = 1000 * 2; // 2 seconds by default
  private timeout: Timer;

  constructor(private commands: Commands, private authService: IAuthService) {
  }

  @HostListener('inViewportAction', ['$event'])
  public markAsRead({ target, visible }: { target: Element; visible: boolean }) {
    // In any case, if the visible changed, then it means we need to reset timer
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    if (this.authService.isLoggedIn && !this.marked && visible) {
      // Want to make sure the element _remains_ visible for some time
      this.timeout = setTimeout(
        () => {
          this.marked = true;
          // console.debug(`Mark as read ${JSON.stringify(this.zefMarkAsRead)}`);
          this.commands.markEventsAsRead.call$({ entityLink: this.zefMarkAsRead })
            .pipe(untilDestroyed((this)))
            .subscribe();
        },
        this.bounceTimeMS
      );
    }
  }
}

