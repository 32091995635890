import {Observable} from 'rxjs';
import {SimpleSchemaValidatorFactory} from 'ngx-simpl-schema-validation';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import SimpleSchema from "simpl-schema";
import {untilDestroyed} from '@ngneat/until-destroy';
import {EntityType} from '@model/entity-type';
import {DateUtils} from '@core/date-utils';
import {isNotNullNotUndefined} from "@core/array.utils";

export interface Error {
  fieldName: string;
  code: string;
  schemaDefinition: any;
}

export class FormField<T> {
  isRequired: boolean;
  valueChanges$: Observable<T>;
  name: string;
  schemaDefinition: any;
  entityType: EntityType;

  constructor(
    protected schema: SimpleSchema,
    private vf: SimpleSchemaValidatorFactory | undefined,
    private formGroup: FormGroup,
    private defaultValue?: T) {
  }

  init(parent: any, name: string, entityType: EntityType): this {
    this.name = name;
    this.entityType = entityType;

    this.formGroup.addControl(this.name, new FormControl(this.defaultValue));

    // For some reason if default value is undefined, it will set it to null (!)
    // Need to fix this here
    this.value = this.defaultValue;
    this.valueChanges$ = this.control.valueChanges;
    const definition = this.schema?.getDefinition(name);
    this.schemaDefinition = definition?.type?.[0];
    this.isRequired = (definition !== undefined && definition.optional !== true);

    // TODO this shouldn't be necessary, issue with SimpleSchemaValidatorFactory
    this.vf?.connectControl({path: name});

    return this;
  }

  get control(): AbstractControl {
    return this.formGroup.get(this.name);
  }

  get value(): T {
    return this.control.value?.trim ? this.control.value.trim() : this.control.value;
  }

  set value(value: T) {
    this.control.setValue(value);
  }

  get errors(): Error[] {
    const fieldName = this.name;
    // FIXME make sure all errors are translated
    const schemaDefinition = this.schemaDefinition;
    const errorMessages: Error[] = this.vf?.getErrorMessages(this.name).map(code => ({
      fieldName,
      code,
      schemaDefinition
    }));
    if (this.isRequired && !isNotNullNotUndefined(this.value)) {
      errorMessages.push({fieldName, code: 'required', schemaDefinition});
    }
    return errorMessages;
  }

  get firstErrorIfTouched(): Error | null {
    if (!this.touched) {
      return null;
    }

    return (this.errors || [null])[0];
  }

  get touched() {
    return this.control.touched;
  }

  resetValue() {
    this.control.reset();
  }

  getSchemaProperty(propertyName: string) {
    return this.schema.get(this.name, propertyName);
  }
}

export class DateStringFormField extends FormField<string> {

  constructor(
    schema: SimpleSchema,
    vf: SimpleSchemaValidatorFactory | undefined,
    formGroup: FormGroup,
    defaultValue?: Date) {
    super(schema, vf, formGroup, DateUtils.toIonicDateString(defaultValue));
  }

  setDateDay(date: Date) {
    const currentDate = new Date(this.value);
    // Only change the "date" part
    currentDate.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
    this.value = DateUtils.toIonicDateString(currentDate);
  }

  get dateValue(): Date {
    return new Date(this.value);
  }
}

export class NumberFormField extends FormField<number> {
  isValidNumber() {
    return !isNaN(parseInt((this.value as any) as string));
  }

  init(parent: any, name: string, entityType: EntityType): this {
    super.init(parent, name, entityType);

    // Ionic slider component seems to transform null values into NaN which in turn is not valid for Schema
    // This shouldn't be necessary if using a different component such as https://github.com/squareetlabs/ionic-rating-component
    this.valueChanges$.pipe(untilDestroyed(parent)).subscribe(score => {
      if (isNaN(score)) {
        this.value = null;
      }
    });

    return this;
  }
}

export interface IForm {
  readonly allErrors: Error[];
  parentEntityType: EntityType;
  group: FormGroup;
  schema: SimpleSchema;
}

export class Form implements IForm {
  protected vf: SimpleSchemaValidatorFactory;

  constructor(public group: FormGroup, public schema: SimpleSchema | undefined, public parentEntityType: EntityType | undefined) {
    if (schema) {
      this.schema = schema
      this.vf = new SimpleSchemaValidatorFactory(this.schema);

      // FIXME fix ngx-simpl-schema-validation models
      // @ts-ignore
      this.vf.connectForm(this.group);
    }
  }
  fields: { [p: string]: FormField<any> };

  init(parent: any): this {
    Object.keys(this.fields)
      .forEach(fieldName => {
        const field = this.fields[fieldName];
        if (field) {
          field.init(parent, fieldName, this.parentEntityType);
        }
      });

    return this;
  }

  get allErrors(): Error[] {
    return Object.keys(this.fields).flatMap(fieldName => {
      const field = this.fields[fieldName] as FormField<any>;
      if (!field) {
        return [];
      }
      if (field.errors?.length > 0) {
        // So that fields with errors will show up
        field.control.markAsTouched();
      }
      return field.errors ?? [];
    });
  }
}
