import {SpotSituation} from './spot-situation';
import {Entity} from './entity';
import {EntityType} from './entity-type';
import {EntityLinkWithOwner} from './entity-link';

export interface Booking extends SpotSituation, Entity {
}

export const getBookingEntity = (booking: Pick<Booking, '_id' | 'riderId'>): EntityLinkWithOwner => ({
  type: EntityType.bookings,
  id: booking._id,
  entityOwnerId: booking.riderId
});
