import {NamedEntity} from './model';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const CountryLanguage = require('country-language');

export type WithoutShortName<T extends NamedEntity> = Omit<T, 'shortName'>;

const languagesToNormalize = ['en', 'fr'];

const countriesPerLanguage = languagesToNormalize.reduce(
  (accumulator: { [lan: string]: string[] }, current: string) => {
    accumulator[current] = CountryLanguage.getLanguageCountries(current).map((s: { code_2: string }) => s.code_2);
    return accumulator;
  },
  {}
);

export class ShortNames {
  static normalize(name: string, separator = '-'): string {
    const fallback = new RegExp(`[^a-zA-Z\\d\\${separator}]`, 'g');
    return name
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f']/g, '')
      .replace(/[\s:.+/]/g, separator)
      .replace(fallback, ''); // Then remove all non-ascii
  }

  static countryNormalize(countryCode: string, name: string): string {
    let normalized: string = ShortNames.normalize(name.trim());

    // Clean up known pronouns. Do it first, as quote would be removed by normalize
    if (countriesPerLanguage['fr'].includes(countryCode.toUpperCase())) {
      normalized = normalized.replace(/^(le-|la-|les-|l')/i, '');
    }
    if (countriesPerLanguage['en'].includes(countryCode.toUpperCase())) {
      normalized = normalized.replace(/^(the-)/i, '');
    }

    // Normalize a second time just in case
    return ShortNames.normalize(normalized);
  }

  static brand({ name }: { name: string }): string {
    return ShortNames.normalize(name);
  }

  static rider({ name }: { name: string }): string {
    return ShortNames.normalize(name, '.'); // Normalize with dot separator
  }

  static gearModel({ brand, name, year, version }: { brand: { name: string }, name: string, year: number, version?: string }): string {
    const versionString = (version?.length ?? 0 > 0) ? `_${version}` : '';
    return `${ShortNames.brand(brand)}/${ShortNames.normalize(name)}${versionString}-${year}`;
  }

  static spot(spot: { countryCode: string, name: string }): string {
    return `${ShortNames.normalize(spot.countryCode)}/${ShortNames.countryNormalize(spot.countryCode, spot.name)}`;
  }

  static spotLaunch(spot: { shortName: string }, spotLaunch: { countryCode: string, name: string }): string {
    return `${ShortNames.normalize(spot.shortName)}/launches/${ShortNames.countryNormalize(spotLaunch.countryCode, spotLaunch.name)}`;
  }

  static datedEntity(namedEntity: { shortName: string }, dayInTz: string): string {
    return `${namedEntity.shortName}/${dayInTz}`;
  }

  static getDate(shortNameAndDay: string): Date {
    const {day} = this.getShortNameAndDay(shortNameAndDay);

    return this.getDateFromDay(day);
  }

  static getDateFromDay(dayString: string): Date {
    const matches = dayString.split("/")
    const day = matches[2]
    const month = matches[1]
    const year = matches[0]

    const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    if (isNaN(date as any)) {
      throw `Invalid date in ${dayString}`
    }

    return date;
  }

  static getShortNameAndDay(shortNameAndDay: string) {
    const routeParamSegments = shortNameAndDay.split('/');
    const day = routeParamSegments.pop();
    const month = routeParamSegments.pop();
    const year = routeParamSegments.pop();

    return {
      day: `${year}/${month}/${day}`,
      // The rest is the short name
      shortName: routeParamSegments.join('/'),
    }
  }
}
