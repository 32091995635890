import {Pipe, PipeTransform} from '@angular/core';
import {TranslateConfigService} from '@services/translate-config.service';

@Pipe({
  name: 'newlinesBr'
})
export class NewLinesBrPipe implements PipeTransform {
  constructor() {
  }

  transform(text: string): string {
    return text.replace(/\n/g, '<br/>');
  }
}
