import {Component, Input, OnInit} from '@angular/core';
import {FieldLabelType} from '@pipes/fields/field-label.pipe';
import {IForm, NumberFormField} from '@utils/form';

@Component({
  selector: 'zef-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss']
})
export class NumberInputComponent implements OnInit {
  @Input() field: NumberFormField;
  @Input() form: IForm;
  @Input() placeholder: string | undefined;
  @Input() showLabel = true
  @Input() unit = ''

  FieldLabelType = FieldLabelType;

  constructor() {
  }

  ngOnInit() {
  }

}
