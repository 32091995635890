import { Component, Input, OnInit } from '@angular/core';
import { EditComponent } from '@components/edit/edit-component.directive';
import { Id } from '@model/entity';
import { Queries } from '@api/queries';
import { FeedbackService } from '@services/feedback.service';
import { ModalController, Platform } from '@ionic/angular';
import { ErrorPipe } from '@pipes/fields/error.pipe';
import { TranslateService } from '@ngx-translate/core';
import { IAuthService } from '@services/i-auth.service';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { FrontendRoutingService } from '@services/frontend-routing.service';
import { ObservationToInsert } from '@model/schema-model';
import { DateUtils } from '@core/date-utils';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { filterNullish } from '@utils/rxjs.utils';
import { NamedEntity } from '@model/entity-with-description';
import { EntityType } from '@model/entity-type';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ObservationForm } from '@components/observation/edit-observation/observation.form';
import { ICachedSpotsService } from '@services/i-cached-spots.service';
import { Commands } from '@services/meteor/commands';
import { DismissRole } from '@utils/dismissRole';
import { EntityTypePipe } from '@pipes/entity-type.pipe';
import { BroadcastService } from '@services/broadcast.service';

@UntilDestroy()
@Component({
  selector: 'zef-edit-observation',
  templateUrl: './edit-observation.component.html',
  styleUrls: ['../../edit/edit.component.scss'] // /!\ notice this is a shared scss
})
export class EditObservationComponent extends EditComponent<ObservationForm> implements OnInit {
  @Input() spotId: Id;

  constructor(
    queries: Queries,
    commands: Commands,
    feedback: FeedbackService,
    modalController: ModalController,
    platform: Platform,
    errorPipe: ErrorPipe,
    translateService: TranslateService,
    authService: IAuthService,
    entityTypePipe: EntityTypePipe,
    fb: FormBuilder,
    private router: Router,
    private routing: FrontendRoutingService,
    private spots: ICachedSpotsService,
    broadcastService: BroadcastService
  ) {
    super(queries, commands, feedback, modalController, errorPipe, translateService, authService, platform, entityTypePipe, fb, broadcastService);
  }

  ngOnInit() {
    const defaultFrom = new Date();

    this.form = new ObservationForm(this.formGroup, {
      spotId: this.spotId,
      fromInUserTZ: defaultFrom
    }).init(this);
  }

  protected doSubmit() {
    const observation = this.getDataToSubmit();
    return this.commands.createObservation
      .call$(observation)
      .pipe(
        take(1),
        switchMap(() => this.spots.getEntityById$(observation.spotId)),
        tap(async ({ shortName }: NamedEntity) => {
          await this.feedback.toast(this.translateService.instant('OBSERVATION.CREATE.CREATED'));
          // Now redirect to the spot day
          const path = this.routing.pathToDatedEntity(
            { type: EntityType.spots, shortName },
            observation.fromInUserTZ
          );
          await this.router.navigate(path?.link, { fragment: path?.fragment });
          await this.modalController.dismiss(undefined, DismissRole.CREATED);
        }),
        untilDestroyed(this)
      ).toPromise();
  }

  protected getDataToSubmit(): ObservationToInsert {
    return {
      activities: this.form.fields.activities.value,
      spotId: this.form.fields.spotId.value,
      spotLaunchId: this.form.fields.spotLaunchId.value,
      fromInUserTZ: this.form.fields.fromInUserTZ.dateValue,
      abstract: this.form.fields.abstract.value,
      score5: this.form.fields.score5.value,
      windStability5: this.form.fields.windStability5.value,
      userTZ: DateUtils.getLocaleTz(),
      quiver: this.form.fields.quiver.value,
      measuredWindKt: this.form.fields.measuredWindKt.value
    };
  }
}
