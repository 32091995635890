<ion-header>
  <ion-toolbar>
    <ion-title>
      {{ (isUpdate ? 'SESSION.UPDATE.TITLE' : 'SESSION.CREATE.TITLE') | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form *ngIf='form' [formGroup]='formGroup' (keydown.meta.Enter)='submit()'>
    <ion-list>
      <zef-spot-select [form]='form' [spotField]='form.start.fields.spotId'
                       [spotLaunchField]='form.start.fields.spotLaunchId'></zef-spot-select>

      <zef-from-select [form]='form' [fromField]='form.start.fields.fromInUserTZ'></zef-from-select>

      <zef-to-select [form]='form' [toField]='form.end.fields.toInUserTZ' [min]='form.minTo'></zef-to-select>

      <zef-edit-situation-gear *ngIf='riderQuiver$|async as riderQuiver'
                               [form]='form'
                               [formField]='form.start.fields.quiver'
                               [riderQuiver]='riderQuiver'></zef-edit-situation-gear>

      <zef-activity-select [form]='form'
                           [activitiesField]='form.start.fields.activities'
                           [quiverField]='form.start.fields.quiver'
      ></zef-activity-select>

      <zef-with-riders-select [form]='form'
                              [formField]='form.start.fields.withRiders'></zef-with-riders-select>

      <zef-rate [form]='form' [score5Field]='form.end.fields.score5'></zef-rate>

      <zef-rate [form]='form' [score5Field]='form.end.fields.windStability5'></zef-rate>

      <zef-number-input
        [form]='form'
        [field]='form.end.fields.distanceKm'
        placeholder='{{"UNIT.KILOMETER" | translate : {count: 2} }}'>
      </zef-number-input>
      <zef-number-input
        [form]='form'
        [field]='form.end.fields.maxSpeedKnot'
        placeholder='{{"UNIT.KNOT" | translate : {count: 2} }}'>
      </zef-number-input>
      <zef-number-input
        [form]='form'
        [field]='form.end.fields.avgSpeedKnot'
        placeholder='{{"UNIT.KNOT" | translate : {count: 2} }}'>
      </zef-number-input>

      <zef-abstract [form]='form' [field]='form.end.fields.abstract'></zef-abstract>
    </ion-list>
  </form>
</ion-content>

<ion-footer>
  <ion-toolbar [popper]='popperAllErrors'
               [popperTrigger]='popperTrigger'
               [popperDisabled]='formGroup.valid'
               (popperOnShown)='onPopperShown()'>
    <ion-buttons slot='start' class="secondary">
      <ion-button (click)='cancel()'>
        {{ 'SHARED.CANCEL' | translate }}
      </ion-button>
    </ion-buttons>
    <ion-buttons slot='end'>
      <ion-button (click)='submit()' [disabled]='disabledButton || !formGroup.valid' color='primary'>
        {{ (isUpdate ? 'SESSION.UPDATE.OK' : 'SESSION.CREATE.OK') | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>

<popper-content #popperAllErrors>
  <p *ngFor='let error of allErrors'>{{error}}</p>
</popper-content>
