import {Pipe, PipeTransform} from '@angular/core';
import {FormField} from '@utils/form';
import {TranslateService} from '@ngx-translate/core';
import {EntityType} from '@model/entity-type';

const TRANSLATE_PREFIX = 'FORMS.FIELDS.';

export enum FieldLabelType {
  TITLE,
  NAME,
  REQUIRED,
  PLACEHOLDER
}

@Pipe({
  name: 'fieldLabel'
})
export class FieldLabelPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
  }

  // FIXME should have dedicated pipe per label type
  transform(field: FormField<any>, type: FieldLabelType, entityType = field.entityType): any {
    const fieldName = this.getFieldTitle(field.name, entityType);
    switch (type) {
      case FieldLabelType.NAME:
        return fieldName;
      case FieldLabelType.TITLE:
        return fieldName + (field.isRequired ? ' *' : '');
      case FieldLabelType.REQUIRED:
        return field.isRequired ? this.translateService.instant('FORMS.REQUIRED', { entity: entityType }) : '';
      case FieldLabelType.PLACEHOLDER:
        return this.translateService.instant(TRANSLATE_PREFIX + field.name + '.PLACEHOLDER', { entity: entityType });
    }
  }

  public getFieldTitle(name: string, entity: EntityType) {
    return this.translateService.instant(TRANSLATE_PREFIX + name + '.TITLE', { entity });
  }
}
