import {Link} from './link';
import {MultiLanguageText} from './multi-language-text';
import {Entity, Id} from './entity';

export interface IdOrShortName {
  // Assumes one or the other is set
  _id?: Id,
  shortName?: string
}

export interface NamedEntity extends Entity {
  name: string;
  shortName: string;
  aliases?: string[];
  avatar?: string;
}

export interface EntityWithDescription extends NamedEntity {
  abstract?: MultiLanguageText;
  description?: MultiLanguageText;
  links?: Link[];
  creatorId?: Id;
  updaterId?: Id;
}

export const namedEntityFields: (keyof NamedEntity)[] = ['_id', 'createdAt', 'updatedAt', 'name', 'shortName', 'aliases', 'avatar'];
