import {Component, Input, OnInit} from '@angular/core';
import {FormField, IForm} from '@utils/form';
import {Id} from '@model/entity';
import {FieldLabelType} from '@pipes/fields/field-label.pipe';
import {Observable} from 'rxjs';
import {NamedEntity} from '@model/entity-with-description';
import {Queries} from '@api/queries';
import {tap} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ICachedSpotsService} from '@services/i-cached-spots.service';
import {filterNullish} from '@utils/rxjs.utils';

@UntilDestroy()
@Component({
  selector: 'zef-spot-select',
  templateUrl: './spot-select.component.html',
  styleUrls: ['./spot-select.component.scss']
})
export class SpotSelectComponent implements OnInit {
  @Input() form: IForm;
  @Input() spotField: FormField<Id>;
  @Input() spotLaunchField: FormField<Id>;
  FieldLabelType = FieldLabelType;

  spots$: Observable<NamedEntity[]>;
  spotLaunches$: Observable<NamedEntity[]>;

  constructor(private queries: Queries, private spots: ICachedSpotsService) {
  }

  ngOnInit() {
    this.spots$ = this.spots.getAllEntities$();

    const updateLaunchesList = spotId => {
      if (spotId) {
        this.spotLaunches$ = this.queries.getSpotLaunchesBySpotId.call$(spotId).pipe(
          filterNullish(),
          tap(spotLaunches => {
            // If there is only one spotLaunch, let's select it automatically
            if (spotLaunches && spotLaunches.length === 1) {
              this.spotLaunchField.value = spotLaunches[0]._id;
            }
          }),
          untilDestroyed(this)
        );
      }
    };

    this.spotField.valueChanges$.pipe(untilDestroyed(this)).subscribe(updateLaunchesList);

    // Initialize
    updateLaunchesList(this.spotField.value);
  }
}
