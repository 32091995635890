import {Location, RawLatLng} from './place';
import {Feature, FeatureCollection, LineString, Point, Position} from 'geojson';
import {DOMParser, L} from '@api/fullstack-leaflet';
import center from '@turf/center';
import distance from '@turf/distance';
import {FeatureGroup} from 'leaflet';
import {removeOutliers, smooth} from '../math-utils';
import {notUndefined} from '../utils';

// eslint-disable-next-line @typescript-eslint/no-var-requires
require('../../api/gpx');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const togeojson = require('@mapbox/togeojson');

export const centerLatLng = (location: Location | LineString): RawLatLng => {
  let latLng: RawLatLng;

  if (location.type === 'Polygon') {
    latLng = L.geoJSON(location).getBounds().getCenter();
  } else if (location.type == 'Point') {
    const coordinates = location.coordinates;
    latLng = positionToRawLatLng(coordinates);
  } else { //if(location.type == 'LineString') {
    const centerLocation = center(location);
    latLng = positionToRawLatLng(centerLocation.geometry.coordinates);
  }

  return latLng;
};

export const distanceM = (from: Point, to: Point): number => distance(from, to);

// See https://datatracker.ietf.org/doc/html/rfc7946#section-3.1.1
export const rawLatLngToPosition = ({lat, lng}: RawLatLng): Position => [lng, lat];
export const rawLatLngToPoint = (rawLatLng: RawLatLng): Point => ({
  type: 'Point',
  coordinates: rawLatLngToPosition(rawLatLng)
});
export const positionToRawLatLng = ([lng, lat]: Position): RawLatLng => ({lat, lng});

export const getCenterPoint = (location: Location): Point => {
  const center = centerLatLng(location);

  return {
    type: 'Point',
    coordinates: rawLatLngToPosition(center)
  };
};

export const getStartPoint = (geoJson: FeatureCollection): Point => ({
  type: 'Point',
  coordinates: ((geoJson.features[0] as Feature).geometry as LineString).coordinates[0]
});

export const gpxToGeoJson = (gpxData: string) => {
  // Use togeojson to get coordinates
  const kml = new DOMParser().parseFromString(gpxData, 'text/xml');
  return togeojson.gpx(kml);
};

export type LeafletGpx = FeatureGroup & {
  get_start_time: () => Date;
  get_end_time: () => Date;
  get_distance: () => number;
  get_moving_speed: () => number;
  _info: {
    name: string,
    length: number,
    elevation: { gain: number, loss: number, max: number, min: number, _points: [] },
    speed: { max: number, _points: [] },
    hr: { avg: number, _total: number, _points: [] },
    duration: { start: null, end: null, moving: number, total: number },
    atemp: { avg: number, _total: number, _points: [] },
    cad: { avg: number, _total: number, _points: [] }
  }
};

export const gpxToLeaflet = (gpxData: string): LeafletGpx => {
  // Use GPX class to get all stats about the file
  return new L.GPX(gpxData, {async: false});
};

export interface GpxData {
  startDate?: Date;
  endDate?: Date;
  avgSpeedKmPerH?: number;
  maxSpeedKmPerH?: number;
  distanceKm: number;
}

export const leafletGpxGetData = (gpx: LeafletGpx): GpxData => {

  let startDate: Date | undefined = gpx.get_start_time()
  startDate = startDate.getTime() <= 0 ? undefined : startDate

  let endDate: Date | undefined = gpx.get_end_time()
  endDate = endDate.getTime() <= 0 ? undefined : endDate

  const speeds = (gpx._info.speed._points as [number, number][])
    // Take speed value
    .map((val) => ({length: val[0], speed: val[1]}))
    // Do not consider speed zero for calculating max!
    // It would make wrong median calculation
    .filter(({length, speed}) => (length !== 0 && speed !== 0));

  // Remove outliers
  removeOutliers(speeds, s => s?.speed);

  // Now apply a gaussian filter to values
  smooth(speeds,
    s => s?.speed,
    (s, speed) => s.speed = speed,
    {
      average: 0.3,
      prev: 0.5,
      curr: 2,
      next: 1
    });

  const filteredSpeeds = speeds.filter(notUndefined) as { length: number, speed: number }[];

  const maxSpeedMs = Math.max(...filteredSpeeds.map(s => s.speed));
  /*
  const avgSpeedMs = filteredSpeeds.reduce((sum, curr) => {
      sum += curr.speed * curr.length;
      return sum;
    }, 0)
    /
    filteredSpeeds.reduce((sum, curr) => {
      sum += curr.length;
      return sum;
    }, 0);
   */

  const distanceKm = gpx.get_distance() / 1000;
  let maxSpeedKmPerH: number | undefined = maxSpeedMs * 3600 / 1000;
  maxSpeedKmPerH = Math.abs(maxSpeedKmPerH) === Infinity ? undefined : maxSpeedKmPerH

  let avgSpeedKmPerH: number | undefined = gpx.get_moving_speed(); //avgSpeedMs * 3600 / 1000;
  avgSpeedKmPerH = Math.abs(avgSpeedKmPerH) === Infinity ? undefined : avgSpeedKmPerH

  return {
    startDate,
    endDate,
    distanceKm,
    maxSpeedKmPerH,
    avgSpeedKmPerH
  };
};

// FIXME might need to define something more clever than the center of France!
export const defaultCenter: RawLatLng = {lat: 46.8547086, lng: 3.5507146};
