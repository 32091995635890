import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CenterAndZoom, MapComponent} from '@components/map/map.component';
import {SpotLaunch} from '@model/spot-launch';
import {EntityTypeWithCollection} from '@model/entity-type';
import {TranslateService} from '@ngx-translate/core';
import {IconService} from '@services/icon.service';
import {SpotLaunchPopupComponent} from '@components/spot/spot-map/spot-launch-popup/spot-launch-popup.component';
import {NgElement, WithProperties} from '@angular/elements';
import {Polygon} from 'geojson';
import {positionToRawLatLng} from '@model/place.utils';
import {Platform} from "@ionic/angular";

@Component({
  selector: 'zef-spot-map',
  templateUrl: '../../../components/map/map.component.html', // /!\ Use map template and CSS
  styleUrls: [
    '../../../components/map/map.component.scss'
  ]
})
export class SpotMapComponent extends MapComponent {
  @Input() spotLocation: Polygon;
  @Input() spotLaunches: SpotLaunch[];
  @Output() clickSpotLaunch = new EventEmitter<SpotLaunch>();
  @Input() defaultCenterAndZoom: CenterAndZoom | undefined;

  constructor(
    translate: TranslateService,
    platform: Platform,
    public icon: IconService) {
    super(translate, platform);
    this.initCreatePolygon({
      allowIntersection: false, // Restrict shapes to simple polygons
      drawError: {
        color: '#e1e100', // Color the shape will turn when intersects
        message: '<strong>Oh snap!<strong> you can\'t draw that!' // Message that will show when intersect
      }
    });
  }

  protected onMapReady() {
    if (this.spotLocation) {
      const location = this.addPolygon(this.spotLocation);
      this.addEditablePolygon(location);

      const icon = this.icon.getLeafletIcon(EntityTypeWithCollection.spotLaunches);

      for (let launch of this.spotLaunches ?? []) {
        const marker = this.addPoint(launch.location, this.map, icon);

        marker
          .bindPopup(fl => {
            const popupEl: NgElement & WithProperties<SpotLaunchPopupComponent> = document.createElement('zef-spot-launch-popup') as any;
            // Listen to the close event
            popupEl.addEventListener('closed', () => document.body.removeChild(popupEl));
            popupEl.addEventListener('click', () => {
              this.exitFullscreen();
              this.clickSpotLaunch.emit(launch);
            });
            popupEl.launch = launch;
            // Add to the DOM
            document.body.appendChild(popupEl);
            return popupEl;
          });
      }

      this.setViewPolygon(location);
    } else if (this.defaultCenterAndZoom) {
      const { center, zoom } = this.defaultCenterAndZoom;
      this.setViewPoint(positionToRawLatLng(center), zoom);
      this.toggleDrawCreatePolygon(true);
    }
  }
}
