import * as moment from 'moment-timezone'; // /!\ don't use "import moment from", will make "schema-to-types" fail
import {Moment} from 'moment-timezone';

export interface Period {
  from: Date,
  to: Date
}

export class DateUtils {
  static readonly ONE_HOUR_IN_MS: number = 3600 * 1000;
  static readonly ONE_DAY_IN_MS: number = 24 * DateUtils.ONE_HOUR_IN_MS;

  static toMomentTz(date: Date, timezone: string): Moment {
    return moment.tz(date, timezone);
  }

  static getDayInTzString(date: Moment): string {
    return date.format('YYYY/MM/DD');
  }

  static getLocaleTz(): string {
    return moment.tz.guess();
  }

  static add(date: Date, time: number): Date {
    return new Date(date.getTime() + time);
  }

  static remove(date: Date, time: number): Date {
    return new Date(date.getTime() - time);
  }

  /**
   * TODO use moment.tz for this
   * Convert the date to the spot's timezone and set it to 00:00:00
   * So that it represents the beginning of the day for this spot
   * @param date
   * @param timezone
   */
  static getStartOfDayForTimezone(date: Date, timezone: string = DateUtils.currentTimeZone()): Date {
    return this.toMidnightDate(this.toMomentTz(date, timezone));
  }

  static toMidnightDate(momentValue: Moment): Date {
    return momentValue
      .clone()
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .toDate();
  }

  static parseHoursMinutes(hoursMinutes: string) {
    const parsedTime = hoursMinutes.split(':').map(Number);
    return {
      hours: parsedTime[0],
      minutes: parsedTime[1]
    };
  }

  /**
   *
   * @param date
   * @param strTime format: HH:mm (24)
   */
  static getDateFromDateAndTime(date: Date, strTime: string): Date {
    const parsedTime = strTime.split(':').map(Number);
    return moment.default(date)
      .set({ hour: parsedTime[0], minute: parsedTime[1], second: 0, millisecond: 0 })
      .toDate();
  }

  static yesterday(date: Date): Date {
    return new Date(date.getTime() - this.ONE_DAY_IN_MS);
  }

  static daysDifference(date1: Date, date2: Date): number {
    return (date1.getTime() - date2.getTime()) / this.ONE_DAY_IN_MS;
  }

  static with2digits(time: number) {
    return time.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
  }

  static toStringWithoutOffset(date: Date): string {
    return `${this.toStringDate(date)}T${this.with2digits(date.getHours())}:${this.with2digits(
      date.getMinutes()
    )}:${this.with2digits(date.getSeconds())}`;
  }

  static toStringDate<D extends Date | undefined>(date: D): D extends undefined ? undefined : string {
    return (date
      ? `${date.getFullYear()}-${this.with2digits(date.getMonth() + 1)}-${this.with2digits(date.getDate())}`
      : undefined) as any;
  }

  static getDay(date: Date): string {
    return moment.default(date).format('YYYY/MM/DD');
  }

  static toStringHourMin(date: Date): string {
    return DateUtils.formatHourMin(date.getHours(), date.getMinutes());
  }

  static formatHourMin(hours: number, minutes: number): string {
    return `${this.with2digits(hours)}:${this.with2digits(minutes)}`;
  }

  /**
   * For some reason this awful hack is needed to format the date with offset when initializing "from" date to set "to" min date...
   * @param date
   */
  static toStringWithOffset(date: Date): string {
    return `${this.toStringWithoutOffset(date)}${this.with2digits(
      Math.floor(date.getTimezoneOffset() / 60)
    )}:${this.with2digits(Math.abs(date.getTimezoneOffset() % 60))}`;
  }

  /**
   *
   * @param date
   */
  static toIonicDateString(date: Date): string {
    const offsetHours = -Math.floor(date.getTimezoneOffset() / 60);
    const formattedOffsetHours = this.with2digits(offsetHours);
    const sign = offsetHours >= 0 ? '+' : '';
    return `${this.toStringWithoutOffset(date)}${sign}${formattedOffsetHours}:${this.with2digits(Math.abs(date.getTimezoneOffset() % 60))}`;
  }

  /**
   * Take a date in the local timezone (ex: GMT+2) and consider it is in the destination timezone.
   * Ex: "Tue Jul 13 2021 08:00:00 GMT+0200" + timezone US/Hawai becomes "Tue Jul 13 2021 08:00:00 HST" in US/Hawai timezone
   */
  static considerInTimeZone<D extends Date | undefined>(date: D, timezoneToConsider: string, originalTimezone: string = DateUtils.getLocaleTz()): D extends undefined ? undefined : Date {
    if (!date) {
      return undefined as any;
    }
    // This is the date in the user's timezone, let's consider it is UTC
    const originalDate = moment.tz(date, originalTimezone).utcOffset(0, true);

    // Get offset for destination timezone
    const offset = moment.tz.zone(timezoneToConsider)!.utcOffset(date.getTime());

    // Now keep the date value and _consider it_ in the destination timezone (using its offset)
    return originalDate.utcOffset(-offset, true).toDate() as any;
  }

  static getMonthPeriod(date: Date): Period {
    // Beginning of month
    const from = new Date(date);
    from.setDate(0);

    // End of month
    const to = new Date(date);
    to.setMonth(to.getMonth() + 1, 0);

    return { from, to };
  }

  static currentTimeZone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
