import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NumberInputComponent} from '@components/input/number-input/number-input.component';
import {IonicModule} from '@ionic/angular';
import {PipesModule} from '@pipes/pipes.module';
import {ReactiveFormsModule} from '@angular/forms';
import {ErrorLineModule} from '@components/input/error-line/error-line.module';

@NgModule({
  declarations: [NumberInputComponent],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    ReactiveFormsModule,
    ErrorLineModule
  ],
  exports: [NumberInputComponent]
})
export class NumberInputModule {
}
