import {Component, Input} from '@angular/core';
import {MapComponent} from '@components/map/map.component';
import {RawLatLng} from '@model/place';
import {Spot} from '@model/spot';
import {EntityTypeWithCollection} from '@model/entity-type';
import {divIcon, DivIcon} from 'leaflet';
import {IconService} from '@services/icon.service';
import {TranslateService} from '@ngx-translate/core';
import {Point} from 'geojson';
import {SpotLaunch} from '@model/spot-launch';
import {Platform} from "@ionic/angular";

@Component({
  selector: 'zef-spot-launch-map',
  templateUrl: '../../map/map.component.html', // /!\ Use map template and CSS
  styleUrls: [
    '../../map/map.component.scss',
    './spot-launch-map.component.scss'
  ]
})
export class SpotLaunchMapComponent extends MapComponent {
  @Input() spotLaunchLocation: Point;
  @Input() spot: Spot;
  @Input() spotLaunches: SpotLaunch[];

  protected icon: DivIcon;

  constructor(
    translate: TranslateService,
    platform: Platform,
    private iconService: IconService,
  ) {
    super(translate, platform);

    this.icon = this.iconService.getLeafletIcon(EntityTypeWithCollection.spotLaunches);
    this.initCreatePoint({icon: this.icon});
  }

  protected onMapReady() {
    const spotLocation = this.addPolygon(this.spot.location);

    if (this.spotLaunchLocation) {
      // Pre-existing spot launch
      const marker = this.addPoint(this.spotLaunchLocation, this.map, this.icon);
      this.addEditablePoint(marker);
      // Zoom on the existing launch
      this.setViewPoint(marker.getLatLng() as RawLatLng, 16);
    } else {
      // New spot launch
      this.toggleDrawCreatePoint(true);
      // Zoom on the whole spot
      this.setViewPolygon(spotLocation);
    }

    const otherIcon = divIcon({
      ...this.icon.options,
      className: 'other-launch'
    });

    for (let launch of this.spotLaunches ?? []) {
      if (launch.location !== this.spotLaunchLocation) {
        this.addPoint(launch.location, this.map, otherIcon);
      }
    }
  }

}
