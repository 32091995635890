import {Id, JustId, Optional, ToPersist} from '@model/entity';
import {SpotDay} from '@model/spot-day';
import {SpotDays} from '../collections/spot-day.collection';
import {EventType, getSessionEntityWithOwner, QuiverItem, Rider, Session, Spot} from '../../core/model/model';
import {EventsQueries} from './event.queries';
import {EntityType} from '@model/entity-type';
import {Sessions} from '../collections/session.collection';
import {ZefCollection} from '../collections/zef-collection';
import {GearQueries} from './gear.queries';
import {DayMetrics, RiderDayMetricsInput, SessionWithQuiver, SpotDaySessions} from './queries.model';
import {DateUtils} from '@core/date-utils';
import {DayMetric} from '../model/metrics';
import moment from 'moment-timezone';
import Selector = Mongo.Selector;

const session_quiver: keyof Session = 'quiver';
const quiver_itemId: keyof QuiverItem = 'itemId';
const out_quiver: keyof SessionWithQuiver = 'quiver';

const aggregateSession = GearQueries.getMapToGearModelAggregateSteps(session_quiver, quiver_itemId, out_quiver);

export type SessionIdWithSpotDay = JustId & { spotDay: SpotDay };

export interface SessionRiderSpot {
  session: Session,
  rider: Rider,
  spot: Spot
}

export class SessionsQueries {
  static insertSession = (
    sessionToPersist: Omit<ToPersist<Session>, 'dayInTz'>,
    spotDay: SpotDay = SpotDays.findOne(sessionToPersist.spotDayId),
    eventType = EventType.created
  ): Id => {
    const session = ZefCollection.setCreatedDate<Session>({
      ...sessionToPersist,
      dayInTz: DateUtils.getDayInTzString(DateUtils.toMomentTz(sessionToPersist.startDate, spotDay.timezone))
    });

    console.log(`insertSession-insertSync`);
    const _id = Sessions.insertSync(session);

    console.log(`insertSession-insertEvent`);
    EventsQueries.persistSpotDayEvent(
      getSessionEntityWithOwner({...session, _id}),
      eventType,
      session.createdAt,
      spotDay,
      session.riderId
    );

    console.log(`insertSession-done`);
    return _id;
  };

  static updateSession = (sessionToUpdate: Optional<Omit<Session, 'dayInTz' | 'createdAt'>, 'activities'>,
                          spotDay: SpotDay = SpotDays.findOne(sessionToUpdate.spotDayId)): Id => {

    const session = {
      ...sessionToUpdate,
      updatedAt: new Date(),
      dayInTz: DateUtils.getDayInTzString(DateUtils.toMomentTz(sessionToUpdate.startDate, spotDay.timezone))
    };

    const $set = session;
    Sessions.updateSync(session._id, {$set: $set});

    EventsQueries.persistSpotDayEvent(
      getSessionEntityWithOwner({...session}),
      EventType.updated,
      session.updatedAt,
      spotDay,
      session.riderId
    );

    return session._id;
  };

  static async getRiderDaysMetrics(input: RiderDayMetricsInput): Promise<DayMetrics> {
    // TODO for the moment is calculated on the fly but in the future, would probably be "cached" with the SpotDay
    const aggregate = Sessions.getAggregateAsync<DayMetric>();

    const session_riderId: keyof Session = 'riderId';
    const session_withRiderIds: keyof Session = 'withRiderIds';
    const startDate: keyof Session = 'startDate';

    const out_count: keyof DayMetric = 'countOf';
    const out_startDate: keyof DayMetric = 'startDate';

    const aggregateQuery = [
      {
        $match: {
          $and: [
            {
              $or: [
                // Consider both sessions directly FROM the rider, and where the rider appeared
                {[session_riderId]: input.riderId},
                {[session_withRiderIds]: input.riderId}
              ]
            },
            {[startDate]: {$gt: input.period.from}},
            {[startDate]: {$lt: input.period.to}}
          ]
        }
      },
      {
        $project: {
          yearMonthDay: {$toDate: {$dateToString: {format: '%Y-%m-%d', date: `$${startDate}`}}}
        }
      },
      {
        $group: {
          _id: '$yearMonthDay',
          sessionCount: {
            $sum: 1
          }
        }
      },
      {
        $project: {
          _id: 0,
          [`${out_count}.${EntityType.sessions}`]: '$sessionCount',
          [out_startDate]: '$_id'
        }
      }
    ];

    return (await aggregate(aggregateQuery).toArray());
  }

  static async getSessionWithQuiver(sessionId: Id): Promise<SessionWithQuiver> {
    const aggregate = Sessions.getAggregateAsync<SessionWithQuiver>();

    const aggregateQuery = [
      {$match: {_id: sessionId}},
      ...aggregateSession
    ];

    return (await aggregate(aggregateQuery).toArray())[0];
  }

  /**
   * Get sessions that start or end around these dates
   * @param riderId
   * @param startDate
   * @param endDate
   */
  static getRiderSessionsByDate(riderId: Id, startDate: Date, endDate: Date): Mongo.Cursor<Session> {
    const session_riderId: keyof Session = 'riderId';
    const session_startDate: keyof Session = 'startDate';
    const session_endDate: keyof Session = 'endDate';

    const allowedMarginMinutes = 15;

    // Sessions started around start date and before end date
    const minStart = moment(startDate).subtract(allowedMarginMinutes, 'minutes').toDate();
    const maxStart = moment(startDate).add(allowedMarginMinutes, 'minutes').toDate();
    const minEnd = moment(endDate).subtract(allowedMarginMinutes, 'minutes').toDate();
    const maxEnd = moment(endDate).add(allowedMarginMinutes, 'minutes').toDate();

    const selector: Selector<Session> = {
      [session_riderId]: riderId,
      [session_startDate]: {
        $gte: minStart,
        $lte: maxStart
      },
      $or: [
        {[session_endDate]: {$type: 'undefined'}},
        {
          [session_endDate]: {
            $gte: minEnd,
            $lte: maxEnd
          }
        }
      ]
    };

    return Sessions.nativeFind(selector);
  }

  static async getRiderDaySessions(riderId: Id, dateString: string): Promise<SpotDaySessions[]> {
    const aggregate = Sessions.getAggregateAsync<SpotDaySessions>();
    const session_riderId: keyof Session = 'riderId';
    const session_withRiderIds: keyof Session = 'withRiderIds';
    const session_dayInTz: keyof Session = 'dayInTz';
    const session_spotDayId: keyof Session = 'spotDayId';
    const session_startDate: keyof Session = 'startDate';

    const spotDay_id: keyof SpotDay = '_id';

    const out_spotDay: keyof SpotDaySessions = 'spotDay';
    const out_sessions: keyof SpotDaySessions = 'sessions';

    const aggregateQuery = [
      {
        $match: {
          $and: [

            {
              $or: [
                // Consider both sessions directly FROM the rider, and where the rider appeared
                {[session_riderId]: riderId},
                {[session_withRiderIds]: riderId}
              ]
            },
            {[session_dayInTz]: dateString}
          ]
        }
      },
      ...aggregateSession,
      {
        $sort: {[session_startDate]: 1}
      },
      {
        // Group per spot day
        $group: {
          _id: {
            [session_spotDayId]: `$${session_spotDayId}`
          },
          [out_sessions]: {
            $push: '$$ROOT'
          }
        }
      },
      {
        $lookup: {
          from: EntityType.spotDays,
          localField: `_id.${session_spotDayId}`,
          foreignField: spotDay_id,
          as: out_spotDay
        }
      },
      {
        // Array of spotdays => one entry per spotday (but should be only one!)
        $unwind: {
          path: `$${out_spotDay}`
        }
      },
      {
        $project: {
          _id: 0
        }
      }
    ];

    return await aggregate(aggregateQuery).toArray();
  }

  static selectUnfinishedSessions(riderId: string): Selector<Session> {
    const key_endDate: keyof Session = 'endDate';
    const key_riderId: keyof Session = 'riderId';
    return {
      [key_riderId]: riderId,
      [key_endDate]: {$exists: false}
    };
  }

  static selectBySpotDayId(spotDayId: Id): Selector<Session> {
    const key_spotDayId: keyof Session = 'spotDayId';
    return {
      [key_spotDayId]: spotDayId
    };
  }

  static async getLatestSpotSessions(spotId: Id, limit = 4): Promise<SessionIdWithSpotDay[]> {
    const spotDay_spotId: keyof SpotDay = 'spotId';
    const out_spotDay: keyof SessionIdWithSpotDay = 'spotDay';

    return await this.getLatestSessions({
      $match: {[`${out_spotDay}.${spotDay_spotId}`]: spotId}
    }, limit);
  }

  static async getLatestRiderSessions(riderId: Id, limit = 4): Promise<SessionIdWithSpotDay[]> {
    const session_riderId: keyof Session = 'riderId';

    return await this.getLatestSessions({
      $match: {[session_riderId]: riderId}
    }, limit);
  }

  static async getLatestWithRiderSessions(riderId: Id, limit = 4): Promise<SessionIdWithSpotDay[]> {
    const session_withRiderIds: keyof Session = 'withRiderIds';

    return await this.getLatestSessions({
      $match: {[session_withRiderIds]: riderId}
    }, limit);
  }

  private static async getLatestSessions(match: { $match: Selector<unknown> }, limit: number) {
    const aggregate = Sessions.getAggregateAsync<SessionIdWithSpotDay>();

    const out_spotDay: keyof SessionIdWithSpotDay = 'spotDay';
    const spotDay_dayInTz: keyof SpotDay = 'dayInTz';

    const aggregateQuery = [
      ...this.getAggregateSpotDay(),
      match,
      {$sort: {[`${out_spotDay}.${spotDay_dayInTz}`]: -1}}, // Date DESC
      {$limit: limit},
      {
        $project: {
          [out_spotDay]: 1
          // _id is projected by default
        }
      }
    ];

    return await aggregate(aggregateQuery).toArray();
  }

  private static getAggregateSpotDay() {
    const session_spotDayId: keyof Session = 'spotDayId';
    const spotDay_id: keyof SpotDay = '_id';

    const out_spotDay: keyof SessionIdWithSpotDay = 'spotDay';
    return [{
      $lookup: {
        from: EntityType.spotDays,
        localField: session_spotDayId,
        foreignField: spotDay_id,
        as: out_spotDay
      }
    },
      {
        // Array of spotdays => one entry per spotday (but should be only one!)
        $unwind: {
          path: `$${out_spotDay}`
        }
      }];
  }

  static async getSessionAndRiderAndSpot(sessionId: Id): Promise<SessionRiderSpot> {
    const aggregate = Sessions.getAggregateAsync<SessionRiderSpot>();

    const session_spotDayId: keyof Session = 'spotDayId'
    const session_riderId: keyof Session = 'riderId'

    const spotDay_id: keyof SpotDay = '_id'
    const spotDay_spotId: keyof SpotDay = 'spotId'

    const rider_id: keyof Rider = '_id'

    const local_spotDay = 'spotDay'

    const spot_id: keyof Spot = '_id'

    const out_session: keyof SessionRiderSpot = 'session'
    const out_rider: keyof SessionRiderSpot = 'rider'
    const out_spot: keyof SessionRiderSpot = 'spot'

    const aggregateQuery = [
      {$match: {_id: sessionId}},
      {
        $project: {
          [out_session]: "$$ROOT"
        }
      },
      {
        $lookup: {
          from: EntityType.riders,
          localField: `${out_session}.${session_riderId}`,
          foreignField: rider_id,
          as: out_rider
        }
      },
      {
        // Array of riders => one entry per rider (but should be only one!)
        $unwind: {
          path: `$${out_rider}`
        }
      },
      {
        $lookup: {
          from: EntityType.spotDays,
          localField: `${out_session}.${session_spotDayId}`,
          foreignField: spotDay_id,
          as: local_spotDay
        }
      },
      {
        // Array of spotDays => one entry per spotDay (but should be only one!)
        $unwind: {
          path: `$${local_spotDay}`
        }
      },
      {
        $lookup: {
          from: EntityType.spots,
          localField: `${local_spotDay}.${spotDay_spotId}`,
          foreignField: spot_id,
          as: out_spot
        }
      },
      {
        // Array of spots => one entry per spot (but should be only one!)
        $unwind: {
          path: `$${out_spot}`
        }
      },
    ];

    return (await aggregate(aggregateQuery).toArray())[0];
  }


}
