import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Language, Languages} from '@model/multi-language-text';
import {Router} from '@angular/router';
import {registerLocaleData} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import moment from 'moment-timezone';
import languageList from '@cospired/i18n-iso-languages';
import {mapDictionary} from '@core/utils';
import {LocalStorageService, StorageKey} from '@services/local-storage.service';
import countries from 'i18n-iso-countries';
import {Subject} from "rxjs";

export interface LangCodeName {
  code: string;
  name: string;
}

/**
 * Init function
 * @param service
 */
export const initTranslateConfigServiceFactory = (service: TranslateConfigService) => async () => {
  await service.init();
};

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {
  constructor(
    private translate: TranslateService,
    private storageService: LocalStorageService,
    private router: Router
  ) {
  }

  languageChanged$ = new Subject<Language>()

  async init() {
    return this.setLoggedOutLanguage();
  }

  async setLoggedOutLanguage() {
    // Take previously set language if it exists, or browser default language
    this.setLang((await this.storageService.get(StorageKey.lang)) ?? this.translate.getBrowserLang());
  }

  get currentLanguage(): Language {
    return this.translate.currentLang as Language;
  }

  get currentLanguageName(): Language {
    return this.translate.currentLang as Language;
  }

  async setLanguage(setLang: Language) {
    if (this.currentLanguage === setLang) {
      return;
    }

    this.setLang(setLang);

    moment.locale(setLang);

    /*
    const prev = this.router.url;
    await this.router.navigate(['/'], { skipLocationChange: true });
    await this.router.navigate([prev], { skipLocationChange: true });
     */

    this.languageChanged$.next(setLang);
  }

  private setLang(setLang: string): boolean {
    if (this.currentLanguage === setLang) {
      return true;
    }

    if (!(Languages as unknown as string[]).includes(setLang)) {
      console.warn(`Trying to set unsupported language: ${setLang}`);
      return false;
    }
    // FIXME verify is in the list of supported languages
    console.debug(`Set lang: ${setLang}`);
    this.translate.use(setLang);
    this.translate.setDefaultLang(setLang);
    moment.locale(setLang);
    // Import locale for angular calendar
    // See https://stackoverflow.com/questions/61035621/how-do-i-dynamically-import-locales-in-angular-9
    import(
      /* webpackInclude: /(de|en|es|fr|it|nl|no|pl|pt-BR|pt|fi|sv|ko|ru|zh|ja)\.js/ */
      /* webpackMode: "lazy-once" */
      /* webpackChunkName: "i18n-base" */
      `@angular/common/locales/${setLang}`
      ).then(locale => {
      registerLocaleData(locale.default);
    });

    let languages = languageList.getNames(setLang);
    if (Object.keys(languages).length === 0) {
      // Register locale for language list (multi-language text)
      languageList.registerLocale(require(`@cospired/i18n-iso-languages/langs/${setLang}.json`));
      languages = languageList.getNames(setLang);
    }

    this.mapOfSupportedLanguages = mapDictionary(languages, (code: string, name: string) => ({ key: code, value: { code, name } }));

    this.listOfSupportedLanguages = Object.values(this.mapOfSupportedLanguages);
    this.sortSupportedLanguages();

    // Register locale for country list
    countries.registerLocale(require(`i18n-iso-countries/langs/${setLang}.json`));

    return true;
  }

  getCountryName(countryCode: string) {
    return countries.getName(countryCode, this.currentLanguage);
  }

  sortSupportedLanguages(languagesToPutFirst: string[] = []) {
    this.listOfSupportedLanguages
      .sort((la, lb) => {
        const aIsPrioritized = languagesToPutFirst.includes(la.code);
        const bIsPrioritized = languagesToPutFirst.includes(lb.code);
        if (aIsPrioritized && bIsPrioritized || !aIsPrioritized && !bIsPrioritized) {
          return la.name.localeCompare(lb.name);
        } else if (aIsPrioritized) {
          return -1;
        } else if (bIsPrioritized) {
          return 1;
        }
      });
  }

  mapOfSupportedLanguages: { [code: string]: LangCodeName };

  listOfSupportedLanguages: LangCodeName[];
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
