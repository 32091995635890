import { Entity, Id } from '@model/entity';
import { ZefCollection } from '../collections/zef-collection';
import { EntityWithDescription, NamedEntity, namedEntityFields } from '@model/entity-with-description';
import { SpotDaySituationIdAndStartDate } from '@model/spot-situation';
import { ListOfDenyIds, ListOfIds, ListOfShortNames } from '@api/queries';
import { EntityIdLinks, EntityLink, EntityLinkWithOwner, EntityShortNameLinks } from '@model/entity-link';
import Selector = Mongo.Selector;
import { WithEntities } from '@model/with-entities';
import Modifier = Mongo.Modifier;

export type EntitySelector = { _id: Id } | { shortName: string };
export type Searched<T> = (T & { score: number })

const id: keyof SpotDaySituationIdAndStartDate = '_id';
const startDate: keyof SpotDaySituationIdAndStartDate = 'startDate';

export function getProjection<T extends Partial<Entity>>(fields: (keyof T)[]): Record<string, 1> {
  return fields.reduce((acc: Record<string, 1>, field) => {
    acc[field as string] = 1;
    return acc;
  }, {});
}

export const namedEntityProjection = getProjection<NamedEntity>(namedEntityFields);

/**
 * Turn a tree of key = value into a flat list of key1.key2.key3 = value
 * @param obj object to flatten
 * @param extraKey prefix to add to the key
 * @param res
 */
export function flattenJSON(obj: Record<string, unknown>, extraKey = '', res: { [p: string]: unknown } = {}): {
  [p: string]: string
} {
  for (const key in obj) {
    if (obj[key] instanceof Date || typeof obj[key] !== 'object') {
      res[extraKey + key] = obj[key];
    } else {
      res = flattenJSON(obj[key] as Record<string, unknown>, `${extraKey}${key}.`, res);
    }
  }
  return res as { [p: string]: string };
}

export const selectSpotDaySituationIdAndStartDate = getProjection<SpotDaySituationIdAndStartDate>([id, startDate]);

export const getSearchAggregation = (text: string, otherProjection: { [p: string]: 1 }) => {
  return [
    { $match: { $text: { $search: text } } },
    {
      $project: {
        ...namedEntityProjection,
        ...otherProjection,
        score: { $meta: 'textScore' }
      }
    },
    { $match: { score: { $gt: 0.5 } } }
  ];
};

/**
 * Do a text search on the collection
 * @param collection
 * @param otherProjection
 */
export const search = <T extends NamedEntity>(collection: ZefCollection<Entity>, otherProjection: {
  [p: string]: 1
} = {}) => (text: string): Mongo.Cursor<Searched<T>> & { toArray: () => Promise<Searched<T>[]> } => {
  const aggregate = collection.getAggregateAsync<Searched<T>>();
  const aggregateQuery = getSearchAggregation(text, otherProjection);
  return aggregate(aggregateQuery);
};

export const getNamedEntitySelector = (input: ListOfIds | ListOfShortNames | ListOfDenyIds): {
  [p: string]: { $in: Id[] } | { $nin: Id[] }
} => {
  const entity_id: keyof NamedEntity = '_id';
  const entity_shortName: keyof NamedEntity = 'shortName';

  const ids = (input as ListOfIds).ids;
  const denyIds = (input as ListOfDenyIds).denyIds;
  const shortNames = (input as ListOfShortNames).shortNames;
  if (ids) {
    return ids.length > 0 ? { [entity_id]: { $in: ids } } : {};
  } else if (denyIds) {
    return { [entity_id]: { $nin: denyIds } };
  } else {
    return shortNames.length > 0 ? { [entity_shortName]: { $in: shortNames } } : {};
  }
};

export enum SanitySeverity {
  WARNING = 'WARNING',
  ERROR = 'ERROR'
}

/**
 * Try to locate by id or by shortname, depending on which one is present.
 * Also, flatten to make sure we don't look for both exclusively (as one should be enough).
 *
 * See utils.queries.spec.ts for examples
 *
 * @param entityLink the entity to locate
 * @param propertyName the name of the entity in the local collection. Example: 'entities.0' for the first one, 'entities' for any in the list
 * @param $elemMatch should try to match any element in the array of propertyName (ex: 'entities')? Or the specific property itself ('entities.1' or 'entity')?
 * @returns a selector to find the entity
 */
export function getSelectorOnProperty<T extends WithEntities | Entity>(entityLink: EntityLink | EntityLinkWithOwner, propertyName: string, $elemMatch: boolean = true): Selector<T> {
  return private_getSelectorOnProperty(entityLink, propertyName, $elemMatch);
}

/**
 * Try to locate by id or by shortname, depending on which one is present.
 * Also, flatten to make sure we don't look for both exclusively (as one should be enough).
 *
 * See utils.queries.spec.ts for examples
 *
 * @param entityLinksLocator the entities to locate
 * @param propertyName the name of the entity in the local collection. Example: 'entities.0' for the first one, 'entities' for any in the list
 * @param $elemMatch should try to match any element in the array of propertyName (ex: 'entities')? Or the specific property itself ('entities.1' or 'entity')?
 * @returns a selector to find the entity
 */
export function getSelectorOnPropertyMultiple<T extends WithEntities | Entity>(
    entityLinksLocator: EntityShortNameLinks | EntityIdLinks,
    propertyName: string,
    $elemMatch: boolean = true): Selector<T> {

  return private_getSelectorOnProperty(entityLinksLocator, propertyName, $elemMatch);
}

function private_getSelectorOnProperty<T>(
  locator: EntityShortNameLinks | EntityIdLinks | EntityLink | EntityLinkWithOwner,
  propertyName: string,
  $elemMatch: boolean
) {
  const entityLink_shortName: keyof EntityLink = 'shortName';
  const entityLink_id: keyof EntityLink = 'id';
  const entityLink_type: keyof EntityLink = 'type';

  const hasId = (<EntityIdLinks> locator).ids ?? (<EntityLink> locator).id;
  const hasShortname = (<EntityShortNameLinks> locator).shortNames ?? (<EntityLink> locator).shortName;

  const type = locator.type;
  const idSelector = (<EntityIdLinks> locator).ids ? { $in: (<EntityIdLinks> locator).ids } : (<EntityLink> locator).id;
  const shortNameSelector = (<EntityShortNameLinks> locator).shortNames ? { $in: (<EntityShortNameLinks> locator).shortNames } : (<EntityLink> locator).shortName;

  if ($elemMatch) {
    if (hasId && hasShortname) {
      return {
        [propertyName as keyof T]: {
          $elemMatch: {
            [entityLink_type]: type,
            $or: [
              { [entityLink_id]: idSelector },
              { [entityLink_shortName]: shortNameSelector }
            ]
          }
        }
      } as Selector<T>;
    } else if (hasId) {
      return {
        [propertyName as keyof T]: {
          $elemMatch: {
            [entityLink_type]: type,
            [entityLink_id]: idSelector
          }
        }
      } as Selector<T>;
    } else if (hasShortname) {
      return {
        [propertyName as keyof T]: {
          $elemMatch: {
            [entityLink_type]: type,
            [entityLink_shortName]: shortNameSelector
          }
        }
      } as Selector<T>;
    } else {
      throw new Error(`EntityLink must have either an id or a shortName: ${JSON.stringify(locator)}`);
    }
  } else {
    if (hasId && hasShortname) {
      return {
        [`${propertyName}.${entityLink_type}`]: type,
        $or: [
          { [`${propertyName}.${entityLink_id}`]: idSelector },
          { [`${propertyName}.${entityLink_shortName}`]: shortNameSelector }
        ]
      } as Selector<T>;
    } else if (hasId) {
      return {
        [`${propertyName}.${entityLink_type}`]: type,
        [`${propertyName}.${entityLink_id}`]: idSelector
      } as Selector<T>;
    } else if (hasShortname) {
      return {
        [`${propertyName}.${entityLink_type}`]: type,
        [`${propertyName}.${entityLink_shortName}`]: shortNameSelector
      } as Selector<T>;
    } else {
      throw new Error(`EntityLink must have either an id or a shortName: ${JSON.stringify(locator)}`);
    }
  }
}

/**
 * Get the selector to search for entities (T) that have a specific entityLink in their "entities" array property.
 * @param entityLink
 * @param index the specific index to look for in the "entities" array property. Null == search for any element in the array ($elemMatch)
 */
export function getSelectorOnEntities<T extends WithEntities>(entityLink: EntityLink | EntityLinkWithOwner, index: number | null = null): Selector<T> {
  return index === null
    ? getSelectorOnProperty<T>(entityLink, 'entities')
    : getSelectorOnProperty<T>(entityLink, `entities.${index}`, false);
}

/**
 * Get the selector to search for entities (T) that have a specific entityLink in their "entity" property.
 * @param entityLink
 * @param propertyName the name of the "entity" property in the local collection. Example: 'entity'
 */
export function getSelectorOnEntity<T extends Entity>(entityLink: EntityLink | EntityLinkWithOwner, propertyName: keyof T = 'entity' as keyof T): Selector<T> {
  return getSelectorOnProperty<T>(entityLink, propertyName as string, false);
}

export function getModifierSetEntityLink<T extends Entity>(entityLink: EntityLink | EntityLinkWithOwner, propertyName: keyof T = 'entity' as keyof T): Modifier<T> {
  const entityLink_shortName: keyof EntityLink = 'shortName';
  const entityLink_id: keyof EntityLink = 'id';
  const entityLink_type: keyof EntityLink = 'type';

  if (entityLink.id || entityLink.shortName) {
    return {
      ...{
        [`${propertyName}.${entityLink_type}`]: entityLink.type,
        [`${propertyName}.${entityLink_id}`]: entityLink.id,
        [`${propertyName}.${entityLink_shortName}`]: entityLink.shortName
      }
    };
  } else {
    throw new Error(`EntityLink must have either an id or a shortName: ${JSON.stringify(entityLink)}`);
  }
}

/**
 * Get the selector to search for entities (T) that match the entityLink
 * @param entityLink
 */
export function getSelectorFindEntity<T extends Entity | EntityWithDescription>(entityLink: EntityLink | EntityLinkWithOwner) {
  const entity_id: keyof Entity = '_id';
  const entity_shortName: keyof EntityWithDescription = 'shortName';

  if (entityLink.id && entityLink.shortName) {
    return {
      $or: [
        { [entity_id]: entityLink.id },
        { [entity_shortName]: entityLink.shortName }
      ]
    } as Selector<T>;
  } else if (entityLink.id) {
    return { [entity_id]: entityLink.id } as Selector<T>;
  } else if (entityLink.shortName) {
    return { [entity_shortName]: entityLink.shortName } as Selector<T>;
  } else {
    throw new Error(`EntityLink must have either an id or a shortName: ${JSON.stringify(entityLink)}`);
  }
}