import { ZefCollection } from './zef-collection';
import { EntityType } from '@model/entity-type';
import { GearModel } from '@model/gear/gear-model';

export const GearModels = new ZefCollection<GearModel<unknown>>(EntityType.gearModels)
  .setUniqueIndexes([
    ['shortName'],
    ['brandName', 'name', 'year']
  ]).setTextIndex([
    'brandName',
    'name',
    'version',
    'year'
  ]);
