import {Component, Input} from '@angular/core';
import {PropertyComponent} from '@components/input/property/property/property.component';

@Component({
  selector: 'zef-enum-multi-property',
  templateUrl: './enum-multi-property.component.html',
  styleUrls: ['./enum-multi-property.component.scss']
})
export class EnumMultiPropertyComponent<Enum extends Record<string, string>> extends PropertyComponent<Enum[]> {
  @Input() enumType: Enum;
  @Input() enumName: string;

}
