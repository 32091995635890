import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {LangCodeName, TranslateConfigService} from '@services/translate-config.service';
import {WithLanguageText} from '@model/schema-model';
import {capitalize} from 'lodash';
import {FieldLabelType} from '@pipes/fields/field-label.pipe';

@Component({
  selector: 'zef-multi-language-input',
  templateUrl: './multi-language-input.component.html',
  styleUrls: ['./multi-language-input.component.scss']
})
export class MultiLanguageInputComponent implements OnInit {
  @Input() title: string;
  @Input() placeholder: string;
  @Input() model: WithLanguageText[];
  @Input() small = false;

  @Output() modelChange = new EventEmitter<WithLanguageText[]>();


  @ViewChild('chooseLang') chooseLang;

  availableLangList: LangCodeName[] = [];
  langDict: { [code: string]: LangCodeName };

  capitalize = capitalize;
  FieldLabelType = FieldLabelType;

  constructor(private translateConfig: TranslateConfigService) {
  }

  ngOnInit() {
    this.langDict = this.translateConfig.mapOfSupportedLanguages;
    this.model = this.model?.length > 0
      ? this.model
      : [{
        lang: this.translateConfig.currentLanguage,
        text: ''
      }];
    this.refreshAvailableLangList();
  }

  add($event: any) {
    const newLang = $event.detail.value;
    this.modelChange.emit([...this.model, { lang: newLang, text: '' }]);
    this.refreshAvailableLangList();
  }

  update(index: number, value: string) {
    this.model[index].text = value;
    this.modelChange.emit(this.model);
  }

  private refreshAvailableLangList() {
    this.availableLangList = this.translateConfig.listOfSupportedLanguages
      ?.filter(l => !this.model.map(t => t.lang).includes(l.code));
  }

}
