import {Place, PlaceProperty, Properties} from './place';
import {Polygon} from 'geojson';
import {EntityType} from './entity-type';
import {EntityLink} from './entity-link';

export enum SpotTypeValue {
  OPEN_SEA,
  LAKE,
  RIVER,
  SALTED_LAKE
}

export type SpotType = PlaceProperty<SpotTypeValue>;

export enum DepthValue {
  TWIN_TIP_ONLY,
  FIN_ONLY,
  MAN_HEIGHT,
  DEEP
}

export type Depth = PlaceProperty<DepthValue>;

export enum Danger {
  CURRENT,
  BOATS,
  JETTY,
  BOTTOM
}

export type Dangers = PlaceProperty<Danger[]>;

export enum EntryLevelValue {
  BEGINNERS,
  EASY,
  EXPERIENCED,
  HARD
}

export type EntryLevel = PlaceProperty<EntryLevelValue>;

export interface SpotProperties extends Properties {
  spotType?: SpotType;
  depth?: Depth;
  dangers?: Dangers;
  entryLevel?: EntryLevel;
  // TODO waves, water conditions
}

export interface Spot extends Place {
  location: Polygon;
  properties: SpotProperties;
}

export const getSpotEntity = (spot: Pick<Spot, '_id' | 'shortName'>): EntityLink => ({
  type: EntityType.spots,
  id: spot._id,
  shortName: spot.shortName
});
