import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {PipesModule} from '@pipes/pipes.module';
import {LeafletDirective, LeafletModule} from '@asymmetrik/ngx-leaflet';
import {TranslateModule} from '@ngx-translate/core';
import {NgxLeafletFullscreenModule} from '@runette/ngx-leaflet-fullscreen';
import {LeafletDrawModule} from '@asymmetrik/ngx-leaflet-draw';
import {MapComponent} from "@components/map/map.component";
import {LeafletMarkerClusterModule} from "@asymmetrik/ngx-leaflet-markercluster";

@NgModule({
  declarations: [
    MapComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    PipesModule,
    LeafletModule,
    LeafletDrawModule,
    NgxLeafletFullscreenModule, // Note that this is needed, even though the leaflet-fullscreen-control component is not used
    TranslateModule,
    LeafletMarkerClusterModule,
  ],
  exports: [
    LeafletModule,
    LeafletDrawModule,
    LeafletMarkerClusterModule,
    NgxLeafletFullscreenModule,
    MapComponent
  ],
  providers: [LeafletDirective]
})
export class MapModule {
}
