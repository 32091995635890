<ion-header>
  <ion-toolbar>
    <ion-title>{{ (isUpdate ? 'SPOT_LAUNCH.UPDATE.TITLE' : 'SPOT_LAUNCH.CREATE.TITLE') | translate : { name: form?.fields.name.value } }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form *ngIf='form' [formGroup]='formGroup' (keydown.meta.Enter)='submit()'>
    <ion-list>

      <zef-abstract [form]='form' [field]='form.fields.name'></zef-abstract>
      <zef-edit-aliases [field]='form.fields.aliases' [form]='form'></zef-edit-aliases>

      <ion-item>
        <zef-spot-launch-map
          [edit]='true'
          [spot]='spot'
          [spotLaunches]='spot.launches'
          [spotLaunchLocation]='form.fields.location.value'
          [options]='{ heightPx: 300, fullScreen: true }'
          (edited)='locationEdited($event)'
        ></zef-spot-launch-map>
      </ion-item>

      <zef-multi-language-input
        title='{{form.fields.abstract | fieldLabel : FieldLabelType.TITLE}}'
        placeholder='{{form.fields.abstract | fieldLabel: FieldLabelType.PLACEHOLDER}}'
        [(model)]='form.fields.abstract.value'
      ></zef-multi-language-input>

      <zef-multi-language-input
        title='{{form.fields.description | fieldLabel : FieldLabelType.TITLE}}'
        placeholder='{{form.fields.description | fieldLabel : FieldLabelType.PLACEHOLDER}}'
        [(model)]='form.fields.description.value'
      ></zef-multi-language-input>

      <zef-multi-language-input
        title='{{form.fields.howToGetThere | fieldLabel : FieldLabelType.TITLE}}'
        placeholder='{{form.fields.howToGetThere | fieldLabel : FieldLabelType.PLACEHOLDER}}'
        [(model)]='form.fields.howToGetThere.value'
      ></zef-multi-language-input>

      <zef-bool-property [form]='form' [field]='form.fields.publicAccess'></zef-bool-property>
      <zef-bool-property [form]='form' [field]='form.fields.freeParking'></zef-bool-property>
      <zef-bool-property [form]='form' [field]='form.fields.suitableForKite'></zef-bool-property>
      <zef-bool-property [form]='form' [field]='form.fields.hasShower'></zef-bool-property>
      <zef-bool-property [form]='form' [field]='form.fields.hasToilets'></zef-bool-property>
      <zef-enum-multi-property [form]='form'
                               [field]='form.fields.gearForRent'
                               enumName='Activity'
                               [enumType]='Activity'
      ></zef-enum-multi-property>
      <zef-enum-property [form]='form'
                         [field]='form.fields.bottomType'
                         enumName='BottomTypeValue'
                         [enumType]='BottomTypeValue'
      ></zef-enum-property>

      <ion-item lines='none'>
        <ion-label class='input' color='dark'>
          {{ "SPOT_LAUNCH.LINKS" | translate }} <!-- FIXME should use labelPipe -->
        </ion-label>
      </ion-item>

      <zef-edit-links [form]='form' [field]='form.fields.links'></zef-edit-links>
    </ion-list>
  </form>
</ion-content>

<ion-footer>
  <ion-toolbar [popper]='popperAllErrors'
               [popperTrigger]='popperTrigger'
               [popperDisabled]='formGroup.valid'
               (popperOnShown)='onPopperShown()'>
    <ion-buttons slot='start' class="secondary">
      <ion-button (click)='cancel()'>
        {{ 'SHARED.CANCEL' | translate }}
      </ion-button>
    </ion-buttons>
    <ion-buttons slot='end'>
      <ion-button (click)='submit()' [disabled]='disabledButton || !formGroup.valid' color='primary'>
        {{ (isUpdate ? 'SPOT_LAUNCH.UPDATE.OK' : 'SPOT_LAUNCH.CREATE.OK') | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>

<popper-content #popperAllErrors>
  <p *ngFor='let error of allErrors'>{{error}}</p>
</popper-content>

