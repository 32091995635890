import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {capitalize} from 'lodash';
import {getEnumValues} from '@core/utils';

@Component({
  selector: 'zef-enum-input',
  templateUrl: './enum-input.component.html',
  styleUrls: ['./enum-input.component.scss']
})
export class EnumInputComponent<Enum extends Record<string, string>> implements OnInit {
  @Input() set enumType(type: Enum) {
    /**
     * 0: "OPEN_SEA"
     * 1: "LAKE"
     * 2: "RIVER"
     * 3: "SALTED_LAKE"
     *
     * ======= keep only this part
     * LAKE: 1
     * OPEN_SEA: 0
     * RIVER: 2
     * SALTED_LAKE: 3
     */
    this.enumDict = getEnumValues(type);
  }

  @Input() allowUnknown = false;
  @Input() enumName: string;
  @Input() showLabel = false
  @Input() isRequired = false
  @Input() model: Enum;
  @Output() modelChange = new EventEmitter<Enum>();

  @ViewChild('template', { static: true }) template;

  capitalize = capitalize;
  keys = Object.keys;

  enumDict: { [key: string]: Enum };

  constructor(private viewContainerRef: ViewContainerRef) {
  }

  ngOnInit() {
    this.viewContainerRef.createEmbeddedView(this.template);
  }
}
