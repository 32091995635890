import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {toDict} from '@core/utils';

@Pipe({
  name: 'smartLabel'
})
export class SmartLabelPipe implements PipeTransform {
  constructor(private translate: TranslateService) {
  }

  /**
   * Takes the provided label key from int8 files,
   * replaces some names with just their name, and then split the text to extract these fake mappings
   * @param key
   * @param namesToMap
   * @param otherMappings
   * @param separator
   */
  transform(key: string, namesToMap: string[], otherMappings: { [key: string]: string }, separator = '|') {
    const fakeMappings = toDict(namesToMap, val => val, val => `${separator}${val}${separator}`);
    return this.translate.instant(key, { ...fakeMappings, ...otherMappings }).split(separator);
  }
}
