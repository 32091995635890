import {PageInfo} from "@core/routing/pages-list";
import {icons} from "../icons";

export enum AccessControl {
  LOGGED_ONLY,
  UNLOGGED_ONLY,
  OPEN,
  LIMITED
}

export interface TabPageInfo extends PageInfo {
  icon: keyof typeof icons;
  accessControl: AccessControl;
}

export const isValidPage = (p: TabPageInfo, isLoggedIn: boolean) =>
  p.accessControl === AccessControl.OPEN ||
  (p.accessControl !== AccessControl.UNLOGGED_ONLY && isLoggedIn) ||
  (p.accessControl !== AccessControl.LOGGED_ONLY && !isLoggedIn);
