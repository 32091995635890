import {Form, FormField} from '@utils/form';
import {FormGroup} from '@angular/forms';
import { Link, SocialLink, WithLanguageText } from '@model/schema-model';
import {EntityType} from '@model/entity-type';
import { LinkType, SocialLinkType } from '@model/link';
import {schemas} from '@model/schemas';

type LinkDefaults = Partial<Link>;

export class SingleLinkForm extends Form {
  constructor(formGroup: FormGroup, private defaults: LinkDefaults) {
    super(formGroup, schemas.Link, EntityType.links);
  }

  fields = {
    type: new FormField<LinkType>(this.schema, this.vf, this.group, this.defaults.type),
    url: new FormField<string>(this.schema, this.vf, this.group, this.defaults.url),
    name: new FormField<string>(this.schema, this.vf, this.group, this.defaults.name),
    description: new FormField<WithLanguageText[]>(this.schema, this.vf, this.group, this.defaults.description)
  };
}

type SocialLinkDefaults = Partial<SocialLink>;
export class SingleSocialLinkForm extends Form {
  constructor(formGroup: FormGroup, private defaults: SocialLinkDefaults) {
    super(formGroup, schemas.SocialLink, EntityType.links);
  }

  fields = {
    type: new FormField<SocialLinkType>(this.schema, this.vf, this.group, this.defaults.type),
    url: new FormField<string>(this.schema, this.vf, this.group, this.defaults.url),
  };
}
