import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InviteToLoginComponent} from '@components/invite-to-login/invite-to-login.component';
import {TranslateModule} from '@ngx-translate/core';
import {IonicModule} from '@ionic/angular';

@NgModule({
  declarations: [InviteToLoginComponent],
  imports: [CommonModule, TranslateModule, IonicModule],
  exports: [InviteToLoginComponent]
})
export class InviteToLoginModule {
}
