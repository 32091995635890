import {Id} from '@model/entity';

export const unique = (value: any, index: number, self: any[]) => {
  return self.indexOf(value) === index;
};

export const meterPerSecToKnots = (speed: number) => speed * 1.94384;

export const kmPerHourToKnots = (speed: number) => speed * 0.539957;

export const notUndefined = <T>(x: T | undefined): x is T => x !== undefined;

export const nullOrUndefined = (x: any) => x === undefined || x === null;

export const objectMap = <T extends Object, V, W = any>(
  obj: T,
  fn: (value: V, key: keyof T, index: number) => W
): { [key in keyof T]: W } =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value], index) => {
      const keyOf = key as keyof T;
      return [keyOf, fn(value, keyOf, index)];
    })
  ) as { [key in keyof T]: W };

/**
 * Turn an array into a dictionary, providing the function to build the key
 * @param array
 * @param keyFn
 * @param valueFn
 */
export const toDict = <T, U = T>(array: T[], keyFn: (value: T, index: number) => string,
                                 valueFn: (t: T, index: number) => U = t => t as unknown as U): { [id: string]: U } =>
  array.reduce((accumulator: { [id: string]: U }, current: T, index: number) => {
    accumulator[keyFn(current, index)] = valueFn(current, index);
    return accumulator;
  }, {});

/**
 * Turn an array of Entities to a dictionary of entities per _id
 * @param array
 */
export const entitiesToDict = <T extends { _id: Id }>(array: T[]) => toDict(array, t => t._id);

/**
 * Group elements of the array that have the same key into a dictionary
 * @param array
 * @param keyFn
 */
export const groupBy = <T>(array: T[], keyFn: (t: T) => string): { [id: string]: T[] } =>
  array.reduce((accumulator: { [id: string]: T[] }, current: T) => {
    const key = keyFn(current);
    accumulator[key] = accumulator[key] ?? [];
    accumulator[key].push(current);
    return accumulator;
  }, {});

/**
 * Turn an array of keys into a dictionary, providing function to build the value
 * @param array
 * @param mapFn
 */
export const keysToDict = <V>(array: string[], mapFn: (key: string) => V) =>
  array.reduce((accumulator: { [id: string]: V }, currentKey: string) => {
    accumulator[currentKey] = mapFn(currentKey);
    return accumulator;
  }, {});

/**
 * Map a dictionary into another dictionary, providing the method to map both the keys and the values
 * @param dictionary
 * @param mapFn
 * @param filterFn
 */
export const mapDictionary = <T, V>(
  dictionary: { [key: string]: T },
  mapFn: (key: string, value: T) => { key: string, value: V },
  filterFn: (key: string, value: T) => boolean = () => true
): { [key: string]: V } =>
  Object.keys(dictionary).reduce((accumulator: { [key: string]: V }, key: string) => {
    const value = dictionary[key];
    if (filterFn(key, value)) {
      const tuple = mapFn(key, value);
      accumulator[tuple.key] = tuple.value;
    }
    return accumulator;
  }, {});

export const filterDictionary = <V>(dictionary: { [key: string]: V }, filterFn: (key: string, value: V) => boolean):  { [key: string]: V } => {
    return Object.keys(dictionary).reduce((accumulator: { [key: string]: V }, key: string) => {
        const value = dictionary[key];
        if (filterFn(key, value)) {
            accumulator[key] = value;
        }
        return accumulator;
    }, {});
}

export const getEnumValues = <Enum extends Record<string, string>>(type: Enum) => mapDictionary({...type}, (key, value) => ({
  key,
  value: <Enum><unknown>value
}), (key) => isNaN(parseInt(key)))

export const getRandomInt = (max: number) => Math.floor(Math.random() * max);

export const toCamelCase = (value: string) => value.toLowerCase().replace(/([-_][a-z])/g, group =>
  group
    .toUpperCase()
    .replace('-', '')
    .replace('_', '')
);

export const toSnakeDashCase = (value: string) => value.replace(/([A-Z])/g, (val) => `-${val.toLowerCase()}`)

export function cutText(text: string | undefined, length: number, ellipsis = '…'): string | undefined {
  return text?.length ?? 0 > length ? text?.substring(0, length) + ellipsis : text;
}
