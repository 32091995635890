<ion-item (click)='click()' class='item-interactive transparent'>
  <ion-grid>
    <ion-row>
      <ion-col size='2'>
        <ion-label class='input'>
          {{ formField | fieldLabel : FieldLabelType.TITLE }}
        </ion-label>
      </ion-col>
      <ion-col size='10' class='values'>
        <div *ngIf='isEmpty; else content' class='select-placeholder'>
          {{ formField | fieldLabel : FieldLabelType.PLACEHOLDER }}
        </div>
        <ng-template #content>
          <ng-content></ng-content>
        </ng-template>
      </ion-col>
    </ion-row>
  </ion-grid>
  <ion-icon name='caret-down-outline' slot='end' class='select'></ion-icon>
</ion-item>
<zef-error-line [field]='formField'></zef-error-line>
