<ion-header>
  <ion-toolbar>
    <ion-title>
      {{ 'SESSION.START.TITLE' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <form [formGroup]='formGroup' (keydown.meta.Enter)='submit()'>
    <ion-list>
      <zef-spot-select [form]='form' [spotField]='form.fields.spotId'
                       [spotLaunchField]='form.fields.spotLaunchId'></zef-spot-select>

      <zef-from-select [form]='form' [fromField]='form.fields.fromInUserTZ'></zef-from-select>

      <zef-edit-situation-gear
        *ngIf='riderQuiver$|async as riderQuiver'
        [form]='form'
        [formField]='form.fields.quiver'
        [riderQuiver]='riderQuiver'
      ></zef-edit-situation-gear>

      <zef-activity-select [form]='form'
                           [activitiesField]='form.fields.activities'
                           [quiverField]='form.fields.quiver'
      ></zef-activity-select>

      <zef-with-riders-select [form]='form'
                              [formField]='form.fields.withRiders'></zef-with-riders-select>
    </ion-list>
  </form>
</ion-content>

<ion-footer>
  <ion-toolbar [popper]='popperAllErrors'
               [popperTrigger]='popperTrigger'
               [popperDisabled]='formGroup.valid'
               (popperOnShown)='onPopperShown()'>
    <ion-buttons slot='start' class="secondary">
      <ion-button (click)='cancel()'>
        {{ 'SHARED.CANCEL' | translate }}
      </ion-button>
    </ion-buttons>
    <ion-buttons slot='end'>
      <ion-button (click)='submit()' [disabled]='disabledButton || !formGroup.valid' color='primary'>
        {{ 'SESSION.START.OK' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>

<popper-content #popperAllErrors>
  <p *ngFor='let error of allErrors'>{{error}}</p>
</popper-content>
