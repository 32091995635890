<ng-template #fab let-side="side">
    <ion-fab-button (click)="toggleBackdrop()" #addMenu>
        <ion-icon name='add'></ion-icon>
    </ion-fab-button>
    <ion-fab-list [side]="side">
        <ion-fab-button title='{{ "RIDER.BUTTONS.START_SESSION" | translate }}' color='danger'
                        (click)='hideBackdrop() && actionMenu.startSession()'>
            <ng-icon name='ion-speedometer'></ng-icon>
        </ion-fab-button>
        <ion-fab-button title='{{ "RIDER.BUTTONS.OBSERVE" | translate }}' color='danger'
                        (click)='hideBackdrop() && actionMenu.addObservation()'>
            <ng-icon name='ion-eye'></ng-icon>
        </ion-fab-button>
        <ion-fab-button title='{{ "RIDER.BUTTONS.BOOK" | translate }}'
                        (click)='hideBackdrop() && actionMenu.addBooking()'>
            <ng-icon name='ion-bookmark'></ng-icon>
        </ion-fab-button>
        <ion-fab-button title='{{ "RIDER.BUTTONS.CREATE_SESSION" | translate }}'
                        (click)='hideBackdrop() && actionMenu.recordOldSession()'>
            <ng-icon name='ion-calendar-number-outline'></ng-icon>
        </ion-fab-button>
        <ion-fab-button title='{{ "BUTTONS.UPLOAD_GPX" | translate }}'
                        (click)='hideBackdrop() && actionMenu.uploadGPX()'>
            <ng-icon name='ion-navigate-circle-outline'></ng-icon>
        </ion-fab-button>
    </ion-fab-list>
</ng-template>

<ion-fab *ngIf='tabBarService.isDesktop && auth.isLoggedIn' horizontal='end' vertical='top' slot='fixed' edge
         id="fab-menu">
    <ng-container *ngTemplateOutlet="fab;context:{side: 'bottom'}"></ng-container>
</ion-fab>

<ion-split-pane contentId='main-content' (ionSplitPaneVisible)='onSplitPaneChange($event)'>
    <ion-menu contentId='main-content' type='overlay' side='end'>
        <ion-content>
            <ion-backdrop [class.visible]="showBackdrop"></ion-backdrop>
            <ion-list>
                <ion-menu-toggle autoHide='false'
                                 *ngFor='let p of menuPages'>
                    <ion-item *ngIf='p.path === loginPageInfo.path || p.path === logoutPageInfo.path; else normalLink'
                              (click)='openLogin()'
                              lines='none' detail='false' button>
                        <zef-tab-icon [tabPageInfo]='p' slot='start'></zef-tab-icon>
                        <ion-label>{{ p.label | translate }}
                        </ion-label>
                    </ion-item>
                    <ng-template #normalLink>
                        <ion-item [routerLink]='[p.fullPath]'
                                  routerLinkActive='selected'
                                  routerDirection='root'
                                  lines='none' detail='false'>
                            <zef-tab-icon [tabPageInfo]='p' slot='start'></zef-tab-icon>
                            <ion-label>{{ p.label | translate }}
                                <zef-tab-badge [tabPageInfo]='p'
                                               [showBadge]='p.label === notificationsPageInfo.label'></zef-tab-badge>
                            </ion-label>
                        </ion-item>
                    </ng-template>
                </ion-menu-toggle>
            </ion-list>
        </ion-content>
    </ion-menu>
    <ion-content id='main-content'>
        <ion-backdrop [class.visible]="showBackdrop"></ion-backdrop>
        <ion-router-outlet></ion-router-outlet>
        <ion-fab *ngIf='!tabBarService.isDesktop && auth.isLoggedIn' vertical='bottom' horizontal='center' slot='fixed'
                 edge id="fab-tabs">
            <ng-container *ngTemplateOutlet="fab;context:{side: 'top'}"></ng-container>
        </ion-fab>
        <ion-tabs>
            <ion-tab-bar #tabBar slot='bottom'>
                <ng-container *ngFor='let p of tabBarPages; let i = index'>
                    <ion-tab-button
                            *ngIf='p.path === loginPageInfo.path || p.path === logoutPageInfo.path; else normalLink'
                            (click)='openLogin()'>
                        <ng-template [ngTemplateOutlet]='tab'></ng-template>
                    </ion-tab-button>
                    <ng-template #normalLink>
                        <ion-tab-button [tab]='p.path'>
                            <ng-template [ngTemplateOutlet]='tab'></ng-template>
                        </ion-tab-button>
                    </ng-template>
                    <ng-template #tab>
                        <zef-tab-icon [tabPageInfo]='p'></zef-tab-icon>
                        <zef-tab-badge [tabPageInfo]='p'
                                       [showBadge]='p.label === notificationsPageInfo.label'></zef-tab-badge>
                        <ion-label>{{ p.label | translate }}</ion-label>
                    </ng-template>
                    <ng-container *ngIf="auth.isLoggedIn && i === (MAX_TABS + 1) / 2 - 1">
                        <ion-tab-button [disabled]="true">
                        </ion-tab-button>
                    </ng-container>
                </ng-container>
                <ion-tab-button (click)='openMenu()' *ngIf='showMenu'>
                    <ion-icon name='menu-outline'></ion-icon>
                    <zef-tab-badge [showBadge]='true'></zef-tab-badge>
                </ion-tab-button>
            </ion-tab-bar>
        </ion-tabs>
    </ion-content>
</ion-split-pane>
