import {Entity} from './entity';
import {SpotSituationInThePast} from './spot-situation';
import {Quiver} from './gear/quiver';
import {EntityType} from './entity-type';
import {EntityLinkWithOwner} from './entity-link';

export interface Observation extends SpotSituationInThePast, Entity {
  measuredWindKt?: number;
  quiver?: Quiver;
}

export const getObservationEntity = (observation: Pick<Observation, '_id' | 'riderId'>): EntityLinkWithOwner => ({
  type: EntityType.observations,
  id: observation._id,
  entityOwnerId: observation.riderId
});
