import {BooleanProperty, Place, PlaceProperty, Properties} from './place';
import {Activity} from './activity';
import {EntityLink} from './entity-link';
import {EntityType} from './entity-type';
import {Point} from 'geojson';
import {MultiLanguageText} from './multi-language-text';

export type GearForRent = PlaceProperty<Activity[]>;

export type BottomType = PlaceProperty<BottomTypeValue>;

export enum BottomTypeValue {
  sand = 'sand',
  pebbles = 'pebbles',
  rocks = 'rocks',
  concrete = 'concrete'
}

export interface SpotLaunchProperties extends Properties {
  publicAccess?: BooleanProperty;
  freeParking?: BooleanProperty;
  hasToilets?: BooleanProperty;
  hasShower?: BooleanProperty;
  suitableForKite?: BooleanProperty;
  gearForRent?: GearForRent;
  bottomType?: BottomType;
  // TODO has rescue service (or should it be a POI?)
}

export interface SpotLaunch extends Place {
  location: Point;
  spotId: string;
  properties: SpotLaunchProperties;
  howToGetThere?: MultiLanguageText;
}

export const getSpotLaunchEntity = (spotLaunch: Pick<SpotLaunch, '_id' | 'shortName'>): EntityLink => ({
  type: EntityType.spotLaunches,
  id: spotLaunch._id,
  shortName: spotLaunch.shortName
});
