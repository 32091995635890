import {Injectable} from '@angular/core';
import {bindCallback, Observable, of} from 'rxjs';
import {Id} from '@model/entity';
import {LoginUser, UserToCreate, UserToRegister} from '@model/schema-model';
import {IAuthService} from '@services/i-auth.service';
import {ICachedRidersService} from '@services/i-cached-riders.service';
import {debounceTime, map, switchMap, tap} from 'rxjs/operators';
import {RiderItem} from '@api/queries';
import {TranslateConfigService} from '@services/translate-config.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {Commands} from '@services/meteor/commands';
import {schemas} from '@model/schemas';
import {filterNullish} from '@utils/rxjs.utils';
import {ShortNames} from '@model/short-names';
import {MeteorNotificationService} from "@services/meteor/meteor-notification.service";
import {EmailNotVerifiedError, ErrorType} from "@api/errors";

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class MeteorAuthService implements IAuthService {
  loggedRider$: Observable<RiderItem | undefined>;
  isAdmin$: Observable<boolean>;

  constructor(
    private commands: Commands,
    private translateConfig: TranslateConfigService,
    private riders: ICachedRidersService,
    private notificationsService: MeteorNotificationService
  ) {
    // For some reason (this is undefined) doesn't work
    // const onLogout = bindCallback<Id>(Accounts.onLogout)();
    // const onLogin = bindCallback<Id>(Accounts.onLogin)();
    // this.isLogged$ = merge(onLogin.pipe(mapTo(true)), onLogout.pipe(mapTo(false)));

    const observable: Observable<boolean> = new Observable(subscriber => {
      Accounts.onLogin(() => subscriber.next(true));
      Accounts.onLogout(() => subscriber.next(false));

      // Generate a first value (ex: on session restore)
      subscriber.next(Accounts.userId() !== undefined);
    });

    this.loggedRider$ = observable.pipe(
      switchMap(isLogged => isLogged ? this.riders.getEntityById$(this.userId) : of(undefined)),
      debounceTime(500)
    );

    // Automatically set language from user
    this.loggedRider$.pipe(
      filterNullish(),
      tap(async rider => {
        if (rider !== undefined) {
          // Switch to user language
          await this.translateConfig.setLanguage(rider.language);

          // Sort languages to make the spoken ones at the top
          this.translateConfig.sortSupportedLanguages(rider.spokenLanguages);

          // Listen to notifications
          this.notificationsService.subscribeToPublications();
        } else {
          // Default language
          await this.translateConfig.setLoggedOutLanguage();

          // Stop listening to notifications
          this.notificationsService.unsubscribeFromPublications();
        }
      }),
      untilDestroyed(this)
    ).subscribe();

    this.isAdmin$ = this.loggedRider$.pipe(map(user =>
        // @ts-ignore FIXME quick hack to limit access to spot edition
        user?.isAdmin
      ),
      untilDestroyed(this)
    );
  }

  register$(user: UserToCreate): Observable<Id> {
    schemas.UserToCreate.validate(user);

    return new Observable<UserToRegister>((subscriber) => {
      // Note: use this method as it will not send the password clear
      const fullUser = {
        email: user.email,
        password: user.password,
        username: ShortNames.rider({name: user.name})
      };
      Accounts.createUser(fullUser, (error) => {
        if ((error as Meteor.Error).error === ErrorType.EmailNotVerified) {
          // This is expected. For some reason Meteor still tries to log the user in immediately
          const _id = (error as EmailNotVerifiedError).details;

          // Remove password to not send it over the wire
          const {password, confirm, ...rest} = user;

          subscriber.next({...rest, _id});
        } else {
          subscriber.error(error);
        }
      });
    }).pipe(
      switchMap(user => this.commands.registerUser.call$(user).pipe(
        map(() => user._id))
      ),
      // Remove rider from cache because it will exist, but without all the "registered" data (only id and created date)
      tap((id) => this.riders.removeEntityById(id))
    );

  }

  login$({email, password}: Pick<LoginUser, 'email' | 'password'>): Observable<void> {
    return bindCallback(Meteor.loginWithPassword, (error: Error) => {
      if (error) {
        throw error;
      }
    })({email}, password);
  }

  get isLoggedIn(): boolean {
    return !!Meteor.userId();
  }

  get userId(): Id | undefined {
    return Meteor.userId();
  }

  logout$(): Observable<void> {
    return bindCallback(Meteor.logout, (error: Error) => {
      if (error) {
        throw error;
      }
    })();
  }
}
