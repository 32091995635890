<ion-header>
  <ion-toolbar>
    <ion-title>
      {{ 'BOOKING.CREATE.TITLE' | translate }}
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]='formGroup' (keydown.meta.Enter)='submit()'>
    <ion-list>
      <zef-spot-select [form]='form' [spotField]='form.fields.spotId'
                       [spotLaunchField]='form.fields.spotLaunchId'></zef-spot-select>

      <zef-from-select [form]='form' [fromField]='form.fields.fromInUserTZ'></zef-from-select>

      <zef-to-select [form]='form' [toField]='form.fields.toInUserTZ' [min]='form.minTo'></zef-to-select>

      <zef-activity-select [form]='form'
                           [activitiesField]='form.fields.activities'
      ></zef-activity-select>

      <zef-abstract [form]='form' [field]='form.fields.abstract'></zef-abstract>
    </ion-list>
  </form>
</ion-content>

<ion-footer>
  <ion-toolbar [popper]='popperAllErrors'
               [popperTrigger]='popperTrigger'
               [popperDisabled]='formGroup.valid'
               (popperOnShown)='onPopperShown()'>
    <ion-buttons slot='start' class="secondary">
      <ion-button (click)='cancel()'>
        {{ 'SHARED.CANCEL' | translate }}
      </ion-button>
    </ion-buttons>
    <ion-buttons slot='end'>
      <ion-button color='primary' (click)='submit()' [disabled]='disabledButton || !formGroup.valid'>
        {{ 'BOOKING.CREATE.OK' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>

<popper-content #popperAllErrors>
  <p *ngFor='let error of allErrors'>{{error}}</p>
</popper-content>
