import {Id} from '@model/entity';
import {Observable} from 'rxjs';
import {RiderItem} from '@api/queries';

export abstract class ICachedRidersService {
  abstract getEntitiesByIds$(ids: Id[]): Observable<RiderItem[]>;

  abstract getEntityById$(_id: Id): Observable<RiderItem>;

  abstract getEntityByShortName$(shortName: string): Observable<RiderItem>;

  abstract removeEntityById(_id: Id): void;

  // TODO this should not be used anymore
  abstract getAllEntities$(): Observable<RiderItem[]>;
}
