import {Queries, SpotItem} from '@api/queries';
import {Id} from '@model/entity';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {MeteorCache} from '@services/meteor/meteor.cache';
import {ICachedSpotsService} from '@services/i-cached-spots.service';
import {first} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MeteorCachedSpotsService extends ICachedSpotsService {
  private meteorCache: MeteorCache<SpotItem>;

  constructor(private queries: Queries) {
    super();
    this.meteorCache = new MeteorCache(this.queries.getSpotItems);
  }

  getEntitiesByIds$(ids: Id[]): Observable<SpotItem[]> {
    return this.meteorCache.getEntitiesByIds$(ids);
  }

  getEntitiesByShortnames$(shortnames: string[]): Observable<SpotItem[]> {
    return this.meteorCache.getEntitiesByShortnames$(shortnames);
  }

  getEntityById$(_id: Id): Observable<SpotItem> {
    return this.meteorCache.getEntityById$(_id).pipe(first());
  }

  getEntityByShortName$(shortName: string): Observable<SpotItem> {
    return this.meteorCache.getEntityByShortName$(shortName);
  }

  getAllEntities$(): Observable<SpotItem[]> {
    return this.meteorCache.getAllEntities$();
  }
}

