import {Form, FormField} from '@utils/form';
import {FormGroup} from '@angular/forms';
import {schemas} from '@model/schemas';
import {EntityType} from '@model/entity-type';
import {ProductSubType, ProductType} from '@model/gear/product';

export class GearItemTypeForm extends Form {
  constructor(formGroup: FormGroup, public parentEntityType: EntityType) {
    super(formGroup, schemas.QuiverItemType, parentEntityType);
  }

  fields = {
    type: new FormField<ProductType>(this.schema, this.vf, this.group),
    subType: new FormField<ProductSubType>(this.schema, this.vf, this.group)
  };
}
