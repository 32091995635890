export interface PageInfo {
  path: string;
  label: string;
  key: string;
}

const id = 'id';

const shortName = 'shortName';
export default interface WithShortName {
  shortName: string
}

export const aboutBasePageInfo: PageInfo = {
  key: 'about',
  path: 'about',
  label: 'ABOUT.TITLE',
};

export const loginBasePageInfo: PageInfo = {
  key: 'login',
  path: 'login',
  label: 'LOGIN.IN.TITLE',
}

export const logoutBasePageInfo: PageInfo = {
  key: 'logout',
  path: 'logout',
  label: 'LOGIN.OUT.TITLE',
}

export const forecastBasePageInfo: PageInfo = {
  key: 'forecast',
  path: 'forecast',
  label: 'forecast',
}

export const gearBrandsBasePageInfo: PageInfo = {
  key: 'gearBrands',
  path: 'brands',
  label: 'BRANDS.TITLE',
}

export const gearBasePageInfo: PageInfo = {
  key: 'gear',
  path: 'gear',
  label: 'GEAR.TITLE',
}

export const helpBasePageInfo: PageInfo = {
  key: 'help',
  path: 'help',
  label: 'HELP.TITLE',
}

export const newsBasePageInfo: PageInfo = {
  key: 'news',
  path: 'news',
  label: 'NEWS.TITLE',
}

export const notificationsBasePageInfo: PageInfo = {
  key: 'notifications',
  path: 'notifications',
  label: 'NOTIFICATIONS.TITLE',
}

export const registerBasePageInfo: PageInfo & {tokenParam: string} = {
  key: 'register',
  path: 'register',
  label: 'register',
  tokenParam: 'verify-token',
}

export const sessionsBasePageInfo: PageInfo = {
  key: 'sessions',
  path: 'sessions',
  label: 'sessions',
}

export const sessionBasePageInfo: PageInfo & {id: string} = {
  key: 'session',
  id,
  path: `:${id}`,
  label: 'session',
}
export const brandBasePageInfo: PageInfo & WithShortName = {
  key: 'brand',
  shortName,
  path: `:${shortName}`,
  label: 'brand',
}
export const gearItemBasePageInfo: PageInfo & {id: string} = {
  key: 'gearItem',
  id,
  path: `:${id}`,
  label: 'gearModel',
}

const brandAndGearModelShortName = 'brandAndGearModelShortName';
export const gearModelBasePageInfo: PageInfo & {brandAndGearModelShortName: string} = {
  key: 'gearModel',
  brandAndGearModelShortName,
  path: `:${brandAndGearModelShortName}`,
  label: 'gearModel',
}

export const search = 'q'
export const gearSearchBasePageInfo: PageInfo & {search: string} = {
  key: 'gearSearch',
  search,
  path: `search`,
  label: 'GEAR_SEARCH.TITLE',
}

const productSubType = 'productSubType';
export const productSubTypeBasePageInfo: PageInfo & {productSubType: string} = {
  key: 'productSubType',
  productSubType,
  path: `:${productSubType}`,
  label: 'productSubType',
}

export const riderBasePageInfo: PageInfo & WithShortName = {
  key: 'rider',
  shortName,
  path: `:${shortName}`,
  label: 'RIDER.TITLE',
}

export const ridersBasePageInfo: PageInfo = {
  key: 'riders',
  path: 'riders',
  label: 'RIDERS.TITLE',
}

export const settingsBasePageInfo: PageInfo = {
  key: 'settings',
  path: 'settings',
  label: 'SETTINGS.TITLE',
}

export const statsPath = 'stats';
export const riderStatsBasePageInfo: PageInfo & WithShortName = {
  key: 'riderStats',
  shortName,
  path: statsPath,
  label: 'rider',
}

const riderShortNameAndDate = 'riderShortNameAndDate';
export interface RiderDayPageInfo {
  riderShortNameAndDate: string
}
export const riderDayBasePageInfo: PageInfo & RiderDayPageInfo = {
  key: 'riderDay',
  riderShortNameAndDate,
  path: `:${riderShortNameAndDate}`,
  label: 'rider',
}

export const spotBasePageInfo: PageInfo & WithShortName = {
  key: 'spot',
  shortName,
  path: `:${shortName}`,
  label: 'spots',
}

export const spotsBasePageInfo: PageInfo = {
  key: 'spots',
  path: 'spots',
  label: 'SPOTS.TITLE',
}

export const spotStatsBasePageInfo: PageInfo & WithShortName = {
  key: 'spotStats',
  shortName,
  path: statsPath,
  label: 'spot',
}

const spotShortNameAndDate = 'spotShortNameAndDate';
export interface SpotDayPageInfo {
  spotShortNameAndDate: string
}
export const spotDayBasePageInfo: PageInfo & SpotDayPageInfo = {
  key: 'spotDay',
  spotShortNameAndDate,
  path: `:${spotShortNameAndDate}`,
  label: 'spots',
}

export const wallBasePageInfo: PageInfo = {
  key: 'wall',
  path: 'wall',
  label: 'WALL.TITLE',
}

export const tabsBasePageInfo: PageInfo = {
  key: 'tabs',
  path: '',
  label: '',
}

/**
 * @deprecated use tabsBasePageInfo instead
 */
export const TABS_ROOT = '';
