import { BoardType, ProductSubType, ProductType, PropulsionType } from '@model/gear/product';
import { groupBy, toDict } from '@core/utils';
import { ErrorType, ZefError } from '@api/errors';
import { FinFamily, KiteBoard, ProductVariant, SurfBoard, WindsurfBoard, WindsurfSail } from '@model/gear/variants';
import {
  Characteristic,
  CharacteristicKey,
  GearModel,
  KiteOrPaddleBoardCharacteristic,
  ProductSubTypeDef,
  SailOrKiteCharacteristic,
  SurfBoardCharacteristic,
  WindsurfOrWingBoardCharacteristic
} from '@model/gear/gear-model';
import { QuiverItem } from '@model/schema-model';
import { UnIdentifiedGearItem } from '@model/gear/gear-item';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import footinch from 'footinch';
import { Id } from '@model/entity';
import { WingBoard } from '@model/gear/variants/wing-board';
import { Wing } from '@model/gear/variants/wing';
import { Kite } from '@model/gear/variants/kite';
import { PaddleBoard } from '@model/gear/variants/paddle-board';
import { RiderAndQuiverItem } from '@api/queries/queries.model';

export const getProductSubType = (productType: ProductType): ProductSubTypeDef => {
  switch (productType) {
    case ProductType.propulsion:
      return {
        name: 'PropulsionType',
        enum: PropulsionType
      };
    case ProductType.board:
      return {
        name: 'BoardType',
        enum: BoardType
      };
    default:
      throw `Type "${productType}" has no sub-type`;
  }
};
export const getTypeFromSubType = (productSubType: ProductSubType): ProductType => {
  if (Object.values(PropulsionType).includes(productSubType as PropulsionType)) {
    return ProductType.propulsion;
  }
  if (Object.values(BoardType).includes(productSubType as BoardType)) {
    return ProductType.board;
  }

  throw `Unrecognized product sub type: ${productSubType}`;
};
const productTypeOrder: { [type in ProductType]?: number } = toDict([
  ProductType.board,
  ProductType.propulsion
], val => val, (_, index) => index);
export const compareProductTypes = (typeA: ProductType, typeB: ProductType): number => {
  if (typeA === typeB) {
    return 0;
  }

  return (productTypeOrder[typeA] ?? 100) - (productTypeOrder[typeB] ?? 100);
};
const productSubTypeOrder: { [type in ProductSubType]?: number } = toDict([
  ProductSubType.windsurfBoard,
  ProductSubType.wingBoard,
  ProductSubType.kiteBoard,
  ProductSubType.paddleBoard,
  ProductSubType.surfBoard,
  ProductSubType.windsurfSail,
  ProductSubType.wing,
  ProductSubType.kite
], val => val, (_, index) => index);
export const compareProductSubTypes = (typeA: ProductSubType, typeB: ProductSubType): number => {
  if (typeA === typeB) {
    return 0;
  }

  return (productSubTypeOrder[typeA] ?? 100) - (productSubTypeOrder[typeB] ?? 100);
};
export const getTypesAndSubTypesFromMap = <T>(map: { [type: string]: { [subType: string]: T[] } }): {
  types: ProductType[];
  subTypes: { [type: string]: ProductSubType[] }
} => {
  // To make sure it is sorted
  const types = [ProductType.board, ProductType.propulsion].filter(t => (Object.keys(map) as ProductType[]).includes(t));

  const subTypes = toDict(types, type => type, type => Object.keys(map[type]) as ProductSubType[]);

  return { types, subTypes };
};
export const getFullName = (gearModel: Pick<GearModel<unknown>, 'brandName' | 'name' | 'year'>) => `${gearModel.brandName} ${gearModel.name} ${gearModel.year}`;
export const compareCharacteristic = <T extends (Characteristic | { lengthFt: string })>(cA: T, cB: T): number => {
  // Order matters here
  if ((cA as SailOrKiteCharacteristic).surfaceM2 && (cB as SailOrKiteCharacteristic).surfaceM2) {
    return (cA as SailOrKiteCharacteristic).surfaceM2 - (cB as SailOrKiteCharacteristic).surfaceM2;
  }
  if ((cA as SurfBoardCharacteristic).lengthFt && (cB as SurfBoardCharacteristic).lengthFt) {
    return (cA as SurfBoardCharacteristic).lengthFt.toString().localeCompare((cB as SurfBoardCharacteristic).lengthFt.toString());
  }
  if ((cA as WindsurfOrWingBoardCharacteristic).volumeL && (cB as WindsurfOrWingBoardCharacteristic).volumeL) {
    return (cA as WindsurfOrWingBoardCharacteristic).volumeL - (cB as WindsurfOrWingBoardCharacteristic).volumeL;
  }
  if ((cA as KiteOrPaddleBoardCharacteristic).lengthCm && (cB as KiteOrPaddleBoardCharacteristic).lengthCm) {
    return (cA as KiteOrPaddleBoardCharacteristic).lengthCm! - (cB as KiteOrPaddleBoardCharacteristic).lengthCm!;
  }

  // Can't compare
  return 0;
};
export const getCompatibleFinFamilies = <VariantType>(subType: ProductSubType, variant: ProductVariant<VariantType>): FinFamily[] | undefined => {
  switch (subType) {
    case ProductSubType.windsurfBoard:
    case ProductSubType.wingBoard:
      return (variant as WindsurfBoard<unknown>).compatibleFinFamilies;
    default:
      return undefined;
  }
};
export const getCharacteristicKeys = (subType: ProductSubType): CharacteristicKey[] => {
  switch (subType) {
    case ProductSubType.windsurfBoard:
      return ['volumeL'];
    case ProductSubType.surfBoard:
    case ProductSubType.wingBoard:
    case ProductSubType.kiteBoard:
    case ProductSubType.paddleBoard:
      return ['lengthFt', 'lengthCm', 'volumeL'];
    case ProductSubType.windsurfSail:
    case ProductSubType.wing:
    case ProductSubType.kite:
      return ['surfaceM2'];
    default:
      throw new ZefError(ErrorType.UnsupportedGearModelType, `Unsupported gear model type`, subType);
  }
};
export const getCharacteristic = <T>(subType: ProductSubType, variant: T) => {
  const characteristics = getCharacteristicKeys(subType) as (keyof T)[];
  const validKeys = characteristics.filter(k => variant[k] !== undefined);
  if (validKeys.length === 0) {
    // TODO throw
  }

  const characteristicKey = validKeys[0];
  return {
    [characteristicKey]: variant[characteristicKey]
  };
};
export const getUnit = (key: string) => {
  if (key.endsWith('Ft')) {
    return 'FOOT';
  }
  if (key.endsWith('Cm')) {
    return 'CENTIMETER';
  }
  if (key.endsWith('M2')) {
    return 'SQUARE_METER';
  }
  if (key.endsWith('M')) {
    return 'METER';
  }
  if (key.endsWith('L')) {
    return 'LITER';
  }
  if (key.endsWith('Kt')) {
    return 'KNOT';
  }
  if (key.endsWith('Kg')) {
    return 'KILOGRAM';
  }

};
export const compareQuiverItems = (itemA: QuiverItem, itemB: QuiverItem): number => {
  return compareProductSubTypes(itemA.subType, itemB.subType) + compareCharacteristic(itemA.characteristic, itemB.characteristic);
};

export function getCharacteristicLabel(info: UnIdentifiedGearItem, translate: (key: string, params?: (Record<string, string | number> | undefined)) => string) {
  const subType = info.subType;

  const characteristic = getCharacteristic(subType, info.characteristic);

  const keyName = Object.keys(characteristic)[0];

  if (keyName === 'lengthFt') {
    return formatFeet(Object.values(characteristic)[0]);
  }
  const unit = getUnit(keyName);
  let displayValue = `${Object.values(characteristic)[0]}${translate(`UNIT.${unit}`, { count: 0 })}`;

  if (info.finFamily) {
    displayValue += ` ${translate(`ENUM.FinFamily.VALUES.${info.finFamily}`)}`;
  }

  return displayValue;
}

const feetFormatter = footinch.format.FT.to.FT.IN.FRAC(8);
export const formatFeet = (feet: number): string => {
  return feetFormatter(feet).replace(' 0"', '').replace(/"$/, "").replace(/ /, '');
}

export const feetParse: (feetString: string) => number = footinch.parse.F;

export const feetToCm: (inFeet: number) => number = inFeet => inFeet * 30.48;

export const byType = <T>(quiverVariants: ModelVariant<T>[]) => groupBy(quiverVariants, variant => variant.type);

// A specific variant of a model, with all the model information (including brand name)
export type ModelVariant<T = { [key: string]: unknown }> =  Omit<RiderAndQuiverItem, 'variant' | 'itemId' | 'modelId' | 'characteristic' > & Omit<GearModel<T>, 'variants'> & {
  brandName: string;
  brandId: Id;
  itemId?: Id;
  variant: WindsurfBoard<T> | WingBoard<T> | SurfBoard<T> | WindsurfSail<T> | Wing<T> | KiteBoard<T> | Kite<T> | PaddleBoard<T>
}