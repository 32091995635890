<ion-header>
  <ion-toolbar>
    <ion-title>{{ (isUpdate ? 'SPOT.UPDATE.TITLE' : 'SPOT.CREATE.TITLE') | translate : { name: form?.fields.name.value } }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form *ngIf='form; else loadingList' [formGroup]='formGroup' (keydown.meta.Enter)='submit()'>
    <ion-list>

      <zef-abstract [form]='form' [field]='form.fields.name'></zef-abstract>
      <zef-edit-aliases [field]='form.fields.aliases' [form]='form'></zef-edit-aliases>

      <ion-item>
        <zef-spot-map *ngIf='form.fields.location.value || defaultCenterAndZoom'
                      [edit]='true'
                      [spotLocation]='form.fields.location.value'
                      [defaultCenterAndZoom]='defaultCenterAndZoom'
                      [options]='{ heightPx: 300, fullScreen: true }'
                      (edited)='locationEdited($event)'
        ></zef-spot-map>
      </ion-item>

      <zef-multi-language-input
        title='{{form.fields.abstract | fieldLabel : FieldLabelType.TITLE}}'
        placeholder='{{form.fields.abstract | fieldLabel: FieldLabelType.PLACEHOLDER}}'
        [(model)]='form.fields.abstract.value'
      ></zef-multi-language-input>

      <zef-multi-language-input
        title='{{form.fields.description | fieldLabel : FieldLabelType.TITLE}}'
        placeholder='{{form.fields.description | fieldLabel : FieldLabelType.PLACEHOLDER}}'
        [(model)]='form.fields.description.value'
      ></zef-multi-language-input>

      <zef-enum-property [form]='form' [field]='form.fields.spotType'
                         enumName='SpotTypeValue'
                         [enumType]='SpotTypeValue'></zef-enum-property>

      <zef-enum-property [form]='form' [field]='form.fields.depth'
                         enumName='DepthValue'
                         [enumType]='DepthValue'></zef-enum-property>

      <zef-enum-multi-property [form]='form' [field]='form.fields.dangers'
                               enumName='Danger'
                               [enumType]='Danger'></zef-enum-multi-property>

      <zef-enum-property [form]='form' [field]='form.fields.entryLevel'
                         enumName='EntryLevelValue'
                         [enumType]='EntryLevelValue'></zef-enum-property>

      <ion-item lines='none'>
        <ion-label class='input' color='dark'>
          {{ "SPOTS.LINKS" | translate }} <!-- FIXME should use labelPipe -->
        </ion-label>
      </ion-item>

      <zef-edit-links [form]='form' [field]='form.fields.links'></zef-edit-links>
    </ion-list>
  </form>

  <ng-template #loadingList>
    <zef-loading-list></zef-loading-list>
  </ng-template>
</ion-content>

<ion-footer>
  <ion-toolbar [popper]='popperAllErrors'
               [popperTrigger]='popperTrigger'
               [popperDisabled]='formGroup.valid'
               (popperOnShown)='onPopperShown()'>
    <ion-buttons slot='start' class="secondary">
      <ion-button (click)='cancel()'>
        {{ 'SHARED.CANCEL' | translate }}
      </ion-button>
    </ion-buttons>
    <ion-buttons slot='end'>
      <ion-button (click)='submit()' [disabled]='!form || disabledButton || !formGroup.valid' color='primary'>
        {{ (isUpdate ? 'SPOT.UPDATE.OK' : 'SPOT.CREATE.OK') | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>

<popper-content #popperAllErrors>
  <p *ngFor='let error of allErrors'>{{error}}</p>
</popper-content>

