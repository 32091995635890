import { Injectable, isDevMode } from '@angular/core';
import { WithLanguageText } from '@model/schema-model';
import { switchMap } from 'rxjs/operators';
import { UploadResponse } from 'imagekit/libs/interfaces/UploadResponse';
import { Queries } from '@api/queries';
import { HttpClient } from '@angular/common/http';
import { Commands } from '@services/meteor/commands';
import { PhotoEntityLink } from '@model/photo';
import { Point } from 'geojson';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';

export interface UploadImageParams {
  path: string;
  data: string;
  name: string;
  description: WithLanguageText[];
}

type RawResponseForDb = Pick<UploadResponse, 'url' | 'thumbnailUrl' | 'height' | 'width'>;

const fakePhoto: RawResponseForDb = {
  url: 'https://picsum.photos/1024/640',
  thumbnailUrl: 'https://picsum.photos/1024/640',
  height: 640,
  width: 1024
}

@Injectable({
  providedIn: 'root'
})
export class ImageKitService {
  constructor(
    private queries: Queries,
    private http: HttpClient,
    private commands: Commands
  ) {
  }

  public uploadImage$(photo: UploadImageParams,
                      folder: string,
                      tags: string[],
                      entities: PhotoEntityLink<unknown>[],
                      location?: Point) {

    // On dev, simply return a fixed random URL
    const uploadPhoto$: Observable<UploadResponse> = !environment.production ? of(fakePhoto) :
      // Get authentication token and signature
      this.queries.getImageKitAuthentication.call$().pipe(
        // Upload to imagekit
        switchMap(authentication => {
          const uploadOptions = {
            file: photo.data,
            fileName: photo.name,
            ...authentication,
            folder,
            tags: tags.join(','),
            publicKey: 'public_OBmmCQvIyJN0boOm9erlu0FAgLs=',
            extensions: [
              {
                name: 'google-auto-tagging', // TODO use credit?
                maxTags: 5,
                minConfidence: 95
              }
            ]
          };

          const formData = Object.keys(uploadOptions).reduce((formData, key) => {
              const value = uploadOptions[key];
              formData.append(key, Array.isArray(value) ? JSON.stringify(value) : value);
              return formData;
            },
            new FormData());

          return this.http.post<any>('https://upload.imagekit.io/api/v1/files/upload', formData);
        }));

    return uploadPhoto$.pipe(
      // Add to DB and associate with entity
      switchMap((response: RawResponseForDb) =>
        this.commands.addPhotoToEntity.call$({
          url: response.url,
          thumbnailUrl: response.thumbnailUrl,
          entities: entities,
          location: location,
          description: photo.description,
          width: response.width,
          height: response.height
        })
      ));
  }

}