import {Component, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import {FormField, IForm} from '@utils/form';
import {FieldLabelType} from '@pipes/fields/field-label.pipe';
import {Queries} from '@api/queries';
import {UntilDestroy} from '@ngneat/until-destroy';
import {groupBy} from '@core/utils';
import {IAuthService} from '@services/i-auth.service';
import {QuiverItem} from '@model/schema-model';
import {capitalize} from 'lodash';
import {RiderAndQuiverItem} from '@api/queries/queries.model';
import {FeedbackService} from '@services/feedback.service';
import {TranslateService} from '@ngx-translate/core';
import {IonSelect, ModalController} from '@ionic/angular';
import {ProductSubType, ProductType} from '@model/gear/product';
import {compareProductSubTypes, compareQuiverItems, getProductSubType} from "@model/gear/gear-utils";

type AvailableItem<T extends QuiverItem | RiderAndQuiverItem = QuiverItem | RiderAndQuiverItem> = T & { selected?: boolean };

@UntilDestroy()
@Component({
  selector: 'zef-select-gear-items',
  templateUrl: './select-gear-items.component.html',
  styleUrls: ['./select-gear-items.component.scss']
})
export class SelectGearItemsComponent implements OnInit {
  @Input() form: IForm;

  private _formField: FormField<QuiverItem[]>;
  @Input() set formField(formField: FormField<QuiverItem[]>) {
    this._formField = formField;

    this._formField.value
      ?.filter(gear => !gear.itemId)
      .forEach(gear => this.addUnidentifiedItem(gear));

    this.initSelectedItems();
  }

  get formField(): FormField<QuiverItem[]> {
    return this._formField;
  }

  private initSelectedItems() {
    this.formField.value
      ?.filter(gearItem => gearItem.itemId)
      .forEach(gearItem => {
        const typeAndSubType = SelectGearItemsComponent.getTypeKey(gearItem);
        const found = this.allGearItems[typeAndSubType]?.find(other => other.itemId === gearItem.itemId);
        if (found) {
          found.selected = true;
        }
      });
  }

  @Input() set riderQuiver(riderQuiver: RiderAndQuiverItem[] | undefined) {
    if (riderQuiver) {
      const quiver = riderQuiver
        // Because cars are not properly populated yet
        .filter(q => q.shortName !== null)
        // We don't want to see items that are not in the quiver anymore
        .filter(q => q.leftAt === undefined);

      this._riderQuiver = groupBy(quiver, SelectGearItemsComponent.getTypeKey);

      // Add rider quiver
      for (let typeAndSubType in this._riderQuiver) {
        this.allGearItems[typeAndSubType] = this.allGearItems[typeAndSubType] ?? [];
        this.allGearItems[typeAndSubType].push(...this._riderQuiver[typeAndSubType]);
        this.allGearItems[typeAndSubType].sort(compareQuiverItems);
      }

      this.updateSubTypesList();

      this.initSelectedItems();
    }
  }

  private static getTypeKey(gearItem: AvailableItem): string {
    return `${gearItem.type}.${gearItem.subType}`;
  }

  _riderQuiver: { [typeAndSubType: string]: RiderAndQuiverItem[] } = {};

  FieldLabelType = FieldLabelType;
  allGearItems: { [typeAndSubType: string]: AvailableItem[] } = {};
  gearSubTypes: { type: string, name: string }[] = [];
  capitalize = capitalize;

  @ViewChildren('fins') finSelects: QueryList<IonSelect>;

  constructor(
    private queries: Queries,
    private authService: IAuthService,
    private feedback: FeedbackService,
    private translate: TranslateService,
    private modalController: ModalController) {
  }

  updateSubTypesList() {
    // List all the "gear sub types" from available gear items
    this.gearSubTypes = Object.keys(this.allGearItems)
      .sort(compareProductSubTypes)
      .map(type => ({ type, name: this.subTypeLabel(type) }));
  }

  ngOnInit() {
  }

  toQuiverValue(gear: AvailableItem): QuiverItem {
    if (!gear.itemId) {
      // Is an unidentified quiver item
      const { selected, ...clean } = gear;
      return clean;
    }

    const quiverItem: QuiverItem = {
      itemId: gear.itemId,
      type: gear.type,
      subType: gear.subType,
      characteristic: gear.characteristic
    };

    if (gear.subType === ProductSubType.windsurfBoard) {
      const finFamilies = (gear as RiderAndQuiverItem).compatibleFinFamilies;
      if (finFamilies?.length === 1) {
        quiverItem.finFamily = finFamilies[0];
      } else {
        quiverItem.finFamily = null; // /!\ Notice it's a special value that will require some action from the user
      }
    }
    return quiverItem;
  };

  toggle(gear: AvailableItem): void {
    if (gear.selected) {
      gear.selected = false;
      if (gear.itemId) {
        // Is from rider's quiver => need invalidate fin family so user will have to re-select it if there are multiple options
        (gear as QuiverItem).finFamily = undefined;
      }
    } else {
      const quiverValue = this.toQuiverValue(gear);
      // => select
      if (quiverValue.finFamily === null) {
        // User needs to select the fin family
        this.finSelects
          // Keep only the select that is relevant
          .filter(select => (select as unknown as { el: { id: string } }).el.id === gear.itemId)
          .forEach(select => select.open());

        // /!\ do NOT select because it will be done when user selects the fin family
        return;
      }
      gear.selected = true;
    }
  }

  setFins(quiverItem: AvailableItem<QuiverItem>, $event: CustomEvent) {
    const finFamily = $event.detail.value;
    if (finFamily) {
      // Update item so that the fin family is displayed
      quiverItem.finFamily = finFamily;
      const quiverValue = this.toQuiverValue(quiverItem);

      // Update quiverValue so that it is selected with fin family
      quiverValue.finFamily = finFamily;
      quiverItem.selected = true;
    }
  }

  private subTypeLabel(typeAndSubType: string): string {
    const [type, subTypeValue] = typeAndSubType.split('.');
    const subType = getProductSubType(type as ProductType);
    return capitalize(this.translate.instant(`ENUM.${subType.name}.VALUES.${subTypeValue}`, { count: 10 }));
  }

  addUnidentifiedItem(quiverItem: AvailableItem<QuiverItem>) {
    // First consider selected
    quiverItem.selected = true;

    // Then add to available list
    const typeAndSubType = SelectGearItemsComponent.getTypeKey(quiverItem);
    this.allGearItems[typeAndSubType] = this.allGearItems[typeAndSubType] ?? [];
    this.allGearItems[typeAndSubType].push(quiverItem);
    this.allGearItems[typeAndSubType].sort(compareQuiverItems);

    this.updateSubTypesList();
  }

  async close() {
    await this.modalController.dismiss();
  }

  async submit() {
    // Keep all items that were selected
    this._formField.value = Object.keys(this.allGearItems)
      .reduce((accumulator: QuiverItem[], key: string) => {
        accumulator.push(...this.allGearItems[key].filter(gearItem => gearItem.selected));
        return accumulator;
      }, [])
      .map(this.toQuiverValue)
      .sort(compareQuiverItems);

    await this.close();
  }
}
