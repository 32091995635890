import {Form, FormField, NumberFormField} from '@utils/form';
import {FormGroup} from '@angular/forms';
import SimpleSchema from "simpl-schema";
import {EntityType} from '@model/entity-type';
import {FinFamily} from '@model/gear/variants';

export class CharacteristicForm extends Form {

  constructor(formGroup: FormGroup, schema: SimpleSchema, public parentEntityType: EntityType) {
    super(formGroup, schema, parentEntityType);

    if (this.needsFinFamily) {
      this.fields.finFamily = new FormField<FinFamily>(this.schema, this.vf, this.group);
    } else {
      delete this.fields.finFamily;
      this.group.removeControl('finFamily');
    }
  }

  get needsFinFamily(): boolean {
    return this.schema?.getDefinition('finFamily') !== undefined;
  }

  fields = {
    volumeL: new NumberFormField(this.schema, this.vf, this.group),
    lengthFt: new NumberFormField(this.schema, this.vf, this.group),
    surfaceM2: new NumberFormField(this.schema, this.vf, this.group),
    // Additionally, a "finFamily" field is added if needed (see constructor)
    finFamily: undefined as FormField<FinFamily>
  };
}
