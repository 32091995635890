import {Entity} from "@model/entity";

export const groupByFirstLetter = <T extends { name: string }>(array: T[]) => {
  return array.reduce((accumulator: {[letter: string]: T[]}, current: T) => {
    const firstLetter = current.name[0].toUpperCase();
    accumulator[firstLetter] ??= [];
    accumulator[firstLetter].push(current);
    return accumulator;
  }, {});
};

export const isNotNullNotUndefined = <T>(val: T | undefined) =>
  val !== null && val !== undefined;

export const onlyUnique = <T>(value: T, index: number, self: T[]) =>
  self.indexOf(value) === index;

export const onlyUniqueById = <T extends Entity>(value: T, index: number, self: T[]) =>
  self.findIndex(val => val._id === value._id) === index;
