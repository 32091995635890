<form [formGroup]='form.group'>
    <ion-item class="transparent">
        <ion-label *ngIf="showLabel" class='input'>{{ field | fieldLabel : FieldLabelType.TITLE }}</ion-label>
        <ion-input type='number'
                   [attr.data-unit]="unit"
                   formControlName='{{ field.name }}'
                   [class.ion-text-right]="unit?.length > 0"
                   [placeholder]='placeholder'
        ></ion-input>
    </ion-item>
    <zef-error-line [field]='field'></zef-error-line>
</form>
