import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {RiderItemComponent} from '@components/rider/rider-item/rider-item.component';
import {EntityModule} from '@components/entity/entity.module';
import {SelectRidersComponent} from '@components/rider/select-rider/select-riders.component';
import {LoadingListModule} from '@components/loading-list/loading-list.module';
import {TranslateModule} from '@ngx-translate/core';
import {PipesModule} from '@pipes/pipes.module';

@NgModule({
  declarations: [RiderItemComponent, SelectRidersComponent],
  imports: [CommonModule, RouterModule, IonicModule, EntityModule, LoadingListModule, TranslateModule, PipesModule],
  exports: [RiderItemComponent, SelectRidersComponent]
})
export class RiderModule {
}
