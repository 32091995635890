import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { EntityAvatarComponent } from '@components/entity/entity-avatar/entity-avatar.component';
import { TextAvatarModule } from '@components/text-avatar';
import { ImagekitioAngularModule } from 'imagekitio-angular';
import { NgIconsModule } from '@ng-icons/core';

@NgModule({
  declarations: [EntityAvatarComponent],
  imports: [CommonModule, RouterModule, IonicModule, TextAvatarModule, ImagekitioAngularModule, NgIconsModule],
  exports: [EntityAvatarComponent]
})
export class EntityModule {}
