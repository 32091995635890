import {Directive, Input, OnInit} from '@angular/core';
import {Form, FormField, IForm} from '@utils/form';
import {FormBuilder} from '@angular/forms';
import {mapDictionary} from '@core/utils';
import {tap} from 'rxjs/operators';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {FieldLabelType} from '@pipes/fields/field-label.pipe';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {FeedbackService} from "@services/feedback.service";

@UntilDestroy()
@Directive()
export abstract class ListInputComponent<T, F extends Form> implements OnInit {
  @Input() form: IForm;
  @Input() field: FormField<T[]>;

  forms: F[] = [];
  FieldLabelType = FieldLabelType;

  subscriptions: Subscription[][] = [];

  // Can add a new element only if the last element is valid (probably means user has started to edit it)
  get canAdd() {
    return this.forms[this.forms.length - 1]?.group.valid ?? true;
  }

  constructor(
    protected fb: FormBuilder,
    protected feedback: FeedbackService,
    protected translate: TranslateService) {
  }

  ngOnInit() {
    this.forms = [];
    this.field.value?.forEach(element => this.addElement(element));
  }

  addElement(element?: T) {
    const form = this.getNewForm(element).init(this);

    this.forms.push(form);

    const defaultElement = mapDictionary(form.fields, (key, field) => ({key, value: field.value})) as any as T;

    if (element === undefined) {
      element = defaultElement;
      this.field.value = this.field.value ?? [];
      this.field.value.push(element);
    } else {
      // Make sure that each property is initialized
      for (let key in defaultElement) {
        element[key] ??= defaultElement[key]
      }
    }

    // update each property when this form field is changed
    this.subscriptions[this.forms.length - 1] = Object.values(form.fields).map(field => field.valueChanges$.pipe(
        tap(val => element[field.name] = val),
        untilDestroyed(this)
      ).subscribe()
    );
  }

  async removeElement(index: number) {
    if (await this.feedback.confirmDelete(this.forms[index].parentEntityType)) {
      // remove from form
      this.forms.splice(index, 1);

      // remove from values
      this.field.value.splice(index, 1);

      // Remove subscriptions
      this.subscriptions[index].forEach(s => s.unsubscribe());
      this.subscriptions.splice(index, 1);
    }
  }

  // Note: one form group per form because fields have the same name
  protected abstract getNewForm(element: T): F;
}
