import {LoginUser, UserToCreate} from '@model/schema-model';
import {Observable} from 'rxjs';
import {Id} from '@model/entity';
import {RiderItem} from '@api/queries';

export abstract class IAuthService {
  readonly userId: string | undefined;

  loggedRider$: Observable<RiderItem | undefined>;

  isLoggedIn: boolean;

  abstract register$(user: UserToCreate): Observable<Id>;

  abstract login$(user: Pick<LoginUser, 'email' | 'password'>): Observable<void>;

  abstract logout$(): Observable<void>;

  isAdmin$: Observable<boolean>;
}
