// Inspired by https://stackoverflow.com/questions/40933619/how-to-redirect-to-a-certain-route-based-on-condition-in-angular-2
import {UntilDestroy} from '@ngneat/until-destroy';
import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {IAuthService} from '@services/i-auth.service';
import {ModalController} from '@ionic/angular';
import {of} from 'rxjs';
import {mePageInfo} from '@pages/riders/me/me.page.info';
import {TABS_ROOT} from "@core/routing/pages-list";

@UntilDestroy()
@Injectable()
export class LoggedOutGuard implements CanActivate {
  constructor(
    private auth: IAuthService,
    private modalController: ModalController,
    private router: Router
  ) {
  }

  canActivate() {
    if (this.auth.isLoggedIn) {
      return this.router.navigate([TABS_ROOT, mePageInfo.path]);
    }
    return of(true);
  }
}
