import {Id} from '@model/entity';
import {Comment} from '@model/comment';
import {Event} from '@model/events';
import {Rider} from '@model/rider';
import {SpotDay} from '@model/spot-day';
import {Session} from '@model/session';
import {Booking} from '@model/booking';
import {SpotLaunch} from '@model/spot-launch';
import {Subscription} from '@model/subscription';
import {IdOrShortName, NamedEntity} from '@model/entity-with-description';
import {Activity} from '@model/activity';
import {Publication} from './publication';
import {affectNamesAutomatically, Method, ObjectWithMethods} from './method';
import {GearItemAndModel} from '@model/gear/gear-item-and-model';
import {Comments} from './collections/comment.collection';
import {Bookings} from './collections/booking.collection';
import {Observations} from './collections/observation.collection';
import {Sessions} from './collections/session.collection';
import {Subscriptions} from './collections/subscription.collection';
import {Events} from './collections/event.collection';
import {ObservableCursor} from 'meteor-rxjs';
import {EntityLink} from '@model/entity-link';
import {Photo, PhotoEntityLink} from '@model/photo';
import {ImageKitAuthentication} from '../../server/image-kit/imagekit';
import {
  BrandList,
  DayMetrics,
  GearModelAndBrand,
  GearModelListItem,
  RiderAndQuiver,
  RiderDayMetricsInput,
  RiderDaySessions,
  RiderList,
  SessionWithQuiver,
  SpotAndLaunches,
  SpotDayAndSpot,
  SpotDayMetricsInput,
  SpotDaySessions,
  SpotList
} from './queries/queries.model';
import {CommentsQueries} from './queries/comment.queries';
import {BookingsQueries} from './queries/booking.queries';
import {ObservationsQueries} from './queries/observation.queries';
import {SessionIdWithSpotDay, SessionsQueries} from './queries/session.queries';
import {GearItemOwning, NameOrEmail} from './queries/rider.queries';
import {MainRiderStats, MainSpotStats, PerActivity, WithPercent} from './queries/statistics.queries';
import {AllTimeStat, YearlyStat} from './model/statistics';
import {Spot} from '@model/spot';
import {TrackLog} from '@model/track-log';
import {GearModelSearchInput} from './queries/gear.queries';
import { getSelectorOnEntity, selectSpotDaySituationIdAndStartDate } from './queries/utils.queries';
import {SpotDaySituationIdAndStartDate} from '@model/spot-situation';
import {Observation} from '@model/observation';
import {Headline} from '@model/headline';
import {Reaction} from '@model/reaction';
import {ReactionsQueries} from './queries/reaction.queries';
import {Reactions} from './collections/reaction.collection';
import {BrandWithPhotos} from "./queries/brand.queries";
import {ProductSubType} from "@model/gear/product";
import {debounceTime, map} from "rxjs/operators";
import {EventsQueries} from "./queries/event.queries";
import {OnboardingStatus} from "./queries/onboarding.queries";
import {GearModel} from "@model/gear/gear-model";
import {FindSpotAndSessionResponse, FinSpotAndSessionsQuery} from "@api/queries/track-log.queries";

export enum Errors {
  NOT_FOUND,
  TOO_MANY
}

export interface ListOfIds {
  ids: Id[];
}

export interface ListOfShortNames {
  shortNames: string[];
}

export interface ListOfDenyIds {
  denyIds: Id[];
}

export type RiderItem = NamedEntity & Pick<Rider, 'language' | 'spokenLanguages' | 'emailNotifications'>;
export type SpotItem = NamedEntity & Pick<Spot, 'timezone'>;
export type SpotDayItem = NamedEntity & Pick<SpotDay, 'spotId' | 'dayInTz'>;

export type Notification = Event;

export interface Stats {
  flat: YearlyStat[],
  perActivity: YearlyStat<PerActivity>[]
}

export interface WithFlatStats {
  flat: AllTimeStat[];
}

type ItemsQuery = ListOfIds | ListOfShortNames | ListOfDenyIds;

export type PublicSession = Omit<SessionWithQuiver, 'abstract' | 'startDate' | 'endDate'> & {
  abstract: undefined; // Just to make the signature compatible with abstract?: string;
  startDate: undefined;
  endDate: undefined;
}

export class Queries {
  constructor() {
    affectNamesAutomatically((this as unknown) as ObjectWithMethods);
  }

  // --------- Old queries
  getRidersByGearItemId = new Method<Id, Rider[]>();
  getEventsForRider = new Method<Id, Event[]>();
  getEventsForEntity = new Method<EntityLink, Event[]>();

  // Allowed to all
  getSpotList = new Method<void, SpotList>();
  getRiderList = new Method<void, RiderList>();
  doesRiderExist = new Method<NameOrEmail, boolean>();
  getSpotAndLaunches = new Method<string, SpotAndLaunches | undefined>();
  getOrCreateSpotDayAndSpot = new Method<{ spotShortName: string; day: string }, SpotDayAndSpot>();
  // TODO this method should only return quiver, not rider
  getRiderAndQuiver = new Method<IdOrShortName, RiderAndQuiver | undefined>();
  getSpotDaysMetrics = new Method<SpotDayMetricsInput, DayMetrics>();
  getRiderDaysMetrics = new Method<RiderDayMetricsInput, DayMetrics>();
  getRiderItems = new Method<ItemsQuery, RiderItem[]>();
  getSpotItems = new Method<ItemsQuery, SpotItem[]>();
  getSpotDayItems = new Method<ItemsQuery, SpotDayItem[]>();
  getSpotLaunchItems = new Method<ItemsQuery, NamedEntity[]>();
  getTricks = new Method<ItemsQuery, NamedEntity[]>();
  getBrands = new Method<ItemsQuery, NamedEntity[]>();
  getRiderDaySessions = new Method<RiderDaySessions, SpotDaySessions[]>();
  getBrandList = new Method<string | undefined, BrandList>();
  getRandomBrandLogos = new Method<number, string[]>();
  getRandomModelLogos = new Method<{ productSubType: ProductSubType, count: number }, string[]>();
  getBrand = new Method<string, BrandWithPhotos>();
  searchGearModels = new Method<GearModelSearchInput, GearModelListItem[]>();
  getGearItemModelBrand = new Method<Id, GearItemAndModel | undefined>();
  getGearItemHistory = new Method<Id, GearItemOwning[]>();
  tryToGetGearModelAndBrand = new Method<string, GearModelAndBrand | undefined>();
  getGearModelAndBrands = new Method<Id[], GearModelAndBrand[]>();
  getSpotDay = new Method<Id, SpotDay | undefined>();
  getSpotLaunch = new Method<Id, SpotLaunch | undefined>();
  getSpotLaunches = new Method<Id[], SpotLaunch[]>();
  getSpotLaunchesBySpotId = new Method<Id, SpotLaunch[]>();
  getActivities = new Method<void, Activity[]>();
  getPhotos = new Method<PhotoEntityLink<unknown>, Photo<unknown>[]>();
  getFollowerIds = new Method<EntityLink, Id[]>();
  getRiderSubscriptions = new Method<Id, Subscription[]>();
  getHeadline = new Method<Id, Headline>();
  getHeadlines = new Method<Id[], Headline[]>();
  getGearModels = new Method<Id[], GearModel<unknown>[]>();
  getLatestHeadlineIds = new Method<void, Id[]>();
  getSessionWithQuiver_public = new Method<Id, PublicSession>();
  getBooking_public = new Method<Id, Booking>();
  getObservation_public = new Method<Id, Observation>();
  getLatestSpotSessions = new Method<Id, SessionIdWithSpotDay[]>();
  getLatestRiderSessions = new Method<Id, SessionIdWithSpotDay[]>();
  getLatestWithRiderSessions = new Method<Id, SessionIdWithSpotDay[]>();
  getRiderMainStats = new Method<Id, MainRiderStats & WithFlatStats>();
  getSpotMainStats = new Method<Id, MainSpotStats & WithFlatStats>();

  // Authenticated only
  getOnboardingStatus = new Method<void, OnboardingStatus>();
  getSession = new Method<Id, Session | undefined>();
  getImageKitAuthentication = new Method<void, ImageKitAuthentication>();
  getSessionWithQuiver = new Method<Id, SessionWithQuiver>();
  getBooking = new Method<Id, Booking>();
  getObservation = new Method<Id, Observation>();
  getSpotActivityStats = new Method<Id, WithPercent<PerActivity>[]>();
  getRiderYearlyCount = new Method<Id, Stats>();
  getSpotYearlyCount = new Method<Id, Stats>();
  findSpotAndSessions = new Method<FinSpotAndSessionsQuery, FindSpotAndSessionResponse>();
  getTrackLog = new Method<Id, TrackLog>();
  getRiderHeaderImage = new Method<Id, Photo<unknown> | undefined>();

  // Publications
  getEntityComments = new Publication<EntityLink, Comment>((entity: EntityLink) =>
    Comments.find(CommentsQueries.selectByEntity(entity)));
  getSpotDaySessions = new Publication<Id, SpotDaySituationIdAndStartDate>((spotDayId: Id) =>
    Sessions.find(SessionsQueries.selectBySpotDayId(spotDayId), selectSpotDaySituationIdAndStartDate));
  getSpotDayBookings = new Publication<Id, SpotDaySituationIdAndStartDate>((spotDayId: Id) =>
    Bookings.find(BookingsQueries.selectBySpotId(spotDayId), selectSpotDaySituationIdAndStartDate));
  getSpotDayObservations = new Publication<Id, SpotDaySituationIdAndStartDate>((spotDayId: Id) =>
    Observations.find(ObservationsQueries.selectBySpotId(spotDayId), selectSpotDaySituationIdAndStartDate));
  getUnfinishedSessions = new Publication<void, Session>(() => {
    const riderId = Meteor.userId() as Id;
    return Sessions.find(SessionsQueries.selectUnfinishedSessions(riderId));
  });
  getSubscriptions = new Publication<void, Subscription>(() => {
    // ⚠️ return all entities that have been synchronized locally
    return Subscriptions.find();
  });

  getNotifications: Publication<void, Notification> = new Publication<void, Notification>(() => {
    const subscriberId = Meteor.userId() as Id;
    // ⚠️ return all entities that have been synchronized locally
    return Events.find().pipe(
      debounceTime(100),
      map(
        // Filter some notifications out
        events => EventsQueries.postProcessFilterLatestEvents(events, subscriberId)
      )
    ) as ObservableCursor<Event>;
  });

  getReactions: Publication<EntityLink, Reaction> = new Publication<EntityLink, Reaction>((link) =>
    Reactions.find(getSelectorOnEntity<Reaction>(link)));
}
