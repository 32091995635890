import {Id} from './entity';
import {DateUtils} from '../date-utils';
import {NamedEntity} from './entity-with-description';
import {EntityType} from './entity-type';
import {EntityLink} from './entity-link';
import {ForecastSource} from '../../../server/weather-api/i-weather.service';

export type PotentialValue = number;

export interface ForecastData {
  tempC: number; // Temperature, °C
  windKt: number; // Wind speed, knots
  windGustKt?: number; // Wind gust, knots
  windDirectionDeg: number; // Wind direction, degrees (meteorological)
  pressureHpa?: number; // Atmospheric pressure on the sea level by default, hPa
  humidityPct?: number; // Humidity, %
  cloudPct: number; // Cloudiness, %
  rainMm?: number; // Rain volume for last hour, mm
  snowMm?: number; // Snow volume for last hour, mm
  wmoCode?: WMOCode;
  // From open weather
  rain3hMm?: number; // Rain volume for last 3 hours, mm
  snow3hMm?: number; // Snow volume for last 3 hours, mm
}

export interface ForecastDataPerTime {
  [utcTime: number]: ForecastData;
}

export interface Forecast {
  generatedAt: Date;
  data: ForecastDataPerTime;
  source?: ForecastSource;
}

export interface Potential {
  [utcTime: number]: PotentialValue;
}

export interface SpotDay extends NamedEntity {
  spotId: Id;
  timezone: string; // TODO investigate if we can remove this
  startDate: Date;
  sunriseDate?: Date;
  sunsetDate?: Date;
  dayInTz: string; // Format: YYYY/MM/DD
  forecast?: Forecast;
  potential?: Potential;
}

export enum WMOCode {
  clear_sky = 0,//	Clear sky
  mainly_clear = 1, partly_cloudy = 2, overcast = 3, //	Mainly clear, partly cloudy, and overcast
  fog = 45, deposing_rime_fog = 48, // Fog and depositing rime fog
  light_drizzle = 51, moderate_drizzle = 53, dense_drizzle = 55, // Drizzle: Light, moderate, and dense intensity
  freezing_light_drizzle = 56, freezing_dense_drizzle = 57, // Freezing Drizzle: Light and dense intensity
  slight_rain = 61, moderate_rain = 63, heavy_rain = 65, // Rain: Slight, moderate and heavy intensity
  freezing_light_rain = 66, freezing_heavy_rain = 67, // Freezing Rain: Light and heavy intensity
  slight_snow = 71, moderate_snow = 73, heavy_snow = 75, // Snow fall: Slight, moderate, and heavy intensity
  snow_grains = 77, // Snow grains
  slight_showers = 80, moderate_showers = 81, violent_showers = 82, // Rain showers: Slight, moderate, and violent
  slight_snow_showers = 85, heavy_snow_showers = 86, // Snow showers slight and heavy
  moderate_thunderstorm = 95, // Thunderstorm: Slight or moderate
  slight_hail_thunderstorm = 96, heavy_hail_thunderstorm = 99, // Thunderstorm with slight and heavy hail
}

export class SpotDay {
  static compareToSpotDayStartDate = (spotDayStartDate: Date, date: Date): number => {
    return DateUtils.daysDifference(spotDayStartDate, DateUtils.yesterday(date));
  };

  static comparaisonKey(spotDayStartDate: Date, current: Date): string {
    const daysDifference = this.compareToSpotDayStartDate(spotDayStartDate, current);
    if (daysDifference >= 0) {
      if (daysDifference <= 1) {
        return 'TODAY';
      }
      if (daysDifference <= 2) {
        return 'TOMORROW';
      }
      return 'IN_DAYS';
    } else {
      if (daysDifference >= -1) {
        return 'YESTERDAY';
      }
      return 'DAYS_AGO';
    }
  }
}

export const getSpotDayEntity = (spotDay: Pick<SpotDay, '_id' | 'shortName'>): EntityLink => {
  if (spotDay?._id === undefined && spotDay?.shortName === undefined) {
    throw `Invalid spot day: ${spotDay}`
  }
  return {
    type: EntityType.spotDays,
    id: spotDay?._id,
    shortName: spotDay?.shortName
  };
};
