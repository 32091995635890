<ion-header>
  <ion-toolbar>
    <ion-title>
      {{ formField | fieldLabel : FieldLabelType.TITLE  }}
    </ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <form [formGroup]='form.group'>
    <ion-list>
      <ng-container *ngFor='let type of gearSubTypes'>
        <ion-item-divider>
          <ion-label>
            {{ type.name }}
          </ion-label>
        </ion-item-divider>

        <ion-item *ngFor='let gearItem of allGearItems[type.type]' [class.unselected]='!gearItem.selected'
                  (click)='toggle(gearItem)'>
          <ion-icon name='{{ gearItem.selected ? "checkbox-outline" : "square-outline"  }}'></ion-icon>
          <ion-avatar>
            <ion-img *ngIf='gearItem.avatar ; else textAvatar'
                     src='{{gearItem.avatar}}' alt='{{gearItem.name}}'
                     title='{{gearItem.name}}'></ion-img>
            <ng-template #textAvatar>
              <zef-text-avatar text='x' color='white' textColor='white'></zef-text-avatar>
            </ng-template>
          </ion-avatar>
          <ion-label>
            <span>{{ gearItem | gearModelItemVariation }}</span>&nbsp;{{gearItem.brandName}} {{gearItem.name}} {{gearItem.year}}
            <ng-container *ngIf='gearItem.compatibleFinFamilies?.length > 1'>
              <ion-select [hidden]='true'
                          [value]='gearItem.finFamily'
                          interface='action-sheet'
                          placeholder='{{ "ENUM.FinFamily.TITLE" | translate }}'
                          name='{{ "ENUM.FinFamily.TITLE" | translate }}'
                          (ionChange)='setFins(gearItem, $event)'
                          [id]='gearItem.itemId'
                          #fins>
                <ion-select-option *ngFor='let family of gearItem.compatibleFinFamilies'
                                   [value]='family'>{{ capitalize("ENUM.FinFamily.VALUES." + family | translate) }}
                </ion-select-option>
              </ion-select>
              <span
                *ngIf='gearItem.finFamily'> {{ "(" + capitalize("ENUM.FinFamily.VALUES." + gearItem.finFamily | translate) + ")" }}</span>
            </ng-container>
          </ion-label>
        </ion-item>

      </ng-container>
      <ion-item lines='none'>
        <ion-label position='top'>{{ 'FORMS.FIELDS.quiver.ADD' | translate }}</ion-label>
      </ion-item>
      <zef-create-unidentified-gear (quiverItem)='addUnidentifiedItem($event)'
                                    [form]='form'></zef-create-unidentified-gear>
      <zef-error-line [field]='formField'></zef-error-line>
    </ion-list>
  </form>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-buttons slot='start' class="secondary">
      <ion-button (click)='close()'>
        {{ 'SHARED.CANCEL' | translate }}
      </ion-button>
    </ion-buttons>
    <ion-buttons slot='end'>
      <ion-button (click)='submit()' color='primary'>
        {{ 'SHARED.OK' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
