import {Pipe, PipeTransform} from '@angular/core';
import {Error} from '@utils/form';
import {TranslateService} from '@ngx-translate/core';
import {FieldLabelPipe} from '@pipes/fields/field-label.pipe';
import {EntityType} from '@model/entity-type';
import {FormatTimePipe} from '@pipes/format-time.pipe';
import {TranslateConfigService} from '@services/translate-config.service';

@Pipe({
  name: 'error'
})
export class ErrorPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService,
    public translateConfig: TranslateConfigService,
    private labelPipe: FieldLabelPipe,
    private timePipe: FormatTimePipe) {
  }

  transform(error: Error | null, entity: EntityType | undefined, fieldTitle: string | undefined = undefined): any {
    if (!error) {
      return '';
    }

    const interpolationValues = { ...error.schemaDefinition };

    if (error.schemaDefinition?.type === Date) {
      interpolationValues.min = interpolationValues.min ? this.timePipe.transform(interpolationValues.min) : undefined;
      interpolationValues.max = interpolationValues.max ? this.timePipe.transform(interpolationValues.max) : undefined;
    }

    return this.translateService.instant(`FORMS.ERRORS.${error.code}`, {
      ...interpolationValues,
      entity,
      field: error.fieldName,
      fieldTitle: fieldTitle ?? this.labelPipe.getFieldTitle(error.fieldName, entity)
    });
  }
}
