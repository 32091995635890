import {Comment, getCommentEntity} from '@model/comment';
import {Comments} from '../collections/comment.collection';
import {EventType} from '@model/events';
import {EntityType} from '@model/entity-type';
import {ZefCollection} from '../collections/zef-collection';
import {Entity, ToPersist} from '@model/entity';
import {SpotDays} from '../collections/spot-day.collection';
import {Spots} from '../collections/spot.collection';
import {Sessions} from '../collections/session.collection';
import {EntityLink, EntityLinkType, EntityLinkWithOwner} from '@model/entity-link';
import {EventsQueries} from './event.queries';
import {SpotSituation} from "@model/spot-situation";
import {Observations} from "../collections/observation.collection";
import {Bookings} from "../collections/booking.collection";
import Selector = Mongo.Selector;
import { getSelectorOnEntities } from '@api/queries/utils.queries';

export class CommentsQueries {
  static persist = (commentToPersist: ToPersist<Comment>): string => {
    const comment = ZefCollection.setCreatedDate(commentToPersist);
    const _id = Comments.insertSync(comment);
    const createdAt = comment.createdAt;

    const entities: (EntityLinkType | EntityLink | EntityLinkWithOwner)[] = [
      getCommentEntity({...comment, _id}),
      ...comment.entities
    ];

    // Add missing entities FIXME optimize (some part could be provided as an input parameter)
    let i = 0;
    while (i < entities.length) {
      const entityLink = entities[i];
      switch (entityLink.type) {

        case EntityType.observations:
        case EntityType.bookings:
        case EntityType.sessions:
          if (entities[i + 1]?.type !== EntityType.spotDays) {
            let collection: ZefCollection<SpotSituation & Entity> | undefined = undefined;

            if (entityLink.type === EntityType.observations) {
              collection = Observations;
            } else if (entityLink.type === EntityType.bookings) {
              collection = Bookings;
            } else if (entityLink.type === EntityType.sessions) {
              collection = Sessions;
            }

            const entity = collection?.findOne((entityLink as EntityLinkWithOwner).id);
            if (!entity) {
              console.error('Create comment event: entity not found', entityLink);
              i++;
              continue;
            }
            const spotDay = SpotDays.findOne(entity.spotDayId);
            entities.push({
              type: EntityType.spotDays,
              id: spotDay._id,
              shortName: spotDay.shortName
            });
          }
          break;

        case EntityType.spotDays:
          if (entities[i + 1]?.type !== EntityType.spots) {
            const spotDay = SpotDays.findOne((entityLink as EntityLinkWithOwner).id);
            if (!spotDay) {
              console.error('Create comment event: spotDay not found', entityLink);
              i++;
              continue;
            }
            const spot = Spots.findOne(spotDay.spotId);
            entities.push({
              type: EntityType.spots,
              id: spot._id,
              shortName: spot.shortName
            });
          }
          break;
      }
      i++;
    }

    // Add event on comment + parent entity
    EventsQueries.insertEvent({
      eventType: EventType.created,
      createdAt,
      entities,
      actorId: comment.commenterId
    });

    return _id;
  };

  static selectByEntity(entityLink: EntityLink): Selector<Comment> {
    return getSelectorOnEntities<Comment>(entityLink, 0);
  }
}
