import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '@core/date-utils';
import { FormatTimePipe, FormatType } from '@pipes/format-time.pipe';
import { Language } from '@model/multi-language-text';
import { TranslateService } from '@ngx-translate/core';
import { TranslateConfigService } from '@services/translate-config.service';

export enum DateType {
  START,
  END
}

/**
 * A moment timezone pipe to support parsing based on time zone abbreviations
 * covers all cases of offset variation due to daylight saving.
 *
 * Same API as DatePipe with additional timezone abbreviation support
 * Official date pipe dropped support for abbreviations names from Angular V5
 */
@Pipe({
  name: 'localizedDate'
})
export class LocalizedDatePipe implements PipeTransform {
  private formatTimePipe: FormatTimePipe;

  constructor(
    translate: TranslateService,
    translateConfig: TranslateConfigService
  ) {
    this.formatTimePipe = new FormatTimePipe(translate, translateConfig);
  }

  transform({
              startDate,
              endDate,
              timezone
            }: {
    startDate?: Date;
    endDate?: Date;
    timezone: string
  }, dateType: DateType, lang: Language, format: FormatType = FormatType.TIME_ONLY): string {
    const date = dateType === DateType.START ? startDate : endDate;

    if (!date) {
      return null;
    }

    return this.formatTimePipe.transform(DateUtils.considerInTimeZone(date, timezone), format).replace(' ', '&nbsp;');
  }
}
