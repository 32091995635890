import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {ForecastChartComponent} from '@components/spot-day/forecast-chart/forecast-chart.component';
import {PipesModule} from '@pipes/pipes.module';
import {FormatTimePipe} from '@pipes/format-time.pipe';
import {EntityModule} from '@components/entity/entity.module';
import {NgxEchartsModule} from 'ngx-echarts';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [ForecastChartComponent],
  imports: [
    NgxEchartsModule,
    IonicModule,
    EntityModule,
    RouterModule,
    PipesModule,
    CommonModule
  ],
  exports: [ForecastChartComponent],
  entryComponents: [],
  providers: [FormatTimePipe]
})
export class SpotDayModule {}
