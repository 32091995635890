import 'meteor-client';
import {APP_INITIALIZER, EventEmitter, Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Queries} from '@api/queries';
import {MeteorAuthService} from '@services/meteor/meteor-auth.service';
import {IAuthService} from '@services/i-auth.service';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {MissingTranslationHandler, TranslateCompiler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateMessageFormatDebugCompiler} from 'ngx-translate-messageformat-compiler';
import {PipesModule} from '@pipes/pipes.module';
import {CalendarModule, DateAdapter} from 'angular-calendar';
import {adapterFactory} from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment-timezone';
import {
  HttpLoaderFactory,
  initTranslateConfigServiceFactory,
  TranslateConfigService
} from '@services/translate-config.service';
import {TextAvatarModule} from '@components/text-avatar';
import {MeteorCachedRidersService} from '@services/meteor/meteor-cached-riders.service';
import {ICachedRidersService} from '@services/i-cached-riders.service';
import {ICachedSpotsService} from '@services/i-cached-spots.service';
import {MeteorCachedSpotsService} from '@services/meteor/meteor-cached-spots.service';
import {ICachedTricksService} from '@services/i-cached-tricks.service';
import {MeteorCachedTricksService} from '@services/meteor/meteor-cached-tricks.service';
import {TabsPageModule} from '@pages/tabs/tabs.module';
import {of} from 'rxjs';
import {repeat} from 'rxjs/operators';
import {ImagekitioAngularModule} from 'imagekitio-angular';
import {Commands} from '@services/meteor/commands';
import {LoginComponent} from '@components/login/login.component';
import {LoginModule} from '@components/login/login.module';
import {ICachedBrandsService} from '@services/i-cached-brands.service';
import {MeteorCachedBrandsService} from '@services/meteor/meteor-cached-brands.service.ts';
import {NgIconsModule} from '@ng-icons/core';
import {IonicStorageModule} from '@ionic/storage-angular';
import {initLocalStorageServiceFactory, LocalStorageService} from '@services/local-storage.service';
import {createCustomElement} from '@angular/elements';
import {SpotLaunchPopupComponent} from '@components/spot/spot-map/spot-launch-popup/spot-launch-popup.component';
import {mapDictionary} from '@core/utils';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {SpotLaunchModule} from '@components/spot-launch/spot-launch.module';
import {MeteorCachedSpotDaysService} from '@services/meteor/meteor-cached-spot-days.service';
import {ICachedSpotDaysService} from '@services/i-cached-spot-days.service';
import {NgxPopperModule} from 'ngx-popper';
import {MissingTranslationHelper} from "@pipes/missing-translation-helper";
import {MeteorCachedSpotLaunchesService} from "@services/meteor/meteor-cached-spot-launches.service";
import {ICachedSpotLaunchesService} from "@services/i-cached-spot-launches.service";
import {icons} from "./icons";
import { AdvancedRouter, IAdvancedRouter } from './routing-utils';
import { ImageKitService } from '@services/imagekit.service';

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

const emptyTranslation = ' ';

// Module
const fakeTranslate = {
  instant: () => emptyTranslation,
  use: () => {
  },
  setDefaultLang: () => {
  },
  get: () => of(emptyTranslation).pipe(repeat()),
  onTranslationChange: new EventEmitter(),
  onLangChange: new EventEmitter(),
  onDefaultLangChange: new EventEmitter(),
  getBrowserLang: () => 'en'
};

const iconsToLoad: Record<string, string> = mapDictionary(
  icons,
  (key, value) => ({
    key: key
      .replace(/-([a-z])/g, (match, x) => x.toUpperCase())
      .replace(/^([a-z])/, x => x.toUpperCase())
    , value
  }));


@NgModule({
  declarations: [AppComponent, SpotLaunchPopupComponent],
  entryComponents: [
    LoginComponent,
    SpotLaunchPopupComponent
  ],
  imports: [
    LoginModule,
    BrowserAnimationsModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    NgxPopperModule.forRoot({}),
    TranslateModule.forRoot({
      compiler: { provide: TranslateCompiler, useClass: TranslateMessageFormatDebugCompiler },
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    PipesModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
    TextAvatarModule,
    TabsPageModule,
    ImagekitioAngularModule.forRoot({
      publicKey: 'public_OBmmCQvIyJN0boOm9erlu0FAgLs=', // or environment.publicKey
      urlEndpoint: 'https://ik.imagekit.io/zef', // or environment.urlEndpoint
      authenticationEndpoint: 'http://www.yourserver.com/auth' // or environment.authenticationEndpoint
    }),
    NgIconsModule.withIcons(iconsToLoad),
    IonicStorageModule.forRoot(),
    SpotLaunchModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Queries,
    Commands,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: IAuthService, useClass: MeteorAuthService },
    { provide: ICachedRidersService, useClass: MeteorCachedRidersService },
    { provide: ICachedSpotsService, useClass: MeteorCachedSpotsService },
    { provide: ICachedSpotDaysService, useClass: MeteorCachedSpotDaysService },
    { provide: ICachedSpotLaunchesService, useClass: MeteorCachedSpotLaunchesService },
    { provide: ICachedTricksService, useClass: MeteorCachedTricksService },
    { provide: ICachedBrandsService, useClass: MeteorCachedBrandsService },
    { provide: IAdvancedRouter, useClass: AdvancedRouter },
    {
      provide: APP_INITIALIZER,
      useFactory: initLocalStorageServiceFactory,
      deps: [LocalStorageService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initTranslateConfigServiceFactory,
      deps: [TranslateConfigService],
      multi: true
    },
    TranslateConfigService,
    ImageKitService,
    Geolocation,
    //{ provide: TranslateService, useValue: fakeTranslate }
    // { provide: MESSAGE_FORMAT_CONFIG, useValue: { locales: Languages } }
    {
      provide: MissingTranslationHandler,
      useClass: MissingTranslationHelper
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    const SpotLaunchPopupElement = createCustomElement(SpotLaunchPopupComponent, { injector });
    // Register the custom element with the browser.
    customElements.define('zef-spot-launch-popup', SpotLaunchPopupElement);
  }
}
