import {Component, Input, OnInit} from '@angular/core';
import {SpotLaunch} from '@model/spot-launch';
import {getDefaultAvatar} from '@model/imagekit-utils';
import {EntityType} from '@model/entity-type';
import {IconService} from '@services/icon.service';
import {TranslateConfigService} from '@services/translate-config.service';

@Component({
  selector: 'zef-spot-launch-popup',
  templateUrl: './spot-launch-popup.component.html',
  styleUrls: ['./spot-launch-popup.component.scss']
})
export class SpotLaunchPopupComponent implements OnInit {
  @Input() launch: SpotLaunch;

  defaultAvatar = getDefaultAvatar(EntityType.spotLaunches);
  aliasString: string;

  constructor(
    public icon: IconService,
    public translateConfig: TranslateConfigService
  ) {
  }

  ngOnInit() {
    this.aliasString = this.launch.aliases?.length > 0 ? ` (${this.launch.aliases.join(', ')})` : '';
  }

}
